import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  clearCompletionType,
  getCompletionType,
} from "../../utils/storage.utils";
import { isEmpty } from "../../utils/validation.utils";

import { PERMISSION_SUBSCRIBER_PAGES } from "../../constants/permission-subscriber.constant";

import {
  getCurrentMarketId,
  getIsMarketAdministrator,
  getMasterPermitPages,
} from "../../store/global/global.selector";
import { setOverlayPosition } from "../../store/component/component.action";
import {
  getOverlayPosition,
  getScreenWidth,
} from "../../store/component/component.selector";
import {
  fetchRegisterFaiStatusStart,
  registerFaiStart,
} from "../../store/integration/integration.action";
import {
  getFaiRegisterStatus,
  getFetchRegisterFaiStatusLoading,
  getIsFetchRegisterFaiStatusHitted,
  getRegisterFaiLoading,
  getRegisterFaiSuccess,
} from "../../store/integration/integration.selector";

import Responsive from "../../components/responsive/responsive.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import ApplicationCompletionOverlayHeader from "../application-completion-overlay-header/application-completion-overlay-header.widget";
import ApplicationCompletionOverlayProgress from "../application-completion-overlay-progress/application-completion-overlay-progress.widget";
import ApplicationCompletionOverlayList from "../application-completion-overlay-list/application-completion-overlay-list.widget";

import {
  ApplicationCompletionOverlayCard,
  ApplicationCompletionOverlayContainer,
} from "./application-completion-overlay.style";
import { parseToBoolean } from "../../utils/parser.utils";
import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";

const defaultPosition = { x: 0, y: 0 };

const ApplicationCompletionOverlay = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isMarketAdministrator = useSelector(getIsMarketAdministrator);
  const masterPermitPages = useSelector(getMasterPermitPages);
  const currenMarketId = useSelector(getCurrentMarketId);

  const overlayPosition = useSelector(getOverlayPosition);
  const screenWidth = useSelector(getScreenWidth);

  const isFetchRegisterFaiStatusHitted = useSelector(
    getIsFetchRegisterFaiStatusHitted
  );
  const fetchRegisterFaiStatusLoading = useSelector(
    getFetchRegisterFaiStatusLoading
  );
  const faiRegisterStatus = useSelector(getFaiRegisterStatus);

  const registerFaiSuccess = useSelector(getRegisterFaiSuccess);
  const registerFaiLoading = useSelector(getRegisterFaiLoading);

  const overlayAreaRef = useRef();
  const overlayCardRef = useRef();

  const [isDragging, setIsDragging] = useState(false);

  const [completions, setCompletions] = useState([]);
  const [progressCount, setProgressCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);

  const [isHasCompletion, setIsHasCompletion] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(defaultPosition);
  const [initialDragging, setInitialDragging] = useState(defaultPosition);
  const [initialPosition, setInitialPosition] = useState(defaultPosition);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsDragging(true);
    setInitialDragging({ x: event.clientX, y: event.clientY });
    setInitialPosition(currentPosition);
  };
  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const area = overlayAreaRef.current.getBoundingClientRect();
    const card = overlayCardRef.current.getBoundingClientRect();

    const posX = initialPosition.x + (event.clientX - initialDragging.x);
    const posY = initialPosition.y + (event.clientY - initialDragging.y);

    const clampedX = Math.max(0, Math.min(area.width - card.width, posX));
    const clampedY = Math.max(0, Math.min(area.height - card.height, posY));

    dispatch(setOverlayPosition({ x: clampedX, y: clampedY }));
  };
  const handleMouseUp = () => setIsDragging(false);

  const handleStorageEvent = () => {
    const isHasPermission = masterPermitPages.includes(
      PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION
    );

    if (isMarketAdministrator || isHasPermission) {
      setIsHasCompletion(!isEmpty(getCompletionType()));
    }
  };

  const handleConnectWithFai = () =>
    dispatch(registerFaiStart({ market_id: currenMarketId }));

  useEffect(() => {
    if (overlayAreaRef.current && overlayCardRef.current) {
      const area = overlayAreaRef.current.getBoundingClientRect();
      const card = overlayCardRef.current.getBoundingClientRect();

      let initialX = overlayPosition?.x;
      let initialY = overlayPosition?.y;

      const isFullHeight = Math.round(area.height) === Math.round(card.height);

      initialX ??= area.width - card.width - 32; // 32px = 2rem
      initialY ??= isFullHeight ? 0 : 32;

      dispatch(
        setOverlayPosition({
          x: Math.max(0, Math.min(area.width - card.width, initialX)),
          y: Math.max(0, Math.min(area.height - card.height, initialY)),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlayAreaRef, overlayCardRef, screenWidth, isHasCompletion]);

  useEffect(() => {
    setCurrentPosition(overlayPosition ?? defaultPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlayPosition]);

  useEffect(() => {
    handleStorageEvent();
    window.addEventListener("localStorage", handleStorageEvent, false);

    return () => window.removeEventListener("localStorage", handleStorageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMarketAdministrator, masterPermitPages]);

  const handleRegisterStatus = () => {
    if (!isHasCompletion) return;
    dispatch(fetchRegisterFaiStatusStart({ market_id: currenMarketId }));
  };

  useEffect(() => {
    const statusTimeout = setTimeout(handleRegisterStatus, 500);
    return () => clearTimeout(statusTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHasCompletion]);

  useEffect(() => {
    const completions = !isEmpty(faiRegisterStatus)
      ? Object.values(faiRegisterStatus)
      : [];

    let progressCount = 0;
    let completeCount = 0;

    for (const completion of completions) {
      const { is_configured, is_required } = completion ?? {};

      if (!parseToBoolean(is_required)) continue;
      if (parseToBoolean(is_configured)) progressCount++;
      completeCount++;
    }

    setCompletions(completions);
    setProgressCount(progressCount);
    setCompleteCount(completeCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faiRegisterStatus]);

  useEffect(() => {
    if (registerFaiSuccess !== null) clearCompletionType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerFaiSuccess]);

  if (!isHasCompletion) return null;

  return (
    <ApplicationCompletionOverlayContainer
      ref={overlayAreaRef}
      onMouseLeave={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <ApplicationCompletionOverlayCard
        ref={overlayCardRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${currentPosition.x}px, ${currentPosition.y}px)`,
        }}
      >
        <Responsive>
          <Responsive.Row rowGap="0.35rem">
            <Responsive.Col>
              <ApplicationCompletionOverlayHeader />
            </Responsive.Col>
            <Responsive.Col></Responsive.Col>
            <Responsive.Col>
              <ApplicationCompletionOverlayProgress
                progressCount={progressCount}
                completeCount={completeCount}
                isLoading={
                  !isFetchRegisterFaiStatusHitted &&
                  fetchRegisterFaiStatusLoading
                }
              />
            </Responsive.Col>
            <Responsive.Col></Responsive.Col>
            <Responsive.Col></Responsive.Col>
            <Responsive.Col>
              <ApplicationCompletionOverlayList
                completions={completions}
                isLoading={
                  !isFetchRegisterFaiStatusHitted &&
                  fetchRegisterFaiStatusLoading
                }
              />
            </Responsive.Col>
            <Responsive.Col></Responsive.Col>
            <Responsive.Col></Responsive.Col>
            <Responsive.Col></Responsive.Col>
            <Responsive.Col>
              {!isFetchRegisterFaiStatusHitted &&
              fetchRegisterFaiStatusLoading ? (
                <Shimmer
                  radius={SHIMMER_RADIUS.PX16}
                  width="186px"
                  minWidth="186px"
                  maxWidth="186px"
                  height="48px"
                />
              ) : (
                <Button
                  type="button"
                  buttonColor={
                    progressCount < completeCount || completeCount === 0
                      ? BUTTON_COLORS.LIGHT
                      : BUTTON_COLORS.LIGHT_SUCCESS
                  }
                  buttonSize={BUTTON_SIZES.NORMAL}
                  buttonType={BUTTON_TYPES.TEXT}
                  onClick={handleConnectWithFai}
                  isDisabled={
                    progressCount < completeCount || completeCount === 0
                  }
                  isLoading={
                    registerFaiLoading || fetchRegisterFaiStatusLoading
                  }
                >
                  {t("Connect With Fai")}
                </Button>
              )}
            </Responsive.Col>
          </Responsive.Row>
        </Responsive>
      </ApplicationCompletionOverlayCard>
    </ApplicationCompletionOverlayContainer>
  );
};

export default ApplicationCompletionOverlay;
