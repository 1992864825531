import SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES from "./select-permission-subscriber.type";

const SELECT_PERMISSION_SUBSCRIBER_INITIAL_STATE = {
  permissions: {},
  searchPermissions: {},

  isPermissionsHasMore: true,
  isSearchPermissionsHasMore: true,

  fetchPermissionsParams: {},
  fetchPermissionsLoading: false,
  fetchPermissionsSuccess: null,
  fetchPermissionsFailed: null,

  searchPermissionsParams: {},
  searchPermissionsLoading: false,
  searchPermissionsSuccess: null,
  searchPermissionsFailed: null,

  appendPermissionsParams: {},
  appendPermissionsLoading: false,
  appendPermissionsSuccess: null,
  appendPermissionsFailed: null,

  isFetchPermissionsHitted: false,
  isSearchPermissionsHitted: false,
  isAppendPermissionsHitted: false,
};

export const selectPermissionSubscriberReducer = (
  state = SELECT_PERMISSION_SUBSCRIBER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_PERMISSIONS:
      return { ...state, permissions: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS:
      return { ...state, searchPermissions: payload };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_PERMISSIONS_HAS_MORE:
      return { ...state, isPermissionsHasMore: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_PERMISSIONS_HAS_MORE:
      return { ...state, isSearchPermissionsHasMore: payload };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_PARAMS:
      return { ...state, fetchPermissionsParams: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_LOADING:
      return { ...state, fetchPermissionsLoading: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SUCCESS:
      return { ...state, fetchPermissionsSuccess: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FAILED:
      return { ...state, fetchPermissionsFailed: payload };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_PARAMS:
      return { ...state, searchPermissionsParams: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_LOADING:
      return { ...state, searchPermissionsLoading: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_SUCCESS:
      return { ...state, searchPermissionsSuccess: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_FAILED:
      return { ...state, searchPermissionsFailed: payload };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_PARAMS:
      return { ...state, appendPermissionsParams: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_LOADING:
      return { ...state, appendPermissionsLoading: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_SUCCESS:
      return { ...state, appendPermissionsSuccess: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_FAILED:
      return { ...state, appendPermissionsFailed: payload };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_PERMISSIONS_HITTED:
      return { ...state, isFetchPermissionsHitted: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_PERMISSIONS_HITTED:
      return { ...state, isSearchPermissionsHitted: payload };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_APPEND_PERMISSIONS_HITTED:
      return { ...state, isAppendPermissionsHitted: payload };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_PERMISSIONS:
      return {
        ...state,
        permissions: { ...state.permissions, ...payload },
      };
    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_SEARCH_PERMISSIONS:
      return {
        ...state,
        searchPermissions: { ...state.searchPermissions, ...payload },
      };

    case SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.RESET_SELECT_PERMISSION_SUBSCRIBER_REDUCER:
      return SELECT_PERMISSION_SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
