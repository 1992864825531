import apiService from "./api";

export const getOrderProducts = async (orderId, params) =>
  (await apiService.innerGet({ urlPath: `/order/${orderId}`, params })).data;
export const getOrderProduct = async (orderId, productId) =>
  (
    await apiService.innerGet({
      urlPath: `/order/${orderId}/product/${productId}`,
    })
  ).data;
export const updateOrderProduct = async (orderId, productId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/order/${orderId}/product/${productId}`,
      request,
    })
  ).data;
