import apiService from "./api";

export const getPaymentMethodSettings = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/payment-methods/settings",
      params,
    })
  ).data;
export const getPaymentMethodSetting = async (settingKey) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/payment-methods/settings/${settingKey}`,
    })
  ).data;
export const createPaymentMethodSetting = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/payment-methods/settings",
      request,
    })
  ).data;
export const updatePaymentMethodSetting = async (settingKey, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/payment-methods/settings/${settingKey}`,
      request,
    })
  ).data;
export const deletePaymentMethodSetting = async (settingKey) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/payment-methods/settings/${settingKey}`,
    })
  ).data;
