import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setAddMarketUserCustomerFailed,
  setAddMarketUserCustomerSuccess,
  setCreateUserCustomerFailed,
  setCreateUserCustomerSuccess,
  setFetchSearchUserCustomersFailed,
  setFetchSearchUserCustomersSuccess,
  setFetchUserCustomerFailed,
  setFetchUserCustomersFailed,
  setFetchUserCustomersSuccess,
  setFetchUserCustomerSuccess,
  setRemoveMarketUserCustomerFailed,
  setRemoveMarketUserCustomerSuccess,
  setUpdateUserCustomerFailed,
  setUpdateUserCustomerSuccess,
} from "../../store/user-customer/user-customer.action";
import {
  getAddMarketUserCustomerFailed,
  getAddMarketUserCustomerSuccess,
  getCreateUserCustomerFailed,
  getCreateUserCustomerSuccess,
  getFetchSearchUserCustomersFailed,
  getFetchSearchUserCustomersSuccess,
  getFetchUserCustomerFailed,
  getFetchUserCustomersFailed,
  getFetchUserCustomersSuccess,
  getFetchUserCustomerSuccess,
  getRemoveMarketUserCustomerFailed,
  getRemoveMarketUserCustomerSuccess,
  getUpdateUserCustomerFailed,
  getUpdateUserCustomerSuccess,
} from "../../store/user-customer/user-customer.selector";

const UserCustomerPopup = () => {
  const dispatch = useDispatch();

  const fetchUserCustomersSuccess = useSelector(getFetchUserCustomersSuccess);
  const fetchSearchUserCustomersSuccess = useSelector(
    getFetchSearchUserCustomersSuccess
  );
  const fetchUserCustomerSuccess = useSelector(getFetchUserCustomerSuccess);
  const createUserCustomerSuccess = useSelector(getCreateUserCustomerSuccess);
  const updateUserCustomerSuccess = useSelector(getUpdateUserCustomerSuccess);
  const addMarketUserCustomerSuccess = useSelector(
    getAddMarketUserCustomerSuccess
  );
  const removeMarketUserCustomerSuccess = useSelector(
    getRemoveMarketUserCustomerSuccess
  );

  const fetchUserCustomersFailed = useSelector(getFetchUserCustomersFailed);
  const fetchSearchUserCustomersFailed = useSelector(
    getFetchSearchUserCustomersFailed
  );
  const fetchUserCustomerFailed = useSelector(getFetchUserCustomerFailed);
  const createUserCustomerFailed = useSelector(getCreateUserCustomerFailed);
  const updateUserCustomerFailed = useSelector(getUpdateUserCustomerFailed);
  const addMarketUserCustomerFailed = useSelector(
    getAddMarketUserCustomerFailed
  );
  const removeMarketUserCustomerFailed = useSelector(
    getRemoveMarketUserCustomerFailed
  );

  useEffect(() => {
    if (
      fetchUserCustomersSuccess !== null ||
      fetchSearchUserCustomersSuccess !== null ||
      fetchUserCustomerSuccess !== null ||
      createUserCustomerSuccess !== null ||
      updateUserCustomerSuccess !== null ||
      addMarketUserCustomerSuccess !== null ||
      removeMarketUserCustomerSuccess !== null
    ) {
      if (
        createUserCustomerSuccess !== null ||
        updateUserCustomerSuccess !== null ||
        addMarketUserCustomerSuccess !== null ||
        removeMarketUserCustomerSuccess !== null
      ) {
        showSuccessMessage(
          createUserCustomerSuccess ??
            updateUserCustomerSuccess ??
            addMarketUserCustomerSuccess ??
            removeMarketUserCustomerSuccess
        );
      }

      if (
        addMarketUserCustomerSuccess !== null ||
        removeMarketUserCustomerSuccess !== null
      ) {
        dispatch(setIsModalOpen(false));
      }

      if (fetchUserCustomersSuccess !== null)
        dispatch(setFetchUserCustomersSuccess(null));
      if (fetchSearchUserCustomersSuccess !== null)
        dispatch(setFetchSearchUserCustomersSuccess(null));
      if (fetchUserCustomerSuccess !== null)
        dispatch(setFetchUserCustomerSuccess(null));
      if (createUserCustomerSuccess !== null)
        dispatch(setCreateUserCustomerSuccess(null));
      if (updateUserCustomerSuccess !== null)
        dispatch(setUpdateUserCustomerSuccess(null));
      if (addMarketUserCustomerSuccess !== null)
        dispatch(setAddMarketUserCustomerSuccess(null));
      if (removeMarketUserCustomerSuccess !== null)
        dispatch(setRemoveMarketUserCustomerSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUserCustomersSuccess,
    fetchSearchUserCustomersSuccess,
    fetchUserCustomerSuccess,
    createUserCustomerSuccess,
    updateUserCustomerSuccess,
    addMarketUserCustomerSuccess,
    removeMarketUserCustomerSuccess,
  ]);

  useEffect(() => {
    if (
      fetchUserCustomersFailed !== null ||
      fetchSearchUserCustomersFailed !== null ||
      fetchUserCustomerFailed !== null ||
      createUserCustomerFailed !== null ||
      updateUserCustomerFailed !== null ||
      addMarketUserCustomerFailed !== null ||
      removeMarketUserCustomerFailed !== null
    ) {
      showErrorMessage(
        fetchUserCustomersFailed ??
          fetchSearchUserCustomersFailed ??
          fetchUserCustomerFailed ??
          createUserCustomerFailed ??
          updateUserCustomerFailed ??
          addMarketUserCustomerFailed ??
          removeMarketUserCustomerFailed
      );

      if (fetchUserCustomersFailed !== null)
        dispatch(setFetchUserCustomersFailed(null));
      if (fetchSearchUserCustomersFailed !== null)
        dispatch(setFetchSearchUserCustomersFailed(null));
      if (fetchUserCustomerFailed !== null)
        dispatch(setFetchUserCustomerFailed(null));
      if (createUserCustomerFailed !== null)
        dispatch(setCreateUserCustomerFailed(null));
      if (updateUserCustomerFailed !== null)
        dispatch(setUpdateUserCustomerFailed(null));
      if (addMarketUserCustomerFailed !== null)
        dispatch(setAddMarketUserCustomerFailed(null));
      if (removeMarketUserCustomerFailed !== null)
        dispatch(setRemoveMarketUserCustomerFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUserCustomersFailed,
    fetchSearchUserCustomersFailed,
    fetchUserCustomerFailed,
    createUserCustomerFailed,
    updateUserCustomerFailed,
    addMarketUserCustomerFailed,
    removeMarketUserCustomerFailed,
  ]);

  return <></>;
};

export default UserCustomerPopup;
