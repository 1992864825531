import { isEmpty } from "./validation.utils";

import { INTEGRATION_KEYS } from "../constants/integration.constant";

const defaultParams = { key: null, marketId: null, branchId: null };
const defaultObject = { key: null, market_id: null, branch_id: null };

const isObjectMatch = (params = defaultParams, object = defaultObject) => {
  const { key, marketId, branchId } = params ?? {};
  const { market_id, branch_id } = object ?? {};

  if (!isEmpty(key) && key !== object?.key) {
    return false;
  }
  if (!isEmpty(marketId) && marketId !== market_id) {
    return false;
  }
  if (!isEmpty(branchId) && branchId !== branch_id) {
    return false;
  }

  return true;
};

const getSideFormat = (integrationKey) => {
  const alternativeFormat = { [integrationKey]: null };

  switch (integrationKey) {
    case INTEGRATION_KEYS.FAI_TAX_NUMBER:
    case INTEGRATION_KEYS.ZATCA_VAT:
      alternativeFormat[INTEGRATION_KEYS.FAI_TAX_NUMBER] = null;
      alternativeFormat[INTEGRATION_KEYS.ZATCA_VAT] = null;
      break;
    case INTEGRATION_KEYS.FAI_LOCATION_STREET:
    case INTEGRATION_KEYS.ZATCA_STREET:
      alternativeFormat[INTEGRATION_KEYS.FAI_LOCATION_STREET] = null;
      alternativeFormat[INTEGRATION_KEYS.ZATCA_STREET] = null;
      break;
    case INTEGRATION_KEYS.FAI_LOCATION_BUILDING:
    case INTEGRATION_KEYS.ZATCA_BUILDING_NUMBER:
      alternativeFormat[INTEGRATION_KEYS.FAI_LOCATION_BUILDING] = null;
      alternativeFormat[INTEGRATION_KEYS.ZATCA_BUILDING_NUMBER] = null;
      break;
    case INTEGRATION_KEYS.FAI_LOCATION_CITY:
    case INTEGRATION_KEYS.ZATCA_CITY:
      alternativeFormat[INTEGRATION_KEYS.FAI_LOCATION_CITY] = null;
      alternativeFormat[INTEGRATION_KEYS.ZATCA_CITY] = null;
      break;
    case INTEGRATION_KEYS.FAI_LOCATION_POSTAL:
    case INTEGRATION_KEYS.ZATCA_POSTAL_CODE:
      alternativeFormat[INTEGRATION_KEYS.FAI_LOCATION_POSTAL] = null;
      alternativeFormat[INTEGRATION_KEYS.ZATCA_POSTAL_CODE] = null;
      break;
    case INTEGRATION_KEYS.FAI_LOCATION_DISTRICT:
    case INTEGRATION_KEYS.ZATCA_DISTRICT:
      alternativeFormat[INTEGRATION_KEYS.FAI_LOCATION_DISTRICT] = null;
      alternativeFormat[INTEGRATION_KEYS.ZATCA_DISTRICT] = null;
      break;
    default:
      break;
  }

  return alternativeFormat;
};

export const findIntegration = (integrations = [], params = defaultParams) =>
  integrations?.find((integration) => isObjectMatch(params, integration));

export const findAlternative = (integrations = [], params = defaultParams) => {
  let mainValue = null;
  const sideValues = getSideFormat(params.key);

  for (const integration of integrations ?? []) {
    const sideKey = integration.key;

    if (isObjectMatch(params, integration)) {
      mainValue = integration;
      break;
    }
    if (
      sideKey in sideValues &&
      isObjectMatch({ ...params, key: sideKey }, integration)
    ) {
      sideValues[sideKey] = integration;
    }
  }

  return !isEmpty(mainValue)
    ? mainValue
    : Object.values(sideValues).find((sideValue) => !isEmpty(sideValue));
};
