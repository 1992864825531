import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setFetchCurrentBranchFailed,
  setFetchCurrentBranchSuccess,
  setFetchCurrentMarketFailed,
  setFetchCurrentMarketSuccess,
  setFetchCurrentMerchantFailed,
  setFetchCurrentMerchantSuccess,
  setFetchCurrentSchedulesFailed,
  setFetchCurrentSchedulesSuccess,
  setFetchGlobalFeatureSubscriptionsFailed,
  setFetchGlobalFeatureSubscriptionsSuccess,
  setFetchGlobalSubscriptionsFailed,
  setFetchGlobalSubscriptionsSuccess,
  setFetchInitializeFailed,
  setFetchInitializeSuccess,
  setFetchMasterPermissionFailed,
  setFetchMasterPermissionSuccess,
  setFetchSecondPermissionFailed,
  setFetchSecondPermissionSuccess,
  setFetchTodayAttendanceFailed,
  setFetchTodayAttendanceSuccess,
} from "../../store/global/global.action";
import {
  getFetchCurrentBranchFailed,
  getFetchCurrentBranchSuccess,
  getFetchCurrentMarketFailed,
  getFetchCurrentMarketSuccess,
  getFetchCurrentMerchantFailed,
  getFetchCurrentMerchantSuccess,
  getFetchCurrentSchedulesFailed,
  getFetchCurrentSchedulesSuccess,
  getFetchGlobalFeatureSubscriptionsFailed,
  getFetchGlobalFeatureSubscriptionsSuccess,
  getFetchGlobalSubscriptionsFailed,
  getFetchGlobalSubscriptionsSuccess,
  getFetchInitializeFailed,
  getFetchInitializeSuccess,
  getFetchMasterPermissionFailed,
  getFetchMasterPermissionSuccess,
  getFetchSecondPermissionFailed,
  getFetchSecondPermissionSuccess,
  getFetchTodayAttendanceFailed,
  getFetchTodayAttendanceSuccess,
} from "../../store/global/global.selector";

const GlobalStatePopup = () => {
  const dispatch = useDispatch();

  const fetchInitializeSuccess = useSelector(getFetchInitializeSuccess);
  const fetchCurrentMarketSuccess = useSelector(getFetchCurrentMarketSuccess);
  const fetchCurrentBranchSuccess = useSelector(getFetchCurrentBranchSuccess);
  const fetchCurrentMerchantSuccess = useSelector(
    getFetchCurrentMerchantSuccess
  );
  const fetchMasterPermissionSuccess = useSelector(
    getFetchMasterPermissionSuccess
  );
  const fetchSecondPermissionSuccess = useSelector(
    getFetchSecondPermissionSuccess
  );
  const fetchCurrentSchedulesSuccess = useSelector(
    getFetchCurrentSchedulesSuccess
  );
  const fetchTodayAttendanceSuccess = useSelector(
    getFetchTodayAttendanceSuccess
  );
  const fetchGlobalSubscriptionsSuccess = useSelector(
    getFetchGlobalSubscriptionsSuccess
  );
  const fetchGlobalFeatureSubscriptionsSuccess = useSelector(
    getFetchGlobalFeatureSubscriptionsSuccess
  );

  const fetchInitializeFailed = useSelector(getFetchInitializeFailed);
  const fetchMasterPermissionFailed = useSelector(
    getFetchMasterPermissionFailed
  );
  const fetchSecondPermissionFailed = useSelector(
    getFetchSecondPermissionFailed
  );
  const fetchCurrentMarketFailed = useSelector(getFetchCurrentMarketFailed);
  const fetchCurrentBranchFailed = useSelector(getFetchCurrentBranchFailed);
  const fetchCurrentMerchantFailed = useSelector(getFetchCurrentMerchantFailed);
  const fetchCurrentSchedulesFailed = useSelector(
    getFetchCurrentSchedulesFailed
  );
  const fetchTodayAttendanceFailed = useSelector(getFetchTodayAttendanceFailed);
  const fetchGlobalSubscriptionsFailed = useSelector(
    getFetchGlobalSubscriptionsFailed
  );
  const fetchGlobalFeatureSubscriptionsFailed = useSelector(
    getFetchGlobalFeatureSubscriptionsFailed
  );

  useEffect(() => {
    if (
      fetchInitializeSuccess !== null ||
      fetchMasterPermissionSuccess !== null ||
      fetchSecondPermissionSuccess !== null ||
      fetchCurrentMarketSuccess !== null ||
      fetchCurrentBranchSuccess !== null ||
      fetchCurrentMerchantSuccess !== null ||
      fetchCurrentSchedulesSuccess !== null ||
      fetchTodayAttendanceSuccess !== null ||
      fetchGlobalSubscriptionsSuccess !== null ||
      fetchGlobalFeatureSubscriptionsSuccess !== null
    ) {
      if (fetchInitializeSuccess !== null)
        dispatch(setFetchInitializeSuccess(null));
      if (fetchMasterPermissionSuccess !== null)
        dispatch(setFetchMasterPermissionSuccess(null));
      if (fetchSecondPermissionSuccess !== null)
        dispatch(setFetchSecondPermissionSuccess(null));
      if (fetchCurrentMarketSuccess !== null)
        dispatch(setFetchCurrentMarketSuccess(null));
      if (fetchCurrentBranchSuccess !== null)
        dispatch(setFetchCurrentBranchSuccess(null));
      if (fetchCurrentMerchantSuccess !== null)
        dispatch(setFetchCurrentMerchantSuccess(null));
      if (fetchCurrentSchedulesSuccess !== null)
        dispatch(setFetchCurrentSchedulesSuccess(null));
      if (fetchTodayAttendanceSuccess !== null)
        dispatch(setFetchTodayAttendanceSuccess(null));
      if (fetchGlobalSubscriptionsSuccess !== null)
        dispatch(setFetchGlobalSubscriptionsSuccess(null));
      if (fetchGlobalFeatureSubscriptionsSuccess !== null)
        dispatch(setFetchGlobalFeatureSubscriptionsSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchInitializeSuccess,
    fetchMasterPermissionSuccess,
    fetchSecondPermissionSuccess,
    fetchCurrentMarketSuccess,
    fetchCurrentBranchSuccess,
    fetchCurrentMerchantSuccess,
    fetchCurrentSchedulesSuccess,
    fetchTodayAttendanceSuccess,
    fetchGlobalSubscriptionsSuccess,
    fetchGlobalFeatureSubscriptionsSuccess,
  ]);

  useEffect(() => {
    if (
      fetchInitializeFailed !== null ||
      fetchMasterPermissionFailed !== null ||
      fetchSecondPermissionFailed !== null ||
      fetchCurrentMarketFailed !== null ||
      fetchCurrentBranchFailed !== null ||
      fetchCurrentMerchantFailed !== null ||
      fetchCurrentSchedulesFailed !== null ||
      fetchTodayAttendanceFailed !== null ||
      fetchGlobalSubscriptionsFailed !== null ||
      fetchGlobalFeatureSubscriptionsFailed !== null
    ) {
      if (
        fetchInitializeFailed !== null ||
        fetchMasterPermissionFailed !== null ||
        fetchSecondPermissionFailed !== null ||
        fetchCurrentMarketFailed !== null ||
        fetchCurrentBranchFailed !== null ||
        fetchCurrentMerchantFailed !== null ||
        fetchCurrentSchedulesFailed !== null ||
        fetchGlobalSubscriptionsFailed !== null ||
        fetchGlobalFeatureSubscriptionsFailed !== null
      ) {
        showErrorMessage(
          fetchInitializeFailed ??
            fetchMasterPermissionFailed ??
            fetchSecondPermissionFailed ??
            fetchCurrentMarketFailed ??
            fetchCurrentBranchFailed ??
            fetchCurrentMerchantFailed ??
            fetchCurrentSchedulesFailed ??
            fetchGlobalSubscriptionsFailed ??
            fetchGlobalFeatureSubscriptionsFailed
        );
      }

      if (fetchInitializeFailed !== null)
        dispatch(setFetchInitializeFailed(null));
      if (fetchMasterPermissionFailed !== null)
        dispatch(setFetchMasterPermissionFailed(null));
      if (fetchSecondPermissionFailed !== null)
        dispatch(setFetchSecondPermissionFailed(null));
      if (fetchCurrentMarketFailed !== null)
        dispatch(setFetchCurrentMarketFailed(null));
      if (fetchCurrentBranchFailed !== null)
        dispatch(setFetchCurrentBranchFailed(null));
      if (fetchCurrentMerchantFailed !== null)
        dispatch(setFetchCurrentMerchantFailed(null));
      if (fetchCurrentSchedulesFailed !== null)
        dispatch(setFetchCurrentSchedulesFailed(null));
      if (fetchTodayAttendanceFailed !== null)
        dispatch(setFetchTodayAttendanceFailed(null));
      if (fetchGlobalSubscriptionsFailed !== null)
        dispatch(setFetchGlobalSubscriptionsFailed(null));
      if (fetchGlobalFeatureSubscriptionsFailed !== null)
        dispatch(setFetchGlobalFeatureSubscriptionsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchInitializeFailed,
    fetchMasterPermissionFailed,
    fetchSecondPermissionFailed,
    fetchCurrentMarketFailed,
    fetchCurrentBranchFailed,
    fetchCurrentMerchantFailed,
    fetchCurrentSchedulesFailed,
    fetchTodayAttendanceFailed,
    fetchGlobalSubscriptionsFailed,
    fetchGlobalFeatureSubscriptionsFailed,
  ]);

  return <></>;
};

export default GlobalStatePopup;
