import apiService from "./api";

export const getCountries = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/public/countries", params })).data;
export const getCountry = async (countryId) =>
  (await apiService.innerGet({ urlPath: `/v2/public/countries/${countryId}` }))
    .data;
export const createCountry = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/countries", request })).data;
export const updateCountry = async (countryId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/countries/${countryId}`,
      request,
    })
  ).data;
export const deleteCountry = async (countryId) =>
  (await apiService.innerDelete({ urlPath: `/v2/countries/${countryId}` }))
    .data;
