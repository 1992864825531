import SELECT_PERMISSION_MERCHANT_ACTION_TYPES from "./select-permission-merchant.type";

const SELECT_PERMISSION_MERCHANT_INITIAL_STATE = {
  permissionMerchants: {},
  permissionSubscribers: {},
  searchPermissionMerchants: {},
  searchPermissionSubscribers: {},

  isPermissionMerchantsHasMore: true,
  isPermissionSubscribersHasMore: true,
  isSearchPermissionMerchantsHasMore: true,
  isSearchPermissionSubscribersHasMore: true,

  fetchPermissionMerchantsParams: {},
  fetchPermissionMerchantsLoading: false,
  fetchPermissionMerchantsSuccess: null,
  fetchPermissionMerchantsFailed: null,

  fetchPermissionSubscribersParams: {},
  fetchPermissionSubscribersLoading: false,
  fetchPermissionSubscribersSuccess: null,
  fetchPermissionSubscribersFailed: null,

  searchPermissionMerchantsParams: {},
  searchPermissionMerchantsLoading: false,
  searchPermissionMerchantsSuccess: null,
  searchPermissionMerchantsFailed: null,

  searchPermissionSubscribersParams: {},
  searchPermissionSubscribersLoading: false,
  searchPermissionSubscribersSuccess: null,
  searchPermissionSubscribersFailed: null,

  appendPermissionMerchantsParams: {},
  appendPermissionMerchantsLoading: false,
  appendPermissionMerchantsSuccess: null,
  appendPermissionMerchantsFailed: null,

  appendPermissionSubscribersParams: {},
  appendPermissionSubscribersLoading: false,
  appendPermissionSubscribersSuccess: null,
  appendPermissionSubscribersFailed: null,

  isFetchPermissionMerchantsHitted: false,
  isFetchPermissionSubscribersHitted: false,
  isSearchPermissionMerchantsHitted: false,
  isSearchPermissionSubscribersHitted: false,
  isAppendPermissionMerchantsHitted: false,
  isAppendPermissionSubscribersHitted: false,
};

export const selectPermissionMerchantReducer = (
  state = SELECT_PERMISSION_MERCHANT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_MERCHANTS:
      return { ...state, permissionMerchants: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS:
      return { ...state, permissionSubscribers: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS:
      return { ...state, searchPermissionMerchants: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS:
      return { ...state, searchPermissionSubscribers: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_MERCHANTS_HAS_MORE:
      return { ...state, isPermissionMerchantsHasMore: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isPermissionSubscribersHasMore: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_MERCHANTS_HAS_MORE:
      return { ...state, isSearchPermissionMerchantsHasMore: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSearchPermissionSubscribersHasMore: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_PARAMS:
      return { ...state, fetchPermissionMerchantsParams: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_LOADING:
      return { ...state, fetchPermissionMerchantsLoading: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SUCCESS:
      return { ...state, fetchPermissionMerchantsSuccess: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FAILED:
      return { ...state, fetchPermissionMerchantsFailed: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PARAMS:
      return { ...state, fetchPermissionSubscribersParams: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, fetchPermissionSubscribersLoading: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchPermissionSubscribersSuccess: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, fetchPermissionSubscribersFailed: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_PARAMS:
      return { ...state, searchPermissionMerchantsParams: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_LOADING:
      return { ...state, searchPermissionMerchantsLoading: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_SUCCESS:
      return { ...state, searchPermissionMerchantsSuccess: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_FAILED:
      return { ...state, searchPermissionMerchantsFailed: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_PARAMS:
      return { ...state, searchPermissionSubscribersParams: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, searchPermissionSubscribersLoading: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, searchPermissionSubscribersSuccess: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, searchPermissionSubscribersFailed: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_PARAMS:
      return { ...state, appendPermissionMerchantsParams: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_LOADING:
      return { ...state, appendPermissionMerchantsLoading: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_SUCCESS:
      return { ...state, appendPermissionMerchantsSuccess: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_FAILED:
      return { ...state, appendPermissionMerchantsFailed: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_PARAMS:
      return { ...state, appendPermissionSubscribersParams: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, appendPermissionSubscribersLoading: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, appendPermissionSubscribersSuccess: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, appendPermissionSubscribersFailed: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_MERCHANTS_HITTED:
      return { ...state, isFetchPermissionMerchantsHitted: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isFetchPermissionSubscribersHitted: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_MERCHANTS_HITTED:
      return { ...state, isSearchPermissionMerchantsHitted: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isSearchPermissionSubscribersHitted: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_PERMISSION_MERCHANTS_HITTED:
      return { ...state, isAppendPermissionMerchantsHitted: payload };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isAppendPermissionSubscribersHitted: payload };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_MERCHANTS:
      return {
        ...state,
        permissionMerchants: { ...state.permissionMerchants, ...payload },
      };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        permissionSubscribers: { ...state.permissionSubscribers, ...payload },
      };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SEARCH_PERMISSION_MERCHANTS:
      return {
        ...state,
        searchPermissionMerchants: {
          ...state.searchPermissionMerchants,
          ...payload,
        },
      };
    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SEARCH_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        searchPermissionSubscribers: {
          ...state.searchPermissionSubscribers,
          ...payload,
        },
      };

    case SELECT_PERMISSION_MERCHANT_ACTION_TYPES.RESET_SELECT_PERMISSION_MERCHANT_REDUCER:
      return SELECT_PERMISSION_MERCHANT_INITIAL_STATE;
    default:
      return state;
  }
};
