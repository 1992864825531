import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateProductCategorySortFailed,
  setCreateOrUpdateProductCategorySortSuccess,
} from "../../store/product-category-sort/product-category-sort.action";
import {
  getCreateOrUpdateProductCategorySortFailed,
  getCreateOrUpdateProductCategorySortSuccess,
} from "../../store/product-category-sort/product-category-sort.selector";

const ProductCategorySortPopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateProductCategorySortSuccess = useSelector(getCreateOrUpdateProductCategorySortSuccess);
  const createOrUpdateProductCategorySortFailed = useSelector(getCreateOrUpdateProductCategorySortFailed);

  useEffect(() => {
    if (createOrUpdateProductCategorySortSuccess !== null) {
      showSuccessMessage(createOrUpdateProductCategorySortSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setCreateOrUpdateProductCategorySortSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateProductCategorySortSuccess]);

  useEffect(() => {
    if (createOrUpdateProductCategorySortFailed !== null) {
      showErrorMessage(createOrUpdateProductCategorySortFailed);
      dispatch(setCreateOrUpdateProductCategorySortFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateProductCategorySortFailed]);

  return <></>;
};

export default ProductCategorySortPopup;
