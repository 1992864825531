import styled from "styled-components";

export const ApplicationCompletionOverlayHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
`;

export const ApplicationCompletionOverlayHeaderInfo = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ApplicationCompletionOverlayHeaderIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 4.25rem;
  min-width: 4.25rem;
  max-width: 4.25rem;
  height: 4.25rem;
  min-height: 4.25rem;
  max-height: 4.25rem;

  border: 1.5px solid #e9e9ee;
  border-radius: 50%;
  background-color: #f2f2f4;
  margin-bottom: 0.75rem;

  svg {
    width: 2rem;
    min-width: 2rem;
    max-width: 2rem;
    height: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    stroke-width: 2.5;

    path,
    polyline,
    line,
    circle {
      stroke: #0e072f;
    }
  }
`;

export const ApplicationCompletionOverlayHeaderTitle = styled.p`
  font-size: 1.15rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin-bottom: 0.35rem;
`;

export const ApplicationCompletionOverlayHeaderDescription = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const ApplicationCompletionOverlayHeaderClose = styled.button.attrs({
  type: "button",
})`
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;

  border: none;
  border-radius: 0.75rem;
  background-color: #f2f2f4;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e6e6e7;
  }

  svg {
    width: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    stroke-width: 5;

    path,
    polyline,
    line,
    circle {
      stroke: #9691ac;
    }
  }
`;
