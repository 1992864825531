import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { parseToBoolean } from "../../utils/parser.utils";
import { getActiveSchedule } from "../../utils/schedule.utils";
import { getIsDontShowGettingStarted } from "../../utils/storage.utils";
import { getActivePlans } from "../../utils/subscription.utils";
import { isEmpty } from "../../utils/validation.utils";

import { USER_ROLES } from "../../constants/user.constant";

import {
  setCurrentModal,
  setIsHasGettingStarted,
  setIsModalOpen,
} from "../../store/component/component.action";
import { getIsHasGettingStarted } from "../../store/component/component.selector";
import { fetchMeStart } from "../../store/authentication/authentication.action";
import {
  getSignInSuccess,
  getSignOutSuccess,
  getSignUpSuccess,
  getUser,
} from "../../store/authentication/authentication.selector";
import {
  fetchCurrentBranchStart,
  fetchCurrentMarketStart,
  fetchCurrentMerchantStart,
  fetchCurrentSchedulesStart,
  fetchGlobalBannersStart,
  fetchGlobalBannerTargetsStart,
  fetchGlobalFeatureSubscriptionsStart,
  fetchGlobalSubscriptionsStart,
  fetchInitializeStart,
  fetchTodayAttendanceStart,
  setBranchPlans,
  setFetchCurrentSchedulesFilterBranchId,
  setFetchCurrentSchedulesFilterMarketId,
  setFetchGlobalBannersKeyBy,
  setFetchGlobalBannersPage,
  setFetchGlobalBannersPerPage,
  setFetchGlobalBannerTargetsFilterRole,
  setFetchGlobalBannerTargetsKeyBy,
  setFetchGlobalBannerTargetsPage,
  setFetchGlobalBannerTargetsPerPage,
  setFetchGlobalFeatureSubscriptionsIncludes,
  setFetchGlobalFeatureSubscriptionsPage,
  setFetchGlobalFeatureSubscriptionsPerPage,
  setFetchGlobalSubscriptionsIncludes,
  setFetchGlobalSubscriptionsKeyBy,
  setFetchGlobalSubscriptionsPage,
  setFetchGlobalSubscriptionsPerPage,
  setFetchTodayAttendanceBranchId,
  setFetchTodayAttendanceMarketId,
  setIsBranchHasAdvancedPlan,
  setIsBranchHasBasicPlan,
  setIsBranchHasPlan,
  setIsBranchHasTrialPlan,
  setIsCurrentKitchenActive,
  setIsMarketHasAdvancedPlan,
  setIsMarketHasBasicPlan,
  setIsMarketHasPlan,
  setIsMarketHasTrialPlan,
  setIsTodayHasAttendance,
  setIsTodayInSchedule,
  setMarketPlans,
  setTodaySchedule,
} from "../../store/global/global.action";
import {
  getCurrentBranch,
  getCurrentBranchId,
  getCurrentMarket,
  getCurrentMarketId,
  getCurrentMerchant,
  getCurrentMerchantId,
  getCurrentSchedules,
  getFetchInitializeLoading,
  getIsBranchHasAdvancedPlan,
  getIsBranchHasPlan,
  getIsMarketAdministrator,
  getTodayAttendance,
  getTodaySchedule,
} from "../../store/global/global.selector";
import { getMultipleCreateProductsSuccess } from "../../store/product/product.selector";
import { getCreateOrderSuccess } from "../../store/order/order.selector";
import { getCreateOrUpdateScheduleSuccess } from "../../store/schedule/schedule.selector";
import { getCreateOrUpdateAttendanceSuccess } from "../../store/attendance/attendance.selector";

import { modalName as gettingStartedModal } from "../getting-started/getting-started.widget";

const GlobalFetch = () => {
  const dispatch = useDispatch();

  const signInSuccess = useSelector(getSignInSuccess);
  const signUpSuccess = useSelector(getSignUpSuccess);
  const signOutSuccess = useSelector(getSignOutSuccess);

  const fetchInitializeLoading = useSelector(getFetchInitializeLoading);

  const createOrUpdateAttendanceSuccess = useSelector(
    getCreateOrUpdateAttendanceSuccess
  );
  const createOrUpdateScheduleSuccess = useSelector(
    getCreateOrUpdateScheduleSuccess
  );
  const multipleCreateProductsSuccess = useSelector(
    getMultipleCreateProductsSuccess
  );
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const isHasGettingStarted = useSelector(getIsHasGettingStarted);

  const isMarketAdministrator = useSelector(getIsMarketAdministrator);

  const isBranchHasAdvancedPlan = useSelector(getIsBranchHasAdvancedPlan);
  const isBranchHasPlan = useSelector(getIsBranchHasPlan);

  const todayAttendance = useSelector(getTodayAttendance);
  const todaySchedule = useSelector(getTodaySchedule);

  const currentMerchantId = useSelector(getCurrentMerchantId);
  const currentMarketId = useSelector(getCurrentMarketId);
  const currentBranchId = useSelector(getCurrentBranchId);

  const currentSchedules = useSelector(getCurrentSchedules);
  const currentMerchant = useSelector(getCurrentMerchant);
  const currentMarket = useSelector(getCurrentMarket);
  const currentBranch = useSelector(getCurrentBranch);
  const authUser = useSelector(getUser);

  const { id, role, userable } = authUser ?? {};
  const { is_has_products, is_has_orders } = userable ?? {};

  const [startSchedule, endSchedule] = todaySchedule ?? [];

  const [isHasProducts, setIsHasProducts] = useState(false);
  const [isHasOrders, setIsHasOrders] = useState(false);

  const handleInitializeFetch = () => {
    if (fetchInitializeLoading) return;
    dispatch(fetchInitializeStart());
  };
  const handleFetchMerchant = () => {
    if (isEmpty(currentMerchantId)) return;
    if (isEmpty(authUser)) return;
    if (currentMerchantId === currentMerchant?.id) return;
    if (authUser?.role !== USER_ROLES.USER_MERCHANT) return;

    dispatch(fetchCurrentMerchantStart(currentMerchantId));
  };
  const handleFetchMarket = () => {
    if (isEmpty(currentMarketId)) return;
    if (isEmpty(authUser)) return;
    if (currentMarketId === currentMarket?.id) return;

    dispatch(fetchCurrentMarketStart(currentMarketId));
  };
  const handleFetchBranch = () => {
    if (isEmpty(currentBranchId)) return;
    if (isEmpty(authUser)) return;
    if (currentBranchId === currentBranch?.id) return;

    dispatch(fetchCurrentBranchStart(currentBranchId));
  };
  const handleFetchSchedule = () => {
    if (isEmpty(currentMarketId)) return;
    if (isEmpty(currentBranchId)) return;
    if (isEmpty(authUser)) return;
    if (!isBranchHasPlan) return;

    dispatch(setFetchCurrentSchedulesFilterMarketId(currentMarketId));
    dispatch(setFetchCurrentSchedulesFilterBranchId(currentBranchId));
    dispatch(fetchCurrentSchedulesStart());
  };
  const handleFetchAttendance = () => {
    dispatch(setFetchTodayAttendanceMarketId(currentMarketId));
    dispatch(setFetchTodayAttendanceBranchId(currentBranchId));
    dispatch(fetchTodayAttendanceStart(id));
  };
  const handleFetchSubscriptions = () => {
    if (isEmpty(authUser)) return;

    dispatch(setFetchGlobalSubscriptionsKeyBy("key"));
    dispatch(setFetchGlobalSubscriptionsPage(1));
    dispatch(setFetchGlobalSubscriptionsPerPage(20));
    dispatch(
      setFetchGlobalSubscriptionsIncludes([
        "includes.feature",
        "includesCount",
        "highlights.feature",
        "highlightsCount",
      ])
    );
    dispatch(fetchGlobalSubscriptionsStart());
  };
  const handleFetchFeatureSubscriptions = () => {
    if (isEmpty(authUser)) return;

    dispatch(setFetchGlobalFeatureSubscriptionsPage(1));
    dispatch(setFetchGlobalFeatureSubscriptionsPerPage(20));
    dispatch(setFetchGlobalFeatureSubscriptionsIncludes(["features"]));
    dispatch(fetchGlobalFeatureSubscriptionsStart());
  };
  const handleFetchBanners = () => {
    if (isEmpty(authUser)) return;

    dispatch(setFetchGlobalBannersKeyBy("id"));
    dispatch(setFetchGlobalBannersPage(1));
    dispatch(setFetchGlobalBannersPerPage(999));
    dispatch(fetchGlobalBannersStart());
  };
  const handleFetchBannerTargets = () => {
    if (isEmpty(authUser)) return;

    dispatch(setFetchGlobalBannerTargetsKeyBy("page"));
    dispatch(setFetchGlobalBannerTargetsPage(1));
    dispatch(setFetchGlobalBannerTargetsPerPage(999));
    dispatch(setFetchGlobalBannerTargetsFilterRole(authUser?.role));
    dispatch(fetchGlobalBannerTargetsStart());
  };

  useEffect(() => {
    dispatch(fetchInitializeStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const initializeTimeout = setTimeout(handleInitializeFetch, 500);
    return () => clearTimeout(initializeTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInSuccess, signUpSuccess, signOutSuccess]);
  useEffect(() => {
    const merchantTimeout = setTimeout(handleFetchMerchant, 500);
    return () => clearTimeout(merchantTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMerchantId, authUser]);
  useEffect(() => {
    const marketTimeout = setTimeout(handleFetchMarket, 500);
    return () => clearTimeout(marketTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarketId, authUser]);
  useEffect(() => {
    const branchTimeout = setTimeout(handleFetchBranch, 500);
    return () => clearTimeout(branchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranchId, authUser]);
  useEffect(() => {
    const marketPlans = currentMarket?.plans ?? [];
    const activePlans = getActivePlans(marketPlans);

    const { isHasAdvancedPlan, isHasBasicPlan, isHasTrialPlan } = activePlans;

    const isMarketHasPlan = !isEmpty(marketPlans) || isMarketAdministrator;
    const isMarketHasAdvancedPlan = isHasAdvancedPlan || isMarketAdministrator;
    const isMarketHasBasicPlan = isHasBasicPlan || isMarketAdministrator;
    const isMarketHasTrialPlan = isHasTrialPlan || isMarketAdministrator;

    dispatch(setMarketPlans(marketPlans));
    dispatch(setIsMarketHasPlan(isMarketHasPlan));
    dispatch(setIsMarketHasAdvancedPlan(isMarketHasAdvancedPlan));
    dispatch(setIsMarketHasBasicPlan(isMarketHasBasicPlan));
    dispatch(setIsMarketHasTrialPlan(isMarketHasTrialPlan));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarket, isMarketAdministrator]);
  useEffect(() => {
    const branchPlans = currentBranch?.plans ?? [];
    const activePlans = getActivePlans(branchPlans);

    const { isHasAdvancedPlan, isHasBasicPlan, isHasTrialPlan } = activePlans;

    const isBranchHasPlan = !isEmpty(branchPlans) || isMarketAdministrator;
    const isBranchHasAdvancedPlan = isHasAdvancedPlan || isMarketAdministrator;
    const isBranchHasBasicPlan = isHasBasicPlan || isMarketAdministrator;
    const isBranchHasTrialPlan = isHasTrialPlan || isMarketAdministrator;

    dispatch(setBranchPlans(branchPlans));
    dispatch(setIsBranchHasPlan(isBranchHasPlan));
    dispatch(setIsBranchHasAdvancedPlan(isBranchHasAdvancedPlan));
    dispatch(setIsBranchHasBasicPlan(isBranchHasBasicPlan));
    dispatch(setIsBranchHasTrialPlan(isBranchHasTrialPlan));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, isMarketAdministrator]);
  useEffect(() => {
    const scheduleTimeout = setTimeout(handleFetchSchedule, 500);
    return () => clearTimeout(scheduleTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMarketId,
    currentBranchId,
    authUser,
    isBranchHasPlan,
    createOrUpdateScheduleSuccess,
  ]);
  useEffect(() => {
    const attendanceTimeout = setTimeout(handleFetchAttendance, 500);
    return () => clearTimeout(attendanceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMarketId,
    currentBranchId,
    authUser,
    createOrUpdateAttendanceSuccess,
  ]);
  useEffect(() => {
    const fetchTimeout = setTimeout(handleFetchSubscriptions, 500);
    return () => clearTimeout(fetchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  useEffect(() => {
    const fetchTimeout = setTimeout(handleFetchFeatureSubscriptions, 500);
    return () => clearTimeout(fetchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  useEffect(() => {
    const fetchTimeout = setTimeout(handleFetchBanners, 500);
    return () => clearTimeout(fetchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  useEffect(() => {
    const fetchTimeout = setTimeout(handleFetchBannerTargets, 500);
    return () => clearTimeout(fetchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    const isUserHasProducts = parseToBoolean(is_has_products);
    const isUserHasOrders = parseToBoolean(is_has_orders);

    setIsHasProducts(isUserHasProducts);
    setIsHasOrders(isUserHasOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  useEffect(() => {
    dispatch(
      setIsHasGettingStarted(
        role === USER_ROLES.USER_SUBSCRIBER && (!isHasProducts || !isHasOrders)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, isHasProducts, isHasOrders]);
  useEffect(() => {
    dispatch(setTodaySchedule(getActiveSchedule(currentSchedules)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedules]);
  useEffect(() => {
    const isTodayInSchedule = !isEmpty(startSchedule) && !isEmpty(endSchedule);
    dispatch(setIsTodayInSchedule(isTodayInSchedule));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todaySchedule]);
  useEffect(() => {
    const isTodayHasAttendance =
      !isEmpty(todayAttendance) && isEmpty(todayAttendance?.end_at);
    dispatch(setIsTodayHasAttendance(isTodayHasAttendance));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayAttendance]);
  useEffect(() => {
    const isKitchenActive = parseToBoolean(
      currentBranch?.setting?.is_kitchen_active
    );
    dispatch(
      setIsCurrentKitchenActive(
        isKitchenActive && isBranchHasPlan && isBranchHasAdvancedPlan
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, isBranchHasPlan, isBranchHasAdvancedPlan]);
  useEffect(() => {
    if (
      (multipleCreateProductsSuccess !== null || createOrderSuccess !== null) &&
      isHasGettingStarted &&
      !isEmpty(authUser)
    ) {
      dispatch(fetchMeStart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleCreateProductsSuccess, createOrderSuccess]);
  useEffect(() => {
    const isDontShowGettingStarted = getIsDontShowGettingStarted();
    if (isHasGettingStarted && !parseToBoolean(isDontShowGettingStarted)) {
      dispatch(setCurrentModal(gettingStartedModal));
      dispatch(setIsModalOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHasGettingStarted, isHasProducts, isHasOrders]);

  return null;
};

export default GlobalFetch;
