import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setExportExcelReportsFailed,
  setExportExcelReportsSuccess,
} from "../../store/export-excel/export-excel.action";
import {
  getExportExcelReportsFailed,
  getExportExcelReportsSuccess,
} from "../../store/export-excel/export-excel.selector";

const ExportExcelPopup = () => {
  const dispatch = useDispatch();

  const exportExcelReportsSuccess = useSelector(getExportExcelReportsSuccess);
  const exportExcelReportsFailed = useSelector(getExportExcelReportsFailed);

  useEffect(() => {
    if (exportExcelReportsSuccess !== null) {
      showSuccessMessage(exportExcelReportsSuccess);

      if (exportExcelReportsSuccess !== null)
        dispatch(setExportExcelReportsSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcelReportsSuccess]);

  useEffect(() => {
    if (exportExcelReportsFailed !== null) {
      showErrorMessage(exportExcelReportsFailed);

      if (exportExcelReportsFailed !== null)
        dispatch(setExportExcelReportsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcelReportsFailed]);

  return <></>;
};

export default ExportExcelPopup;
