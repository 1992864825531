import styled, { css } from "styled-components";

export const ApplicationFaiSequenceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ApplicationFaiSequenceIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;

  border: 1.5px solid #dfdcef;
  border-radius: 50%;
  background-color: #f2f2f4;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;

  svg {
    width: 1.75rem;
    min-width: 1.75rem;
    max-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
    max-height: 1.75rem;

    path,
    polyline,
    line,
    circle {
      &[fill] {
        fill: #0e072f;
      }
      &[stroke] {
        stroke: #0e072f;
      }
    }
  }
`;

export const ApplicationFaiSequenceCheck = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1.625rem;
  min-width: 1.625rem;
  max-width: 1.625rem;
  height: 1.625rem;
  min-height: 1.625rem;
  max-height: 1.625rem;

  border: 1.5px solid #dfdcef;
  border-radius: 50%;
  background-color: #ffffff;

  svg {
    display: none;

    width: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    stroke-width: 3;

    path,
    polyline,
    line,
    circle {
      &[fill] {
        fill: #ffffff;
      }
      &[stroke] {
        stroke: #ffffff;
      }
    }
  }
`;

export const ApplicationFaiSequenceName = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const ApplicationFaiSequenceInfo = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin-bottom: 0;
`;

export const ApplicationFaiSequenceContainer = styled.div`
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.35rem;

  padding: 1.25rem;
  border: 1.5px solid #dfdcef;
  border-radius: 1rem;
  background-color: #ffffff;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f2f2f4;

    ${ApplicationFaiSequenceIcon} {
      background-color: #ffffff;
    }
    ${ApplicationFaiSequenceCheck} {
      background-color: #ffffff;
    }
  }

  ${({ isSolid }) =>
    isSolid &&
    css`
      background-color: #f2f2f4 !important;
    `}

  ${ApplicationFaiSequenceIcon} {
    ${({ isSolid }) =>
      isSolid &&
      css`
        background-color: #f9f9f9 !important;
      `}
  }
  ${ApplicationFaiSequenceCheck} {
    margin-left: ${({ isRightToLeft }) => (!isRightToLeft ? "auto" : 0)};
    margin-right: ${({ isRightToLeft }) => (isRightToLeft ? "auto" : 0)};

    ${({ isSolid }) =>
      isSolid &&
      css`
        background-color: #f9f9f9 !important;
      `}

    ${({ isActive }) =>
      isActive &&
      css`
        border-color: #0e072f !important;
        background-color: #0e072f !important;
      `}

    svg {
      ${({ isActive }) =>
        isActive &&
        css`
          display: initial !important;
        `}
    }
  }
`;
