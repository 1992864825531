import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateTaxFailed,
  setCreateTaxSuccess,
  setDeleteTaxFailed,
  setDeleteTaxSuccess,
  setFetchTaxFailed,
  setFetchTaxesFailed,
  setSortTaxesFailed,
  setSortTaxesSuccess,
  setUpdateTaxFailed,
  setUpdateTaxSuccess,
} from "../../store/tax/tax.action";
import {
  getCreateTaxFailed,
  getCreateTaxSuccess,
  getDeleteTaxFailed,
  getDeleteTaxSuccess,
  getFetchTaxFailed,
  getFetchTaxesFailed,
  getSortTaxesFailed,
  getSortTaxesSuccess,
  getUpdateTaxFailed,
  getUpdateTaxSuccess,
} from "../../store/tax/tax.selector";

const TaxPopup = () => {
  const dispatch = useDispatch();

  const createTaxSuccess = useSelector(getCreateTaxSuccess);
  const updateTaxSuccess = useSelector(getUpdateTaxSuccess);
  const deleteTaxSuccess = useSelector(getDeleteTaxSuccess);
  const sortTaxesSuccess = useSelector(getSortTaxesSuccess);

  const fetchTaxesFailed = useSelector(getFetchTaxesFailed);
  const fetchTaxFailed = useSelector(getFetchTaxFailed);
  const createTaxFailed = useSelector(getCreateTaxFailed);
  const updateTaxFailed = useSelector(getUpdateTaxFailed);
  const deleteTaxFailed = useSelector(getDeleteTaxFailed);
  const sortTaxesFailed = useSelector(getSortTaxesFailed);

  useEffect(() => {
    if (
      createTaxSuccess !== null ||
      updateTaxSuccess !== null ||
      deleteTaxSuccess !== null ||
      sortTaxesSuccess !== null
    ) {
      showSuccessMessage(
        createTaxSuccess ??
          updateTaxSuccess ??
          deleteTaxSuccess ??
          sortTaxesSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createTaxSuccess !== null) dispatch(setCreateTaxSuccess(null));
      if (updateTaxSuccess !== null) dispatch(setUpdateTaxSuccess(null));
      if (deleteTaxSuccess !== null) dispatch(setDeleteTaxSuccess(null));
      if (sortTaxesSuccess !== null) dispatch(setSortTaxesSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTaxSuccess, updateTaxSuccess, deleteTaxSuccess, sortTaxesSuccess]);

  useEffect(() => {
    if (
      fetchTaxesFailed !== null ||
      fetchTaxFailed !== null ||
      createTaxFailed !== null ||
      updateTaxFailed !== null ||
      deleteTaxFailed !== null ||
      sortTaxesFailed !== null
    ) {
      showErrorMessage(
        fetchTaxesFailed ??
          fetchTaxFailed ??
          createTaxFailed ??
          updateTaxFailed ??
          deleteTaxFailed ??
          sortTaxesFailed
      );

      if (fetchTaxesFailed !== null) dispatch(setFetchTaxesFailed(null));
      if (fetchTaxFailed !== null) dispatch(setFetchTaxFailed(null));
      if (createTaxFailed !== null) dispatch(setCreateTaxFailed(null));
      if (updateTaxFailed !== null) dispatch(setUpdateTaxFailed(null));
      if (deleteTaxFailed !== null) dispatch(setDeleteTaxFailed(null));
      if (sortTaxesFailed !== null) dispatch(setSortTaxesFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchTaxesFailed,
    fetchTaxFailed,
    createTaxFailed,
    updateTaxFailed,
    deleteTaxFailed,
    sortTaxesFailed,
  ]);

  return <></>;
};

export default TaxPopup;
