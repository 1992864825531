import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateFeatureFailed,
  setCreateFeatureSuccess,
  setDeleteFeatureFailed,
  setDeleteFeatureSuccess,
  setUpdateFeatureFailed,
  setUpdateFeatureSuccess,
} from "../../store/feature-subscription/feature-subscription.action";
import {
  getCreateFeatureFailed,
  getCreateFeatureSuccess,
  getDeleteFeatureFailed,
  getDeleteFeatureSuccess,
  getUpdateFeatureFailed,
  getUpdateFeatureSuccess,
} from "../../store/feature-subscription/feature-subscription.selector";

const FeatureSubscriptionPopup = () => {
  const dispatch = useDispatch();

  const createFeatureSuccess = useSelector(getCreateFeatureSuccess);
  const updateFeatureSuccess = useSelector(getUpdateFeatureSuccess);
  const deleteFeatureSuccess = useSelector(getDeleteFeatureSuccess);

  const createFeatureFailed = useSelector(getCreateFeatureFailed);
  const updateFeatureFailed = useSelector(getUpdateFeatureFailed);
  const deleteFeatureFailed = useSelector(getDeleteFeatureFailed);

  useEffect(() => {
    if (
      createFeatureSuccess !== null ||
      updateFeatureSuccess !== null ||
      deleteFeatureSuccess !== null
    ) {
      showSuccessMessage(
        createFeatureSuccess ?? updateFeatureSuccess ?? deleteFeatureSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createFeatureSuccess !== null)
        dispatch(setCreateFeatureSuccess(null));
      if (updateFeatureSuccess !== null)
        dispatch(setUpdateFeatureSuccess(null));
      if (deleteFeatureSuccess !== null)
        dispatch(setDeleteFeatureSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFeatureSuccess, updateFeatureSuccess, deleteFeatureSuccess]);

  useEffect(() => {
    if (
      createFeatureFailed !== null ||
      updateFeatureFailed !== null ||
      deleteFeatureFailed !== null
    ) {
      showErrorMessage(
        createFeatureFailed ?? updateFeatureFailed ?? deleteFeatureFailed
      );

      if (createFeatureFailed !== null) dispatch(setCreateFeatureFailed(null));
      if (updateFeatureFailed !== null) dispatch(setUpdateFeatureFailed(null));
      if (deleteFeatureFailed !== null) dispatch(setDeleteFeatureFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFeatureFailed, updateFeatureFailed, deleteFeatureFailed]);

  return <></>;
};

export default FeatureSubscriptionPopup;
