import apiService from "./api";

export const getProductCategories = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/product-categories", params }))
    .data;
export const getProductCategory = async (categoryId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/product-categories/${categoryId}`,
    })
  ).data;
export const sortProductCategories = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/product-categories/sort",
      request,
    })
  ).data;
export const createProductCategory = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/product-categories", request }))
    .data;
export const updateProductCategory = async (categoryId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/product-categories/${categoryId}`,
      request,
    })
  ).data;
export const deleteProductCategory = async (categoryId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/product-categories/${categoryId}`,
    })
  ).data;
