import apiService from "./api";

export const getTermConditions = async (params) =>
  (await apiService.innerGet({ urlPath: "/term-condition", params })).data;
export const getTermCondition = async (termConditionId) =>
  (await apiService.innerGet({ urlPath: `/term-condition/${termConditionId}` }))
    .data;
export const createTermCondition = async (request) =>
  (await apiService.innerPost({ urlPath: "/term-condition", request })).data;
export const updateTermCondition = async (termConditionId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/term-condition/${termConditionId}`,
      request,
    })
  ).data;
export const deleteTermCondition = async (termConditionId) =>
  (
    await apiService.innerDelete({
      urlPath: `/term-condition/${termConditionId}`,
    })
  ).data;
