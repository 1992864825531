import apiService from "./api";

export const getAdvertisements = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/advertisements", params })).data;
export const getAdvertisement = async (advertisementId) =>
  (await apiService.innerGet({ urlPath: `/v2/advertisements/${advertisementId}` }))
    .data;
export const createAdvertisement = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/advertisements", request })).data;
export const updateAdvertisement = async (advertisementId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/advertisements/${advertisementId}`,
      request,
    })
  ).data;
export const deleteAdvertisement = async (advertisementId) =>
  (await apiService.innerDelete({ urlPath: `/v2/advertisements/${advertisementId}` })).data;
