import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setAppendSelectPermissionsFailed,
  setCreatePermissionFailed,
  setCreatePermissionSuccess,
  setDeletePermissionFailed,
  setDeletePermissionSuccess,
  setFetchPermissionFailed,
  setFetchPermissionsFailed,
  setFetchSelectPermissionsFailed,
  setUpdatePermissionFailed,
  setUpdatePermissionSuccess,
} from "../../store/permission-subscriber/permission-subscriber.action";
import {
  getAppendSelectPermissionsFailed,
  getCreatePermissionFailed,
  getCreatePermissionSuccess,
  getDeletePermissionFailed,
  getDeletePermissionSuccess,
  getFetchPermissionFailed,
  getFetchPermissionsFailed,
  getFetchSelectPermissionsFailed,
  getUpdatePermissionFailed,
  getUpdatePermissionSuccess,
} from "../../store/permission-subscriber/permission-subscriber.selector";

const PermissionPopupSubscriber = () => {
  const dispatch = useDispatch();

  const createPermissionSuccess = useSelector(getCreatePermissionSuccess);
  const updatePermissionSuccess = useSelector(getUpdatePermissionSuccess);
  const deletePermissionSuccess = useSelector(getDeletePermissionSuccess);
  const fetchPermissionsFailed = useSelector(getFetchPermissionsFailed);
  const fetchSelectPermissionsFailed = useSelector(
    getFetchSelectPermissionsFailed
  );
  const appendSelectPermissionsFailed = useSelector(
    getAppendSelectPermissionsFailed
  );
  const fetchPermissionFailed = useSelector(getFetchPermissionFailed);
  const createPermissionFailed = useSelector(getCreatePermissionFailed);
  const updatePermissionFailed = useSelector(getUpdatePermissionFailed);
  const deletePermissionFailed = useSelector(getDeletePermissionFailed);

  useEffect(() => {
    if (
      createPermissionSuccess !== null ||
      updatePermissionSuccess !== null ||
      deletePermissionSuccess !== null
    ) {
      showSuccessMessage(
        createPermissionSuccess ??
          updatePermissionSuccess ??
          deletePermissionSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createPermissionSuccess !== null)
        dispatch(setCreatePermissionSuccess(null));
      if (updatePermissionSuccess !== null)
        dispatch(setUpdatePermissionSuccess(null));
      if (deletePermissionSuccess !== null)
        dispatch(setDeletePermissionSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createPermissionSuccess,
    updatePermissionSuccess,
    deletePermissionSuccess,
  ]);

  useEffect(() => {
    if (
      fetchPermissionsFailed !== null ||
      fetchSelectPermissionsFailed !== null ||
      appendSelectPermissionsFailed !== null ||
      fetchPermissionFailed !== null ||
      createPermissionFailed !== null ||
      updatePermissionFailed !== null ||
      deletePermissionFailed !== null
    ) {
      showErrorMessage(
        fetchPermissionsFailed ??
          fetchSelectPermissionsFailed ??
          appendSelectPermissionsFailed ??
          fetchPermissionFailed ??
          createPermissionFailed ??
          updatePermissionFailed ??
          deletePermissionFailed
      );

      if (fetchPermissionsFailed !== null)
        dispatch(setFetchPermissionsFailed(null));
      if (fetchSelectPermissionsFailed !== null)
        dispatch(setFetchSelectPermissionsFailed(null));
      if (appendSelectPermissionsFailed !== null)
        dispatch(setAppendSelectPermissionsFailed(null));
      if (fetchPermissionFailed !== null)
        dispatch(setFetchPermissionFailed(null));
      if (createPermissionFailed !== null)
        dispatch(setCreatePermissionFailed(null));
      if (updatePermissionFailed !== null)
        dispatch(setUpdatePermissionFailed(null));
      if (deletePermissionFailed !== null)
        dispatch(setDeletePermissionFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPermissionsFailed,
    fetchSelectPermissionsFailed,
    appendSelectPermissionsFailed,
    fetchPermissionFailed,
    createPermissionFailed,
    updatePermissionFailed,
    deletePermissionFailed,
  ]);

  return <></>;
};

export default PermissionPopupSubscriber;
