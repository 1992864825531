import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setSendVerificationFailed,
  setSendVerificationSuccess,
  setVerifyVerificationFailed,
  setVerifyVerificationSuccess,
} from "../../store/verification/verification.action";
import {
  getSendVerificationFailed,
  getSendVerificationSuccess,
  getVerifyVerificationFailed,
  getVerifyVerificationSuccess,
} from "../../store/verification/verification.selector";

const VerificationPopup = () => {
  const dispatch = useDispatch();

  const sendVerificationSuccess = useSelector(getSendVerificationSuccess);
  const verifyVerificationSuccess = useSelector(getVerifyVerificationSuccess);
  const sendVerificationFailed = useSelector(getSendVerificationFailed);
  const verifyVerificationFailed = useSelector(getVerifyVerificationFailed);

  useEffect(() => {
    if (sendVerificationSuccess !== null || verifyVerificationSuccess !== null) {
      showSuccessMessage(sendVerificationSuccess ?? verifyVerificationSuccess);

      if (sendVerificationSuccess !== null) dispatch(setSendVerificationSuccess(null));
      if (verifyVerificationSuccess !== null) dispatch(setVerifyVerificationSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendVerificationSuccess, verifyVerificationSuccess]);

  useEffect(() => {
    if (sendVerificationFailed !== null || verifyVerificationFailed !== null) {
      showErrorMessage(sendVerificationFailed ?? verifyVerificationFailed);

      if (sendVerificationFailed !== null) dispatch(setSendVerificationFailed(null));
      if (verifyVerificationFailed !== null) dispatch(setVerifyVerificationFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendVerificationFailed, verifyVerificationFailed]);

  return <></>;
};

export default VerificationPopup;
