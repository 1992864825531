import { createAction } from "../../utils/store.utils";

import SELECT_PAYMENT_METHOD_ACTION_TYPES from "./select-payment-method.type";

export const setPaymentMethods = (paymentMethods) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_PAYMENT_METHODS,
    paymentMethods
  );
export const setSearchPaymentMethods = (searchPaymentMethods) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS,
    searchPaymentMethods
  );

export const appendPaymentMethods = (paymentMethods) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.APPEND_PAYMENT_METHODS,
    paymentMethods
  );
export const appendSearchPaymentMethods = (searchPaymentMethods) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.APPEND_SEARCH_PAYMENT_METHODS,
    searchPaymentMethods
  );

export const setIsPaymentMethodsHasMore = (isPaymentMethodsHasMore) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_PAYMENT_METHODS_HAS_MORE,
    isPaymentMethodsHasMore
  );
export const setIsSearchPaymentMethodsHasMore = (
  isSearchPaymentMethodsHasMore
) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_SEARCH_PAYMENT_METHODS_HAS_MORE,
    isSearchPaymentMethodsHasMore
  );

export const setFetchPaymentMethodsParams = (fetchPaymentMethodsParams) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_PARAMS,
    fetchPaymentMethodsParams
  );
export const setFetchPaymentMethodsLoading = (fetchPaymentMethodsLoading) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_LOADING,
    fetchPaymentMethodsLoading
  );
export const setFetchPaymentMethodsSuccess = (fetchPaymentMethodsSuccess) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_SUCCESS,
    fetchPaymentMethodsSuccess
  );
export const setFetchPaymentMethodsFailed = (fetchPaymentMethodsFailed) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_FAILED,
    fetchPaymentMethodsFailed
  );

export const setSearchPaymentMethodsParams = (searchPaymentMethodsParams) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_PARAMS,
    searchPaymentMethodsParams
  );
export const setSearchPaymentMethodsLoading = (searchPaymentMethodsLoading) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_LOADING,
    searchPaymentMethodsLoading
  );
export const setSearchPaymentMethodsSuccess = (searchPaymentMethodsSuccess) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_SUCCESS,
    searchPaymentMethodsSuccess
  );
export const setSearchPaymentMethodsFailed = (searchPaymentMethodsFailed) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_FAILED,
    searchPaymentMethodsFailed
  );

export const setAppendPaymentMethodsParams = (appendPaymentMethodsParams) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_PARAMS,
    appendPaymentMethodsParams
  );
export const setAppendPaymentMethodsLoading = (appendPaymentMethodsLoading) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_LOADING,
    appendPaymentMethodsLoading
  );
export const setAppendPaymentMethodsSuccess = (appendPaymentMethodsSuccess) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_SUCCESS,
    appendPaymentMethodsSuccess
  );
export const setAppendPaymentMethodsFailed = (appendPaymentMethodsFailed) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_FAILED,
    appendPaymentMethodsFailed
  );

export const setIsFetchPaymentMethodsHitted = (isFetchPaymentMethodsHitted) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHODS_HITTED,
    isFetchPaymentMethodsHitted
  );
export const setIsSearchPaymentMethodsHitted = (isSearchPaymentMethodsHitted) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_SEARCH_PAYMENT_METHODS_HITTED,
    isSearchPaymentMethodsHitted
  );
export const setIsAppendPaymentMethodsHitted = (isAppendPaymentMethodsHitted) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_APPEND_PAYMENT_METHODS_HITTED,
    isAppendPaymentMethodsHitted
  );

export const fetchPaymentMethodsStart = (params) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.FETCH_PAYMENT_METHODS_START,
    params
  );
export const searchPaymentMethodsStart = (params) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SEARCH_PAYMENT_METHODS_START,
    params
  );
export const appendPaymentMethodsStart = (params) =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.APPEND_PAYMENT_METHODS_START,
    params
  );

export const resetPaymentMethodReducer = () =>
  createAction(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.RESET_SELECT_PAYMENT_METHOD_REDUCER
  );
