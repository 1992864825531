import slugify from "slugify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { findIntegration } from "../../utils/integration.utils";
import {
  showCustomErrorMessage,
  showErrorMessage,
} from "../../utils/popup.utils";
import {
  getValidationErrors,
  isEmpty,
  rule,
} from "../../utils/validation.utils";

import { INTEGRATION_KEYS } from "../../constants/integration.constant";

import {
  getCurrentMarket,
  getCurrentMarketId,
} from "../../store/global/global.selector";
import {
  registerFaiStart,
  setupFaiStart,
} from "../../store/integration/integration.action";
import {
  getCreateOrUpdateIntegrationLoading,
  getCreateOrUpdateIntegrationSuccess,
  getFetchIntegrationsLoading,
  getIntegrations,
} from "../../store/integration/integration.selector";

import Modal from "../../components/modal/modal.component";
import Responsive from "../../components/responsive/responsive.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import FormLabel, {
  FORM_LABEL_COLORS,
} from "../../components/form-label/form-label.component";
import FormInput, {
  FORM_INPUT_SIZES,
  FORM_INPUT_TYPES,
} from "../../components/form-input/form-input.component";
import ApplicationCompletionTrack from "../application-completion-track/application-completion-track.widget";
import ApplicationFaiSequence from "../application-fai-sequence/application-fai-sequence.widget";
import FormSlugInstruction from "../form-slug-instruction/form-slug-instruction.widget";

import { Settings01Icon, Sorting19Icon } from "hugeicons-react";

import { ReactComponent as TaxSvg } from "../../assets/icons/PageTax.svg";
import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";
import { ReactComponent as EditSvg } from "../../assets/icons/Edit3.svg";
import { ReactComponent as CancelSvg } from "../../assets/icons/stroke/Cancel.svg";

export const modalName = "APPLICATION_FAI_SETTINGS";

const defaultValues = {
  company_slug: "",
  invoice_method: "",
  street_en: "",
  street_ar: "",
  building_number: "",
  city: "",
  postal_code: "",
  district_en: "",
  district_ar: "",
  sales_invoice_prefix: "",
  sales_invoice_start: "",
  return_sales_invoice_prefix: "",
  return_sales_invoice_start: "",
};

const ApplicationFaiSettings = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const currentMarketId = useSelector(getCurrentMarketId);
  const currentMarket = useSelector(getCurrentMarket);

  const createOrUpdateIntegrationLoading = useSelector(
    getCreateOrUpdateIntegrationLoading
  );
  const createOrUpdateIntegrationSuccess = useSelector(
    getCreateOrUpdateIntegrationSuccess
  );

  const fetchIntegrationsLoading = useSelector(getFetchIntegrationsLoading);
  const integrations = useSelector(getIntegrations);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [completions, setCompletions] = useState([]);
  const [integration, setIntegration] = useState(defaultValues);
  const [inputValues, setInputValues] = useState(defaultValues);

  const [progressCount, setProgressCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);

  const handleEditConfiguration = () => {
    if (progressCount < completeCount) {
      showCustomErrorMessage(
        t("Please complete your business information first to continue.")
      );
      return;
    }
    setIsEditing((isEditing) => !isEditing);
  };
  const handleSequenceChange = (invoiceMethod) => () => {
    if (!isEditing) return;
    setInputValues((inputValues) => ({
      ...inputValues,
      invoice_method: invoiceMethod,
    }));
  };

  const handleInputChange = ({ name, value }) =>
    setInputValues({ ...inputValues, [name]: value });

  const handleFormSubmit = () => {
    const {
      company_slug,
      invoice_method,
      street_en,
      street_ar,
      building_number,
      city,
      postal_code,
      district_en,
      district_ar,
      sales_invoice_prefix,
      sales_invoice_start,
      return_sales_invoice_prefix,
      return_sales_invoice_start,
    } = inputValues;

    const rules = {
      business_slug: [rule.required(), rule.slug()],
      invoice_method: [rule.required()],
      street_ar: [rule.required()],
      building_number: [rule.required()],
      city: [rule.required()],
      postal_code: [rule.required()],
      district_ar: [rule.required()],
      sales_prefix: invoice_method === 2 ? [rule.required()] : [],
      sales_prefix_start_number: invoice_method === 2 ? [rule.required()] : [],
      return_prefix: invoice_method === 2 ? [rule.required()] : [],
      return_prefix_start_number: invoice_method === 2 ? [rule.required()] : [],
    };
    const datas = {
      business_slug: company_slug,
      sales_prefix: sales_invoice_prefix,
      sales_prefix_start_number: sales_invoice_start,
      return_prefix: return_sales_invoice_prefix,
      return_prefix_start_number: return_sales_invoice_start,
      invoice_method,
      street_ar,
      building_number,
      city,
      postal_code,
      district_ar,
    };

    const errors = getValidationErrors(datas, rules);

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    dispatch(
      setupFaiStart({
        market_id: currentMarketId,
        market: { slug: company_slug },
        location: {
          street_en,
          street_ar,
          building_number,
          city,
          postal_code,
          district_en,
          district_ar,
        },
        integration: {
          invoice_method,
          sales_invoice_prefix,
          return_sales_invoice_prefix,
          sales_invoice_start,
          return_sales_invoice_start,
        },
      })
    );
  };

  useEffect(() => {
    const { logo_img, crn, tax_number } = currentMarket ?? {};

    const taxCompletion = {
      label: t("Tax Number"),
      value: tax_number,
      urlPath: "/settings/authentication",
      required: true,
    };
    const crnCompletion = {
      label: t("Commercial Registration Number"),
      value: crn,
      urlPath: "/settings/authentication",
      required: true,
    };
    const logoCompletion = {
      label: t("Business Logo"),
      value: logo_img,
      urlPath: "/settings/profile",
      required: false,
    };

    const completions = [taxCompletion, crnCompletion, logoCompletion];

    let progressCount = 0;
    if (!isEmpty(tax_number)) progressCount++;
    if (!isEmpty(crn)) progressCount++;

    setCompletions(completions);
    setProgressCount(progressCount);
    setCompleteCount(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarket, i18n.language]);

  useEffect(() => {
    if (!isEmpty(currentMarketId) && integrations.length > 0) {
      const methodParams = {
        key: INTEGRATION_KEYS.FAI_INVOICE_METHOD,
        market_id: currentMarketId,
      };
      const salesPrefixParams = {
        key: INTEGRATION_KEYS.FAI_SALES_INVOICE_PREFIX,
        market_id: currentMarketId,
      };
      const returnPrefixParams = {
        key: INTEGRATION_KEYS.FAI_RETURN_SALES_INVOICE_PREFIX,
        market_id: currentMarketId,
      };
      const salesStartParams = {
        key: INTEGRATION_KEYS.FAI_SALES_INVOICE_START,
        market_id: currentMarketId,
      };
      const returnStartParams = {
        key: INTEGRATION_KEYS.FAI_RETURN_SALES_INVOICE_START,
        market_id: currentMarketId,
      };

      const defaultSlug = slugify(currentMarket?.name_en, { lower: true });

      const location = currentMarket?.location;
      const method = findIntegration(integrations, methodParams);
      const salesPrefix = findIntegration(integrations, salesPrefixParams);
      const returnPrefix = findIntegration(integrations, returnPrefixParams);
      const salesStart = findIntegration(integrations, salesStartParams);
      const returnStart = findIntegration(integrations, returnStartParams);

      const integration = { ...defaultValues };

      let filledCount = 0;
      if (!isEmpty(currentMarket?.slug)) {
        integration.company_slug = currentMarket.slug;
        filledCount++;
      } else if (!isEmpty(defaultSlug)) {
        integration.company_slug = defaultSlug;
      }
      if (!isEmpty(method?.value)) {
        integration.invoice_method = method.value;
        filledCount++;
      }
      if (!isEmpty(location?.street_en)) {
        integration.street_en = location.street_en;
        filledCount++;
      }
      if (!isEmpty(location?.street_ar)) {
        integration.street_ar = location.street_ar;
        filledCount++;
      }
      if (!isEmpty(location?.building_number)) {
        integration.building_number = location.building_number;
        filledCount++;
      }
      if (!isEmpty(location?.city)) {
        integration.city = location.city;
        filledCount++;
      }
      if (!isEmpty(location?.postal_code)) {
        integration.postal_code = location.postal_code;
        filledCount++;
      }
      if (!isEmpty(location?.district_en)) {
        integration.district_en = location.district_en;
        filledCount++;
      }
      if (!isEmpty(location?.district_ar)) {
        integration.district_ar = location.district_ar;
        filledCount++;
      }
      if (!isEmpty(salesPrefix?.value)) {
        integration.sales_invoice_prefix = salesPrefix.value;
        filledCount++;
      }
      if (!isEmpty(returnPrefix?.value)) {
        integration.sales_invoice_start = returnPrefix.value;
        filledCount++;
      }
      if (!isEmpty(salesStart?.value)) {
        integration.return_sales_invoice_prefix = salesStart.value;
        filledCount++;
      }
      if (!isEmpty(returnStart?.value)) {
        integration.return_sales_invoice_start = returnStart.value;
        filledCount++;
      }

      setIntegration(integration);
      setIsEditing(filledCount === 0 ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, currentMarketId, currentMarket]);

  useEffect(() => {
    setInputValues(integration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, isEditing]);

  useEffect(() => {
    setIsLoading(fetchIntegrationsLoading || createOrUpdateIntegrationLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchIntegrationsLoading, createOrUpdateIntegrationLoading]);

  useEffect(() => {
    if (!isEmpty(createOrUpdateIntegrationSuccess)) {
      dispatch(registerFaiStart({ market_id: currentMarketId }));
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateIntegrationSuccess]);

  return (
    <Modal
      name={modalName}
      icon={<TaxSvg />}
      title={t("Fai Settings")}
      description={t("Please fill in the following fields")}
    >
      <Responsive>
        <Responsive.Row rowGap="1rem">
          {completions.length !== progressCount && (
            <Responsive.Col>
              <ApplicationCompletionTrack completions={completions} />
            </Responsive.Col>
          )}
          <Responsive.Col>
            <FormSlugInstruction />
          </Responsive.Col>
          <Responsive.Col>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Business Slug")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="company_slug"
              placeholder={t("Enter business slug")}
              onChange={handleInputChange}
              value={
                isEditing ? inputValues.company_slug : integration.company_slug
              }
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Street :language", { language: "English" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="street_en"
              placeholder={t("Enter street")}
              onChange={handleInputChange}
              value={isEditing ? inputValues.street_en : integration.street_en}
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Street :language", { language: "Arabic" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="street_ar"
              placeholder={t("Enter street")}
              onChange={handleInputChange}
              value={isEditing ? inputValues.street_ar : integration.street_ar}
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Building Number")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.NUMBER}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="building_number"
              placeholder={t("Enter building number")}
              onChange={handleInputChange}
              value={
                isEditing
                  ? inputValues.building_number
                  : integration.building_number
              }
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Postal Code")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.NUMBER}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="postal_code"
              placeholder={t("Enter postal code")}
              onChange={handleInputChange}
              value={
                isEditing ? inputValues.postal_code : integration.postal_code
              }
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("City")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="city"
              placeholder={t("Enter city")}
              onChange={handleInputChange}
              value={isEditing ? inputValues.city : integration.city}
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("District :language", { language: "English" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="district_en"
              placeholder={t("Enter district")}
              onChange={handleInputChange}
              value={
                isEditing ? inputValues.district_en : integration.district_en
              }
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("District :language", { language: "Arabic" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="district_ar"
              placeholder={t("Enter district")}
              onChange={handleInputChange}
              value={
                isEditing ? inputValues.district_ar : integration.district_ar
              }
              isDisabled={!isEditing}
              isSolid={!isEditing}
            />
          </Responsive.Col>
          <Responsive.Col>
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Invoice Method")}
            </FormLabel>
            <Responsive.Row rowGap="1rem">
              <Responsive.Col sm={6}>
                <ApplicationFaiSequence
                  icon={<Settings01Icon />}
                  name={t("Non-Sequential")}
                  info={t(
                    "Invoice numbers are manually entered by the user through the UI."
                  )}
                  isActive={
                    (isEditing
                      ? inputValues.invoice_method
                      : integration.invoice_method) === 1
                  }
                  onClick={handleSequenceChange(1)}
                  isSolid={!isEditing}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <ApplicationFaiSequence
                  icon={<Sorting19Icon />}
                  name={t("Sequential")}
                  info={t(
                    "Invoices are generated with system-assigned, sequential invoice numbers."
                  )}
                  isActive={
                    (isEditing
                      ? inputValues.invoice_method
                      : integration.invoice_method) === 2
                  }
                  onClick={handleSequenceChange(2)}
                  isSolid={!isEditing}
                />
              </Responsive.Col>
            </Responsive.Row>
          </Responsive.Col>
          {(isEditing
            ? inputValues.invoice_method
            : integration.invoice_method) === 2 && (
            <>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Sales Prefix")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="sales_invoice_prefix"
                  placeholder={t("Enter sales prefix")}
                  onChange={handleInputChange}
                  value={
                    isEditing
                      ? inputValues.sales_invoice_prefix
                      : integration.sales_invoice_prefix
                  }
                  isDisabled={!isEditing}
                  isSolid={!isEditing}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Sales Prefix Start Number")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="sales_invoice_start"
                  placeholder={t("Enter sales prefix start number")}
                  onChange={handleInputChange}
                  value={
                    isEditing
                      ? inputValues.sales_invoice_start
                      : integration.sales_invoice_start
                  }
                  isDisabled={!isEditing}
                  isSolid={!isEditing}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Return Prefix")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="return_sales_invoice_prefix"
                  placeholder={t("Enter return prefix")}
                  onChange={handleInputChange}
                  value={
                    isEditing
                      ? inputValues.return_sales_invoice_prefix
                      : integration.return_sales_invoice_prefix
                  }
                  isDisabled={!isEditing}
                  isSolid={!isEditing}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Return Prefix Start Number")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="return_sales_invoice_start"
                  placeholder={t("Enter return prefix start number")}
                  onChange={handleInputChange}
                  value={
                    isEditing
                      ? inputValues.return_sales_invoice_start
                      : integration.return_sales_invoice_start
                  }
                  isDisabled={!isEditing}
                  isSolid={!isEditing}
                />
              </Responsive.Col>
            </>
          )}
        </Responsive.Row>
        <Responsive.Row rowGap="1rem">
          <Responsive.Col></Responsive.Col>
          <Responsive.Col></Responsive.Col>
          {isEditing ? (
            <>
              <Responsive.Col sm={6}>
                <Button
                  type="button"
                  buttonColor={BUTTON_COLORS.PRIMARY}
                  buttonSize={BUTTON_SIZES.NORMAL}
                  buttonType={BUTTON_TYPES.TEXT_ICON}
                  prefixIcon={<ConfirmSvg />}
                  onClick={handleFormSubmit}
                  isLoading={isLoading}
                  isFullWidth
                >
                  {t("Save Changes")}
                </Button>
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <Button
                  type="button"
                  buttonColor={BUTTON_COLORS.SECONDARY}
                  buttonSize={BUTTON_SIZES.NORMAL}
                  buttonType={BUTTON_TYPES.TEXT_ICON}
                  prefixIcon={<CancelSvg />}
                  onClick={handleEditConfiguration}
                  isLoading={isLoading}
                  isFullWidth
                >
                  {t("Cancel")}
                </Button>
              </Responsive.Col>
            </>
          ) : (
            <Responsive.Col>
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.LIGHT_PRIMARY}
                buttonSize={BUTTON_SIZES.NORMAL}
                buttonType={BUTTON_TYPES.TEXT_ICON}
                prefixIcon={<EditSvg />}
                onClick={handleEditConfiguration}
                isLoading={isLoading}
              >
                {t("Edit Configuration")}
              </Button>
            </Responsive.Col>
          )}
        </Responsive.Row>
      </Responsive>
    </Modal>
  );
};

export default ApplicationFaiSettings;
