const SELECT_PERMISSION_ADMIN_ACTION_TYPES = {
  RESET_SELECT_PERMISSION_ADMIN_REDUCER:
    "select-permission-admin/RESET_SELECT_PERMISSION_ADMIN_REDUCER",

  SET_PERMISSION_ADMINS: "select-permission-admin/SET_PERMISSION_ADMINS",
  SET_PERMISSION_SUBSCRIBERS:
    "select-permission-admin/SET_PERMISSION_SUBSCRIBERS",
  SET_SEARCH_PERMISSION_ADMINS:
    "select-permission-admin/SET_SEARCH_PERMISSION_ADMINS",
  SET_SEARCH_PERMISSION_SUBSCRIBERS:
    "select-permission-admin/SET_SEARCH_PERMISSION_SUBSCRIBERS",

  APPEND_PERMISSION_ADMINS: "select-permission-admin/APPEND_PERMISSION_ADMINS",
  APPEND_PERMISSION_SUBSCRIBERS:
    "select-permission-admin/APPEND_PERMISSION_SUBSCRIBERS",
  APPEND_SEARCH_PERMISSION_ADMINS:
    "select-permission-admin/APPEND_SEARCH_PERMISSION_ADMINS",
  APPEND_SEARCH_PERMISSION_SUBSCRIBERS:
    "select-permission-admin/APPEND_SEARCH_PERMISSION_SUBSCRIBERS",

  SET_IS_PERMISSION_ADMINS_HAS_MORE:
    "select-permission-admin/SET_IS_PERMISSION_ADMINS_HAS_MORE",
  SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
    "select-permission-admin/SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE",
  SET_IS_SEARCH_PERMISSION_ADMINS_HAS_MORE:
    "select-permission-admin/SET_IS_SEARCH_PERMISSION_ADMINS_HAS_MORE",
  SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HAS_MORE:
    "select-permission-admin/SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_PERMISSION_ADMINS_PARAMS:
    "select-permission-admin/SET_FETCH_PERMISSION_ADMINS_PARAMS",
  SET_FETCH_PERMISSION_ADMINS_LOADING:
    "select-permission-admin/SET_FETCH_PERMISSION_ADMINS_LOADING",
  SET_FETCH_PERMISSION_ADMINS_SUCCESS:
    "select-permission-admin/SET_FETCH_PERMISSION_ADMINS_SUCCESS",
  SET_FETCH_PERMISSION_ADMINS_FAILED:
    "select-permission-admin/SET_FETCH_PERMISSION_ADMINS_FAILED",

  SET_FETCH_PERMISSION_SUBSCRIBERS_PARAMS:
    "select-permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_PARAMS",
  SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
    "select-permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING",
  SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
    "select-permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
    "select-permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED",

  SET_SEARCH_PERMISSION_ADMINS_PARAMS:
    "select-permission-admin/SET_SEARCH_PERMISSION_ADMINS_PARAMS",
  SET_SEARCH_PERMISSION_ADMINS_LOADING:
    "select-permission-admin/SET_SEARCH_PERMISSION_ADMINS_LOADING",
  SET_SEARCH_PERMISSION_ADMINS_SUCCESS:
    "select-permission-admin/SET_SEARCH_PERMISSION_ADMINS_SUCCESS",
  SET_SEARCH_PERMISSION_ADMINS_FAILED:
    "select-permission-admin/SET_SEARCH_PERMISSION_ADMINS_FAILED",

  SET_SEARCH_PERMISSION_SUBSCRIBERS_PARAMS:
    "select-permission-admin/SET_SEARCH_PERMISSION_SUBSCRIBERS_PARAMS",
  SET_SEARCH_PERMISSION_SUBSCRIBERS_LOADING:
    "select-permission-admin/SET_SEARCH_PERMISSION_SUBSCRIBERS_LOADING",
  SET_SEARCH_PERMISSION_SUBSCRIBERS_SUCCESS:
    "select-permission-admin/SET_SEARCH_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_SEARCH_PERMISSION_SUBSCRIBERS_FAILED:
    "select-permission-admin/SET_SEARCH_PERMISSION_SUBSCRIBERS_FAILED",

  SET_APPEND_PERMISSION_ADMINS_PARAMS:
    "select-permission-admin/SET_APPEND_PERMISSION_ADMINS_PARAMS",
  SET_APPEND_PERMISSION_ADMINS_LOADING:
    "select-permission-admin/SET_APPEND_PERMISSION_ADMINS_LOADING",
  SET_APPEND_PERMISSION_ADMINS_SUCCESS:
    "select-permission-admin/SET_APPEND_PERMISSION_ADMINS_SUCCESS",
  SET_APPEND_PERMISSION_ADMINS_FAILED:
    "select-permission-admin/SET_APPEND_PERMISSION_ADMINS_FAILED",

  SET_APPEND_PERMISSION_SUBSCRIBERS_PARAMS:
    "select-permission-admin/SET_APPEND_PERMISSION_SUBSCRIBERS_PARAMS",
  SET_APPEND_PERMISSION_SUBSCRIBERS_LOADING:
    "select-permission-admin/SET_APPEND_PERMISSION_SUBSCRIBERS_LOADING",
  SET_APPEND_PERMISSION_SUBSCRIBERS_SUCCESS:
    "select-permission-admin/SET_APPEND_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_APPEND_PERMISSION_SUBSCRIBERS_FAILED:
    "select-permission-admin/SET_APPEND_PERMISSION_SUBSCRIBERS_FAILED",

  SET_IS_FETCH_PERMISSION_ADMINS_HITTED:
    "select-permission-admin/SET_IS_FETCH_PERMISSION_ADMINS_HITTED",
  SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
    "select-permission-admin/SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED",
  SET_IS_SEARCH_PERMISSION_ADMINS_HITTED:
    "select-permission-admin/SET_IS_SEARCH_PERMISSION_ADMINS_HITTED",
  SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HITTED:
    "select-permission-admin/SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HITTED",
  SET_IS_APPEND_PERMISSION_ADMINS_HITTED:
    "select-permission-admin/SET_IS_APPEND_PERMISSION_ADMINS_HITTED",
  SET_IS_APPEND_PERMISSION_SUBSCRIBERS_HITTED:
    "select-permission-admin/SET_IS_APPEND_PERMISSION_SUBSCRIBERS_HITTED",

  FETCH_PERMISSION_ADMINS_START:
    "select-permission-admin/FETCH_PERMISSION_ADMINS_START",
  FETCH_PERMISSION_SUBSCRIBERS_START:
    "select-permission-admin/FETCH_PERMISSION_SUBSCRIBERS_START",
  SEARCH_PERMISSION_ADMINS_START:
    "select-permission-admin/SEARCH_PERMISSION_ADMINS_START",
  SEARCH_PERMISSION_SUBSCRIBERS_START:
    "select-permission-admin/SEARCH_PERMISSION_SUBSCRIBERS_START",
  APPEND_PERMISSION_ADMINS_START:
    "select-permission-admin/APPEND_PERMISSION_ADMINS_START",
  APPEND_PERMISSION_SUBSCRIBERS_START:
    "select-permission-admin/APPEND_PERMISSION_SUBSCRIBERS_START",
};

export default SELECT_PERMISSION_ADMIN_ACTION_TYPES;
