import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateProductModifierFailed,
  setCreateProductModifierSuccess,
  setUpdateProductModifierFailed,
  setUpdateProductModifierSuccess,
} from "../../store/product-modifier/product-modifier.action";
import {
  getCreateProductModifierFailed,
  getCreateProductModifierSuccess,
  getUpdateProductModifierFailed,
  getUpdateProductModifierSuccess,
} from "../../store/product-modifier/product-modifier.selector";

const ProductModifierPopup = () => {
  const dispatch = useDispatch();

  const createProductModifierSuccess = useSelector(getCreateProductModifierSuccess);
  const updateProductModifierSuccess = useSelector(getUpdateProductModifierSuccess);
  const createProductModifierFailed = useSelector(getCreateProductModifierFailed);
  const updateProductModifierFailed = useSelector(getUpdateProductModifierFailed);

  useEffect(() => {
    if (createProductModifierSuccess !== null || updateProductModifierSuccess !== null) {
      dispatch(setIsModalOpen(false));
      if (createProductModifierSuccess !== null) {
        dispatch(setCreateProductModifierSuccess(null));
        showSuccessMessage(createProductModifierSuccess);
      }
      if (updateProductModifierSuccess !== null) dispatch(setUpdateProductModifierSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductModifierSuccess, updateProductModifierSuccess]);

  useEffect(() => {
    if (createProductModifierFailed !== null || updateProductModifierFailed !== null) {
      showErrorMessage(createProductModifierFailed ?? updateProductModifierFailed);
      if (createProductModifierFailed !== null) dispatch(setCreateProductModifierFailed(null));
      if (updateProductModifierFailed !== null) dispatch(setUpdateProductModifierFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductModifierFailed, updateProductModifierFailed]);

  return <></>;
};

export default ProductModifierPopup;
