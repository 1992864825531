import GettingStartedItem from "../getting-started-item/getting-started-item.widget";

import {
  GettingStartedListColumn,
  GettingStartedListRow,
} from "./getting-started-list.style";

const GettingStartedList = ({ startedItems = [] }) => {
  return (
    <GettingStartedListRow>
      {startedItems.map((startedItem, index) => (
        <GettingStartedListColumn key={index}>
          <GettingStartedItem startedItem={startedItem} />
        </GettingStartedListColumn>
      ))}
    </GettingStartedListRow>
  );
};

export default GettingStartedList;
