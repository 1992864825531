import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateTopUpFailed,
  setCreateTopUpSuccess,
  setDeleteTopUpFailed,
  setDeleteTopUpSuccess,
  setFetchTopUpFailed,
  setFetchTopUpsFailed,
  setUpdateTopUpFailed,
  setUpdateTopUpSuccess,
} from "../../store/top-up/top-up.action";
import {
  getCreateTopUpFailed,
  getCreateTopUpSuccess,
  getDeleteTopUpFailed,
  getDeleteTopUpSuccess,
  getFetchTopUpFailed,
  getFetchTopUpsFailed,
  getUpdateTopUpFailed,
  getUpdateTopUpSuccess,
} from "../../store/top-up/top-up.selector";

const TopUpPopup = () => {
  const dispatch = useDispatch();

  const createTopUpSuccess = useSelector(getCreateTopUpSuccess);
  const updateTopUpSuccess = useSelector(getUpdateTopUpSuccess);
  const deleteTopUpSuccess = useSelector(getDeleteTopUpSuccess);
  const fetchTopUpsFailed = useSelector(getFetchTopUpsFailed);
  const fetchTopUpFailed = useSelector(getFetchTopUpFailed);
  const createTopUpFailed = useSelector(getCreateTopUpFailed);
  const updateTopUpFailed = useSelector(getUpdateTopUpFailed);
  const deleteTopUpFailed = useSelector(getDeleteTopUpFailed);

  useEffect(() => {
    if (createTopUpSuccess !== null || updateTopUpSuccess !== null || deleteTopUpSuccess !== null) {
      showSuccessMessage(createTopUpSuccess ?? updateTopUpSuccess ?? deleteTopUpSuccess);
      dispatch(setIsModalOpen(false));

      if (createTopUpSuccess !== null) dispatch(setCreateTopUpSuccess(null));
      if (updateTopUpSuccess !== null) dispatch(setUpdateTopUpSuccess(null));
      if (deleteTopUpSuccess !== null) dispatch(setDeleteTopUpSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTopUpSuccess, updateTopUpSuccess, deleteTopUpSuccess]);

  useEffect(() => {
    if (
      fetchTopUpsFailed !== null ||
      fetchTopUpFailed !== null ||
      createTopUpFailed !== null ||
      updateTopUpFailed !== null ||
      deleteTopUpFailed !== null
    ) {
      showErrorMessage(
        fetchTopUpsFailed ?? fetchTopUpFailed ?? createTopUpFailed ?? updateTopUpFailed ?? deleteTopUpFailed
      );

      if (fetchTopUpsFailed !== null) dispatch(setFetchTopUpsFailed(null));
      if (fetchTopUpFailed !== null) dispatch(setFetchTopUpFailed(null));
      if (createTopUpFailed !== null) dispatch(setCreateTopUpFailed(null));
      if (updateTopUpFailed !== null) dispatch(setUpdateTopUpFailed(null));
      if (deleteTopUpFailed !== null) dispatch(setDeleteTopUpFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTopUpsFailed, fetchTopUpFailed, createTopUpFailed, updateTopUpFailed, deleteTopUpFailed]);

  return <></>;
};

export default TopUpPopup;
