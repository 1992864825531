import { createSelector } from "reselect";

const verificationSelector = ({ verification }) => verification;

export const getSendVerificationResult = createSelector(
  [verificationSelector],
  ({ sendVerificationResult }) => sendVerificationResult
);
export const getVerifyVerificationResult = createSelector(
  [verificationSelector],
  ({ verifyVerificationResult }) => verifyVerificationResult
);

export const getSendVerificationLoading = createSelector(
  [verificationSelector],
  ({ sendVerificationLoading }) => sendVerificationLoading
);
export const getSendVerificationSuccess = createSelector(
  [verificationSelector],
  ({ sendVerificationSuccess }) => sendVerificationSuccess
);
export const getSendVerificationFailed = createSelector(
  [verificationSelector],
  ({ sendVerificationFailed }) => sendVerificationFailed
);

export const getVerifyVerificationLoading = createSelector(
  [verificationSelector],
  ({ verifyVerificationLoading }) => verifyVerificationLoading
);
export const getVerifyVerificationSuccess = createSelector(
  [verificationSelector],
  ({ verifyVerificationSuccess }) => verifyVerificationSuccess
);
export const getVerifyVerificationFailed = createSelector(
  [verificationSelector],
  ({ verifyVerificationFailed }) => verifyVerificationFailed
);

export const getIsSendVerificationHitted = createSelector(
  [verificationSelector],
  ({ isSendVerificationHitted }) => isSendVerificationHitted
);
export const getIsVerifyVerificationHitted = createSelector(
  [verificationSelector],
  ({ isVerifyVerificationHitted }) => isVerifyVerificationHitted
);
