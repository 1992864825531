import { createSelector } from "reselect";

const renewalAdminSelector = ({ renewalAdmin }) => renewalAdmin;

export const getRenewalMarket = createSelector(
  [renewalAdminSelector],
  ({ renewalMarket }) => renewalMarket
);
export const getRenewalBranches = createSelector(
  [renewalAdminSelector],
  ({ renewalBranches }) => renewalBranches
);
export const getRenewalCalculate = createSelector(
  [renewalAdminSelector],
  ({ renewalCalculate }) => renewalCalculate
);
export const getRenewalCreate = createSelector(
  [renewalAdminSelector],
  ({ renewalCreate }) => renewalCreate
);

export const getIsRenewalBranchesHasMore = createSelector(
  [renewalAdminSelector],
  ({ isRenewalBranchesHasMore }) => isRenewalBranchesHasMore
);

export const getFetchRenewalMarketParams = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketParams }) => fetchRenewalMarketParams
);
export const getFetchRenewalMarketLoading = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketLoading }) => fetchRenewalMarketLoading
);
export const getFetchRenewalMarketSuccess = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketSuccess }) => fetchRenewalMarketSuccess
);
export const getFetchRenewalMarketFailed = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketFailed }) => fetchRenewalMarketFailed
);

export const getFetchRenewalBranchesParams = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesParams }) => fetchRenewalBranchesParams
);
export const getFetchRenewalBranchesLoading = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesLoading }) => fetchRenewalBranchesLoading
);
export const getFetchRenewalBranchesSuccess = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesSuccess }) => fetchRenewalBranchesSuccess
);
export const getFetchRenewalBranchesFailed = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesFailed }) => fetchRenewalBranchesFailed
);

export const getRenewalCalculateLoading = createSelector(
  [renewalAdminSelector],
  ({ renewalCalculateLoading }) => renewalCalculateLoading
);
export const getRenewalCalculateSuccess = createSelector(
  [renewalAdminSelector],
  ({ renewalCalculateSuccess }) => renewalCalculateSuccess
);
export const getRenewalCalculateFailed = createSelector(
  [renewalAdminSelector],
  ({ renewalCalculateFailed }) => renewalCalculateFailed
);

export const getRenewalCreateLoading = createSelector(
  [renewalAdminSelector],
  ({ renewalCreateLoading }) => renewalCreateLoading
);
export const getRenewalCreateSuccess = createSelector(
  [renewalAdminSelector],
  ({ renewalCreateSuccess }) => renewalCreateSuccess
);
export const getRenewalCreateFailed = createSelector(
  [renewalAdminSelector],
  ({ renewalCreateFailed }) => renewalCreateFailed
);

export const getIsFetchRenewalMarketHitted = createSelector(
  [renewalAdminSelector],
  ({ isFetchRenewalMarketHitted }) => isFetchRenewalMarketHitted
);
export const getIsFetchRenewalBranchesHitted = createSelector(
  [renewalAdminSelector],
  ({ isFetchRenewalBranchesHitted }) => isFetchRenewalBranchesHitted
);
export const getIsRenewalCalculateHitted = createSelector(
  [renewalAdminSelector],
  ({ isRenewalCalculateHitted }) => isRenewalCalculateHitted
);
export const getIsRenewalCreateHitted = createSelector(
  [renewalAdminSelector],
  ({ isRenewalCreateHitted }) => isRenewalCreateHitted
);
