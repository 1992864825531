import { createSelector } from "reselect";

const selectPaymentMethodSelector = ({ selectPaymentMethod }) =>
  selectPaymentMethod;

export const getPaymentMethods = createSelector(
  [selectPaymentMethodSelector],
  ({ paymentMethods }) => paymentMethods
);
export const getSearchPaymentMethods = createSelector(
  [selectPaymentMethodSelector],
  ({ searchPaymentMethods }) => searchPaymentMethods
);

export const getIsPaymentMethodsHasMore = createSelector(
  [selectPaymentMethodSelector],
  ({ isPaymentMethodsHasMore }) => isPaymentMethodsHasMore
);
export const getIsSearchPaymentMethodsHasMore = createSelector(
  [selectPaymentMethodSelector],
  ({ isSearchPaymentMethodsHasMore }) => isSearchPaymentMethodsHasMore
);

export const getFetchPaymentMethodsParams = createSelector(
  [selectPaymentMethodSelector],
  ({ fetchPaymentMethodsParams }) => fetchPaymentMethodsParams
);
export const getFetchPaymentMethodsLoading = createSelector(
  [selectPaymentMethodSelector],
  ({ fetchPaymentMethodsLoading }) => fetchPaymentMethodsLoading
);
export const getFetchPaymentMethodsSuccess = createSelector(
  [selectPaymentMethodSelector],
  ({ fetchPaymentMethodsSuccess }) => fetchPaymentMethodsSuccess
);
export const getFetchPaymentMethodsFailed = createSelector(
  [selectPaymentMethodSelector],
  ({ fetchPaymentMethodsFailed }) => fetchPaymentMethodsFailed
);

export const getSearchPaymentMethodsParams = createSelector(
  [selectPaymentMethodSelector],
  ({ searchPaymentMethodsParams }) => searchPaymentMethodsParams
);
export const getSearchPaymentMethodsLoading = createSelector(
  [selectPaymentMethodSelector],
  ({ searchPaymentMethodsLoading }) => searchPaymentMethodsLoading
);
export const getSearchPaymentMethodsSuccess = createSelector(
  [selectPaymentMethodSelector],
  ({ searchPaymentMethodsSuccess }) => searchPaymentMethodsSuccess
);
export const getSearchPaymentMethodsFailed = createSelector(
  [selectPaymentMethodSelector],
  ({ searchPaymentMethodsFailed }) => searchPaymentMethodsFailed
);

export const getAppendPaymentMethodsParams = createSelector(
  [selectPaymentMethodSelector],
  ({ appendPaymentMethodsParams }) => appendPaymentMethodsParams
);
export const getAppendPaymentMethodsLoading = createSelector(
  [selectPaymentMethodSelector],
  ({ appendPaymentMethodsLoading }) => appendPaymentMethodsLoading
);
export const getAppendPaymentMethodsSuccess = createSelector(
  [selectPaymentMethodSelector],
  ({ appendPaymentMethodsSuccess }) => appendPaymentMethodsSuccess
);
export const getAppendPaymentMethodsFailed = createSelector(
  [selectPaymentMethodSelector],
  ({ appendPaymentMethodsFailed }) => appendPaymentMethodsFailed
);

export const getIsFetchPaymentMethodsHitted = createSelector(
  [selectPaymentMethodSelector],
  ({ isFetchPaymentMethodsHitted }) => isFetchPaymentMethodsHitted
);
export const getIsSearchPaymentMethodsHitted = createSelector(
  [selectPaymentMethodSelector],
  ({ isSearchPaymentMethodsHitted }) => isSearchPaymentMethodsHitted
);
export const getIsAppendPaymentMethodsHitted = createSelector(
  [selectPaymentMethodSelector],
  ({ isAppendPaymentMethodsHitted }) => isAppendPaymentMethodsHitted
);
