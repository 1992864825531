import { createSelector } from "reselect";

const productCategorySortSelector = ({ productCategorySort }) => productCategorySort;

export const getCreateOrUpdateProductCategorySortLoading = createSelector(
  [productCategorySortSelector],
  ({ createOrUpdateProductCategorySortLoading }) => createOrUpdateProductCategorySortLoading
);

export const getCreateOrUpdateProductCategorySortSuccess = createSelector(
  [productCategorySortSelector],
  ({ createOrUpdateProductCategorySortSuccess }) => createOrUpdateProductCategorySortSuccess
);

export const getCreateOrUpdateProductCategorySortFailed = createSelector(
  [productCategorySortSelector],
  ({ createOrUpdateProductCategorySortFailed }) => createOrUpdateProductCategorySortFailed
);
