import styled, { css } from "styled-components";

import { MODAL_POSITIONS, MODAL_SIZES } from "./modal.component";

export const ModalContainer = styled.div`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: max-content;
  padding: 1rem 2rem;
  background-color: #ffffff;

  @media (min-width: 576px) {
    width: 580px;
    min-width: 580px;
  }

  @media (min-width: 992px) {
    width: ${({ modalSize }) => {
      switch (modalSize) {
        case MODAL_SIZES.EXTRA_LARGE:
        case MODAL_SIZES.LARGE:
          return "840px";
        case MODAL_SIZES.SMALL:
          return "500px";
        case MODAL_SIZES.NORMAL:
        default:
          return "720px";
      }
    }};
    min-width: ${({ modalSize }) => {
      switch (modalSize) {
        case MODAL_SIZES.EXTRA_LARGE:
        case MODAL_SIZES.LARGE:
          return "840px";
        case MODAL_SIZES.SMALL:
          return "500px";
        case MODAL_SIZES.NORMAL:
        default:
          return "720px";
      }
    }};
  }

  @media (min-width: 1200px) {
    width: ${({ modalSize }) => {
      switch (modalSize) {
        case MODAL_SIZES.EXTRA_LARGE:
          return "1140px";
        case MODAL_SIZES.LARGE:
          return "840px";
        case MODAL_SIZES.SMALL:
          return "500px";
        case MODAL_SIZES.NORMAL:
        default:
          return "720px";
      }
    }};
    min-width: ${({ modalSize }) => {
      switch (modalSize) {
        case MODAL_SIZES.EXTRA_LARGE:
          return "1140px";
        case MODAL_SIZES.LARGE:
          return "840px";
        case MODAL_SIZES.SMALL:
          return "500px";
        case MODAL_SIZES.NORMAL:
        default:
          return "720px";
      }
    }};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
  column-gap: 1rem;
`;

export const ModalInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.15rem;
`;

export const ModalIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;

  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  border-radius: 50%;
  background-color: #f2f2f4;

  ${({ isLarge }) =>
    isLarge &&
    css`
      width: 3.25rem;
      min-width: 3.25rem;
      max-width: 3.25rem;
      height: 3.25rem;
      min-height: 3.25rem;
      max-height: 3.25rem;
    `}

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;

    ${({ isLarge }) =>
      isLarge &&
      css`
        width: 1.75rem;
        min-width: 1.75rem;
        max-width: 1.75rem;
        height: 1.75rem;
        min-height: 1.75rem;
        max-height: 1.75rem;
      `}

    &[stroke] {
      stroke: #2900ee;
    }

    path,
    polyline,
    line {
      &[fill] {
        fill: #2900ee;
      }
      &[stroke] {
        stroke: #2900ee;
      }
    }
  }
`;

export const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const ModalDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
`;

export const ModalActionGroup = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 1rem;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-color: rgba(150, 145, 172, 0.56);
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0.2s, visibility 0s ease 0.2s;
  z-index: 105;

  ${({ isActive }) =>
    isActive &&
    css`
      transition: opacity 0.3s, visibility 0s;
      opacity: 1;
      visibility: visible;
    `}

  ${({ modalPosition }) =>
    modalPosition === MODAL_POSITIONS.CENTER
      ? css`
          align-items: stretch;
          justify-content: stretch;
        `
      : modalPosition === MODAL_POSITIONS.RIGHT
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: flex-start;
        `}

  ${ModalContainer} {
    ${ModalHeader} {
      ${ModalActionGroup} {
        margin-left: ${({ isRightToLeft }) =>
          !isRightToLeft ? "auto" : "unset"};
        margin-right: ${({ isRightToLeft }) =>
          isRightToLeft ? "auto" : "unset"};
      }
    }

    ${({ modalPosition, isRightToLeft }) =>
      modalPosition === MODAL_POSITIONS.CENTER &&
      (isRightToLeft
        ? css`
            right: 50%;
            min-height: auto;
            transform: translateX(50%);
            margin: 2rem 0;
          `
        : css`
            left: 50%;
            min-height: auto;
            transform: translateX(-50%);
            margin: 2rem 0;
          `)}

    ${({ modalPosition, isRightToLeft }) =>
      ((modalPosition === MODAL_POSITIONS.RIGHT && !isRightToLeft) ||
        (modalPosition === MODAL_POSITIONS.LEFT && isRightToLeft)) &&
      css`
        left: initial;
        right: -100%;
        transition: right 0.3s;
      `}

    ${({ modalPosition, isRightToLeft }) =>
      ((modalPosition === MODAL_POSITIONS.LEFT && !isRightToLeft) ||
        (modalPosition === MODAL_POSITIONS.RIGHT && isRightToLeft)) &&
      css`
        right: initial;
        left: -100%;
        transition: left 0.3s;
      `}

    ${({ modalPosition, isRightToLeft, isActive }) =>
      ((modalPosition === MODAL_POSITIONS.RIGHT &&
        !isRightToLeft &&
        isActive) ||
        (modalPosition === MODAL_POSITIONS.LEFT &&
          isRightToLeft &&
          isActive)) &&
      css`
        left: initial;
        right: 0;
      `}

    ${({ modalPosition, isRightToLeft, isActive }) =>
      ((modalPosition === MODAL_POSITIONS.LEFT && !isRightToLeft && isActive) ||
        (modalPosition === MODAL_POSITIONS.RIGHT &&
          isRightToLeft &&
          isActive)) &&
      css`
        right: initial;
        left: 0;
      `}

    @media (min-width: 576px) {
      ${({ modalPosition }) =>
        modalPosition === MODAL_POSITIONS.CENTER &&
        css`
          border-radius: 16px;
        `}

      ${({ modalPosition, isRightToLeft }) =>
        ((modalPosition === MODAL_POSITIONS.RIGHT && !isRightToLeft) ||
          (modalPosition === MODAL_POSITIONS.LEFT && isRightToLeft)) &&
        css`
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        `}

      ${({ modalPosition, isRightToLeft }) =>
        ((modalPosition === MODAL_POSITIONS.LEFT && !isRightToLeft) ||
          (modalPosition === MODAL_POSITIONS.RIGHT && isRightToLeft)) &&
        css`
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        `}
    }
  }
`;
