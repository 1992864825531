import { createSelector } from "reselect";

const globalSelector = ({ global }) => global;

export const getIsRightToLeft = createSelector(
  [globalSelector],
  ({ isRightToLeft }) => isRightToLeft
);
export const getIsGlobalInitialized = createSelector(
  [globalSelector],
  ({ isGlobalInitialized }) => isGlobalInitialized
);
export const getIsGlobalLoading = createSelector(
  [globalSelector],
  ({ isGlobalLoading }) => isGlobalLoading
);
export const getCurrentLanguage = createSelector(
  [globalSelector],
  ({ currentLanguage }) => currentLanguage
);

export const getMasterPermissionId = createSelector(
  [globalSelector],
  ({ masterPermissionId }) => masterPermissionId
);
export const getSecondPermissionId = createSelector(
  [globalSelector],
  ({ secondPermissionId }) => secondPermissionId
);

export const getMasterPermission = createSelector(
  [globalSelector],
  ({ masterPermission }) => masterPermission
);
export const getSecondPermission = createSelector(
  [globalSelector],
  ({ secondPermission }) => secondPermission
);

export const getMasterAccessPages = createSelector(
  [globalSelector],
  ({ masterAccessPages }) => masterAccessPages
);
export const getMasterForbidPages = createSelector(
  [globalSelector],
  ({ masterForbidPages }) => masterForbidPages
);
export const getMasterPermitPages = createSelector(
  [globalSelector],
  ({ masterPermitPages }) => masterPermitPages
);

export const getSecondAccessPages = createSelector(
  [globalSelector],
  ({ secondAccessPages }) => secondAccessPages
);
export const getSecondForbidPages = createSelector(
  [globalSelector],
  ({ secondForbidPages }) => secondForbidPages
);
export const getSecondPermitPages = createSelector(
  [globalSelector],
  ({ secondPermitPages }) => secondPermitPages
);

export const getIsMarketAdministrator = createSelector(
  [globalSelector],
  ({ isMarketAdministrator }) => isMarketAdministrator
);
export const getIsCenterAdministrator = createSelector(
  [globalSelector],
  ({ isCenterAdministrator }) => isCenterAdministrator
);

export const getCurrentMarket = createSelector(
  [globalSelector],
  ({ currentMarket }) => currentMarket
);
export const getCurrentBranch = createSelector(
  [globalSelector],
  ({ currentBranch }) => currentBranch
);
export const getCurrentMerchant = createSelector(
  [globalSelector],
  ({ currentMerchant }) => currentMerchant
);
export const getCurrentSchedules = createSelector(
  [globalSelector],
  ({ currentSchedules }) => currentSchedules
);

export const getCurrentMarketId = createSelector(
  [globalSelector],
  ({ currentMarketId }) => currentMarketId
);
export const getCurrentBranchId = createSelector(
  [globalSelector],
  ({ currentBranchId }) => currentBranchId
);
export const getCurrentMerchantId = createSelector(
  [globalSelector],
  ({ currentMerchantId }) => currentMerchantId
);

export const getIsCurrentKitchenActive = createSelector(
  [globalSelector],
  ({ isCurrentKitchenActive }) => isCurrentKitchenActive
);

export const getTodaySchedule = createSelector(
  [globalSelector],
  ({ todaySchedule }) => todaySchedule
);
export const getIsTodayInSchedule = createSelector(
  [globalSelector],
  ({ isTodayInSchedule }) => isTodayInSchedule
);

export const getTodayAttendance = createSelector(
  [globalSelector],
  ({ todayAttendance }) => todayAttendance
);
export const getIsTodayHasAttendance = createSelector(
  [globalSelector],
  ({ isTodayHasAttendance }) => isTodayHasAttendance
);

export const getMarketPlans = createSelector(
  [globalSelector],
  ({ marketPlans }) => marketPlans
);
export const getBranchPlans = createSelector(
  [globalSelector],
  ({ branchPlans }) => branchPlans
);

export const getIsHasActivePlan = createSelector(
  [globalSelector],
  ({ isHasActivePlan }) => isHasActivePlan
);
export const getIsHasActiveAdvancedPlan = createSelector(
  [globalSelector],
  ({ isHasActiveAdvancedPlan }) => isHasActiveAdvancedPlan
);
export const getIsHasActiveBasicPlan = createSelector(
  [globalSelector],
  ({ isHasActiveBasicPlan }) => isHasActiveBasicPlan
);
export const getIsHasActiveTrialPlan = createSelector(
  [globalSelector],
  ({ isHasActiveTrialPlan }) => isHasActiveTrialPlan
);

export const getIsMarketHasPlan = createSelector(
  [globalSelector],
  ({ isMarketHasPlan }) => isMarketHasPlan
);
export const getIsMarketHasAdvancedPlan = createSelector(
  [globalSelector],
  ({ isMarketHasAdvancedPlan }) => isMarketHasAdvancedPlan
);
export const getIsMarketHasBasicPlan = createSelector(
  [globalSelector],
  ({ isMarketHasBasicPlan }) => isMarketHasBasicPlan
);
export const getIsMarketHasTrialPlan = createSelector(
  [globalSelector],
  ({ isMarketHasTrialPlan }) => isMarketHasTrialPlan
);

export const getIsBranchHasPlan = createSelector(
  [globalSelector],
  ({ isBranchHasPlan }) => isBranchHasPlan
);
export const getIsBranchHasAdvancedPlan = createSelector(
  [globalSelector],
  ({ isBranchHasAdvancedPlan }) => isBranchHasAdvancedPlan
);
export const getIsBranchHasBasicPlan = createSelector(
  [globalSelector],
  ({ isBranchHasBasicPlan }) => isBranchHasBasicPlan
);
export const getIsBranchHasTrialPlan = createSelector(
  [globalSelector],
  ({ isBranchHasTrialPlan }) => isBranchHasTrialPlan
);

export const getPlanBillingCycles = createSelector(
  [globalSelector],
  ({ planBillingCycles }) => planBillingCycles
);
export const getGlobalSubscriptions = createSelector(
  [globalSelector],
  ({ globalSubscriptions }) => globalSubscriptions
);
export const getGlobalFeatureSubscriptions = createSelector(
  [globalSelector],
  ({ globalFeatureSubscriptions }) => globalFeatureSubscriptions
);

export const getGlobalBanners = createSelector(
  [globalSelector],
  ({ globalBanners }) => globalBanners
);
export const getGlobalBannerTargets = createSelector(
  [globalSelector],
  ({ globalBannerTargets }) => globalBannerTargets
);

export const getIsGlobalSubscriptionsHasMore = createSelector(
  [globalSelector],
  ({ isGlobalSubscriptionsHasMore }) => isGlobalSubscriptionsHasMore
);
export const getIsGlobalFeatureSubscriptionsHasMore = createSelector(
  [globalSelector],
  ({ isGlobalFeatureSubscriptionsHasMore }) =>
    isGlobalFeatureSubscriptionsHasMore
);

export const getIsGlobalBannersHasMore = createSelector(
  [globalSelector],
  ({ isGlobalBannersHasMore }) => isGlobalBannersHasMore
);
export const getIsGlobalBannerTargetsHasMore = createSelector(
  [globalSelector],
  ({ isGlobalBannerTargetsHasMore }) => isGlobalBannerTargetsHasMore
);

export const getFetchInitializeLoading = createSelector(
  [globalSelector],
  ({ fetchInitializeLoading }) => fetchInitializeLoading
);
export const getFetchInitializeSuccess = createSelector(
  [globalSelector],
  ({ fetchInitializeSuccess }) => fetchInitializeSuccess
);
export const getFetchInitializeFailed = createSelector(
  [globalSelector],
  ({ fetchInitializeFailed }) => fetchInitializeFailed
);

export const getFetchMasterPermissionLoading = createSelector(
  [globalSelector],
  ({ fetchMasterPermissionLoading }) => fetchMasterPermissionLoading
);
export const getFetchMasterPermissionSuccess = createSelector(
  [globalSelector],
  ({ fetchMasterPermissionSuccess }) => fetchMasterPermissionSuccess
);
export const getFetchMasterPermissionFailed = createSelector(
  [globalSelector],
  ({ fetchMasterPermissionFailed }) => fetchMasterPermissionFailed
);

export const getFetchSecondPermissionLoading = createSelector(
  [globalSelector],
  ({ fetchSecondPermissionLoading }) => fetchSecondPermissionLoading
);
export const getFetchSecondPermissionSuccess = createSelector(
  [globalSelector],
  ({ fetchSecondPermissionSuccess }) => fetchSecondPermissionSuccess
);
export const getFetchSecondPermissionFailed = createSelector(
  [globalSelector],
  ({ fetchSecondPermissionFailed }) => fetchSecondPermissionFailed
);

export const getFetchCurrentMarketLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketLoading }) => fetchCurrentMarketLoading
);
export const getFetchCurrentMarketSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketSuccess }) => fetchCurrentMarketSuccess
);
export const getFetchCurrentMarketFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketFailed }) => fetchCurrentMarketFailed
);

export const getFetchCurrentBranchLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchLoading }) => fetchCurrentBranchLoading
);
export const getFetchCurrentBranchSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchSuccess }) => fetchCurrentBranchSuccess
);
export const getFetchCurrentBranchFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchFailed }) => fetchCurrentBranchFailed
);

export const getFetchCurrentMerchantLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantLoading }) => fetchCurrentMerchantLoading
);
export const getFetchCurrentMerchantSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantSuccess }) => fetchCurrentMerchantSuccess
);
export const getFetchCurrentMerchantFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantFailed }) => fetchCurrentMerchantFailed
);

export const getFetchCurrentSchedulesIncludes = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesIncludes }) => fetchCurrentSchedulesIncludes
);
export const getFetchCurrentSchedulesFilterMarketId = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFilterMarketId }) =>
    fetchCurrentSchedulesFilterMarketId
);
export const getFetchCurrentSchedulesFilterBranchId = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFilterBranchId }) =>
    fetchCurrentSchedulesFilterBranchId
);
export const getFetchCurrentSchedulesLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesLoading }) => fetchCurrentSchedulesLoading
);
export const getFetchCurrentSchedulesSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesSuccess }) => fetchCurrentSchedulesSuccess
);
export const getFetchCurrentSchedulesFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFailed }) => fetchCurrentSchedulesFailed
);

export const getFetchTodayAttendanceMarketId = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceMarketId }) => fetchTodayAttendanceMarketId
);
export const getFetchTodayAttendanceBranchId = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceBranchId }) => fetchTodayAttendanceBranchId
);
export const getFetchTodayAttendanceLoading = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceLoading }) => fetchTodayAttendanceLoading
);
export const getFetchTodayAttendanceSuccess = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceSuccess }) => fetchTodayAttendanceSuccess
);
export const getFetchTodayAttendanceFailed = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceFailed }) => fetchTodayAttendanceFailed
);

export const getFetchGlobalSubscriptionsSearch = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsSearch }) => fetchGlobalSubscriptionsSearch
);
export const getFetchGlobalSubscriptionsSort = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsSort }) => fetchGlobalSubscriptionsSort
);
export const getFetchGlobalSubscriptionsKeyBy = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsKeyBy }) => fetchGlobalSubscriptionsKeyBy
);
export const getFetchGlobalSubscriptionsPage = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsPage }) => fetchGlobalSubscriptionsPage
);
export const getFetchGlobalSubscriptionsPerPage = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsPerPage }) => fetchGlobalSubscriptionsPerPage
);
export const getFetchGlobalSubscriptionsIncludes = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsIncludes }) => fetchGlobalSubscriptionsIncludes
);
export const getFetchGlobalSubscriptionsLoading = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsLoading }) => fetchGlobalSubscriptionsLoading
);
export const getFetchGlobalSubscriptionsSuccess = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsSuccess }) => fetchGlobalSubscriptionsSuccess
);
export const getFetchGlobalSubscriptionsFailed = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsFailed }) => fetchGlobalSubscriptionsFailed
);

export const getFetchGlobalFeatureSubscriptionsSearch = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsSearch }) =>
    fetchGlobalFeatureSubscriptionsSearch
);
export const getFetchGlobalFeatureSubscriptionsSort = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsSort }) =>
    fetchGlobalFeatureSubscriptionsSort
);
export const getFetchGlobalFeatureSubscriptionsKeyBy = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsKeyBy }) =>
    fetchGlobalFeatureSubscriptionsKeyBy
);
export const getFetchGlobalFeatureSubscriptionsPage = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsPage }) =>
    fetchGlobalFeatureSubscriptionsPage
);
export const getFetchGlobalFeatureSubscriptionsPerPage = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsPerPage }) =>
    fetchGlobalFeatureSubscriptionsPerPage
);
export const getFetchGlobalFeatureSubscriptionsIncludes = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsIncludes }) =>
    fetchGlobalFeatureSubscriptionsIncludes
);
export const getFetchGlobalFeatureSubscriptionsFilterIsHighlight =
  createSelector(
    [globalSelector],
    ({ fetchGlobalFeatureSubscriptionsFilterIsHighlight }) =>
      fetchGlobalFeatureSubscriptionsFilterIsHighlight
  );
export const getFetchGlobalFeatureSubscriptionsLoading = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsLoading }) =>
    fetchGlobalFeatureSubscriptionsLoading
);
export const getFetchGlobalFeatureSubscriptionsSuccess = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsSuccess }) =>
    fetchGlobalFeatureSubscriptionsSuccess
);
export const getFetchGlobalFeatureSubscriptionsFailed = createSelector(
  [globalSelector],
  ({ fetchGlobalFeatureSubscriptionsFailed }) =>
    fetchGlobalFeatureSubscriptionsFailed
);

export const getFetchGlobalBannersSearch = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersSearch }) => fetchGlobalBannersSearch
);
export const getFetchGlobalBannersSort = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersSort }) => fetchGlobalBannersSort
);
export const getFetchGlobalBannersKeyBy = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersKeyBy }) => fetchGlobalBannersKeyBy
);
export const getFetchGlobalBannersPage = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersPage }) => fetchGlobalBannersPage
);
export const getFetchGlobalBannersPerPage = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersPerPage }) => fetchGlobalBannersPerPage
);
export const getFetchGlobalBannersIncludes = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersIncludes }) => fetchGlobalBannersIncludes
);
export const getFetchGlobalBannersLoading = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersLoading }) => fetchGlobalBannersLoading
);
export const getFetchGlobalBannersSuccess = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersSuccess }) => fetchGlobalBannersSuccess
);
export const getFetchGlobalBannersFailed = createSelector(
  [globalSelector],
  ({ fetchGlobalBannersFailed }) => fetchGlobalBannersFailed
);

export const getFetchGlobalBannerTargetsSearch = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsSearch }) => fetchGlobalBannerTargetsSearch
);
export const getFetchGlobalBannerTargetsSort = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsSort }) => fetchGlobalBannerTargetsSort
);
export const getFetchGlobalBannerTargetsKeyBy = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsKeyBy }) => fetchGlobalBannerTargetsKeyBy
);
export const getFetchGlobalBannerTargetsPage = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsPage }) => fetchGlobalBannerTargetsPage
);
export const getFetchGlobalBannerTargetsPerPage = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsPerPage }) => fetchGlobalBannerTargetsPerPage
);
export const getFetchGlobalBannerTargetsIncludes = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsIncludes }) => fetchGlobalBannerTargetsIncludes
);
export const getFetchGlobalBannerTargetsFilterRole = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsFilterRole }) => fetchGlobalBannerTargetsFilterRole
);
export const getFetchGlobalBannerTargetsLoading = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsLoading }) => fetchGlobalBannerTargetsLoading
);
export const getFetchGlobalBannerTargetsSuccess = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsSuccess }) => fetchGlobalBannerTargetsSuccess
);
export const getFetchGlobalBannerTargetsFailed = createSelector(
  [globalSelector],
  ({ fetchGlobalBannerTargetsFailed }) => fetchGlobalBannerTargetsFailed
);

export const getIsFetchInitializeHitted = createSelector(
  [globalSelector],
  ({ isFetchInitializeHitted }) => isFetchInitializeHitted
);
export const getIsFetchMasterPermissionHitted = createSelector(
  [globalSelector],
  ({ isFetchMasterPermissionHitted }) => isFetchMasterPermissionHitted
);
export const getIsFetchSecondPermissionHitted = createSelector(
  [globalSelector],
  ({ isFetchSecondPermissionHitted }) => isFetchSecondPermissionHitted
);
export const getIsFetchCurrentMarketHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentMarketHitted }) => isFetchCurrentMarketHitted
);
export const getIsFetchCurrentBranchHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentBranchHitted }) => isFetchCurrentBranchHitted
);
export const getIsFetchCurrentMerchantHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentMerchantHitted }) => isFetchCurrentMerchantHitted
);
export const getIsFetchCurrentSchedulesHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentSchedulesHitted }) => isFetchCurrentSchedulesHitted
);
export const getIsFetchTodayAttendanceHitted = createSelector(
  [globalSelector],
  ({ isFetchTodayAttendanceHitted }) => isFetchTodayAttendanceHitted
);
export const getIsFetchGlobalSubscriptionsHitted = createSelector(
  [globalSelector],
  ({ isFetchGlobalSubscriptionsHitted }) => isFetchGlobalSubscriptionsHitted
);
export const getIsFetchGlobalFeatureSubscriptionsHitted = createSelector(
  [globalSelector],
  ({ isFetchGlobalFeatureSubscriptionsHitted }) =>
    isFetchGlobalFeatureSubscriptionsHitted
);
export const getIsFetchGlobalBannersHitted = createSelector(
  [globalSelector],
  ({ isFetchGlobalBannersHitted }) => isFetchGlobalBannersHitted
);
export const getIsFetchGlobalBannerTargetsHitted = createSelector(
  [globalSelector],
  ({ isFetchGlobalBannerTargetsHitted }) => isFetchGlobalBannerTargetsHitted
);
