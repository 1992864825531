import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateSectorFailed,
  setCreateSectorSuccess,
  setDeleteSectorFailed,
  setDeleteSectorSuccess,
  setFetchSectorFailed,
  setFetchSectorsFailed,
  setFetchSelectSectorsFailed,
  setUpdateSectorFailed,
  setUpdateSectorSuccess,
} from "../../store/sector/sector.action";
import {
  getCreateSectorFailed,
  getCreateSectorSuccess,
  getDeleteSectorFailed,
  getDeleteSectorSuccess,
  getFetchSectorFailed,
  getFetchSectorsFailed,
  getFetchSelectSectorsFailed,
  getUpdateSectorFailed,
  getUpdateSectorSuccess,
} from "../../store/sector/sector.selector";

const SectorPopup = () => {
  const dispatch = useDispatch();

  const createSectorSuccess = useSelector(getCreateSectorSuccess);
  const updateSectorSuccess = useSelector(getUpdateSectorSuccess);
  const deleteSectorSuccess = useSelector(getDeleteSectorSuccess);
  const fetchSectorsFailed = useSelector(getFetchSectorsFailed);
  const fetchSelectSectorsFailed = useSelector(getFetchSelectSectorsFailed);
  const fetchSectorFailed = useSelector(getFetchSectorFailed);
  const createSectorFailed = useSelector(getCreateSectorFailed);
  const updateSectorFailed = useSelector(getUpdateSectorFailed);
  const deleteSectorFailed = useSelector(getDeleteSectorFailed);

  useEffect(() => {
    if (createSectorSuccess !== null || updateSectorSuccess !== null || deleteSectorSuccess !== null) {
      showSuccessMessage(createSectorSuccess ?? updateSectorSuccess ?? deleteSectorSuccess);
      dispatch(setIsModalOpen(false));

      if (createSectorSuccess !== null) dispatch(setCreateSectorSuccess(null));
      if (updateSectorSuccess !== null) dispatch(setUpdateSectorSuccess(null));
      if (deleteSectorSuccess !== null) dispatch(setDeleteSectorSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSectorSuccess, updateSectorSuccess, deleteSectorSuccess]);

  useEffect(() => {
    if (
      fetchSectorsFailed !== null ||
      fetchSelectSectorsFailed !== null ||
      fetchSectorFailed !== null ||
      createSectorFailed !== null ||
      updateSectorFailed !== null ||
      deleteSectorFailed !== null
    ) {
      showErrorMessage(
        fetchSectorsFailed ??
          fetchSelectSectorsFailed ??
          fetchSectorFailed ??
          createSectorFailed ??
          updateSectorFailed ??
          deleteSectorFailed
      );

      if (fetchSectorsFailed !== null) dispatch(setFetchSectorsFailed(null));
      if (fetchSelectSectorsFailed !== null) dispatch(setFetchSelectSectorsFailed(null));
      if (fetchSectorFailed !== null) dispatch(setFetchSectorFailed(null));
      if (createSectorFailed !== null) dispatch(setCreateSectorFailed(null));
      if (updateSectorFailed !== null) dispatch(setUpdateSectorFailed(null));
      if (deleteSectorFailed !== null) dispatch(setDeleteSectorFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchSectorsFailed,
    fetchSelectSectorsFailed,
    fetchSectorFailed,
    createSectorFailed,
    updateSectorFailed,
    deleteSectorFailed,
  ]);

  return <></>;
};

export default SectorPopup;
