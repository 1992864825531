import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setExportPdfOrderInvoiceFailed,
  setExportPdfOrderInvoiceSuccess,
  setExportPdfOrderKitchenFailed,
  setExportPdfOrderKitchenSuccess,
} from "../../store/export-pdf/export-pdf.action";
import {
  getExportPdfOrderInvoiceFailed,
  getExportPdfOrderInvoiceSuccess,
  getExportPdfOrderKitchenFailed,
  getExportPdfOrderKitchenSuccess,
} from "../../store/export-pdf/export-pdf.selector";

const ExportPdfPopup = () => {
  const dispatch = useDispatch();

  const exportPdfOrderInvoiceSuccess = useSelector(
    getExportPdfOrderInvoiceSuccess
  );
  const exportPdfOrderKitchenSuccess = useSelector(
    getExportPdfOrderKitchenSuccess
  );

  const exportPdfOrderInvoiceFailed = useSelector(
    getExportPdfOrderInvoiceFailed
  );
  const exportPdfOrderKitchenFailed = useSelector(
    getExportPdfOrderKitchenFailed
  );

  useEffect(() => {
    if (
      exportPdfOrderInvoiceSuccess !== null ||
      exportPdfOrderKitchenSuccess !== null
    ) {
      showSuccessMessage(
        exportPdfOrderInvoiceSuccess ?? exportPdfOrderKitchenSuccess
      );

      if (exportPdfOrderInvoiceSuccess !== null)
        dispatch(setExportPdfOrderInvoiceSuccess(null));
      if (exportPdfOrderKitchenSuccess !== null)
        dispatch(setExportPdfOrderKitchenSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPdfOrderInvoiceSuccess, exportPdfOrderKitchenSuccess]);

  useEffect(() => {
    if (
      exportPdfOrderInvoiceFailed !== null ||
      exportPdfOrderKitchenFailed !== null
    ) {
      showErrorMessage(
        exportPdfOrderInvoiceFailed ?? exportPdfOrderKitchenFailed
      );

      if (exportPdfOrderInvoiceFailed !== null)
        dispatch(setExportPdfOrderInvoiceFailed(null));
      if (exportPdfOrderKitchenFailed !== null)
        dispatch(setExportPdfOrderKitchenFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPdfOrderInvoiceFailed, exportPdfOrderKitchenFailed]);

  return <></>;
};

export default ExportPdfPopup;
