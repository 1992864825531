import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";

import {
  ApplicationFaiSequenceCheck,
  ApplicationFaiSequenceContainer,
  ApplicationFaiSequenceHeader,
  ApplicationFaiSequenceIcon,
  ApplicationFaiSequenceInfo,
  ApplicationFaiSequenceName,
} from "./application-fai-sequence.style";

import { Tick02Icon } from "hugeicons-react";

const ApplicationFaiSequence = ({ icon, name, info, ...props }) => {
  const { t } = useTranslation();

  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <ApplicationFaiSequenceContainer isRightToLeft={isRightToLeft} {...props}>
      <ApplicationFaiSequenceHeader>
        {!isEmpty(icon) && (
          <ApplicationFaiSequenceIcon>{icon}</ApplicationFaiSequenceIcon>
        )}
        <ApplicationFaiSequenceCheck>
          <Tick02Icon />
        </ApplicationFaiSequenceCheck>
      </ApplicationFaiSequenceHeader>
      <ApplicationFaiSequenceName>
        {!isEmpty(name) ? name : t("No name")}
      </ApplicationFaiSequenceName>
      <ApplicationFaiSequenceInfo>
        {!isEmpty(info) ? info : t("No description")}
      </ApplicationFaiSequenceInfo>
    </ApplicationFaiSequenceContainer>
  );
};

export default ApplicationFaiSequence;
