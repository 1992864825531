import { createAction } from "../../utils/store.utils";

import SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES from "./select-permission-subscriber.type";

export const setPermissions = (permissions) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_PERMISSIONS,
    permissions
  );
export const setSearchPermissions = (searchPermissions) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS,
    searchPermissions
  );

export const appendPermissions = (permissions) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_PERMISSIONS,
    permissions
  );
export const appendSearchPermissions = (searchPermissions) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_SEARCH_PERMISSIONS,
    searchPermissions
  );

export const setIsPermissionsHasMore = (isPermissionsHasMore) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_PERMISSIONS_HAS_MORE,
    isPermissionsHasMore
  );
export const setIsSearchPermissionsHasMore = (isSearchPermissionsHasMore) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_PERMISSIONS_HAS_MORE,
    isSearchPermissionsHasMore
  );

export const setFetchPermissionsParams = (fetchPermissionsParams) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_PARAMS,
    fetchPermissionsParams
  );
export const setFetchPermissionsLoading = (fetchPermissionsLoading) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_LOADING,
    fetchPermissionsLoading
  );
export const setFetchPermissionsSuccess = (fetchPermissionsSuccess) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SUCCESS,
    fetchPermissionsSuccess
  );
export const setFetchPermissionsFailed = (fetchPermissionsFailed) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FAILED,
    fetchPermissionsFailed
  );

export const setSearchPermissionsParams = (searchPermissionsParams) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_PARAMS,
    searchPermissionsParams
  );
export const setSearchPermissionsLoading = (searchPermissionsLoading) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_LOADING,
    searchPermissionsLoading
  );
export const setSearchPermissionsSuccess = (searchPermissionsSuccess) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_SUCCESS,
    searchPermissionsSuccess
  );
export const setSearchPermissionsFailed = (searchPermissionsFailed) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_PERMISSIONS_FAILED,
    searchPermissionsFailed
  );

export const setAppendPermissionsParams = (appendPermissionsParams) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_PARAMS,
    appendPermissionsParams
  );
export const setAppendPermissionsLoading = (appendPermissionsLoading) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_LOADING,
    appendPermissionsLoading
  );
export const setAppendPermissionsSuccess = (appendPermissionsSuccess) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_SUCCESS,
    appendPermissionsSuccess
  );
export const setAppendPermissionsFailed = (appendPermissionsFailed) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_PERMISSIONS_FAILED,
    appendPermissionsFailed
  );

export const setIsFetchPermissionsHitted = (isFetchPermissionsHitted) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_PERMISSIONS_HITTED,
    isFetchPermissionsHitted
  );
export const setIsSearchPermissionsHitted = (isSearchPermissionsHitted) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_PERMISSIONS_HITTED,
    isSearchPermissionsHitted
  );
export const setIsAppendPermissionsHitted = (isAppendPermissionsHitted) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_APPEND_PERMISSIONS_HITTED,
    isAppendPermissionsHitted
  );

export const fetchPermissionsStart = (params) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_PERMISSIONS_START,
    params
  );
export const searchPermissionsStart = (params) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SEARCH_PERMISSIONS_START,
    params
  );
export const appendPermissionsStart = (params) =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_PERMISSIONS_START,
    params
  );

export const resetPermissionSubscriberReducer = () =>
  createAction(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.RESET_SELECT_PERMISSION_SUBSCRIBER_REDUCER
  );
