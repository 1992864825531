const SELECT_SUBSCRIBER_ACTION_TYPES = {
  RESET_SELECT_SUBSCRIBER_REDUCER:
    "select-subscriber/RESET_SELECT_SUBSCRIBER_REDUCER",

  SET_SUBSCRIBERS: "select-subscriber/SET_SUBSCRIBERS",
  SET_SEARCH_SUBSCRIBERS: "select-subscriber/SET_SEARCH_SUBSCRIBERS",

  APPEND_SUBSCRIBERS: "select-subscriber/APPEND_SUBSCRIBERS",
  APPEND_SEARCH_SUBSCRIBERS: "select-subscriber/APPEND_SEARCH_SUBSCRIBERS",

  SET_IS_SUBSCRIBERS_HAS_MORE: "select-subscriber/SET_IS_SUBSCRIBERS_HAS_MORE",
  SET_IS_SEARCH_SUBSCRIBERS_HAS_MORE:
    "select-subscriber/SET_IS_SEARCH_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_SUBSCRIBERS_PARAMS:
    "select-subscriber/SET_FETCH_SUBSCRIBERS_PARAMS",
  SET_FETCH_SUBSCRIBERS_LOADING:
    "select-subscriber/SET_FETCH_SUBSCRIBERS_LOADING",
  SET_FETCH_SUBSCRIBERS_SUCCESS:
    "select-subscriber/SET_FETCH_SUBSCRIBERS_SUCCESS",
  SET_FETCH_SUBSCRIBERS_FAILED:
    "select-subscriber/SET_FETCH_SUBSCRIBERS_FAILED",

  SET_SEARCH_SUBSCRIBERS_PARAMS:
    "select-subscriber/SET_SEARCH_SUBSCRIBERS_PARAMS",
  SET_SEARCH_SUBSCRIBERS_LOADING:
    "select-subscriber/SET_SEARCH_SUBSCRIBERS_LOADING",
  SET_SEARCH_SUBSCRIBERS_SUCCESS:
    "select-subscriber/SET_SEARCH_SUBSCRIBERS_SUCCESS",
  SET_SEARCH_SUBSCRIBERS_FAILED:
    "select-subscriber/SET_SEARCH_SUBSCRIBERS_FAILED",

  SET_APPEND_SUBSCRIBERS_PARAMS:
    "select-subscriber/SET_APPEND_SUBSCRIBERS_PARAMS",
  SET_APPEND_SUBSCRIBERS_LOADING:
    "select-subscriber/SET_APPEND_SUBSCRIBERS_LOADING",
  SET_APPEND_SUBSCRIBERS_SUCCESS:
    "select-subscriber/SET_APPEND_SUBSCRIBERS_SUCCESS",
  SET_APPEND_SUBSCRIBERS_FAILED:
    "select-subscriber/SET_APPEND_SUBSCRIBERS_FAILED",

  SET_IS_FETCH_SUBSCRIBERS_HITTED:
    "select-subscriber/SET_IS_FETCH_SUBSCRIBERS_HITTED",
  SET_IS_SEARCH_SUBSCRIBERS_HITTED:
    "select-subscriber/SET_IS_SEARCH_SUBSCRIBERS_HITTED",
  SET_IS_APPEND_SUBSCRIBERS_HITTED:
    "select-subscriber/SET_IS_APPEND_SUBSCRIBERS_HITTED",

  FETCH_SUBSCRIBERS_START: "select-subscriber/FETCH_SUBSCRIBERS_START",
  SEARCH_SUBSCRIBERS_START: "select-subscriber/SEARCH_SUBSCRIBERS_START",
  APPEND_SUBSCRIBERS_START: "select-subscriber/APPEND_SUBSCRIBERS_START",
};

export default SELECT_SUBSCRIBER_ACTION_TYPES;
