import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendIngredientsFailed,
  setFetchIngredientsFailed,
  setSearchIngredientsFailed,
} from "../../store/select-ingredient/select-ingredient.action";
import {
  getAppendIngredientsFailed,
  getFetchIngredientsFailed,
  getSearchIngredientsFailed,
} from "../../store/select-ingredient/select-ingredient.selector";

const SelectIngredientPopup = () => {
  const dispatch = useDispatch();

  const fetchIngredientsFailed = useSelector(getFetchIngredientsFailed);
  const searchIngredientsFailed = useSelector(getSearchIngredientsFailed);
  const appendIngredientsFailed = useSelector(getAppendIngredientsFailed);

  useEffect(() => {
    if (
      fetchIngredientsFailed !== null ||
      searchIngredientsFailed !== null ||
      appendIngredientsFailed !== null
    ) {
      showErrorMessage(
        fetchIngredientsFailed ??
          searchIngredientsFailed ??
          appendIngredientsFailed
      );

      if (fetchIngredientsFailed !== null)
        dispatch(setFetchIngredientsFailed(null));
      if (searchIngredientsFailed !== null)
        dispatch(setSearchIngredientsFailed(null));
      if (appendIngredientsFailed !== null)
        dispatch(setAppendIngredientsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchIngredientsFailed,
    searchIngredientsFailed,
    appendIngredientsFailed,
  ]);

  return <></>;
};

export default SelectIngredientPopup;
