import {
  SUBSCRIPTION_ADMIN_TABS,
  SUBSCRIPTION_SUBSCRIBER_TABS,
  SUBSCRIPTIONS_OPTION_KEYS,
  SUBSCRIPTIONS_KEYS,
} from "../../constants/subscription.constant";

import STATE_SUBSCRIPTION_ACTION_TYPES from "./state-subscription.type";

export const STATE_SUBSCRIPTION_INITIAL_STATE = {
  subscriptionAdminTab: SUBSCRIPTION_ADMIN_TABS.PLANS,
  subscriptionSubscriberTab: SUBSCRIPTION_SUBSCRIBER_TABS.PLANS,

  billingPlan: SUBSCRIPTIONS_KEYS.ADVANCED,
  billingCycle: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,

  renewalBranchesIds: [],
  renewalPlan: SUBSCRIPTIONS_KEYS.ADVANCED,
  renewalCycle: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,
};

export const stateSubscriptionReducer = (
  state = STATE_SUBSCRIPTION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_ADMIN_TAB:
      return { ...state, subscriptionAdminTab: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_SUBSCRIBER_TAB:
      return { ...state, subscriptionSubscriberTab: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLAN:
      return { ...state, billingPlan: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_CYCLE:
      return { ...state, billingCycle: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_BRANCHES_IDS:
      return { ...state, renewalBranchesIds: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_PLAN:
      return { ...state, renewalPlan: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_CYCLE:
      return { ...state, renewalCycle: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.RESET_STATE_SUBSCRIPTION_REDUCER:
      return STATE_SUBSCRIPTION_INITIAL_STATE;
    default:
      return state;
  }
};
