import apiService from "./api";

export const getUserDrivers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/drivers", params })).data;
export const getUserDriver = async (driverId) =>
  (await apiService.innerGet({ urlPath: `/v2/users/drivers/${driverId}` }))
    .data;
export const createUserDriver = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/drivers", request })).data;
export const updateUserDriver = async (driverId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/drivers/${driverId}`,
      request,
    })
  ).data;

export const addMarketUserDriver = async (driverId, marketId) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/drivers/${driverId}/markets/${marketId}`,
    })
  ).data;
export const removeMarketUserDriver = async (driverId, marketId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/users/drivers/${driverId}/markets/${marketId}`,
    })
  ).data;
