import BANNER_TARGET_ACTION_TYPES from "./banner-target.type";

export const BANNER_TARGET_INITIAL_STATE = {
  targetAdmins: {},
  targetMerchants: {},
  targetSubscribers: {},
  target: null,

  isTargetAdminsHasMore: true,
  isTargetMerchantsHasMore: true,
  isTargetSubscribersHasMore: true,

  fetchTargetAdminsSearch: null,
  fetchTargetAdminsSort: null,
  fetchTargetAdminsKeyBy: null,
  fetchTargetAdminsPage: 1,
  fetchTargetAdminsPerPage: null,
  fetchTargetAdminsFilterBannerId: null,
  fetchTargetAdminsFilterExceptBannerId: null,
  fetchTargetAdminsLoading: false,
  fetchTargetAdminsSuccess: null,
  fetchTargetAdminsFailed: null,

  fetchTargetMerchantsSearch: null,
  fetchTargetMerchantsSort: null,
  fetchTargetMerchantsKeyBy: null,
  fetchTargetMerchantsPage: 1,
  fetchTargetMerchantsPerPage: null,
  fetchTargetMerchantsFilterBannerId: null,
  fetchTargetMerchantsFilterExceptBannerId: null,
  fetchTargetMerchantsLoading: false,
  fetchTargetMerchantsSuccess: null,
  fetchTargetMerchantsFailed: null,

  fetchTargetSubscribersSearch: null,
  fetchTargetSubscribersSort: null,
  fetchTargetSubscribersKeyBy: null,
  fetchTargetSubscribersPage: 1,
  fetchTargetSubscribersPerPage: null,
  fetchTargetSubscribersFilterBannerId: null,
  fetchTargetSubscribersFilterExceptBannerId: null,
  fetchTargetSubscribersLoading: false,
  fetchTargetSubscribersSuccess: null,
  fetchTargetSubscribersFailed: null,

  fetchTargetLoading: false,
  fetchTargetSuccess: null,
  fetchTargetFailed: null,

  isFetchTargetAdminsHitted: false,
  isFetchTargetMerchantsHitted: false,
  isFetchTargetSubscribersHitted: false,
  isFetchTargetHitted: false,
};

export const bannerTargetReducer = (
  state = BANNER_TARGET_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case BANNER_TARGET_ACTION_TYPES.SET_TARGET_ADMINS:
      return { ...state, targetAdmins: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_TARGET_MERCHANTS:
      return { ...state, targetMerchants: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_TARGET_SUBSCRIBERS:
      return { ...state, targetSubscribers: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_TARGET:
      return { ...state, target: payload };

    case BANNER_TARGET_ACTION_TYPES.SET_IS_TARGET_ADMINS_HAS_MORE:
      return { ...state, isTargetAdminsHasMore: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_IS_TARGET_MERCHANTS_HAS_MORE:
      return { ...state, isTargetMerchantsHasMore: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_IS_TARGET_SUBSCRIBERS_HAS_MORE:
      return { ...state, isTargetSubscribersHasMore: payload };

    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_SEARCH:
      return { ...state, fetchTargetAdminsSearch: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_SORT:
      return { ...state, fetchTargetAdminsSort: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_KEY_BY:
      return { ...state, fetchTargetAdminsKeyBy: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_PAGE:
      return { ...state, fetchTargetAdminsPage: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_PER_PAGE:
      return { ...state, fetchTargetAdminsPerPage: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_FILTER_BANNER_ID:
      return { ...state, fetchTargetAdminsFilterBannerId: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_FILTER_EXCEPT_BANNER_ID:
      return { ...state, fetchTargetAdminsFilterExceptBannerId: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_LOADING:
      return { ...state, fetchTargetAdminsLoading: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_SUCCESS:
      return { ...state, fetchTargetAdminsSuccess: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_FAILED:
      return { ...state, fetchTargetAdminsFailed: payload };

    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_SEARCH:
      return { ...state, fetchTargetMerchantsSearch: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_SORT:
      return { ...state, fetchTargetMerchantsSort: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_KEY_BY:
      return { ...state, fetchTargetMerchantsKeyBy: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_PAGE:
      return { ...state, fetchTargetMerchantsPage: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_PER_PAGE:
      return { ...state, fetchTargetMerchantsPerPage: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_FILTER_BANNER_ID:
      return { ...state, fetchTargetMerchantsFilterBannerId: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_FILTER_EXCEPT_BANNER_ID:
      return { ...state, fetchTargetMerchantsFilterExceptBannerId: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_LOADING:
      return { ...state, fetchTargetMerchantsLoading: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_SUCCESS:
      return { ...state, fetchTargetMerchantsSuccess: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_FAILED:
      return { ...state, fetchTargetMerchantsFailed: payload };

    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_SEARCH:
      return { ...state, fetchTargetSubscribersSearch: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_SORT:
      return { ...state, fetchTargetSubscribersSort: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_KEY_BY:
      return { ...state, fetchTargetSubscribersKeyBy: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_PAGE:
      return { ...state, fetchTargetSubscribersPage: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchTargetSubscribersPerPage: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_FILTER_BANNER_ID:
      return { ...state, fetchTargetSubscribersFilterBannerId: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_FILTER_EXCEPT_BANNER_ID:
      return { ...state, fetchTargetSubscribersFilterExceptBannerId: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_LOADING:
      return { ...state, fetchTargetSubscribersLoading: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchTargetSubscribersSuccess: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_FAILED:
      return { ...state, fetchTargetSubscribersFailed: payload };

    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_LOADING:
      return { ...state, fetchTargetLoading: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUCCESS:
      return { ...state, fetchTargetSuccess: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_FAILED:
      return { ...state, fetchTargetFailed: payload };

    case BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_ADMINS_HITTED:
      return { ...state, isFetchTargetAdminsHitted: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_MERCHANTS_HITTED:
      return { ...state, isFetchTargetMerchantsHitted: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_SUBSCRIBERS_HITTED:
      return { ...state, isFetchTargetSubscribersHitted: payload };
    case BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_HITTED:
      return { ...state, isFetchTargetHitted: payload };

    case BANNER_TARGET_ACTION_TYPES.APPEND_TARGET_ADMINS:
      return { ...state, targetAdmins: { ...state.targetAdmins, ...payload } };
    case BANNER_TARGET_ACTION_TYPES.APPEND_TARGET_MERCHANTS:
      return {
        ...state,
        targetMerchants: { ...state.targetMerchants, ...payload },
      };
    case BANNER_TARGET_ACTION_TYPES.APPEND_TARGET_SUBSCRIBERS:
      return {
        ...state,
        targetSubscribers: { ...state.targetSubscribers, ...payload },
      };

    case BANNER_TARGET_ACTION_TYPES.RESET_BANNER_TARGET_REDUCER:
      return BANNER_TARGET_INITIAL_STATE;
    default:
      return state;
  }
};
