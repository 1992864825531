import apiService from "./api";

export const getKitchenGroups = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/kitchen-groups", params })).data;
export const getKitchenGroup = async (groupId) =>
  (await apiService.innerGet({ urlPath: `/v2/kitchen-groups/${groupId}` }))
    .data;
export const createKitchenGroup = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/kitchen-groups", request })).data;
export const updateKitchenGroup = async (groupId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/kitchen-groups/${groupId}`,
      request,
    })
  ).data;
export const deleteKitchenGroup = async (groupId) =>
  (await apiService.innerDelete({ urlPath: `/v2/kitchen-groups/${groupId}` }))
    .data;
