const SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES = {
  RESET_SELECT_PERMISSION_SUBSCRIBER_REDUCER:
    "select-permission-subscriber/RESET_SELECT_PERMISSION_SUBSCRIBER_REDUCER",

  SET_PERMISSIONS: "select-permission-subscriber/SET_PERMISSIONS",
  SET_SEARCH_PERMISSIONS: "select-permission-subscriber/SET_SEARCH_PERMISSIONS",

  APPEND_PERMISSIONS: "select-permission-subscriber/APPEND_PERMISSIONS",
  APPEND_SEARCH_PERMISSIONS:
    "select-permission-subscriber/APPEND_SEARCH_PERMISSIONS",

  SET_IS_PERMISSIONS_HAS_MORE:
    "select-permission-subscriber/SET_IS_PERMISSIONS_HAS_MORE",
  SET_IS_SEARCH_PERMISSIONS_HAS_MORE:
    "select-permission-subscriber/SET_IS_SEARCH_PERMISSIONS_HAS_MORE",

  SET_FETCH_PERMISSIONS_PARAMS:
    "select-permission-subscriber/SET_FETCH_PERMISSIONS_PARAMS",
  SET_FETCH_PERMISSIONS_LOADING:
    "select-permission-subscriber/SET_FETCH_PERMISSIONS_LOADING",
  SET_FETCH_PERMISSIONS_SUCCESS:
    "select-permission-subscriber/SET_FETCH_PERMISSIONS_SUCCESS",
  SET_FETCH_PERMISSIONS_FAILED:
    "select-permission-subscriber/SET_FETCH_PERMISSIONS_FAILED",

  SET_SEARCH_PERMISSIONS_PARAMS:
    "select-permission-subscriber/SET_SEARCH_PERMISSIONS_PARAMS",
  SET_SEARCH_PERMISSIONS_LOADING:
    "select-permission-subscriber/SET_SEARCH_PERMISSIONS_LOADING",
  SET_SEARCH_PERMISSIONS_SUCCESS:
    "select-permission-subscriber/SET_SEARCH_PERMISSIONS_SUCCESS",
  SET_SEARCH_PERMISSIONS_FAILED:
    "select-permission-subscriber/SET_SEARCH_PERMISSIONS_FAILED",

  SET_APPEND_PERMISSIONS_PARAMS:
    "select-permission-subscriber/SET_APPEND_PERMISSIONS_PARAMS",
  SET_APPEND_PERMISSIONS_LOADING:
    "select-permission-subscriber/SET_APPEND_PERMISSIONS_LOADING",
  SET_APPEND_PERMISSIONS_SUCCESS:
    "select-permission-subscriber/SET_APPEND_PERMISSIONS_SUCCESS",
  SET_APPEND_PERMISSIONS_FAILED:
    "select-permission-subscriber/SET_APPEND_PERMISSIONS_FAILED",

  SET_IS_FETCH_PERMISSIONS_HITTED:
    "select-permission-subscriber/SET_IS_FETCH_PERMISSIONS_HITTED",
  SET_IS_SEARCH_PERMISSIONS_HITTED:
    "select-permission-subscriber/SET_IS_SEARCH_PERMISSIONS_HITTED",
  SET_IS_APPEND_PERMISSIONS_HITTED:
    "select-permission-subscriber/SET_IS_APPEND_PERMISSIONS_HITTED",

  FETCH_PERMISSIONS_START:
    "select-permission-subscriber/FETCH_PERMISSIONS_START",
  SEARCH_PERMISSIONS_START:
    "select-permission-subscriber/SEARCH_PERMISSIONS_START",
  APPEND_PERMISSIONS_START:
    "select-permission-subscriber/APPEND_PERMISSIONS_START",
};

export default SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES;
