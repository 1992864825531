import { createSelector } from "reselect";

const productModifierSelector = ({ productModifier }) => productModifier;

export const getOptionPriceTypes = createSelector(
  [productModifierSelector],
  ({ optionPriceTypes }) => optionPriceTypes
);

export const getAdditionalPriceTypes = createSelector(
  [productModifierSelector],
  ({ additionalPriceTypes }) => additionalPriceTypes
);

export const getScannedProductModifier = createSelector(
  [productModifierSelector],
  ({ scannedProductModifier }) => scannedProductModifier
);

export const getCreateProductModifier = createSelector(
  [productModifierSelector],
  ({ createProductModifier }) => createProductModifier
);

export const getUpdateProductModifier = createSelector(
  [productModifierSelector],
  ({ updateProductModifier }) => updateProductModifier
);

export const getOrderVariableProductModifiers = createSelector(
  [productModifierSelector],
  ({ orderVariableProductModifiers }) => orderVariableProductModifiers
);

export const getOrderVariableCartRequest = createSelector(
  [productModifierSelector],
  ({ orderVariableCartRequest }) => orderVariableCartRequest
);

export const getOrderWeightProductModifiers = createSelector(
  [productModifierSelector],
  ({ orderWeightProductModifiers }) => orderWeightProductModifiers
);

export const getOrderWeightCartRequest = createSelector(
  [productModifierSelector],
  ({ orderWeightCartRequest }) => orderWeightCartRequest
);

export const getOrderCustomizeProductModifiers = createSelector(
  [productModifierSelector],
  ({ orderCustomizeProductModifiers }) => orderCustomizeProductModifiers
);

export const getCreateProductModifierLoading = createSelector(
  [productModifierSelector],
  ({ createProductModifierLoading }) => createProductModifierLoading
);

export const getCreateProductModifierSuccess = createSelector(
  [productModifierSelector],
  ({ createProductModifierSuccess }) => createProductModifierSuccess
);

export const getCreateProductModifierFailed = createSelector(
  [productModifierSelector],
  ({ createProductModifierFailed }) => createProductModifierFailed
);

export const getUpdateProductModifierLoading = createSelector(
  [productModifierSelector],
  ({ updateProductModifierLoading }) => updateProductModifierLoading
);

export const getUpdateProductModifierSuccess = createSelector(
  [productModifierSelector],
  ({ updateProductModifierSuccess }) => updateProductModifierSuccess
);

export const getUpdateProductModifierFailed = createSelector(
  [productModifierSelector],
  ({ updateProductModifierFailed }) => updateProductModifierFailed
);

export const getFetchScannedProductModifierLoading = createSelector(
  [productModifierSelector],
  ({ fetchScannedProductModifierLoading }) => fetchScannedProductModifierLoading
);

export const getFetchScannedProductModifierSuccess = createSelector(
  [productModifierSelector],
  ({ fetchScannedProductModifierSuccess }) => fetchScannedProductModifierSuccess
);

export const getFetchScannedProductModifierFailed = createSelector(
  [productModifierSelector],
  ({ fetchScannedProductModifierFailed }) => fetchScannedProductModifierFailed
);
