import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendSubscribersFailed,
  setFetchSubscribersFailed,
  setSearchSubscribersFailed,
} from "../../store/select-subscriber/select-subscriber.action";
import {
  getAppendSubscribersFailed,
  getFetchSubscribersFailed,
  getSearchSubscribersFailed,
} from "../../store/select-subscriber/select-subscriber.selector";

const SelectSubscriberPopup = () => {
  const dispatch = useDispatch();

  const fetchSubscribersFailed = useSelector(getFetchSubscribersFailed);
  const searchSubscribersFailed = useSelector(getSearchSubscribersFailed);
  const appendSubscribersFailed = useSelector(getAppendSubscribersFailed);

  useEffect(() => {
    if (
      fetchSubscribersFailed !== null ||
      searchSubscribersFailed !== null ||
      appendSubscribersFailed !== null
    ) {
      showErrorMessage(
        fetchSubscribersFailed ??
          searchSubscribersFailed ??
          appendSubscribersFailed
      );

      if (fetchSubscribersFailed !== null)
        dispatch(setFetchSubscribersFailed(null));
      if (searchSubscribersFailed !== null)
        dispatch(setSearchSubscribersFailed(null));
      if (appendSubscribersFailed !== null)
        dispatch(setAppendSubscribersFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchSubscribersFailed,
    searchSubscribersFailed,
    appendSubscribersFailed,
  ]);

  return <></>;
};

export default SelectSubscriberPopup;
