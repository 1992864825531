import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setFetchSubscriptionFailed,
  setUpdateSubscriptionFailed,
  setUpdateSubscriptionSuccess,
} from "../../store/subscription/subscription.action";
import {
  getFetchSubscriptionFailed,
  getUpdateSubscriptionFailed,
  getUpdateSubscriptionSuccess,
} from "../../store/subscription/subscription.selector";

const SubscriptionPopup = () => {
  const dispatch = useDispatch();

  const updateSubscriptionSuccess = useSelector(getUpdateSubscriptionSuccess);
  const fetchSubscriptionFailed = useSelector(getFetchSubscriptionFailed);
  const updateSubscriptionFailed = useSelector(getUpdateSubscriptionFailed);

  useEffect(() => {
    if (updateSubscriptionSuccess !== null) {
      showSuccessMessage(updateSubscriptionSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setUpdateSubscriptionSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSubscriptionSuccess]);

  useEffect(() => {
    if (fetchSubscriptionFailed !== null || updateSubscriptionFailed !== null) {
      showErrorMessage(fetchSubscriptionFailed ?? updateSubscriptionFailed);

      if (fetchSubscriptionFailed !== null)
        dispatch(setFetchSubscriptionFailed(null));
      if (updateSubscriptionFailed !== null)
        dispatch(setUpdateSubscriptionFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSubscriptionFailed, updateSubscriptionFailed]);

  return <></>;
};

export default SubscriptionPopup;
