import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getValidFiles } from "../../utils/store.utils";
import { isEmpty } from "../../utils/validation.utils";

import { UPLOAD_TYPES } from "../../constants/upload.constant";

import {
  getBanners,
  getBanner,
  createBanner,
  updateBanner,
  deleteBanner,
} from "../../api/banner.api";
import { uploadFile } from "../../api/upload.api";

import BANNER_ACTION_TYPES from "./banner.type";
import {
  appendBanners,
  setBanners,
  setBanner,
  setCreateBannerFailed,
  setCreateBannerLoading,
  setCreateBannerSuccess,
  setDeleteBannerFailed,
  setDeleteBannerLoading,
  setDeleteBannerSuccess,
  setFetchBannersFailed,
  setFetchBannersLoading,
  setFetchBannersPage,
  setFetchBannersSuccess,
  setFetchBannerFailed,
  setFetchBannerLoading,
  setFetchBannerSuccess,
  setIsBannersHasMore,
  setIsCreateBannerHitted,
  setIsDeleteBannerHitted,
  setIsFetchBannersHitted,
  setIsFetchBannerHitted,
  setIsUpdateBannerHitted,
  setUpdateBannerFailed,
  setUpdateBannerLoading,
  setUpdateBannerSuccess,
} from "./banner.action";
import {
  getFetchBannersIncludes,
  getFetchBannersKeyBy,
  getFetchBannersPage,
  getFetchBannersPerPage,
  getFetchBannersSearch,
  getFetchBannersSort,
  getIsFetchBannersHitted,
} from "./banner.selector";

export function* _getBanners() {
  try {
    yield put(setFetchBannersLoading(true));

    const search = yield select(getFetchBannersSearch);
    const sort = yield select(getFetchBannersSort);
    const key_by = yield select(getFetchBannersKeyBy);
    const page = yield select(getFetchBannersPage);
    const per_page = yield select(getFetchBannersPerPage);
    const includes = yield select(getFetchBannersIncludes);

    const parameters = { search, sort, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: { data: banners },
    } = yield call(getBanners, parameters);

    yield put(setIsFetchBannersHitted(true));
    yield put(setIsBannersHasMore(banners.length > 0));

    if (page > 1) {
      yield put(appendBanners(banners));
    } else {
      yield put(setBanners(banners));
    }

    yield put(setFetchBannersSuccess(message));
    yield put(setFetchBannersLoading(false));
  } catch (error) {
    yield put(setFetchBannersFailed(error));
    yield put(setFetchBannersLoading(false));
  }
}
export function* _getBanner({ payload: bannerId }) {
  try {
    yield put(setFetchBannerLoading(true));

    const {
      meta: { message },
      data: banner,
    } = yield call(getBanner, bannerId);

    yield put(setIsFetchBannerHitted(true));
    yield put(setBanner(banner));

    yield put(setFetchBannerSuccess(message));
    yield put(setFetchBannerLoading(false));
  } catch (error) {
    yield put(setFetchBannerFailed(error));
    yield put(setFetchBannerLoading(false));
  }
}
export function* _createBanner({ payload: request }) {
  try {
    yield put(setCreateBannerLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.cover_img)) {
      const files = getValidFiles([payload.cover_img]);

      if (!isEmpty(files)) {
        const request = { type: UPLOAD_TYPES.BANNER, file: files[0] };
        const { data: imageUrl } = yield call(uploadFile, request);

        payload.cover_img = imageUrl;
      }
    }

    const {
      meta: { message },
    } = yield call(createBanner, payload);

    yield put(setIsCreateBannerHitted(true));

    const isFetchBannersHitted = yield select(getIsFetchBannersHitted);

    if (isFetchBannersHitted) {
      yield put(setFetchBannersPage(1));
      yield call(_getBanners);
    }

    yield put(setCreateBannerSuccess(message));
    yield put(setCreateBannerLoading(false));
  } catch (error) {
    yield put(setCreateBannerFailed(error));
    yield put(setCreateBannerLoading(false));
  }
}
export function* _updateBanner({ payload: { bannerId, request } }) {
  try {
    yield put(setUpdateBannerLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.cover_img)) {
      const files = getValidFiles([payload.cover_img]);

      if (!isEmpty(files)) {
        const request = { type: UPLOAD_TYPES.BANNER, file: files[0] };
        const { data: imageUrl } = yield call(uploadFile, request);

        payload.cover_img = imageUrl;
      }
    }

    const {
      meta: { message },
    } = yield call(updateBanner, bannerId, payload);

    yield put(setIsUpdateBannerHitted(true));

    const isFetchBannersHitted = yield select(getIsFetchBannersHitted);

    if (isFetchBannersHitted) {
      yield put(setFetchBannersPage(1));
      yield call(_getBanners);
    }

    yield put(setUpdateBannerSuccess(message));
    yield put(setUpdateBannerLoading(false));
  } catch (error) {
    yield put(setUpdateBannerFailed(error));
    yield put(setUpdateBannerLoading(false));
  }
}
export function* _deleteBanner({ payload: bannerId }) {
  try {
    yield put(setDeleteBannerLoading(true));

    const {
      meta: { message },
    } = yield call(deleteBanner, bannerId);

    yield put(setIsDeleteBannerHitted(true));

    const isFetchBannersHitted = yield select(getIsFetchBannersHitted);

    if (isFetchBannersHitted) {
      yield put(setFetchBannersPage(1));
      yield call(_getBanners);
    }

    yield put(setDeleteBannerSuccess(message));
    yield put(setDeleteBannerLoading(false));
  } catch (error) {
    yield put(setDeleteBannerFailed(error));
    yield put(setDeleteBannerLoading(false));
  }
}

export function* onFetchBannersStart() {
  yield takeLatest(BANNER_ACTION_TYPES.FETCH_BANNERS_START, _getBanners);
}
export function* onFetchBannerStart() {
  yield takeLatest(BANNER_ACTION_TYPES.FETCH_BANNER_START, _getBanner);
}
export function* onCreateBannerStart() {
  yield takeLatest(BANNER_ACTION_TYPES.CREATE_BANNER_START, _createBanner);
}
export function* onUpdateBannerStart() {
  yield takeLatest(BANNER_ACTION_TYPES.UPDATE_BANNER_START, _updateBanner);
}
export function* onDeleteBannerStart() {
  yield takeLatest(BANNER_ACTION_TYPES.DELETE_BANNER_START, _deleteBanner);
}

export function* bannerSaga() {
  yield all([
    call(onFetchBannersStart),
    call(onFetchBannerStart),
    call(onCreateBannerStart),
    call(onUpdateBannerStart),
    call(onDeleteBannerStart),
  ]);
}
