import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendPaymentMethodsFailed,
  setFetchPaymentMethodsFailed,
  setSearchPaymentMethodsFailed,
} from "../../store/select-payment-method/select-payment-method.action";
import {
  getAppendPaymentMethodsFailed,
  getFetchPaymentMethodsFailed,
  getSearchPaymentMethodsFailed,
} from "../../store/select-payment-method/select-payment-method.selector";

const SelectPaymentMethodPopup = () => {
  const dispatch = useDispatch();

  const fetchPaymentMethodsFailed = useSelector(getFetchPaymentMethodsFailed);
  const searchPaymentMethodsFailed = useSelector(getSearchPaymentMethodsFailed);
  const appendPaymentMethodsFailed = useSelector(getAppendPaymentMethodsFailed);

  useEffect(() => {
    if (
      fetchPaymentMethodsFailed !== null ||
      searchPaymentMethodsFailed !== null ||
      appendPaymentMethodsFailed !== null
    ) {
      showErrorMessage(
        fetchPaymentMethodsFailed ??
          searchPaymentMethodsFailed ??
          appendPaymentMethodsFailed
      );

      if (fetchPaymentMethodsFailed !== null)
        dispatch(setFetchPaymentMethodsFailed(null));
      if (searchPaymentMethodsFailed !== null)
        dispatch(setSearchPaymentMethodsFailed(null));
      if (appendPaymentMethodsFailed !== null)
        dispatch(setAppendPaymentMethodsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPaymentMethodsFailed,
    searchPaymentMethodsFailed,
    appendPaymentMethodsFailed,
  ]);

  return <></>;
};

export default SelectPaymentMethodPopup;
