import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreatePaymentMethodFailed,
  setCreatePaymentMethodSuccess,
  setDeletePaymentMethodFailed,
  setDeletePaymentMethodSuccess,
  setFetchPaymentMethodsFailed,
  setFetchSelectPaymentMethodsFailed,
  setUpdatePaymentMethodFailed,
  setUpdatePaymentMethodSuccess,
} from "../../store/payment-method/payment-method.action";
import {
  getCreatePaymentMethodFailed,
  getCreatePaymentMethodSuccess,
  getDeletePaymentMethodFailed,
  getDeletePaymentMethodSuccess,
  getFetchPaymentMethodsFailed,
  getFetchSelectPaymentMethodsFailed,
  getUpdatePaymentMethodFailed,
  getUpdatePaymentMethodSuccess,
} from "../../store/payment-method/payment-method.selector";

const PaymentMethodPopup = () => {
  const dispatch = useDispatch();

  const createPaymentMethodSuccess = useSelector(getCreatePaymentMethodSuccess);
  const updatePaymentMethodSuccess = useSelector(getUpdatePaymentMethodSuccess);
  const deletePaymentMethodSuccess = useSelector(getDeletePaymentMethodSuccess);
  const fetchPaymentMethodsFailed = useSelector(getFetchPaymentMethodsFailed);
  const fetchSelectPaymentMethodsFailed = useSelector(getFetchSelectPaymentMethodsFailed);
  const createPaymentMethodFailed = useSelector(getCreatePaymentMethodFailed);
  const updatePaymentMethodFailed = useSelector(getUpdatePaymentMethodFailed);
  const deletePaymentMethodFailed = useSelector(getDeletePaymentMethodFailed);

  useEffect(() => {
    if (
      createPaymentMethodSuccess !== null ||
      updatePaymentMethodSuccess !== null ||
      deletePaymentMethodSuccess !== null
    ) {
      showSuccessMessage(createPaymentMethodSuccess ?? updatePaymentMethodSuccess ?? deletePaymentMethodSuccess);
      dispatch(setIsModalOpen(false));

      if (createPaymentMethodSuccess !== null) dispatch(setCreatePaymentMethodSuccess(null));
      if (updatePaymentMethodSuccess !== null) dispatch(setUpdatePaymentMethodSuccess(null));
      if (deletePaymentMethodSuccess !== null) dispatch(setDeletePaymentMethodSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPaymentMethodSuccess, updatePaymentMethodSuccess, deletePaymentMethodSuccess]);

  useEffect(() => {
    if (
      fetchPaymentMethodsFailed !== null ||
      fetchSelectPaymentMethodsFailed !== null ||
      createPaymentMethodFailed !== null ||
      updatePaymentMethodFailed !== null ||
      deletePaymentMethodFailed !== null
    ) {
      showErrorMessage(
        fetchPaymentMethodsFailed ??
          fetchSelectPaymentMethodsFailed ??
          createPaymentMethodFailed ??
          updatePaymentMethodFailed ??
          deletePaymentMethodFailed
      );

      if (fetchPaymentMethodsFailed !== null) dispatch(setFetchPaymentMethodsFailed(null));
      if (fetchSelectPaymentMethodsFailed !== null) dispatch(setFetchSelectPaymentMethodsFailed(null));
      if (createPaymentMethodFailed !== null) dispatch(setCreatePaymentMethodFailed(null));
      if (updatePaymentMethodFailed !== null) dispatch(setUpdatePaymentMethodFailed(null));
      if (deletePaymentMethodFailed !== null) dispatch(setDeletePaymentMethodFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPaymentMethodsFailed,
    fetchSelectPaymentMethodsFailed,
    createPaymentMethodFailed,
    updatePaymentMethodFailed,
    deletePaymentMethodFailed,
  ]);

  return <></>;
};

export default PaymentMethodPopup;
