import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateUserAdminFailed,
  setCreateUserAdminSuccess,
  setDeleteUserAdminFailed,
  setDeleteUserAdminSuccess,
  setFetchUserAdminFailed,
  setFetchUserAdminsFailed,
  setUpdateUserAdminFailed,
  setUpdateUserAdminSuccess,
} from "../../store/user-admin/user-admin.action";
import {
  getCreateUserAdminFailed,
  getCreateUserAdminSuccess,
  getDeleteUserAdminFailed,
  getDeleteUserAdminSuccess,
  getFetchUserAdminFailed,
  getFetchUserAdminsFailed,
  getUpdateUserAdminFailed,
  getUpdateUserAdminSuccess,
} from "../../store/user-admin/user-admin.selector";

const UserAdminPopup = () => {
  const dispatch = useDispatch();

  const createUserAdminSuccess = useSelector(getCreateUserAdminSuccess);
  const updateUserAdminSuccess = useSelector(getUpdateUserAdminSuccess);
  const deleteUserAdminSuccess = useSelector(getDeleteUserAdminSuccess);
  const fetchUserAdminsFailed = useSelector(getFetchUserAdminsFailed);
  const fetchUserAdminFailed = useSelector(getFetchUserAdminFailed);
  const createUserAdminFailed = useSelector(getCreateUserAdminFailed);
  const updateUserAdminFailed = useSelector(getUpdateUserAdminFailed);
  const deleteUserAdminFailed = useSelector(getDeleteUserAdminFailed);

  useEffect(() => {
    if (
      createUserAdminSuccess !== null ||
      updateUserAdminSuccess !== null ||
      deleteUserAdminSuccess !== null
    ) {
      showSuccessMessage(
        createUserAdminSuccess ??
          updateUserAdminSuccess ??
          deleteUserAdminSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createUserAdminSuccess !== null)
        dispatch(setCreateUserAdminSuccess(null));
      if (updateUserAdminSuccess !== null)
        dispatch(setUpdateUserAdminSuccess(null));
      if (deleteUserAdminSuccess !== null)
        dispatch(setDeleteUserAdminSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserAdminSuccess, updateUserAdminSuccess, deleteUserAdminSuccess]);

  useEffect(() => {
    if (
      fetchUserAdminsFailed !== null ||
      fetchUserAdminFailed !== null ||
      createUserAdminFailed !== null ||
      updateUserAdminFailed !== null ||
      deleteUserAdminFailed !== null
    ) {
      showErrorMessage(
        fetchUserAdminsFailed ??
          fetchUserAdminFailed ??
          createUserAdminFailed ??
          updateUserAdminFailed ??
          deleteUserAdminFailed
      );

      if (fetchUserAdminsFailed !== null)
        dispatch(setFetchUserAdminsFailed(null));
      if (fetchUserAdminFailed !== null)
        dispatch(setFetchUserAdminFailed(null));
      if (createUserAdminFailed !== null)
        dispatch(setCreateUserAdminFailed(null));
      if (updateUserAdminFailed !== null)
        dispatch(setUpdateUserAdminFailed(null));
      if (deleteUserAdminFailed !== null)
        dispatch(setDeleteUserAdminFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUserAdminsFailed,
    fetchUserAdminFailed,
    createUserAdminFailed,
    updateUserAdminFailed,
    deleteUserAdminFailed,
  ]);

  return <></>;
};

export default UserAdminPopup;
