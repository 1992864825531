import BANNER_ACTION_TYPES from "./banner.type";

export const BANNER_INITIAL_STATE = {
  banners: [],
  banner: null,

  isBannersHasMore: true,

  fetchBannersSearch: null,
  fetchBannersSort: null,
  fetchBannersKeyBy: null,
  fetchBannersPage: 1,
  fetchBannersPerPage: null,
  fetchBannersIncludes: null,
  fetchBannersLoading: false,
  fetchBannersSuccess: null,
  fetchBannersFailed: null,

  fetchBannerLoading: false,
  fetchBannerSuccess: null,
  fetchBannerFailed: null,

  createBannerLoading: false,
  createBannerSuccess: null,
  createBannerFailed: null,

  updateBannerLoading: false,
  updateBannerSuccess: null,
  updateBannerFailed: null,

  deleteBannerLoading: false,
  deleteBannerSuccess: null,
  deleteBannerFailed: null,

  isFetchBannersHitted: false,
  isFetchBannerHitted: false,
  isCreateBannerHitted: false,
  isUpdateBannerHitted: false,
  isDeleteBannerHitted: false,
};

export const bannerReducer = (state = BANNER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case BANNER_ACTION_TYPES.SET_BANNERS:
      return { ...state, banners: payload };
    case BANNER_ACTION_TYPES.SET_BANNER:
      return { ...state, banner: payload };

    case BANNER_ACTION_TYPES.SET_IS_BANNERS_HAS_MORE:
      return { ...state, isBannersHasMore: payload };

    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_SEARCH:
      return { ...state, fetchBannersSearch: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_SORT:
      return { ...state, fetchBannersSort: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_KEY_BY:
      return { ...state, fetchBannersKeyBy: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_PAGE:
      return { ...state, fetchBannersPage: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_PER_PAGE:
      return { ...state, fetchBannersPerPage: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_INCLUDES:
      return { ...state, fetchBannersIncludes: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_LOADING:
      return { ...state, fetchBannersLoading: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_SUCCESS:
      return { ...state, fetchBannersSuccess: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNERS_FAILED:
      return { ...state, fetchBannersFailed: payload };

    case BANNER_ACTION_TYPES.SET_FETCH_BANNER_LOADING:
      return { ...state, fetchBannerLoading: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNER_SUCCESS:
      return { ...state, fetchBannerSuccess: payload };
    case BANNER_ACTION_TYPES.SET_FETCH_BANNER_FAILED:
      return { ...state, fetchBannerFailed: payload };

    case BANNER_ACTION_TYPES.SET_CREATE_BANNER_LOADING:
      return { ...state, createBannerLoading: payload };
    case BANNER_ACTION_TYPES.SET_CREATE_BANNER_SUCCESS:
      return { ...state, createBannerSuccess: payload };
    case BANNER_ACTION_TYPES.SET_CREATE_BANNER_FAILED:
      return { ...state, createBannerFailed: payload };

    case BANNER_ACTION_TYPES.SET_UPDATE_BANNER_LOADING:
      return { ...state, updateBannerLoading: payload };
    case BANNER_ACTION_TYPES.SET_UPDATE_BANNER_SUCCESS:
      return { ...state, updateBannerSuccess: payload };
    case BANNER_ACTION_TYPES.SET_UPDATE_BANNER_FAILED:
      return { ...state, updateBannerFailed: payload };

    case BANNER_ACTION_TYPES.SET_DELETE_BANNER_LOADING:
      return { ...state, deleteBannerLoading: payload };
    case BANNER_ACTION_TYPES.SET_DELETE_BANNER_SUCCESS:
      return { ...state, deleteBannerSuccess: payload };
    case BANNER_ACTION_TYPES.SET_DELETE_BANNER_FAILED:
      return { ...state, deleteBannerFailed: payload };

    case BANNER_ACTION_TYPES.SET_IS_FETCH_BANNERS_HITTED:
      return { ...state, isFetchBannersHitted: payload };
    case BANNER_ACTION_TYPES.SET_IS_FETCH_BANNER_HITTED:
      return { ...state, isFetchBannerHitted: payload };
    case BANNER_ACTION_TYPES.SET_IS_CREATE_BANNER_HITTED:
      return { ...state, isCreateBannerHitted: payload };
    case BANNER_ACTION_TYPES.SET_IS_UPDATE_BANNER_HITTED:
      return { ...state, isUpdateBannerHitted: payload };
    case BANNER_ACTION_TYPES.SET_IS_DELETE_BANNER_HITTED:
      return { ...state, isDeleteBannerHitted: payload };

    case BANNER_ACTION_TYPES.APPEND_BANNERS:
      return { ...state, banners: [...state.banners, ...payload] };

    case BANNER_ACTION_TYPES.RESET_BANNER_REDUCER:
      return BANNER_INITIAL_STATE;
    default:
      return state;
  }
};
