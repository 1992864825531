import apiService from "./api";

export const getPrinterTypes = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/printer-types", params })).data;
export const getPrinterType = async (typeId) =>
  (await apiService.innerGet({ urlPath: `/v2/printer-types/${typeId}` })).data;
export const createPrinterType = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/printer-types", request })).data;
export const updatePrinterType = async (typeId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/printer-types/${typeId}`,
      request,
    })
  ).data;
export const deletePrinterType = async (typeId) =>
  (await apiService.innerDelete({ urlPath: `/v2/printer-types/${typeId}` }))
    .data;
