const BANNER_TARGET_ACTION_TYPES = {
  RESET_BANNER_TARGET_REDUCER: "banner-target/RESET_BANNER_TARGET_REDUCER",

  SET_TARGET_ADMINS: "banner-target/SET_TARGET_ADMINS",
  SET_TARGET_MERCHANTS: "banner-target/SET_TARGET_MERCHANTS",
  SET_TARGET_SUBSCRIBERS: "banner-target/SET_TARGET_SUBSCRIBERS",
  SET_TARGET: "banner-target/SET_TARGET",

  SET_IS_TARGET_ADMINS_HAS_MORE: "banner-target/SET_IS_TARGET_ADMINS_HAS_MORE",
  SET_IS_TARGET_MERCHANTS_HAS_MORE:
    "banner-target/SET_IS_TARGET_MERCHANTS_HAS_MORE",
  SET_IS_TARGET_SUBSCRIBERS_HAS_MORE:
    "banner-target/SET_IS_TARGET_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_TARGET_ADMINS_SEARCH:
    "banner-target/SET_FETCH_TARGET_ADMINS_SEARCH",
  SET_FETCH_TARGET_ADMINS_SORT: "banner-target/SET_FETCH_TARGET_ADMINS_SORT",
  SET_FETCH_TARGET_ADMINS_KEY_BY:
    "banner-target/SET_FETCH_TARGET_ADMINS_KEY_BY",
  SET_FETCH_TARGET_ADMINS_PAGE: "banner-target/SET_FETCH_TARGET_ADMINS_PAGE",
  SET_FETCH_TARGET_ADMINS_PER_PAGE:
    "banner-target/SET_FETCH_TARGET_ADMINS_PER_PAGE",
  SET_FETCH_TARGET_ADMINS_FILTER_BANNER_ID:
    "banner-target/SET_FETCH_TARGET_ADMINS_FILTER_BANNER_ID",
  SET_FETCH_TARGET_ADMINS_FILTER_EXCEPT_BANNER_ID:
    "banner-target/SET_FETCH_TARGET_ADMINS_FILTER_EXCEPT_BANNER_ID",
  SET_FETCH_TARGET_ADMINS_LOADING:
    "banner-target/SET_FETCH_TARGET_ADMINS_LOADING",
  SET_FETCH_TARGET_ADMINS_SUCCESS:
    "banner-target/SET_FETCH_TARGET_ADMINS_SUCCESS",
  SET_FETCH_TARGET_ADMINS_FAILED:
    "banner-target/SET_FETCH_TARGET_ADMINS_FAILED",

  SET_FETCH_TARGET_MERCHANTS_SEARCH:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_SEARCH",
  SET_FETCH_TARGET_MERCHANTS_SORT:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_SORT",
  SET_FETCH_TARGET_MERCHANTS_KEY_BY:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_KEY_BY",
  SET_FETCH_TARGET_MERCHANTS_PAGE:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_PAGE",
  SET_FETCH_TARGET_MERCHANTS_PER_PAGE:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_PER_PAGE",
  SET_FETCH_TARGET_MERCHANTS_FILTER_BANNER_ID:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_FILTER_BANNER_ID",
  SET_FETCH_TARGET_MERCHANTS_FILTER_EXCEPT_BANNER_ID:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_FILTER_EXCEPT_BANNER_ID",
  SET_FETCH_TARGET_MERCHANTS_LOADING:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_LOADING",
  SET_FETCH_TARGET_MERCHANTS_SUCCESS:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_SUCCESS",
  SET_FETCH_TARGET_MERCHANTS_FAILED:
    "banner-target/SET_FETCH_TARGET_MERCHANTS_FAILED",

  SET_FETCH_TARGET_SUBSCRIBERS_SEARCH:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_SEARCH",
  SET_FETCH_TARGET_SUBSCRIBERS_SORT:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_SORT",
  SET_FETCH_TARGET_SUBSCRIBERS_KEY_BY:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_KEY_BY",
  SET_FETCH_TARGET_SUBSCRIBERS_PAGE:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_PAGE",
  SET_FETCH_TARGET_SUBSCRIBERS_PER_PAGE:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_TARGET_SUBSCRIBERS_FILTER_BANNER_ID:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_FILTER_BANNER_ID",
  SET_FETCH_TARGET_SUBSCRIBERS_FILTER_EXCEPT_BANNER_ID:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_FILTER_EXCEPT_BANNER_ID",
  SET_FETCH_TARGET_SUBSCRIBERS_LOADING:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_LOADING",
  SET_FETCH_TARGET_SUBSCRIBERS_SUCCESS:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_SUCCESS",
  SET_FETCH_TARGET_SUBSCRIBERS_FAILED:
    "banner-target/SET_FETCH_TARGET_SUBSCRIBERS_FAILED",

  SET_FETCH_TARGET_LOADING: "banner-target/SET_FETCH_TARGET_LOADING",
  SET_FETCH_TARGET_SUCCESS: "banner-target/SET_FETCH_TARGET_SUCCESS",
  SET_FETCH_TARGET_FAILED: "banner-target/SET_FETCH_TARGET_FAILED",

  APPEND_TARGET_ADMINS: "banner-target/APPEND_TARGET_ADMINS",
  APPEND_TARGET_MERCHANTS: "banner-target/APPEND_TARGET_MERCHANTS",
  APPEND_TARGET_SUBSCRIBERS: "banner-target/APPEND_TARGET_SUBSCRIBERS",

  SET_IS_FETCH_TARGET_ADMINS_HITTED:
    "banner-target/SET_IS_FETCH_TARGET_ADMINS_HITTED",
  SET_IS_FETCH_TARGET_MERCHANTS_HITTED:
    "banner-target/SET_IS_FETCH_TARGET_MERCHANTS_HITTED",
  SET_IS_FETCH_TARGET_SUBSCRIBERS_HITTED:
    "banner-target/SET_IS_FETCH_TARGET_SUBSCRIBERS_HITTED",
  SET_IS_FETCH_TARGET_HITTED: "banner-target/SET_IS_FETCH_TARGET_HITTED",

  FETCH_TARGET_ADMINS_START: "banner-target/FETCH_TARGET_ADMINS_START",
  FETCH_TARGET_MERCHANTS_START: "banner-target/FETCH_TARGET_MERCHANTS_START",
  FETCH_TARGET_SUBSCRIBERS_START:
    "banner-target/FETCH_TARGET_SUBSCRIBERS_START",
  FETCH_TARGET_START: "banner-target/FETCH_TARGET_START",
};

export default BANNER_TARGET_ACTION_TYPES;
