import { useTranslation } from "react-i18next";

import Responsive from "../../components/responsive/responsive.component";
import ApplicationCompletionTrackProgress from "../application-completion-track-progress/application-completion-track-progress.widget";
import ApplicationCompletionTrackList from "../application-completion-track-list/application-completion-track-list.widget";

import {
  ApplicationCompletionTrackContainer,
  ApplicationCompletionTrackDescription,
  ApplicationCompletionTrackTitle,
} from "./application-completion-track.style";

const ApplicationCompletionTrack = ({ completions = [] }) => {
  const { t } = useTranslation();

  return (
    <ApplicationCompletionTrackContainer>
      <Responsive>
        <Responsive.Row rowGap="0.35rem">
          <Responsive.Col>
            <ApplicationCompletionTrackTitle>
              {t("Complete Business Profile")}
            </ApplicationCompletionTrackTitle>
            <ApplicationCompletionTrackDescription>
              {t(
                "Complete the following information before integrating with Fai"
              )}
            </ApplicationCompletionTrackDescription>
          </Responsive.Col>
          <Responsive.Col></Responsive.Col>
          <Responsive.Col>
            <ApplicationCompletionTrackProgress completions={completions} />
          </Responsive.Col>
          <Responsive.Col></Responsive.Col>
          <Responsive.Col></Responsive.Col>
          <Responsive.Col>
            <ApplicationCompletionTrackList completions={completions} />
          </Responsive.Col>
        </Responsive.Row>
      </Responsive>
    </ApplicationCompletionTrackContainer>
  );
};

export default ApplicationCompletionTrack;
