import apiService from "./api";

export const getMarkets = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/markets", params })).data;
export const getMarket = async (marketId, params) =>
  (await apiService.innerGet({ urlPath: `/v2/markets/${marketId}`, params }))
    .data;
export const createMarket = async (request) =>
  (await apiService.innerPost({ urlPath: `/v2/markets`, request })).data;
export const updateMarket = async (marketId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/markets/${marketId}`, request }))
    .data;
export const deleteMarket = async (marketId) =>
  (await apiService.innerDelete({ urlPath: `/v2/markets/${marketId}` })).data;
