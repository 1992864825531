import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateAttendanceFailed,
  setCreateOrUpdateAttendanceSuccess,
  setFetchAttendancesFailed,
} from "../../store/attendance/attendance.action";
import {
  getCreateOrUpdateAttendanceFailed,
  getCreateOrUpdateAttendanceSuccess,
  getFetchAttendancesFailed,
} from "../../store/attendance/attendance.selector";

const AttendancePopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateAttendanceSuccess = useSelector(getCreateOrUpdateAttendanceSuccess);
  const fetchAttendancesFailed = useSelector(getFetchAttendancesFailed);
  const createOrUpdateAttendanceFailed = useSelector(getCreateOrUpdateAttendanceFailed);

  useEffect(() => {
    if (createOrUpdateAttendanceSuccess !== null) {
      showSuccessMessage(createOrUpdateAttendanceSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setCreateOrUpdateAttendanceSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateAttendanceSuccess]);

  useEffect(() => {
    if (fetchAttendancesFailed !== null || createOrUpdateAttendanceFailed !== null) {
      showErrorMessage(fetchAttendancesFailed ?? createOrUpdateAttendanceFailed);

      if (fetchAttendancesFailed !== null) dispatch(setFetchAttendancesFailed(null));
      if (createOrUpdateAttendanceFailed !== null) dispatch(setCreateOrUpdateAttendanceFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAttendancesFailed, createOrUpdateAttendanceFailed]);

  return <></>;
};

export default AttendancePopup;
