import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateMsegatFailed,
  setCreateOrUpdateMsegatSuccess,
  setFetchMsegatFailed,
  setFetchMsegatsFailed,
} from "../../store/msegat/msegat.action";
import {
  getCreateOrUpdateMsegatFailed,
  getCreateOrUpdateMsegatSuccess,
  getFetchMsegatFailed,
  getFetchMsegatsFailed,
} from "../../store/msegat/msegat.selector";

const MsegatPopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateMsegatSuccess = useSelector(getCreateOrUpdateMsegatSuccess);
  const fetchMsegatsFailed = useSelector(getFetchMsegatsFailed);
  // const fetchMsegatFailed = useSelector(getFetchMsegatFailed);
  const createOrUpdateMsegatFailed = useSelector(getCreateOrUpdateMsegatFailed);

  useEffect(() => {
    if (createOrUpdateMsegatSuccess !== null) {
      showSuccessMessage(createOrUpdateMsegatSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setCreateOrUpdateMsegatSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateMsegatSuccess]);

  useEffect(() => {
    if (fetchMsegatsFailed !== null || createOrUpdateMsegatFailed !== null) {
      showErrorMessage(fetchMsegatsFailed ?? createOrUpdateMsegatFailed);

      if (fetchMsegatsFailed !== null) dispatch(setFetchMsegatsFailed(null));
      if (createOrUpdateMsegatFailed !== null) dispatch(setCreateOrUpdateMsegatFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMsegatsFailed, createOrUpdateMsegatFailed]);

  return <></>;
};

export default MsegatPopup;
