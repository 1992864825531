import INTEGRATION_ACTION_TYPES from "./integration.type";

export const INTEGRATION_INITIAL_STATE = {
  isModalAutoClose: true,

  integrations: [],
  integration: null,

  qoyodStatus: null,
  qoyodSync: null,
  grubtechStatus: null,
  grubtechSync: null,
  ballurhStatus: null,
  ballurhGenerate: null,
  ballurhSync: null,
  zatcaSetup: null,
  zatcaRegister: null,
  zatcaStatus: null,
  zatcaSync: null,
  faiSetup: null,
  faiRegister: null,
  faiRegisterStatus: null,
  faiStatus: null,
  faiSync: null,

  isIntegrationsHasMore: true,

  fetchIntegrationsSort: null,
  fetchIntegrationsKeyBy: null,
  fetchIntegrationsPage: 1,
  fetchIntegrationsPerPage: null,
  fetchIntegrationsIncludes: null,
  fetchIntegrationsFilterMarketId: null,
  fetchIntegrationsFilterBranchId: null,
  fetchIntegrationsFilterKeys: null,
  fetchIntegrationsLoading: false,
  fetchIntegrationsSuccess: null,
  fetchIntegrationsFailed: null,

  fetchIntegrationLoading: false,
  fetchIntegrationSuccess: null,
  fetchIntegrationFailed: null,

  createOrUpdateIntegrationLoading: false,
  createOrUpdateIntegrationSuccess: null,
  createOrUpdateIntegrationFailed: null,

  fetchQoyodStatusLoading: false,
  fetchQoyodStatusSuccess: null,
  fetchQoyodStatusFailed: null,

  syncQoyodLoading: false,
  syncQoyodSuccess: null,
  syncQoyodFailed: null,

  fetchGrubtechStatusLoading: false,
  fetchGrubtechStatusSuccess: null,
  fetchGrubtechStatusFailed: null,

  syncGrubtechLoading: false,
  syncGrubtechSuccess: null,
  syncGrubtechFailed: null,

  fetchBallurhStatusLoading: false,
  fetchBallurhStatusSuccess: null,
  fetchBallurhStatusFailed: null,

  generateBallurhLoading: false,
  generateBallurhSuccess: null,
  generateBallurhFailed: null,

  syncBallurhLoading: false,
  syncBallurhSuccess: null,
  syncBallurhFailed: null,

  setupZatcaLoading: false,
  setupZatcaSuccess: null,
  setupZatcaFailed: null,

  registerZatcaLoading: false,
  registerZatcaSuccess: null,
  registerZatcaFailed: null,

  fetchZatcaStatusLoading: false,
  fetchZatcaStatusSuccess: null,
  fetchZatcaStatusFailed: null,

  syncZatcaLoading: false,
  syncZatcaSuccess: null,
  syncZatcaFailed: null,

  setupFaiLoading: false,
  setupFaiSuccess: null,
  setupFaiFailed: null,

  registerFaiLoading: false,
  registerFaiSuccess: null,
  registerFaiFailed: null,

  fetchRegisterFaiStatusLoading: false,
  fetchRegisterFaiStatusSuccess: null,
  fetchRegisterFaiStatusFailed: null,

  fetchFaiStatusLoading: false,
  fetchFaiStatusSuccess: null,
  fetchFaiStatusFailed: null,

  syncFaiLoading: false,
  syncFaiSuccess: null,
  syncFaiFailed: null,

  isFetchIntegrationsHitted: false,
  isFetchIntegrationHitted: false,
  isCreateOrUpdateIntegrationHitted: false,
  isFetchQoyodStatusHitted: false,
  isSyncQoyodHitted: false,
  isFetchGrubtechStatusHitted: false,
  isSyncGrubtechHitted: false,
  isFetchBallurhStatusHitted: false,
  isGenerateBallurhHitted: false,
  isSyncBallurhHitted: false,
  isSetupZatcaHitted: false,
  isRegisterZatcaHitted: false,
  isFetchZatcaStatusHitted: false,
  isSyncZatcaHitted: false,
  isSetupFaiHitted: false,
  isRegisterFaiHitted: false,
  isFetchRegisterFaiStatusHitted: false,
  isFetchFaiStatusHitted: false,
  isSyncFaiHitted: false,
};

export const integrationReducer = (
  state = INTEGRATION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case INTEGRATION_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };

    case INTEGRATION_ACTION_TYPES.SET_INTEGRATIONS:
      return { ...state, integrations: payload };
    case INTEGRATION_ACTION_TYPES.SET_INTEGRATION:
      return { ...state, integration: payload };
    case INTEGRATION_ACTION_TYPES.SET_QOYOD_STATUS:
      return { ...state, qoyodStatus: payload };
    case INTEGRATION_ACTION_TYPES.SET_QOYOD_SYNC:
      return { ...state, qoyodSync: payload };
    case INTEGRATION_ACTION_TYPES.SET_GRUBTECH_STATUS:
      return { ...state, grubtechStatus: payload };
    case INTEGRATION_ACTION_TYPES.SET_GRUBTECH_SYNC:
      return { ...state, grubtechSync: payload };
    case INTEGRATION_ACTION_TYPES.SET_BALLURH_STATUS:
      return { ...state, ballurhStatus: payload };
    case INTEGRATION_ACTION_TYPES.SET_BALLURH_GENERATE:
      return { ...state, ballurhGenerate: payload };
    case INTEGRATION_ACTION_TYPES.SET_BALLURH_SYNC:
      return { ...state, ballurhSync: payload };
    case INTEGRATION_ACTION_TYPES.SET_ZATCA_SETUP:
      return { ...state, zatcaSetup: payload };
    case INTEGRATION_ACTION_TYPES.SET_ZATCA_REGISTER:
      return { ...state, zatcaRegister: payload };
    case INTEGRATION_ACTION_TYPES.SET_ZATCA_STATUS:
      return { ...state, zatcaStatus: payload };
    case INTEGRATION_ACTION_TYPES.SET_ZATCA_SYNC:
      return { ...state, zatcaSync: payload };
    case INTEGRATION_ACTION_TYPES.SET_FAI_SETUP:
      return { ...state, faiSetup: payload };
    case INTEGRATION_ACTION_TYPES.SET_FAI_REGISTER:
      return { ...state, faiRegister: payload };
    case INTEGRATION_ACTION_TYPES.SET_FAI_REGISTER_STATUS:
      return { ...state, faiRegisterStatus: payload };
    case INTEGRATION_ACTION_TYPES.SET_FAI_STATUS:
      return { ...state, faiStatus: payload };
    case INTEGRATION_ACTION_TYPES.SET_FAI_SYNC:
      return { ...state, faiSync: payload };

    case INTEGRATION_ACTION_TYPES.SET_IS_INTEGRATIONS_HAS_MORE:
      return { ...state, isIntegrationsHasMore: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_SORT:
      return { ...state, fetchIntegrationsSort: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_KEY_BY:
      return { ...state, fetchIntegrationsKeyBy: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_PAGE:
      return { ...state, fetchIntegrationsPage: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_PER_PAGE:
      return { ...state, fetchIntegrationsPerPage: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_INCLUDES:
      return { ...state, fetchIntegrationsIncludes: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FILTER_MARKET_ID:
      return { ...state, fetchIntegrationsFilterMarketId: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FILTER_BRANCH_ID:
      return { ...state, fetchIntegrationsFilterBranchId: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FILTER_KEYS:
      return { ...state, fetchIntegrationsFilterKeys: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_LOADING:
      return { ...state, fetchIntegrationsLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_SUCCESS:
      return { ...state, fetchIntegrationsSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FAILED:
      return { ...state, fetchIntegrationsFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATION_LOADING:
      return { ...state, fetchIntegrationLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATION_SUCCESS:
      return { ...state, fetchIntegrationSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATION_FAILED:
      return { ...state, fetchIntegrationFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_CREATE_OR_UPDATE_INTEGRATION_LOADING:
      return { ...state, createOrUpdateIntegrationLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_CREATE_OR_UPDATE_INTEGRATION_SUCCESS:
      return { ...state, createOrUpdateIntegrationSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_CREATE_OR_UPDATE_INTEGRATION_FAILED:
      return { ...state, createOrUpdateIntegrationFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_QOYOD_STATUS_LOADING:
      return { ...state, fetchQoyodStatusLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_QOYOD_STATUS_SUCCESS:
      return { ...state, fetchQoyodStatusSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_QOYOD_STATUS_FAILED:
      return { ...state, fetchQoyodStatusFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SYNC_QOYOD_LOADING:
      return { ...state, syncQoyodLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_QOYOD_FAILED:
      return { ...state, syncQoyodSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_QOYOD_SUCCESS:
      return { ...state, syncQoyodFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_GRUBTECH_STATUS_LOADING:
      return { ...state, fetchGrubtechStatusLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_GRUBTECH_STATUS_SUCCESS:
      return { ...state, fetchGrubtechStatusSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_GRUBTECH_STATUS_FAILED:
      return { ...state, fetchGrubtechStatusFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SYNC_GRUBTECH_LOADING:
      return { ...state, syncGrubtechLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_GRUBTECH_FAILED:
      return { ...state, syncGrubtechSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_GRUBTECH_SUCCESS:
      return { ...state, syncGrubtechFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_BALLURH_STATUS_LOADING:
      return { ...state, fetchBallurhStatusLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_BALLURH_STATUS_SUCCESS:
      return { ...state, fetchBallurhStatusSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_BALLURH_STATUS_FAILED:
      return { ...state, fetchBallurhStatusFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_GENERATE_BALLURH_LOADING:
      return { ...state, generateBallurhLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_GENERATE_BALLURH_SUCCESS:
      return { ...state, generateBallurhSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_GENERATE_BALLURH_FAILED:
      return { ...state, generateBallurhFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SYNC_BALLURH_LOADING:
      return { ...state, syncBallurhLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_BALLURH_SUCCESS:
      return { ...state, syncBallurhSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_BALLURH_FAILED:
      return { ...state, syncBallurhFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SETUP_ZATCA_LOADING:
      return { ...state, setupZatcaLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SETUP_ZATCA_SUCCESS:
      return { ...state, setupZatcaSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SETUP_ZATCA_FAILED:
      return { ...state, setupZatcaFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_REGISTER_ZATCA_LOADING:
      return { ...state, registerZatcaLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_REGISTER_ZATCA_SUCCESS:
      return { ...state, registerZatcaSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_REGISTER_ZATCA_FAILED:
      return { ...state, registerZatcaFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_ZATCA_STATUS_LOADING:
      return { ...state, fetchZatcaStatusLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_ZATCA_STATUS_SUCCESS:
      return { ...state, fetchZatcaStatusSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_ZATCA_STATUS_FAILED:
      return { ...state, fetchZatcaStatusFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SYNC_ZATCA_LOADING:
      return { ...state, syncZatcaLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_ZATCA_SUCCESS:
      return { ...state, syncZatcaSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_ZATCA_FAILED:
      return { ...state, syncZatcaFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SETUP_FAI_LOADING:
      return { ...state, setupFaiLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SETUP_FAI_SUCCESS:
      return { ...state, setupFaiSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SETUP_FAI_FAILED:
      return { ...state, setupFaiFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_REGISTER_FAI_LOADING:
      return { ...state, registerFaiLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_REGISTER_FAI_SUCCESS:
      return { ...state, registerFaiSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_REGISTER_FAI_FAILED:
      return { ...state, registerFaiFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_REGISTER_FAI_STATUS_LOADING:
      return { ...state, fetchRegisterFaiStatusLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_REGISTER_FAI_STATUS_SUCCESS:
      return { ...state, fetchRegisterFaiStatusSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_REGISTER_FAI_STATUS_FAILED:
      return { ...state, fetchRegisterFaiStatusFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_FETCH_FAI_STATUS_LOADING:
      return { ...state, fetchFaiStatusLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_FAI_STATUS_SUCCESS:
      return { ...state, fetchFaiStatusSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_FETCH_FAI_STATUS_FAILED:
      return { ...state, fetchFaiStatusFailed: payload };

    case INTEGRATION_ACTION_TYPES.SET_SYNC_FAI_LOADING:
      return { ...state, syncFaiLoading: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_FAI_SUCCESS:
      return { ...state, syncFaiSuccess: payload };
    case INTEGRATION_ACTION_TYPES.SET_SYNC_FAI_FAILED:
      return { ...state, syncFaiFailed: payload };

    case INTEGRATION_ACTION_TYPES.APPEND_INTEGRATIONS:
      return { ...state, integrations: [...state.integrations, ...payload] };

    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_INTEGRATIONS_HITTED:
      return { ...state, isFetchIntegrationsHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_INTEGRATION_HITTED:
      return { ...state, isFetchIntegrationHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_INTEGRATION_HITTED:
      return { ...state, isCreateOrUpdateIntegrationHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_QOYOD_STATUS_HITTED:
      return { ...state, isFetchQoyodStatusHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SYNC_QOYOD_HITTED:
      return { ...state, isSyncQoyodHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_GRUBTECH_STATUS_HITTED:
      return { ...state, isFetchGrubtechStatusHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SYNC_GRUBTECH_HITTED:
      return { ...state, isSyncGrubtechHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_BALLURH_STATUS_HITTED:
      return { ...state, isFetchBallurhStatusHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_GENERATE_BALLURH_HITTED:
      return { ...state, isGenerateBallurhHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SYNC_BALLURH_HITTED:
      return { ...state, isSyncBallurhHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SETUP_ZATCA_HITTED:
      return { ...state, isSetupZatcaHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_REGISTER_ZATCA_HITTED:
      return { ...state, isRegisterZatcaHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_ZATCA_STATUS_HITTED:
      return { ...state, isFetchZatcaStatusHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SYNC_ZATCA_HITTED:
      return { ...state, isSyncZatcaHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SETUP_FAI_HITTED:
      return { ...state, isSetupFaiHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_REGISTER_FAI_HITTED:
      return { ...state, isRegisterFaiHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_REGISTER_FAI_STATUS_HITTED:
      return { ...state, isFetchRegisterFaiStatusHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_FETCH_FAI_STATUS_HITTED:
      return { ...state, isFetchFaiStatusHitted: payload };
    case INTEGRATION_ACTION_TYPES.SET_IS_SYNC_FAI_HITTED:
      return { ...state, isSyncFaiHitted: payload };

    case INTEGRATION_ACTION_TYPES.RESET_INTEGRATION_REDUCER:
      return INTEGRATION_INITIAL_STATE;
    default:
      return state;
  }
};
