import { createSelector } from "reselect";

const selectPermissionAdminSelector = ({ selectPermissionAdmin }) =>
  selectPermissionAdmin;

export const getPermissionAdmins = createSelector(
  [selectPermissionAdminSelector],
  ({ permissionAdmins }) => permissionAdmins
);
export const getPermissionSubscribers = createSelector(
  [selectPermissionAdminSelector],
  ({ permissionSubscribers }) => permissionSubscribers
);
export const getSearchPermissionAdmins = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionAdmins }) => searchPermissionAdmins
);
export const getSearchPermissionSubscribers = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionSubscribers }) => searchPermissionSubscribers
);

export const getIsPermissionAdminsHasMore = createSelector(
  [selectPermissionAdminSelector],
  ({ isPermissionAdminsHasMore }) => isPermissionAdminsHasMore
);
export const getIsPermissionSubscribersHasMore = createSelector(
  [selectPermissionAdminSelector],
  ({ isPermissionSubscribersHasMore }) => isPermissionSubscribersHasMore
);
export const getIsSearchPermissionAdminsHasMore = createSelector(
  [selectPermissionAdminSelector],
  ({ isSearchPermissionAdminsHasMore }) => isSearchPermissionAdminsHasMore
);
export const getIsSearchPermissionSubscribersHasMore = createSelector(
  [selectPermissionAdminSelector],
  ({ isSearchPermissionSubscribersHasMore }) =>
    isSearchPermissionSubscribersHasMore
);

export const getFetchPermissionAdminsParams = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionAdminsParams }) => fetchPermissionAdminsParams
);
export const getFetchPermissionAdminsLoading = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionAdminsLoading }) => fetchPermissionAdminsLoading
);
export const getFetchPermissionAdminsSuccess = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionAdminsSuccess }) => fetchPermissionAdminsSuccess
);
export const getFetchPermissionAdminsFailed = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionAdminsFailed }) => fetchPermissionAdminsFailed
);

export const getFetchPermissionSubscribersParams = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionSubscribersParams }) => fetchPermissionSubscribersParams
);
export const getFetchPermissionSubscribersLoading = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionSubscribersLoading }) => fetchPermissionSubscribersLoading
);
export const getFetchPermissionSubscribersSuccess = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionSubscribersSuccess }) => fetchPermissionSubscribersSuccess
);
export const getFetchPermissionSubscribersFailed = createSelector(
  [selectPermissionAdminSelector],
  ({ fetchPermissionSubscribersFailed }) => fetchPermissionSubscribersFailed
);

export const getSearchPermissionAdminsParams = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionAdminsParams }) => searchPermissionAdminsParams
);
export const getSearchPermissionAdminsLoading = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionAdminsLoading }) => searchPermissionAdminsLoading
);
export const getSearchPermissionAdminsSuccess = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionAdminsSuccess }) => searchPermissionAdminsSuccess
);
export const getSearchPermissionAdminsFailed = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionAdminsFailed }) => searchPermissionAdminsFailed
);

export const getSearchPermissionSubscribersParams = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionSubscribersParams }) => searchPermissionSubscribersParams
);
export const getSearchPermissionSubscribersLoading = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionSubscribersLoading }) => searchPermissionSubscribersLoading
);
export const getSearchPermissionSubscribersSuccess = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionSubscribersSuccess }) => searchPermissionSubscribersSuccess
);
export const getSearchPermissionSubscribersFailed = createSelector(
  [selectPermissionAdminSelector],
  ({ searchPermissionSubscribersFailed }) => searchPermissionSubscribersFailed
);

export const getAppendPermissionAdminsParams = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionAdminsParams }) => appendPermissionAdminsParams
);
export const getAppendPermissionAdminsLoading = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionAdminsLoading }) => appendPermissionAdminsLoading
);
export const getAppendPermissionAdminsSuccess = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionAdminsSuccess }) => appendPermissionAdminsSuccess
);
export const getAppendPermissionAdminsFailed = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionAdminsFailed }) => appendPermissionAdminsFailed
);

export const getAppendPermissionSubscribersParams = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionSubscribersParams }) => appendPermissionSubscribersParams
);
export const getAppendPermissionSubscribersLoading = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionSubscribersLoading }) => appendPermissionSubscribersLoading
);
export const getAppendPermissionSubscribersSuccess = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionSubscribersSuccess }) => appendPermissionSubscribersSuccess
);
export const getAppendPermissionSubscribersFailed = createSelector(
  [selectPermissionAdminSelector],
  ({ appendPermissionSubscribersFailed }) => appendPermissionSubscribersFailed
);

export const getIsFetchPermissionAdminsHitted = createSelector(
  [selectPermissionAdminSelector],
  ({ isFetchPermissionAdminsHitted }) => isFetchPermissionAdminsHitted
);
export const getIsFetchPermissionSubscribersHitted = createSelector(
  [selectPermissionAdminSelector],
  ({ isFetchPermissionSubscribersHitted }) => isFetchPermissionSubscribersHitted
);
export const getIsSearchPermissionAdminsHitted = createSelector(
  [selectPermissionAdminSelector],
  ({ isSearchPermissionAdminsHitted }) => isSearchPermissionAdminsHitted
);
export const getIsSearchPermissionSubscribersHitted = createSelector(
  [selectPermissionAdminSelector],
  ({ isSearchPermissionSubscribersHitted }) =>
    isSearchPermissionSubscribersHitted
);
export const getIsAppendPermissionAdminsHitted = createSelector(
  [selectPermissionAdminSelector],
  ({ isAppendPermissionAdminsHitted }) => isAppendPermissionAdminsHitted
);
export const getIsAppendPermissionSubscribersHitted = createSelector(
  [selectPermissionAdminSelector],
  ({ isAppendPermissionSubscribersHitted }) =>
    isAppendPermissionSubscribersHitted
);
