import { PERMISSION_SUBSCRIBER_PAGES } from "./permission-subscriber.constant";

export const INTEGRATION_COMPETION_TYPES = {
  COMPLETION_FAI: "COMPLETION_FAI",
  COMPLETION_ZATCA: "COMPLETION_ZATCA",
};

export const INTEGRATION_FAI_COMPETIONS = {
  FAI_BUSINESS_METHOD: "FAI_BUSINESS_METHOD",
  FAI_BUSINESS_SLUG: "FAI_BUSINESS_SLUG",
  FAI_BUSINESS_LOGO: "FAI_BUSINESS_LOGO",
  FAI_BUSINESS_CRN: "FAI_BUSINESS_CRN",
  FAI_BUSINESS_TAX: "FAI_BUSINESS_TAX",
  FAI_BUSINESS_LOCATION: "FAI_BUSINESS_LOCATION",
};

export const getFaiCompletionInfo = (statusKey) =>
  ({
    [INTEGRATION_FAI_COMPETIONS.FAI_BUSINESS_METHOD]: {
      permission_key: PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION,
      label: "Invoice Method",
    },
    [INTEGRATION_FAI_COMPETIONS.FAI_BUSINESS_SLUG]: {
      permission_key: PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION,
      label: "Business Slug",
    },
    [INTEGRATION_FAI_COMPETIONS.FAI_BUSINESS_LOGO]: {
      permission_key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE,
      label: "Business Logo",
    },
    [INTEGRATION_FAI_COMPETIONS.FAI_BUSINESS_TAX]: {
      permission_key: PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION,
      label: "Tax Number",
    },
    [INTEGRATION_FAI_COMPETIONS.FAI_BUSINESS_CRN]: {
      permission_key: PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION,
      label: "Commercial Registration Number",
    },
    [INTEGRATION_FAI_COMPETIONS.FAI_BUSINESS_LOCATION]: {
      permission_key: PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION,
      label: "Business Location",
    },
  }?.[statusKey]);
