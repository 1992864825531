import apiService from "./api";

export const getIngredientHistories = async (ingredientId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/ingredients/${ingredientId}/histories`,
      params,
    })
  ).data;
export const getIngredientHistory = async (ingredientId, ingredientHistoryId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/ingredients/${ingredientId}/histories/${ingredientHistoryId}`,
    })
  ).data;
