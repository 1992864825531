import styled from "styled-components";

export const GettingStartedListRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  row-gap: 1rem;
`;

export const GettingStartedListColumn = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
`;
