import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setAppendSelectPermissionAdminsFailed,
  setAppendSelectPermissionSubscribersFailed,
  setCreatePermissionFailed,
  setCreatePermissionSuccess,
  setDeletePermissionFailed,
  setDeletePermissionSuccess,
  setFetchPermissionAdminsFailed,
  setFetchPermissionFailed,
  setFetchPermissionSubscribersFailed,
  setFetchSelectPermissionAdminsFailed,
  setFetchSelectPermissionSubscribersFailed,
  setUpdatePermissionFailed,
  setUpdatePermissionSuccess,
} from "../../store/permission-admin/permission-admin.action";
import {
  getAppendSelectPermissionAdminsFailed,
  getAppendSelectPermissionSubscribersFailed,
  getCreatePermissionFailed,
  getCreatePermissionSuccess,
  getDeletePermissionFailed,
  getDeletePermissionSuccess,
  getFetchPermissionAdminsFailed,
  getFetchPermissionFailed,
  getFetchPermissionSubscribersFailed,
  getFetchSelectPermissionAdminsFailed,
  getFetchSelectPermissionSubscribersFailed,
  getUpdatePermissionFailed,
  getUpdatePermissionSuccess,
} from "../../store/permission-admin/permission-admin.selector";

const PermissionPopupAdmin = () => {
  const dispatch = useDispatch();

  const createPermissionSuccess = useSelector(getCreatePermissionSuccess);
  const updatePermissionSuccess = useSelector(getUpdatePermissionSuccess);
  const deletePermissionSuccess = useSelector(getDeletePermissionSuccess);
  const fetchPermissionAdminsFailed = useSelector(
    getFetchPermissionAdminsFailed
  );
  const fetchPermissionSubscribersFailed = useSelector(
    getFetchPermissionSubscribersFailed
  );
  const fetchSelectPermissionAdminsFailed = useSelector(
    getFetchSelectPermissionAdminsFailed
  );
  const fetchSelectPermissionSubscribersFailed = useSelector(
    getFetchSelectPermissionSubscribersFailed
  );
  const appendSelectPermissionAdminsFailed = useSelector(
    getAppendSelectPermissionAdminsFailed
  );
  const appendSelectPermissionSubscribersFailed = useSelector(
    getAppendSelectPermissionSubscribersFailed
  );
  const fetchPermissionFailed = useSelector(getFetchPermissionFailed);
  const createPermissionFailed = useSelector(getCreatePermissionFailed);
  const updatePermissionFailed = useSelector(getUpdatePermissionFailed);
  const deletePermissionFailed = useSelector(getDeletePermissionFailed);

  useEffect(() => {
    if (
      createPermissionSuccess !== null ||
      updatePermissionSuccess !== null ||
      deletePermissionSuccess !== null
    ) {
      showSuccessMessage(
        createPermissionSuccess ??
          updatePermissionSuccess ??
          deletePermissionSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createPermissionSuccess !== null)
        dispatch(setCreatePermissionSuccess(null));
      if (updatePermissionSuccess !== null)
        dispatch(setUpdatePermissionSuccess(null));
      if (deletePermissionSuccess !== null)
        dispatch(setDeletePermissionSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createPermissionSuccess,
    updatePermissionSuccess,
    deletePermissionSuccess,
  ]);

  useEffect(() => {
    if (
      fetchPermissionAdminsFailed !== null ||
      fetchPermissionSubscribersFailed !== null ||
      fetchSelectPermissionAdminsFailed !== null ||
      fetchSelectPermissionSubscribersFailed !== null ||
      appendSelectPermissionAdminsFailed !== null ||
      appendSelectPermissionSubscribersFailed !== null ||
      fetchPermissionFailed !== null ||
      createPermissionFailed !== null ||
      updatePermissionFailed !== null ||
      deletePermissionFailed !== null
    ) {
      showErrorMessage(
        fetchPermissionAdminsFailed ??
          fetchPermissionSubscribersFailed ??
          fetchSelectPermissionAdminsFailed ??
          fetchSelectPermissionSubscribersFailed ??
          appendSelectPermissionAdminsFailed ??
          appendSelectPermissionSubscribersFailed ??
          fetchPermissionFailed ??
          createPermissionFailed ??
          updatePermissionFailed ??
          deletePermissionFailed
      );

      if (fetchPermissionAdminsFailed !== null)
        dispatch(setFetchPermissionAdminsFailed(null));
      if (fetchPermissionSubscribersFailed !== null)
        dispatch(setFetchPermissionSubscribersFailed(null));
      if (fetchSelectPermissionAdminsFailed !== null)
        dispatch(setFetchSelectPermissionAdminsFailed(null));
      if (fetchSelectPermissionSubscribersFailed !== null)
        dispatch(setFetchSelectPermissionSubscribersFailed(null));
      if (appendSelectPermissionAdminsFailed !== null)
        dispatch(setAppendSelectPermissionAdminsFailed(null));
      if (appendSelectPermissionSubscribersFailed !== null)
        dispatch(setAppendSelectPermissionSubscribersFailed(null));
      if (fetchPermissionFailed !== null)
        dispatch(setFetchPermissionFailed(null));
      if (createPermissionFailed !== null)
        dispatch(setCreatePermissionFailed(null));
      if (updatePermissionFailed !== null)
        dispatch(setUpdatePermissionFailed(null));
      if (deletePermissionFailed !== null)
        dispatch(setDeletePermissionFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPermissionAdminsFailed,
    fetchPermissionSubscribersFailed,
    fetchSelectPermissionAdminsFailed,
    fetchSelectPermissionSubscribersFailed,
    appendSelectPermissionAdminsFailed,
    appendSelectPermissionSubscribersFailed,
    fetchPermissionFailed,
    createPermissionFailed,
    updatePermissionFailed,
    deletePermissionFailed,
  ]);

  return <></>;
};

export default PermissionPopupAdmin;
