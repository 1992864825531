import { useEffect } from "react";

import { BURAQ_PUBLIC_KEY } from "../../config";

const GlobalChatBot = () => {
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== "local") {
      const head = document.querySelector("head");
      const script = document.createElement("script");

      script.setAttribute(
        "src",
        `//app.buraq.ai/chat-widget/${BURAQ_PUBLIC_KEY}.js`
      );
      // script.setAttribute("src", `//code.tidio.co/${TIDIO_PUBLIC_KEY}.js`);
      head.append(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default GlobalChatBot;
