import { createSelector } from "reselect";

const exportPdfSelector = ({ exportPdf }) => exportPdf;

export const getExportPdfOrderInvoiceUrl = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceUrl }) => exportPdfOrderInvoiceUrl
);
export const getExportPdfOrderInvoiceBlob = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceBlob }) => exportPdfOrderInvoiceBlob
);
export const getExportPdfOrderInvoiceName = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceName }) => exportPdfOrderInvoiceName
);

export const getExportPdfOrderKitchenUrl = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenUrl }) => exportPdfOrderKitchenUrl
);
export const getExportPdfOrderKitchenBlob = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenBlob }) => exportPdfOrderKitchenBlob
);
export const getExportPdfOrderKitchenName = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenName }) => exportPdfOrderKitchenName
);

export const getExportPdfExtendInvoiceUrl = createSelector(
  [exportPdfSelector],
  ({ exportPdfExtendInvoiceUrl }) => exportPdfExtendInvoiceUrl
);
export const getExportPdfExtendInvoiceBlob = createSelector(
  [exportPdfSelector],
  ({ exportPdfExtendInvoiceBlob }) => exportPdfExtendInvoiceBlob
);
export const getExportPdfExtendInvoiceName = createSelector(
  [exportPdfSelector],
  ({ exportPdfExtendInvoiceName }) => exportPdfExtendInvoiceName
);

export const getExportPdfOrderInvoiceLoading = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceLoading }) => exportPdfOrderInvoiceLoading
);
export const getExportPdfOrderInvoiceSuccess = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceSuccess }) => exportPdfOrderInvoiceSuccess
);
export const getExportPdfOrderInvoiceFailed = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceFailed }) => exportPdfOrderInvoiceFailed
);

export const getExportPdfOrderKitchenLoading = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenLoading }) => exportPdfOrderKitchenLoading
);
export const getExportPdfOrderKitchenSuccess = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenSuccess }) => exportPdfOrderKitchenSuccess
);
export const getExportPdfOrderKitchenFailed = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenFailed }) => exportPdfOrderKitchenFailed
);

export const getExportPdfExtendInvoiceLoading = createSelector(
  [exportPdfSelector],
  ({ exportPdfExtendInvoiceLoading }) => exportPdfExtendInvoiceLoading
);
export const getExportPdfExtendInvoiceSuccess = createSelector(
  [exportPdfSelector],
  ({ exportPdfExtendInvoiceSuccess }) => exportPdfExtendInvoiceSuccess
);
export const getExportPdfExtendInvoiceFailed = createSelector(
  [exportPdfSelector],
  ({ exportPdfExtendInvoiceFailed }) => exportPdfExtendInvoiceFailed
);

export const getIsExportPdfOrderInvoiceHitted = createSelector(
  [exportPdfSelector],
  ({ isExportPdfOrderInvoiceHitted }) => isExportPdfOrderInvoiceHitted
);
export const getIsExportPdfOrderKitchenHitted = createSelector(
  [exportPdfSelector],
  ({ isExportPdfOrderKitchenHitted }) => isExportPdfOrderKitchenHitted
);
export const getIsExportPdfExtendInvoiceHitted = createSelector(
  [exportPdfSelector],
  ({ isExportPdfExtendInvoiceHitted }) => isExportPdfExtendInvoiceHitted
);
