import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateIntegrationFailed,
  setCreateOrUpdateIntegrationSuccess,
  setFetchFaiStatusFailed,
  setFetchIntegrationFailed,
  setFetchIntegrationsFailed,
  setFetchZatcaStatusFailed,
  setRegisterFaiFailed,
  setRegisterFaiSuccess,
  setRegisterZatcaFailed,
  setRegisterZatcaSuccess,
  setSyncFaiFailed,
  setSyncFaiSuccess,
  setSyncGrubtechFailed,
  setSyncGrubtechSuccess,
  setSyncQoyodFailed,
  setSyncQoyodSuccess,
  setSyncZatcaFailed,
  setSyncZatcaSuccess,
} from "../../store/integration/integration.action";
import {
  getCreateOrUpdateIntegrationFailed,
  getCreateOrUpdateIntegrationSuccess,
  getFetchFaiStatusFailed,
  getFetchIntegrationFailed,
  getFetchIntegrationsFailed,
  getFetchZatcaStatusFailed,
  getIsModalAutoClose,
  getRegisterFaiFailed,
  getRegisterFaiSuccess,
  getRegisterZatcaFailed,
  getRegisterZatcaSuccess,
  getSyncFaiFailed,
  getSyncFaiSuccess,
  getSyncGrubtechFailed,
  getSyncGrubtechSuccess,
  getSyncQoyodFailed,
  getSyncQoyodSuccess,
  getSyncZatcaFailed,
  getSyncZatcaSuccess,
} from "../../store/integration/integration.selector";

const IntegrationPopup = () => {
  const dispatch = useDispatch();

  const isModalAutoClose = useSelector(getIsModalAutoClose);
  const createOrUpdateIntegrationSuccess = useSelector(
    getCreateOrUpdateIntegrationSuccess
  );
  const syncGrubtechSuccess = useSelector(getSyncGrubtechSuccess);
  const syncQoyodSuccess = useSelector(getSyncQoyodSuccess);
  const registerZatcaSuccess = useSelector(getRegisterZatcaSuccess);
  const syncZatcaSuccess = useSelector(getSyncZatcaSuccess);
  const registerFaiSuccess = useSelector(getRegisterFaiSuccess);
  const syncFaiSuccess = useSelector(getSyncFaiSuccess);

  const fetchIntegrationsFailed = useSelector(getFetchIntegrationsFailed);
  const fetchIntegrationFailed = useSelector(getFetchIntegrationFailed);
  const createOrUpdateIntegrationFailed = useSelector(
    getCreateOrUpdateIntegrationFailed
  );
  const syncGrubtechFailed = useSelector(getSyncGrubtechFailed);
  const syncQoyodFailed = useSelector(getSyncQoyodFailed);
  const registerZatcaFailed = useSelector(getRegisterZatcaFailed);
  const fetchZatcaStatusFailed = useSelector(getFetchZatcaStatusFailed);
  const syncZatcaFailed = useSelector(getSyncZatcaFailed);
  const registerFaiFailed = useSelector(getRegisterFaiFailed);
  const fetchFaiStatusFailed = useSelector(getFetchFaiStatusFailed);
  const syncFaiFailed = useSelector(getSyncFaiFailed);

  useEffect(() => {
    if (
      createOrUpdateIntegrationSuccess !== null ||
      syncGrubtechSuccess !== null ||
      syncQoyodSuccess !== null ||
      registerZatcaSuccess !== null ||
      syncZatcaSuccess !== null ||
      registerFaiSuccess !== null ||
      syncFaiSuccess !== null
    ) {
      showSuccessMessage(
        createOrUpdateIntegrationSuccess ??
          syncGrubtechSuccess ??
          syncQoyodSuccess ??
          registerZatcaSuccess ??
          syncZatcaSuccess ??
          registerFaiSuccess ??
          syncFaiSuccess
      );
      if (isModalAutoClose) dispatch(setIsModalOpen(false));

      if (createOrUpdateIntegrationSuccess !== null)
        dispatch(setCreateOrUpdateIntegrationSuccess(null));
      if (syncGrubtechSuccess !== null) dispatch(setSyncGrubtechSuccess(null));
      if (syncQoyodSuccess !== null) dispatch(setSyncQoyodSuccess(null));
      if (registerZatcaSuccess !== null)
        dispatch(setRegisterZatcaSuccess(null));
      if (syncZatcaSuccess !== null) dispatch(setSyncZatcaSuccess(null));
      if (registerFaiSuccess !== null) dispatch(setRegisterFaiSuccess(null));
      if (syncFaiSuccess !== null) dispatch(setSyncFaiSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createOrUpdateIntegrationSuccess,
    syncGrubtechSuccess,
    syncQoyodSuccess,
    registerZatcaSuccess,
    syncZatcaSuccess,
    registerFaiSuccess,
    syncFaiSuccess,
  ]);

  useEffect(() => {
    if (
      fetchIntegrationsFailed !== null ||
      fetchIntegrationFailed !== null ||
      createOrUpdateIntegrationFailed !== null ||
      syncGrubtechFailed !== null ||
      syncQoyodFailed !== null ||
      registerZatcaFailed !== null ||
      fetchZatcaStatusFailed !== null ||
      syncZatcaFailed !== null ||
      registerFaiFailed !== null ||
      fetchFaiStatusFailed !== null ||
      syncFaiFailed !== null
    ) {
      showErrorMessage(
        fetchIntegrationsFailed ??
          fetchIntegrationFailed ??
          createOrUpdateIntegrationFailed ??
          syncGrubtechFailed ??
          syncQoyodFailed ??
          registerZatcaFailed ??
          fetchZatcaStatusFailed ??
          syncZatcaFailed ??
          registerFaiFailed ??
          fetchFaiStatusFailed ??
          syncFaiFailed
      );

      if (fetchIntegrationsFailed !== null)
        dispatch(setFetchIntegrationsFailed(null));
      if (fetchIntegrationFailed !== null)
        dispatch(setFetchIntegrationFailed(null));
      if (createOrUpdateIntegrationFailed !== null)
        dispatch(setCreateOrUpdateIntegrationFailed(null));
      if (syncGrubtechFailed !== null) dispatch(setSyncGrubtechFailed(null));
      if (syncQoyodFailed !== null) dispatch(setSyncQoyodFailed(null));
      if (registerZatcaFailed !== null) dispatch(setRegisterZatcaFailed(null));
      if (fetchZatcaStatusFailed !== null)
        dispatch(setFetchZatcaStatusFailed(null));
      if (syncZatcaFailed !== null) dispatch(setSyncZatcaFailed(null));
      if (registerFaiFailed !== null) dispatch(setRegisterFaiFailed(null));
      if (fetchFaiStatusFailed !== null)
        dispatch(setFetchFaiStatusFailed(null));
      if (syncFaiFailed !== null) dispatch(setSyncFaiFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchIntegrationsFailed,
    fetchIntegrationFailed,
    createOrUpdateIntegrationFailed,
    syncGrubtechFailed,
    syncQoyodFailed,
    registerZatcaFailed,
    fetchZatcaStatusFailed,
    syncZatcaFailed,
    registerFaiFailed,
    fetchFaiStatusFailed,
    syncFaiFailed,
  ]);

  return <></>;
};

export default IntegrationPopup;
