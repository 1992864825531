export class ArrayExtension {
  static getHasSameValue(array1, array2) {
    return array1.some((item) => array2.includes(item));
  }

  static getUniqueValues(array1, array2) {
    const uniqueArray1 = array1.filter((item) => !array2.includes(item));
    const uniqueArray2 = array2.filter((item) => !array1.includes(item));

    return [...uniqueArray1, ...uniqueArray2];
  }
}
