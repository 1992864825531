import moment from "moment";
import { publicIpv4 } from "public-ip";
import { useLocation } from "react-router-dom";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getIpAddress, setIpAddress } from "../../utils/storage.utils";
import { isEmpty } from "../../utils/validation.utils";

import { LANGUAGE_DATAS, LANGUAGES } from "../../constants/common.constant";

import {
  setCurrentLanguage,
  setIsRightToLeft,
} from "../../store/global/global.action";
import {
  setIsSidenavOpen,
  setScreenWidth,
} from "../../store/component/component.action";
import { updateMeStart } from "../../store/authentication/authentication.action";
import { getUser } from "../../store/authentication/authentication.selector";

const GlobalState = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const ipAddress = getIpAddress();
  const authUser = useSelector(getUser);
  const lang = authUser?.lang;

  document.body.dir = i18n.dir();
  moment.locale(
    Object.values(LANGUAGES).includes(i18n.language)
      ? i18n.language
      : LANGUAGES.EN
  );

  useEffect(() => {
    dispatch(setIsSidenavOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (Object.values(LANGUAGES).includes(lang)) {
      i18n.changeLanguage(lang);
    } else if (!Object.values(LANGUAGES).includes(i18n.language)) {
      i18n.changeLanguage(LANGUAGES.EN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (!isEmpty(LANGUAGE_DATAS?.[i18n.language])) {
      dispatch(setIsRightToLeft(i18n.language === LANGUAGES.AR));
      dispatch(setCurrentLanguage(LANGUAGE_DATAS[i18n.language]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (i18n.language !== lang && !isEmpty(authUser)) {
      dispatch(updateMeStart({ lang: i18n.language }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, lang]);

  useEffect(() => {
    if (isEmpty(ipAddress)) (async () => setIpAddress(await publicIpv4()))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddress]);

  useEffect(() => {
    dispatch(setScreenWidth(window.innerWidth));

    const handleResize = () => dispatch(setScreenWidth(window.innerWidth));
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return null;
};

export default GlobalState;
