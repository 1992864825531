import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateApplicationFailed,
  setCreateApplicationSuccess,
  setDeleteApplicationFailed,
  setDeleteApplicationSuccess,
  setFetchApplicationFailed,
  setFetchApplicationsFailed,
  setUpdateApplicationFailed,
  setUpdateApplicationSuccess,
} from "../../store/application/application.action";
import {
  getCreateApplicationFailed,
  getCreateApplicationSuccess,
  getDeleteApplicationFailed,
  getDeleteApplicationSuccess,
  getFetchApplicationFailed,
  getFetchApplicationsFailed,
  getUpdateApplicationFailed,
  getUpdateApplicationSuccess,
} from "../../store/application/application.selector";

const ApplicationPopup = () => {
  const dispatch = useDispatch();

  const createApplicationSuccess = useSelector(getCreateApplicationSuccess);
  const updateApplicationSuccess = useSelector(getUpdateApplicationSuccess);
  const deleteApplicationSuccess = useSelector(getDeleteApplicationSuccess);

  const fetchApplicationsFailed = useSelector(getFetchApplicationsFailed);
  const fetchApplicationFailed = useSelector(getFetchApplicationFailed);
  const createApplicationFailed = useSelector(getCreateApplicationFailed);
  const updateApplicationFailed = useSelector(getUpdateApplicationFailed);
  const deleteApplicationFailed = useSelector(getDeleteApplicationFailed);

  useEffect(() => {
    if (
      createApplicationSuccess !== null ||
      updateApplicationSuccess !== null ||
      deleteApplicationSuccess !== null
    ) {
      showSuccessMessage(
        createApplicationSuccess ??
          updateApplicationSuccess ??
          deleteApplicationSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createApplicationSuccess !== null)
        dispatch(setCreateApplicationSuccess(null));
      if (updateApplicationSuccess !== null)
        dispatch(setUpdateApplicationSuccess(null));
      if (deleteApplicationSuccess !== null)
        dispatch(setDeleteApplicationSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createApplicationSuccess,
    updateApplicationSuccess,
    deleteApplicationSuccess,
  ]);

  useEffect(() => {
    if (
      fetchApplicationsFailed !== null ||
      fetchApplicationFailed !== null ||
      createApplicationFailed !== null ||
      updateApplicationFailed !== null ||
      deleteApplicationFailed !== null
    ) {
      showErrorMessage(
        fetchApplicationsFailed ??
          fetchApplicationFailed ??
          createApplicationFailed ??
          updateApplicationFailed ??
          deleteApplicationFailed
      );

      if (fetchApplicationsFailed !== null)
        dispatch(setFetchApplicationsFailed(null));
      if (fetchApplicationFailed !== null)
        dispatch(setFetchApplicationFailed(null));
      if (createApplicationFailed !== null)
        dispatch(setCreateApplicationFailed(null));
      if (updateApplicationFailed !== null)
        dispatch(setUpdateApplicationFailed(null));
      if (deleteApplicationFailed !== null)
        dispatch(setDeleteApplicationFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchApplicationsFailed,
    fetchApplicationFailed,
    createApplicationFailed,
    updateApplicationFailed,
    deleteApplicationFailed,
  ]);

  return <></>;
};

export default ApplicationPopup;
