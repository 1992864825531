import { createSelector } from "reselect";

const subscriptionSelector = ({ subscription }) => subscription;

export const getSubscription = createSelector(
  [subscriptionSelector],
  ({ subscription }) => subscription
);

export const getFetchSubscriptionLoading = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionLoading }) => fetchSubscriptionLoading
);
export const getFetchSubscriptionSuccess = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionSuccess }) => fetchSubscriptionSuccess
);
export const getFetchSubscriptionFailed = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionFailed }) => fetchSubscriptionFailed
);

export const getUpdateSubscriptionLoading = createSelector(
  [subscriptionSelector],
  ({ updateSubscriptionLoading }) => updateSubscriptionLoading
);
export const getUpdateSubscriptionSuccess = createSelector(
  [subscriptionSelector],
  ({ updateSubscriptionSuccess }) => updateSubscriptionSuccess
);
export const getUpdateSubscriptionFailed = createSelector(
  [subscriptionSelector],
  ({ updateSubscriptionFailed }) => updateSubscriptionFailed
);

export const getIsFetchSubscriptionHitted = createSelector(
  [subscriptionSelector],
  ({ isFetchSubscriptionHitted }) => isFetchSubscriptionHitted
);
export const getIsUpdateSubscriptionHitted = createSelector(
  [subscriptionSelector],
  ({ isUpdateSubscriptionHitted }) => isUpdateSubscriptionHitted
);
