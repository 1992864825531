import { isEmpty, isInvalidNumber } from "./validation.utils";

export const parseToString = (value) => `${value ?? ""}`;
export const parseToInteger = (value) => {
  let parsedValue = parseInt(value ?? 0);
  if (isInvalidNumber(parsedValue)) parsedValue = 0;

  return parsedValue;
};
export const parseToFloat = (value) => {
  let parsedValue = parseFloat(value ?? 0);
  if (isInvalidNumber(parsedValue)) parsedValue = 0;

  return parsedValue;
};
export const parseToBoolean = (value) => [1, "1", true, "true"].includes(value);

export const parseSnakeToTitle = (value) =>
  value
    ?.toLowerCase()
    ?.split("_")
    ?.map((word) => word?.charAt?.(0)?.toUpperCase?.() + word?.slice?.(1))
    ?.join(" ");
export const parseSnakeToCamel = (value) =>
  value
    ?.toLowerCase?.()
    .replace(/(_\w)/g, (match) => match?.[1]?.toUpperCase?.());
export const parseCamelToSnake = (value) =>
  value?.replace(/([A-Z])/g, "_$1")?.toLowerCase?.();
