import apiService from "./api";

export const getApplications = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/applications", params })).data;
export const getApplication = async (applicationKey) =>
  (await apiService.innerGet({ urlPath: `/v2/applications/${applicationKey}` }))
    .data;
export const createApplication = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/applications", request })).data;
export const updateApplication = async (applicationKey, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/applications/${applicationKey}`,
      request,
    })
  ).data;
export const deleteApplication = async (applicationKey) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/applications/${applicationKey}`,
    })
  ).data;
