import { useTranslation } from "react-i18next";

import {
  NotificationItemContainer,
  NotificationItemDescription,
  NotificationItemTitle,
} from "./notification-item.style";
import Shimmer, { SHIMMER_RADIUS } from "../../components/shimmer/shimmer.component";

const NotificationItem = ({ notification, isLoading = false }) => {
  const { i18n, t } = useTranslation();

  const { title_en, description_en } = notification ?? {};

  return (
    <NotificationItemContainer>
      {isLoading ? (
        <>
          <NotificationItemTitle>
            <Shimmer
              radius={SHIMMER_RADIUS.PX18}
              widthXs="100%"
              widthSm="80%"
              widthMd="75%"
              widthLg="75%"
              widthXl="75%"
              widthXxl="75%"
              height="2rem"
            />
          </NotificationItemTitle>
          <NotificationItemDescription>
            {Array.from(Array(5).keys()).map((index) => (
              <Shimmer key={index} radius={SHIMMER_RADIUS.PX18} width="100%" height="1.5rem" />
            ))}
          </NotificationItemDescription>
        </>
      ) : (
        <>
          <NotificationItemTitle>
            {notification?.[`title_${i18n.language}`] ?? title_en ?? t("No title")}
          </NotificationItemTitle>
          <NotificationItemDescription
            dangerouslySetInnerHTML={{
              __html: notification?.[`description_${i18n.language}`] ?? description_en ?? t("No description"),
            }}
          />
        </>
      )}
    </NotificationItemContainer>
  );
};

export default NotificationItem;
