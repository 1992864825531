import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setFetchTargetAdminsFailed,
  setFetchTargetFailed,
  setFetchTargetMerchantsFailed,
  setFetchTargetSubscribersFailed,
} from "../../store/banner-target/banner-target.action";
import {
  getFetchTargetAdminsFailed,
  getFetchTargetFailed,
  getFetchTargetMerchantsFailed,
  getFetchTargetSubscribersFailed,
} from "../../store/banner-target/banner-target.selector";

const BannerTargetPopup = () => {
  const dispatch = useDispatch();

  const fetchTargetAdminsFailed = useSelector(getFetchTargetAdminsFailed);
  const fetchTargetMerchantsFailed = useSelector(getFetchTargetMerchantsFailed);
  const fetchTargetSubscribersFailed = useSelector(
    getFetchTargetSubscribersFailed
  );
  const fetchTargetFailed = useSelector(getFetchTargetFailed);

  useEffect(() => {
    if (
      fetchTargetAdminsFailed !== null ||
      fetchTargetMerchantsFailed !== null ||
      fetchTargetSubscribersFailed !== null ||
      fetchTargetFailed !== null
    ) {
      showErrorMessage(
        fetchTargetAdminsFailed ??
          fetchTargetMerchantsFailed ??
          fetchTargetSubscribersFailed ??
          fetchTargetFailed
      );

      if (fetchTargetAdminsFailed !== null)
        dispatch(setFetchTargetAdminsFailed(null));
      if (fetchTargetMerchantsFailed !== null)
        dispatch(setFetchTargetMerchantsFailed(null));
      if (fetchTargetSubscribersFailed !== null)
        dispatch(setFetchTargetSubscribersFailed(null));
      if (fetchTargetFailed !== null) dispatch(setFetchTargetFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchTargetAdminsFailed,
    fetchTargetMerchantsFailed,
    fetchTargetSubscribersFailed,
    fetchTargetFailed,
  ]);

  return <></>;
};

export default BannerTargetPopup;
