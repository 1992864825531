import SELECT_SUBSCRIBER_ACTION_TYPES from "./select-subscriber.type";

const SELECT_SUBSCRIBER_INITIAL_STATE = {
  subscribers: {},
  searchSubscribers: {},

  isSubscribersHasMore: true,
  isSearchSubscribersHasMore: true,

  fetchSubscribersParams: {},
  fetchSubscribersLoading: false,
  fetchSubscribersSuccess: null,
  fetchSubscribersFailed: null,

  searchSubscribersParams: {},
  searchSubscribersLoading: false,
  searchSubscribersSuccess: null,
  searchSubscribersFailed: null,

  appendSubscribersParams: {},
  appendSubscribersLoading: false,
  appendSubscribersSuccess: null,
  appendSubscribersFailed: null,

  isFetchSubscribersHitted: false,
  isSearchSubscribersHitted: false,
  isAppendSubscribersHitted: false,
};

export const selectSubscriberReducer = (
  state = SELECT_SUBSCRIBER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBERS:
      return { ...state, subscribers: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS:
      return { ...state, searchSubscribers: payload };

    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSubscribersHasMore: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSearchSubscribersHasMore: payload };

    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_PARAMS:
      return { ...state, fetchSubscribersParams: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_LOADING:
      return { ...state, fetchSubscribersLoading: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchSubscribersSuccess: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FAILED:
      return { ...state, fetchSubscribersFailed: payload };

    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_PARAMS:
      return { ...state, searchSubscribersParams: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_LOADING:
      return { ...state, searchSubscribersLoading: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_SUCCESS:
      return { ...state, searchSubscribersSuccess: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_FAILED:
      return { ...state, searchSubscribersFailed: payload };

    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_PARAMS:
      return { ...state, appendSubscribersParams: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_LOADING:
      return { ...state, appendSubscribersLoading: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_SUCCESS:
      return { ...state, appendSubscribersSuccess: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_FAILED:
      return { ...state, appendSubscribersFailed: payload };

    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBERS_HITTED:
      return { ...state, isFetchSubscribersHitted: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_SUBSCRIBERS_HITTED:
      return { ...state, isSearchSubscribersHitted: payload };
    case SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_APPEND_SUBSCRIBERS_HITTED:
      return { ...state, isAppendSubscribersHitted: payload };

    case SELECT_SUBSCRIBER_ACTION_TYPES.APPEND_SUBSCRIBERS:
      return {
        ...state,
        subscribers: { ...state.subscribers, ...payload },
      };
    case SELECT_SUBSCRIBER_ACTION_TYPES.APPEND_SEARCH_SUBSCRIBERS:
      return {
        ...state,
        searchSubscribers: { ...state.searchSubscribers, ...payload },
      };

    case SELECT_SUBSCRIBER_ACTION_TYPES.RESET_SELECT_SUBSCRIBER_REDUCER:
      return SELECT_SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
