import apiService from "./api";

export const getBranches = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/branches", params })).data;
export const getBranch = async (branchId) =>
  (await apiService.innerGet({ urlPath: `/v2/branches/${branchId}` })).data;
export const createBranch = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/branches/", request })).data;
export const updateBranch = async (branchId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/branches/${branchId}`, request }))
    .data;
export const deleteBranch = async (branchId) =>
  (await apiService.innerDelete({ urlPath: `/v2/branches/${branchId}` })).data;
