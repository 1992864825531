import apiService from "./api";

export const getReportTotals = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/reports/attendances/totals",
      params,
    })
  ).data;
export const getReportAttendances = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/attendances", params }))
    .data;
export const getReportAttendance = async (attendanceId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/reports/attendances/${attendanceId}`,
      params,
    })
  ).data;
export const getReportAttendanceTotals = async (attendanceId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/reports/attendances/${attendanceId}/totals`,
      params,
    })
  ).data;
