import { createSelector } from "reselect";

const selectCashierSelector = ({ selectCashier }) => selectCashier;

export const getCashiers = createSelector(
  [selectCashierSelector],
  ({ cashiers }) => cashiers
);
export const getSearchCashiers = createSelector(
  [selectCashierSelector],
  ({ searchCashiers }) => searchCashiers
);

export const getIsCashiersHasMore = createSelector(
  [selectCashierSelector],
  ({ isCashiersHasMore }) => isCashiersHasMore
);
export const getIsSearchCashiersHasMore = createSelector(
  [selectCashierSelector],
  ({ isSearchCashiersHasMore }) => isSearchCashiersHasMore
);

export const getFetchCashiersParams = createSelector(
  [selectCashierSelector],
  ({ fetchCashiersParams }) => fetchCashiersParams
);
export const getFetchCashiersLoading = createSelector(
  [selectCashierSelector],
  ({ fetchCashiersLoading }) => fetchCashiersLoading
);
export const getFetchCashiersSuccess = createSelector(
  [selectCashierSelector],
  ({ fetchCashiersSuccess }) => fetchCashiersSuccess
);
export const getFetchCashiersFailed = createSelector(
  [selectCashierSelector],
  ({ fetchCashiersFailed }) => fetchCashiersFailed
);

export const getSearchCashiersParams = createSelector(
  [selectCashierSelector],
  ({ searchCashiersParams }) => searchCashiersParams
);
export const getSearchCashiersLoading = createSelector(
  [selectCashierSelector],
  ({ searchCashiersLoading }) => searchCashiersLoading
);
export const getSearchCashiersSuccess = createSelector(
  [selectCashierSelector],
  ({ searchCashiersSuccess }) => searchCashiersSuccess
);
export const getSearchCashiersFailed = createSelector(
  [selectCashierSelector],
  ({ searchCashiersFailed }) => searchCashiersFailed
);

export const getAppendCashiersParams = createSelector(
  [selectCashierSelector],
  ({ appendCashiersParams }) => appendCashiersParams
);
export const getAppendCashiersLoading = createSelector(
  [selectCashierSelector],
  ({ appendCashiersLoading }) => appendCashiersLoading
);
export const getAppendCashiersSuccess = createSelector(
  [selectCashierSelector],
  ({ appendCashiersSuccess }) => appendCashiersSuccess
);
export const getAppendCashiersFailed = createSelector(
  [selectCashierSelector],
  ({ appendCashiersFailed }) => appendCashiersFailed
);

export const getIsFetchCashiersHitted = createSelector(
  [selectCashierSelector],
  ({ isFetchCashiersHitted }) => isFetchCashiersHitted
);
export const getIsSearchCashiersHitted = createSelector(
  [selectCashierSelector],
  ({ isSearchCashiersHitted }) => isSearchCashiersHitted
);
export const getIsAppendCashiersHitted = createSelector(
  [selectCashierSelector],
  ({ isAppendCashiersHitted }) => isAppendCashiersHitted
);
