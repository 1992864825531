import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendMarketsFailed,
  setFetchMarketsFailed,
  setSearchMarketsFailed,
} from "../../store/select-market/select-market.action";
import {
  getAppendMarketsFailed,
  getFetchMarketsFailed,
  getSearchMarketsFailed,
} from "../../store/select-market/select-market.selector";

const SelectMarketPopup = () => {
  const dispatch = useDispatch();

  const fetchMarketsFailed = useSelector(getFetchMarketsFailed);
  const searchMarketsFailed = useSelector(getSearchMarketsFailed);
  const appendMarketsFailed = useSelector(getAppendMarketsFailed);

  useEffect(() => {
    if (
      fetchMarketsFailed !== null ||
      searchMarketsFailed !== null ||
      appendMarketsFailed !== null
    ) {
      showErrorMessage(
        fetchMarketsFailed ?? searchMarketsFailed ?? appendMarketsFailed
      );

      if (fetchMarketsFailed !== null) dispatch(setFetchMarketsFailed(null));
      if (searchMarketsFailed !== null) dispatch(setSearchMarketsFailed(null));
      if (appendMarketsFailed !== null) dispatch(setAppendMarketsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMarketsFailed, searchMarketsFailed, appendMarketsFailed]);

  return <></>;
};

export default SelectMarketPopup;
