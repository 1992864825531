import { createSelector } from "reselect";

const productSelector = ({ productQuick }) => productQuick;

export const getCreateProduct = createSelector(
  [productSelector],
  ({ createProduct }) => createProduct
);
export const getUpdateProduct = createSelector(
  [productSelector],
  ({ updateProduct }) => updateProduct
);

export const getCreateProductLoading = createSelector(
  [productSelector],
  ({ createProductLoading }) => createProductLoading
);
export const getCreateProductSuccess = createSelector(
  [productSelector],
  ({ createProductSuccess }) => createProductSuccess
);
export const getCreateProductFailed = createSelector(
  [productSelector],
  ({ createProductFailed }) => createProductFailed
);

export const getUpdateProductLoading = createSelector(
  [productSelector],
  ({ updateProductLoading }) => updateProductLoading
);
export const getUpdateProductSuccess = createSelector(
  [productSelector],
  ({ updateProductSuccess }) => updateProductSuccess
);
export const getUpdateProductFailed = createSelector(
  [productSelector],
  ({ updateProductFailed }) => updateProductFailed
);

export const getCreateProductsLoading = createSelector(
  [productSelector],
  ({ createProductsLoading }) => createProductsLoading
);
export const getCreateProductsSuccess = createSelector(
  [productSelector],
  ({ createProductsSuccess }) => createProductsSuccess
);
export const getCreateProductsFailed = createSelector(
  [productSelector],
  ({ createProductsFailed }) => createProductsFailed
);

export const getIsCreateProductHitted = createSelector(
  [productSelector],
  ({ isCreateProductHitted }) => isCreateProductHitted
);
export const getIsUpdateProductHitted = createSelector(
  [productSelector],
  ({ isUpdateProductHitted }) => isUpdateProductHitted
);
export const getIsCreateProductsHitted = createSelector(
  [productSelector],
  ({ isCreateProductsHitted }) => isCreateProductsHitted
);
