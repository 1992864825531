import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateWhatsappFailed,
  setCreateOrUpdateWhatsappSuccess,
  setFetchWhatsappFailed,
  setFetchWhatsappsFailed,
} from "../../store/whatsapp/whatsapp.action";
import {
  getCreateOrUpdateWhatsappFailed,
  getCreateOrUpdateWhatsappSuccess,
  getFetchWhatsappFailed,
  getFetchWhatsappsFailed,
} from "../../store/whatsapp/whatsapp.selector";

const WhatsappPopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateWhatsappSuccess = useSelector(getCreateOrUpdateWhatsappSuccess);
  const fetchWhatsappsFailed = useSelector(getFetchWhatsappsFailed);
  const fetchWhatsappFailed = useSelector(getFetchWhatsappFailed);
  const createOrUpdateWhatsappFailed = useSelector(getCreateOrUpdateWhatsappFailed);

  useEffect(() => {
    if (createOrUpdateWhatsappSuccess !== null) {
      showSuccessMessage(createOrUpdateWhatsappSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setCreateOrUpdateWhatsappSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateWhatsappSuccess]);

  useEffect(() => {
    if (fetchWhatsappsFailed !== null || fetchWhatsappFailed !== null || createOrUpdateWhatsappFailed !== null) {
      showErrorMessage(fetchWhatsappsFailed ?? fetchWhatsappFailed ?? createOrUpdateWhatsappFailed);

      if (fetchWhatsappsFailed !== null) dispatch(setFetchWhatsappsFailed(null));
      if (fetchWhatsappFailed !== null) dispatch(setFetchWhatsappFailed(null));
      if (createOrUpdateWhatsappFailed !== null) dispatch(setCreateOrUpdateWhatsappFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWhatsappsFailed, fetchWhatsappFailed, createOrUpdateWhatsappFailed]);

  return <></>;
};

export default WhatsappPopup;
