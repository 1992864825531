import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateUserMerchantFailed,
  setCreateUserMerchantSuccess,
  setDeleteUserMerchantFailed,
  setDeleteUserMerchantSuccess,
  setFetchUserMerchantFailed,
  setFetchUserMerchantsFailed,
  setUpdateUserMerchantFailed,
  setUpdateUserMerchantSuccess,
} from "../../store/user-merchant/user-merchant.action";
import {
  getCreateUserMerchantFailed,
  getCreateUserMerchantSuccess,
  getDeleteUserMerchantFailed,
  getDeleteUserMerchantSuccess,
  getFetchUserMerchantFailed,
  getFetchUserMerchantsFailed,
  getUpdateUserMerchantFailed,
  getUpdateUserMerchantSuccess,
} from "../../store/user-merchant/user-merchant.selector";

const UserMerchantPopup = () => {
  const dispatch = useDispatch();

  const createUserMerchantSuccess = useSelector(getCreateUserMerchantSuccess);
  const updateUserMerchantSuccess = useSelector(getUpdateUserMerchantSuccess);
  const deleteUserMerchantSuccess = useSelector(getDeleteUserMerchantSuccess);
  const fetchUserMerchantsFailed = useSelector(getFetchUserMerchantsFailed);
  const fetchUserMerchantFailed = useSelector(getFetchUserMerchantFailed);
  const createUserMerchantFailed = useSelector(getCreateUserMerchantFailed);
  const updateUserMerchantFailed = useSelector(getUpdateUserMerchantFailed);
  const deleteUserMerchantFailed = useSelector(getDeleteUserMerchantFailed);

  useEffect(() => {
    if (
      createUserMerchantSuccess !== null ||
      updateUserMerchantSuccess !== null ||
      deleteUserMerchantSuccess !== null
    ) {
      showSuccessMessage(
        createUserMerchantSuccess ??
          updateUserMerchantSuccess ??
          deleteUserMerchantSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createUserMerchantSuccess !== null)
        dispatch(setCreateUserMerchantSuccess(null));
      if (updateUserMerchantSuccess !== null)
        dispatch(setUpdateUserMerchantSuccess(null));
      if (deleteUserMerchantSuccess !== null)
        dispatch(setDeleteUserMerchantSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createUserMerchantSuccess,
    updateUserMerchantSuccess,
    deleteUserMerchantSuccess,
  ]);

  useEffect(() => {
    if (
      fetchUserMerchantsFailed !== null ||
      fetchUserMerchantFailed !== null ||
      createUserMerchantFailed !== null ||
      updateUserMerchantFailed !== null ||
      deleteUserMerchantFailed !== null
    ) {
      showErrorMessage(
        fetchUserMerchantsFailed ??
          fetchUserMerchantFailed ??
          createUserMerchantFailed ??
          updateUserMerchantFailed ??
          deleteUserMerchantFailed
      );

      if (fetchUserMerchantsFailed !== null)
        dispatch(setFetchUserMerchantsFailed(null));
      if (fetchUserMerchantFailed !== null)
        dispatch(setFetchUserMerchantFailed(null));
      if (createUserMerchantFailed !== null)
        dispatch(setCreateUserMerchantFailed(null));
      if (updateUserMerchantFailed !== null)
        dispatch(setUpdateUserMerchantFailed(null));
      if (deleteUserMerchantFailed !== null)
        dispatch(setDeleteUserMerchantFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUserMerchantsFailed,
    fetchUserMerchantFailed,
    createUserMerchantFailed,
    updateUserMerchantFailed,
    deleteUserMerchantFailed,
  ]);

  return <></>;
};

export default UserMerchantPopup;
