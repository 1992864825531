import apiService from "./api";

export const getAnnouncements = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/announcements", params })).data;
export const getAnnouncement = async (announcementId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/announcements/${announcementId}`,
    })
  ).data;
export const createAnnouncement = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/announcements", request })).data;
export const updateAnnouncement = async (announcementId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/announcements/${announcementId}`,
      request,
    })
  ).data;
export const deleteAnnouncement = async (announcementId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/announcements/${announcementId}`,
    })
  ).data;
export const readAnnouncement = async (request) =>
  (await apiService.innerPost(`/v2/announcements/read`, request)).data;
