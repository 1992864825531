import apiService from "./api";

export const getUserMerchants = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/merchants", params })).data;
export const getUserMerchant = async (merchantId) =>
  (await apiService.innerGet({ urlPath: `/v2/users/merchants/${merchantId}` }))
    .data;
export const createUserMerchant = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/merchants", request }))
    .data;
export const updateUserMerchant = async (merchantId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/merchants/${merchantId}`,
      request,
    })
  ).data;
export const deleteUserMerchant = async (merchantId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/users/merchants/${merchantId}`,
    })
  ).data;
