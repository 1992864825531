import { createSelector } from "reselect";

const stateSubscriptionSelector = ({ stateSubscription }) => stateSubscription;

export const getSubscriptionAdminTab = createSelector(
  [stateSubscriptionSelector],
  ({ subscriptionAdminTab }) => subscriptionAdminTab
);
export const getSubscriptionSubscriberTab = createSelector(
  [stateSubscriptionSelector],
  ({ subscriptionSubscriberTab }) => subscriptionSubscriberTab
);

export const getBillingPlan = createSelector(
  [stateSubscriptionSelector],
  ({ billingPlan }) => billingPlan
);
export const getBillingCycle = createSelector(
  [stateSubscriptionSelector],
  ({ billingCycle }) => billingCycle
);

export const getRenewalBranchesIds = createSelector(
  [stateSubscriptionSelector],
  ({ renewalBranchesIds }) => renewalBranchesIds
);
export const getRenewalPlan = createSelector(
  [stateSubscriptionSelector],
  ({ renewalPlan }) => renewalPlan
);
export const getRenewalCycle = createSelector(
  [stateSubscriptionSelector],
  ({ renewalCycle }) => renewalCycle
);
