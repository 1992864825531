import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendCountriesFailed,
  setFetchCountriesFailed,
  setSearchCountriesFailed,
} from "../../store/select-country/select-country.action";
import {
  getAppendCountriesFailed,
  getFetchCountriesFailed,
  getSearchCountriesFailed,
} from "../../store/select-country/select-country.selector";

const SelectCountryPopup = () => {
  const dispatch = useDispatch();

  const fetchCountriesFailed = useSelector(getFetchCountriesFailed);
  const searchCountriesFailed = useSelector(getSearchCountriesFailed);
  const appendCountriesFailed = useSelector(getAppendCountriesFailed);

  useEffect(() => {
    if (
      fetchCountriesFailed !== null ||
      searchCountriesFailed !== null ||
      appendCountriesFailed !== null
    ) {
      showErrorMessage(
        fetchCountriesFailed ?? searchCountriesFailed ?? appendCountriesFailed
      );

      if (fetchCountriesFailed !== null)
        dispatch(setFetchCountriesFailed(null));
      if (searchCountriesFailed !== null)
        dispatch(setSearchCountriesFailed(null));
      if (appendCountriesFailed !== null)
        dispatch(setAppendCountriesFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCountriesFailed, searchCountriesFailed, appendCountriesFailed]);

  return <></>;
};

export default SelectCountryPopup;
