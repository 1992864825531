import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useIntersectionObserver } from "../../utils/common.utils";
import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import { SUBSCRIPTIONS_KEYS } from "../../constants/subscription.constant";
import { SUBSCRIPTION_FEATURE_TYPES } from "../../constants/subscription-feature.constant";

import { getGlobalSubscriptions } from "../../store/global/global.selector";

import Table, {
  TABLE_COLUMN_ALIGNS,
  TABLE_COLUMN_WRAPS,
  TABLE_STYLES,
} from "../../components/table-v2/table.component";
import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";
import EmptyStateTable from "../empty-state-table/empty-state-table.widget";
import SubscriptionPlanTableHeader from "../subscription-plan-table-header/subscription-plan-table-header.widget";

import {
  SubscriptionPlanTableCheck,
  SubscriptionPlanTableFeature,
  SubscriptionPlanTableItem,
  SubscriptionPlanTableLabel,
  SubscriptionPlanTableList,
} from "./subscription-plan-table.style";

import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";
import { ReactComponent as CancelSvg } from "../../assets/icons/stroke/Cancel.svg";

const TableValueBoolean = ({ value }) => {
  return (
    <SubscriptionPlanTableCheck isAllowed={parseToBoolean(value)}>
      {parseToBoolean(value) ? <ConfirmSvg /> : <CancelSvg />}
    </SubscriptionPlanTableCheck>
  );
};

const TableValueString = ({ value }) => {
  return <SubscriptionPlanTableLabel>{value}</SubscriptionPlanTableLabel>;
};

const TableValueArray = ({ value: values = [] }) => {
  const { i18n, t } = useTranslation();

  return (
    <SubscriptionPlanTableList>
      {values?.map((value, index) => {
        const description =
          value?.[`description_${i18n.language}`] ??
          value?.description_en ??
          value?.description_ar;

        return (
          <SubscriptionPlanTableItem key={index}>
            {!isEmpty(description) ? description : t("No information")}
          </SubscriptionPlanTableItem>
        );
      })}
    </SubscriptionPlanTableList>
  );
};

const getTableValue = (valueType) =>
  ({
    [SUBSCRIPTION_FEATURE_TYPES.NONE]: TableValueBoolean,
    [SUBSCRIPTION_FEATURE_TYPES.TEXT]: TableValueString,
    [SUBSCRIPTION_FEATURE_TYPES.LIST]: TableValueArray,
  }?.[valueType] ?? TableValueBoolean);

const TableRowLoading = () => {
  return (
    <Table.Row>
      <Table.Col>
        <Shimmer radius={SHIMMER_RADIUS.PX12} width="14rem" height="18px" />
      </Table.Col>
      <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.CENTER}>
        <Shimmer
          radius={SHIMMER_RADIUS.CIRCLE}
          width="1.65rem"
          minWidth="1.65rem"
          maxWidth="1.65rem"
          height="1.65rem"
          minHeight="1.65rem"
          maxHeight="1.65rem"
        />
      </Table.Col>
      <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.CENTER}>
        <Shimmer
          radius={SHIMMER_RADIUS.CIRCLE}
          width="1.65rem"
          minWidth="1.65rem"
          maxWidth="1.65rem"
          height="1.65rem"
          minHeight="1.65rem"
          maxHeight="1.65rem"
        />
      </Table.Col>
      <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.CENTER}>
        <Shimmer
          radius={SHIMMER_RADIUS.CIRCLE}
          width="1.65rem"
          minWidth="1.65rem"
          maxWidth="1.65rem"
          height="1.65rem"
          minHeight="1.65rem"
          maxHeight="1.65rem"
        />
      </Table.Col>
    </Table.Row>
  );
};

const TableColumnData = ({ feature: value }) => {
  const { i18n } = useTranslation();

  const feature = useMemo(() => value, [value]);

  const { is_checked, type, details } = feature ?? {};

  const [columnValue, setColumnValue] = useState(null);
  const [colVerAlign, setColVerAlign] = useState(TABLE_COLUMN_ALIGNS.START);
  const [colHorAlign, setColHorAlign] = useState(TABLE_COLUMN_ALIGNS.START);

  const TableValue = getTableValue(type);

  useEffect(() => {
    const getColumnValue = () => {
      switch (type) {
        case SUBSCRIPTION_FEATURE_TYPES.LIST:
          return details;
        case SUBSCRIPTION_FEATURE_TYPES.TEXT:
          return (
            details?.[`description_${i18n.language}`] ??
            details?.description_en ??
            details?.description_ar
          );
        case SUBSCRIPTION_FEATURE_TYPES.NONE:
          return parseToBoolean(is_checked);
      }
    };

    setColumnValue(getColumnValue());
    setColVerAlign(TABLE_COLUMN_ALIGNS.START);
    setColHorAlign(
      type === SUBSCRIPTION_FEATURE_TYPES.NONE
        ? TABLE_COLUMN_ALIGNS.CENTER
        : TABLE_COLUMN_ALIGNS.START
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature, i18n.language]);

  return (
    <Table.Col
      colVerAlign={colVerAlign}
      colHorAlign={colHorAlign}
      colTextWrap={TABLE_COLUMN_WRAPS.WRAP}
    >
      <TableValue value={columnValue} />
    </Table.Col>
  );
};

const TableRowData = forwardRef(({ feature: data }, ref) => {
  const { i18n, t } = useTranslation();

  const feature = useMemo(() => data, [data]);

  const [featureName, setFeatureName] = useState("");
  const [featureTrial, setFeatureTrial] = useState(false);
  const [featureBasic, setFeatureBasic] = useState(false);
  const [featureAdvanced, setFeatureAdvanced] = useState(false);

  useEffect(() => {
    const { name_en, name_ar, features } = feature ?? {};

    const getFeatureDetails = (key) =>
      features?.find(({ subscription_key }) => subscription_key === key);

    const featureName =
      feature?.[`name_${i18n.language}`] ?? name_en ?? name_ar;
    const featureTrial = getFeatureDetails(SUBSCRIPTIONS_KEYS.TRIAL);
    const featureBasic = getFeatureDetails(SUBSCRIPTIONS_KEYS.BASIC);
    const featureAdvanced = getFeatureDetails(SUBSCRIPTIONS_KEYS.ADVANCED);

    setFeatureName(!isEmpty(featureName) ? featureName : t("No name"));
    setFeatureTrial(!isEmpty(featureTrial) ? featureTrial : false);
    setFeatureBasic(!isEmpty(featureBasic) ? featureBasic : false);
    setFeatureAdvanced(!isEmpty(featureAdvanced) ? featureAdvanced : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  return (
    <Table.Row ref={ref}>
      <Table.Col colVerAlign={TABLE_COLUMN_ALIGNS.START}>
        <SubscriptionPlanTableFeature>
          {featureName}
        </SubscriptionPlanTableFeature>
      </Table.Col>
      <TableColumnData feature={featureTrial} />
      <TableColumnData feature={featureBasic} />
      <TableColumnData feature={featureAdvanced} />
    </Table.Row>
  );
});

const SubscriptionPlanTable = ({
  features = [],
  page = 1,
  isLoading = false,
  isHasMore = false,
  handleBottomScroll,
}) => {
  const subscriptions = useSelector(getGlobalSubscriptions);

  const trialBillingPlan = subscriptions?.[SUBSCRIPTIONS_KEYS.TRIAL];
  const trialBillingBasic = subscriptions?.[SUBSCRIPTIONS_KEYS.BASIC];
  const trialBillingAdvanced = subscriptions?.[SUBSCRIPTIONS_KEYS.ADVANCED];

  const isLastItem = (index) => features.length === index + 1;

  const lastItemRef = useCallback(
    useIntersectionObserver(() => {
      if (!isLoading && isHasMore) handleBottomScroll?.();
    }),
    [isLoading, isHasMore, handleBottomScroll]
  );

  return (
    <Table tableStyle={TABLE_STYLES.STYLE_3}>
      <Table.Head>
        <Table.Row>
          <Table.Col></Table.Col>
          <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.CENTER}>
            {!isEmpty(trialBillingPlan) && (
              <SubscriptionPlanTableHeader billingPlan={trialBillingPlan} />
            )}
          </Table.Col>
          <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.CENTER}>
            {!isEmpty(trialBillingBasic) && (
              <SubscriptionPlanTableHeader billingPlan={trialBillingBasic} />
            )}
          </Table.Col>
          <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.CENTER}>
            {!isEmpty(trialBillingAdvanced) && (
              <SubscriptionPlanTableHeader billingPlan={trialBillingAdvanced} />
            )}
          </Table.Col>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {!(page === 1 && isLoading) &&
          features.map((feature, index) => (
            <TableRowData
              key={feature.id}
              ref={isLastItem(index) ? lastItemRef : null}
              feature={feature}
            />
          ))}
        {!isLoading && features.length === 0 && (
          <Table.Row>
            <Table.Col colSpan={4}>
              <EmptyStateTable />
            </Table.Col>
          </Table.Row>
        )}
        {isLoading &&
          Array.from(Array(20).keys()).map((index) => (
            <TableRowLoading key={index} />
          ))}
      </Table.Body>
    </Table>
  );
};

export default SubscriptionPlanTable;
