import styled from "styled-components";

import { isEmpty } from "../../utils/validation.utils";

export const ApplicationCompletionOverlayProgressContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const ApplicationCompletionOverlayProgressBar = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 0.625rem;
  background-color: #e9e9ee;
  border-radius: 1.5rem;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: ${({ percent }) => (!isEmpty(percent) ? `${percent}%` : 0)};
    height: 100%;
    border-radius: 1.5rem;
    background-color: #008d0e;
    transition: 0.3s all ease;
  }
`;

export const ApplicationCompletionOverlayProgressLabel = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;
