import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setAppendSelectIngredientsFailed,
  setCreateIngredientFailed,
  setCreateIngredientSuccess,
  setDeleteIngredientFailed,
  setDeleteIngredientSuccess,
  setFetchIngredientFailed,
  setFetchIngredientsFailed,
  setFetchOutOfStockIngredientsFailed,
  setFetchSelectIngredientsFailed,
  setReduceStockIngredientFailed,
  setReduceStockIngredientSuccess,
  setStoreStockIngredientFailed,
  setStoreStockIngredientSuccess,
  setUpdateIngredientFailed,
  setUpdateIngredientSuccess,
} from "../../store/ingredient/ingredient.action";
import {
  getAppendSelectIngredientsFailed,
  getCreateIngredientFailed,
  getCreateIngredientSuccess,
  getDeleteIngredientFailed,
  getDeleteIngredientSuccess,
  getFetchIngredientFailed,
  getFetchIngredientsFailed,
  getFetchOutOfStockIngredientsFailed,
  getFetchSelectIngredientsFailed,
  getReduceStockIngredientFailed,
  getReduceStockIngredientSuccess,
  getStoreStockIngredientFailed,
  getStoreStockIngredientSuccess,
  getUpdateIngredientFailed,
  getUpdateIngredientSuccess,
} from "../../store/ingredient/ingredient.selector";

const IngredientPopup = () => {
  const dispatch = useDispatch();

  const createIngredientSuccess = useSelector(getCreateIngredientSuccess);
  const updateIngredientSuccess = useSelector(getUpdateIngredientSuccess);
  const deleteIngredientSuccess = useSelector(getDeleteIngredientSuccess);
  const storeStockIngredientSuccess = useSelector(
    getStoreStockIngredientSuccess
  );
  const reduceStockIngredientSuccess = useSelector(
    getReduceStockIngredientSuccess
  );

  const fetchIngredientsFailed = useSelector(getFetchIngredientsFailed);
  const fetchSelectIngredientsFailed = useSelector(
    getFetchSelectIngredientsFailed
  );
  const fetchOutOfStockIngredientsFailed = useSelector(
    getFetchOutOfStockIngredientsFailed
  );
  const appendSelectIngredientsFailed = useSelector(
    getAppendSelectIngredientsFailed
  );
  const fetchIngredientFailed = useSelector(getFetchIngredientFailed);
  const createIngredientFailed = useSelector(getCreateIngredientFailed);
  const updateIngredientFailed = useSelector(getUpdateIngredientFailed);
  const deleteIngredientFailed = useSelector(getDeleteIngredientFailed);
  const storeStockIngredientFailed = useSelector(getStoreStockIngredientFailed);
  const reduceStockIngredientFailed = useSelector(
    getReduceStockIngredientFailed
  );

  useEffect(() => {
    if (
      createIngredientSuccess !== null ||
      updateIngredientSuccess !== null ||
      deleteIngredientSuccess !== null ||
      storeStockIngredientSuccess !== null ||
      reduceStockIngredientSuccess !== null
    ) {
      showSuccessMessage(
        createIngredientSuccess ??
          updateIngredientSuccess ??
          deleteIngredientSuccess ??
          storeStockIngredientSuccess ??
          reduceStockIngredientSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createIngredientSuccess !== null)
        dispatch(setCreateIngredientSuccess(null));
      if (updateIngredientSuccess !== null)
        dispatch(setUpdateIngredientSuccess(null));
      if (deleteIngredientSuccess !== null)
        dispatch(setDeleteIngredientSuccess(null));
      if (storeStockIngredientSuccess !== null)
        dispatch(setStoreStockIngredientSuccess(null));
      if (reduceStockIngredientSuccess !== null)
        dispatch(setReduceStockIngredientSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createIngredientSuccess,
    updateIngredientSuccess,
    deleteIngredientSuccess,
    storeStockIngredientSuccess,
    reduceStockIngredientSuccess,
  ]);

  useEffect(() => {
    if (
      fetchIngredientsFailed !== null ||
      fetchSelectIngredientsFailed !== null ||
      fetchOutOfStockIngredientsFailed !== null ||
      appendSelectIngredientsFailed !== null ||
      fetchIngredientFailed !== null ||
      createIngredientFailed !== null ||
      updateIngredientFailed !== null ||
      deleteIngredientFailed !== null ||
      storeStockIngredientFailed !== null ||
      reduceStockIngredientFailed !== null
    ) {
      showErrorMessage(
        fetchIngredientsFailed ??
          fetchSelectIngredientsFailed ??
          fetchOutOfStockIngredientsFailed ??
          appendSelectIngredientsFailed ??
          fetchIngredientFailed ??
          createIngredientFailed ??
          updateIngredientFailed ??
          deleteIngredientFailed ??
          storeStockIngredientFailed ??
          reduceStockIngredientFailed
      );

      if (fetchIngredientsFailed !== null)
        dispatch(setFetchIngredientsFailed(null));
      if (fetchSelectIngredientsFailed !== null)
        dispatch(setFetchSelectIngredientsFailed(null));
      if (fetchOutOfStockIngredientsFailed !== null)
        dispatch(setFetchOutOfStockIngredientsFailed(null));
      if (appendSelectIngredientsFailed !== null)
        dispatch(setAppendSelectIngredientsFailed(null));
      if (fetchIngredientFailed !== null)
        dispatch(setFetchIngredientFailed(null));
      if (createIngredientFailed !== null)
        dispatch(setCreateIngredientFailed(null));
      if (updateIngredientFailed !== null)
        dispatch(setUpdateIngredientFailed(null));
      if (deleteIngredientFailed !== null)
        dispatch(setDeleteIngredientFailed(null));
      if (storeStockIngredientFailed !== null)
        dispatch(setStoreStockIngredientFailed(null));
      if (reduceStockIngredientFailed !== null)
        dispatch(setReduceStockIngredientFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchIngredientsFailed,
    fetchSelectIngredientsFailed,
    fetchOutOfStockIngredientsFailed,
    appendSelectIngredientsFailed,
    fetchIngredientFailed,
    createIngredientFailed,
    updateIngredientFailed,
    deleteIngredientFailed,
    storeStockIngredientFailed,
    reduceStockIngredientFailed,
  ]);

  return <></>;
};

export default IngredientPopup;
