import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateTableFailed,
  setCreateTableSuccess,
  setDeleteTableFailed,
  setDeleteTableSuccess,
  setFetchOrderTablesFailed,
  setFetchSelectTablesFailed,
  setFetchTableFailed,
  setFetchTablesFailed,
  setMultipleCreateTableFailed,
  setMultipleCreateTableSuccess,
  setUpdateTableFailed,
  setUpdateTableSuccess,
} from "../../store/table/table.action";
import {
  getCreateTableFailed,
  getCreateTableSuccess,
  getDeleteTableFailed,
  getDeleteTableSuccess,
  getFetchOrderTablesFailed,
  getFetchSelectTablesFailed,
  getFetchTableFailed,
  getFetchTablesFailed,
  getIsModalAutoClose,
  getMultipleCreateTableFailed,
  getMultipleCreateTableSuccess,
  getUpdateTableFailed,
  getUpdateTableSuccess,
} from "../../store/table/table.selector";

const TablePopup = () => {
  const dispatch = useDispatch();

  const isModalAutoClose = useSelector(getIsModalAutoClose);
  const createTableSuccess = useSelector(getCreateTableSuccess);
  const updateTableSuccess = useSelector(getUpdateTableSuccess);
  const deleteTableSuccess = useSelector(getDeleteTableSuccess);
  const multipleCreateTableSuccess = useSelector(getMultipleCreateTableSuccess);

  const fetchTablesFailed = useSelector(getFetchTablesFailed);
  const fetchOrderTablesFailed = useSelector(getFetchOrderTablesFailed);
  const fetchSelectTablesFailed = useSelector(getFetchSelectTablesFailed);
  const fetchTableFailed = useSelector(getFetchTableFailed);
  const createTableFailed = useSelector(getCreateTableFailed);
  const updateTableFailed = useSelector(getUpdateTableFailed);
  const deleteTableFailed = useSelector(getDeleteTableFailed);
  const multipleCreateTableFailed = useSelector(getMultipleCreateTableFailed);

  useEffect(() => {
    if (
      createTableSuccess !== null ||
      updateTableSuccess !== null ||
      deleteTableSuccess !== null ||
      multipleCreateTableSuccess !== null
    ) {
      showSuccessMessage(
        createTableSuccess ??
          updateTableSuccess ??
          deleteTableSuccess ??
          multipleCreateTableSuccess
      );
      if (isModalAutoClose) dispatch(setIsModalOpen(false));

      if (createTableSuccess !== null) dispatch(setCreateTableSuccess(null));
      if (updateTableSuccess !== null) dispatch(setUpdateTableSuccess(null));
      if (deleteTableSuccess !== null) dispatch(setDeleteTableSuccess(null));
      if (multipleCreateTableSuccess !== null)
        dispatch(setMultipleCreateTableSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createTableSuccess,
    updateTableSuccess,
    deleteTableSuccess,
    multipleCreateTableSuccess,
  ]);

  useEffect(() => {
    if (
      fetchTablesFailed !== null ||
      fetchOrderTablesFailed !== null ||
      fetchSelectTablesFailed !== null ||
      fetchTableFailed !== null ||
      createTableFailed !== null ||
      updateTableFailed !== null ||
      deleteTableFailed !== null ||
      multipleCreateTableFailed !== null
    ) {
      showErrorMessage(
        fetchTablesFailed ??
          fetchOrderTablesFailed ??
          fetchSelectTablesFailed ??
          fetchTableFailed ??
          createTableFailed ??
          updateTableFailed ??
          deleteTableFailed ??
          multipleCreateTableFailed
      );

      if (fetchTablesFailed !== null) dispatch(setFetchTablesFailed(null));
      if (fetchOrderTablesFailed !== null)
        dispatch(setFetchOrderTablesFailed(null));
      if (fetchSelectTablesFailed !== null)
        dispatch(setFetchSelectTablesFailed(null));
      if (fetchTableFailed !== null) dispatch(setFetchTableFailed(null));
      if (createTableFailed !== null) dispatch(setCreateTableFailed(null));
      if (updateTableFailed !== null) dispatch(setUpdateTableFailed(null));
      if (deleteTableFailed !== null) dispatch(setDeleteTableFailed(null));
      if (multipleCreateTableFailed !== null)
        dispatch(setMultipleCreateTableFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchTablesFailed,
    fetchOrderTablesFailed,
    fetchSelectTablesFailed,
    fetchTableFailed,
    createTableFailed,
    updateTableFailed,
    deleteTableFailed,
    multipleCreateTableFailed,
  ]);

  return <></>;
};

export default TablePopup;
