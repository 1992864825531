import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateDriverFailed,
  setCreateDriverSuccess,
  setFetchDriverFailed,
  setFetchDriversFailed,
  setUpdateDriverFailed,
  setUpdateDriverSuccess,
} from "../../store/driver/driver.action";
import {
  getCreateDriverFailed,
  getCreateDriverSuccess,
  getFetchDriverFailed,
  getFetchDriversFailed,
  getUpdateDriverFailed,
  getUpdateDriverSuccess,
} from "../../store/driver/driver.selector";

const DriverPopup = () => {
  const dispatch = useDispatch();

  const createDriverSuccess = useSelector(getCreateDriverSuccess);
  const updateDriverSuccess = useSelector(getUpdateDriverSuccess);
  const fetchDriversFailed = useSelector(getFetchDriversFailed);
  const fetchDriverFailed = useSelector(getFetchDriverFailed);
  const createDriverFailed = useSelector(getCreateDriverFailed);
  const updateDriverFailed = useSelector(getUpdateDriverFailed);

  useEffect(() => {
    if (createDriverSuccess !== null || updateDriverSuccess !== null) {
      showSuccessMessage(createDriverSuccess ?? updateDriverSuccess);
      dispatch(setIsModalOpen(false));

      if (createDriverSuccess !== null) dispatch(setCreateDriverSuccess(null));
      if (updateDriverSuccess !== null) dispatch(setUpdateDriverSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDriverSuccess, updateDriverSuccess]);

  useEffect(() => {
    if (
      fetchDriversFailed !== null ||
      fetchDriverFailed !== null ||
      createDriverFailed !== null ||
      updateDriverFailed !== null
    ) {
      showErrorMessage(
        fetchDriversFailed ??
          fetchDriverFailed ??
          createDriverFailed ??
          updateDriverFailed
      );

      if (fetchDriversFailed !== null) dispatch(setFetchDriversFailed(null));
      if (fetchDriverFailed !== null) dispatch(setFetchDriverFailed(null));
      if (createDriverFailed !== null) dispatch(setCreateDriverFailed(null));
      if (updateDriverFailed !== null) dispatch(setUpdateDriverFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDriversFailed,
    fetchDriverFailed,
    createDriverFailed,
    updateDriverFailed,
  ]);

  return <></>;
};

export default DriverPopup;
