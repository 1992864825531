import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setCreateDiscountFailed,
  setCreateDiscountSuccess,
  setDeleteDiscountFailed,
  setDeleteDiscountSuccess,
  setFetchDiscountFailed,
  setFetchDiscountsFailed,
  setUpdateDiscountFailed,
  setUpdateDiscountSuccess,
  setVerifyCouponDiscountFailed,
  setVerifyCouponDiscountSuccess,
  setVerifyLoyaProDiscountFailed,
  setVerifyLoyaProDiscountSuccess,
} from "../../store/discount/discount.action";
import {
  getCreateDiscountFailed,
  getCreateDiscountSuccess,
  getDeleteDiscountFailed,
  getDeleteDiscountSuccess,
  getFetchDiscountFailed,
  getFetchDiscountsFailed,
  getUpdateDiscountFailed,
  getUpdateDiscountSuccess,
  getVerifyCouponDiscountFailed,
  getVerifyCouponDiscountSuccess,
  getVerifyLoyaProDiscountFailed,
  getVerifyLoyaProDiscountSuccess,
} from "../../store/discount/discount.selector";

const DiscountPopup = () => {
  const dispatch = useDispatch();
  const effectRan = useRef(false);

  const createDiscountSuccess = useSelector(getCreateDiscountSuccess);
  const updateDiscountSuccess = useSelector(getUpdateDiscountSuccess);
  const deleteDiscountSuccess = useSelector(getDeleteDiscountSuccess);
  const verifyCouponDiscountSuccess = useSelector(
    getVerifyCouponDiscountSuccess
  );
  const verifyLoyaProDiscountSuccess = useSelector(
    getVerifyLoyaProDiscountSuccess
  );

  const fetchDiscountsFailed = useSelector(getFetchDiscountsFailed);
  const fetchDiscountFailed = useSelector(getFetchDiscountFailed);
  const createDiscountFailed = useSelector(getCreateDiscountFailed);
  const updateDiscountFailed = useSelector(getUpdateDiscountFailed);
  const deleteDiscountFailed = useSelector(getDeleteDiscountFailed);
  const verifyCouponDiscountFailed = useSelector(getVerifyCouponDiscountFailed);
  const verifyLoyaProDiscountFailed = useSelector(
    getVerifyLoyaProDiscountFailed
  );

  useEffect(() => {
    if (effectRan.current === true) {
      if (
        createDiscountSuccess !== null ||
        updateDiscountSuccess !== null ||
        deleteDiscountSuccess !== null ||
        verifyCouponDiscountSuccess !== null ||
        verifyLoyaProDiscountSuccess !== null
      ) {
        showSuccessMessage(
          createDiscountSuccess ??
            updateDiscountSuccess ??
            deleteDiscountSuccess ??
            verifyCouponDiscountSuccess ??
            verifyLoyaProDiscountSuccess
        );

        if (createDiscountSuccess !== null)
          dispatch(setCreateDiscountSuccess(null));
        if (updateDiscountSuccess !== null)
          dispatch(setUpdateDiscountSuccess(null));
        if (deleteDiscountSuccess !== null)
          dispatch(setDeleteDiscountSuccess(null));
        if (verifyCouponDiscountSuccess !== null)
          dispatch(setVerifyCouponDiscountSuccess(null));
        if (verifyLoyaProDiscountSuccess !== null)
          dispatch(setVerifyLoyaProDiscountSuccess(null));
      }
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createDiscountSuccess,
    updateDiscountSuccess,
    deleteDiscountSuccess,
    verifyCouponDiscountSuccess,
    verifyLoyaProDiscountSuccess,
  ]);

  useEffect(() => {
    if (effectRan.current === true) {
      if (
        fetchDiscountsFailed !== null ||
        fetchDiscountFailed !== null ||
        createDiscountFailed !== null ||
        updateDiscountFailed !== null ||
        deleteDiscountFailed !== null ||
        verifyCouponDiscountFailed !== null ||
        verifyLoyaProDiscountFailed !== null
      ) {
        showErrorMessage(
          fetchDiscountsFailed ??
            fetchDiscountFailed ??
            createDiscountFailed ??
            updateDiscountFailed ??
            deleteDiscountFailed ??
            verifyCouponDiscountFailed ??
            verifyLoyaProDiscountFailed
        );

        if (fetchDiscountsFailed !== null)
          dispatch(setFetchDiscountsFailed(null));
        if (fetchDiscountFailed !== null)
          dispatch(setFetchDiscountFailed(null));
        if (createDiscountFailed !== null)
          dispatch(setCreateDiscountFailed(null));
        if (updateDiscountFailed !== null)
          dispatch(setUpdateDiscountFailed(null));
        if (deleteDiscountFailed !== null)
          dispatch(setDeleteDiscountFailed(null));
        if (verifyCouponDiscountFailed !== null)
          dispatch(setVerifyCouponDiscountFailed(null));
        if (verifyLoyaProDiscountFailed !== null)
          dispatch(setVerifyLoyaProDiscountFailed(null));
      }
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDiscountsFailed,
    fetchDiscountFailed,
    createDiscountFailed,
    updateDiscountFailed,
    deleteDiscountFailed,
    verifyCouponDiscountFailed,
    verifyLoyaProDiscountFailed,
  ]);

  return <></>;
};

export default DiscountPopup;
