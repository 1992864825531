import apiService from "./api";

export const getIntegrations = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/integrations", params })).data;
export const getIntegration = async (integrationId) =>
  (await apiService.innerGet({ urlPath: `/v2/integrations/${integrationId}` }))
    .data;
export const createOrUpdateIntegration = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/integrations", request })).data;

export const ballurhStatus = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/integrations/ballurh/status",
      params,
    })
  ).data;
export const ballurhGenerate = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/ballurh/generate",
      request,
    })
  ).data;
export const ballurhSync = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/ballurh/sync",
      request,
    })
  ).data;

export const faiSetup = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/fai/setup",
      request,
    })
  ).data;
export const faiRegister = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/fai/register",
      request,
    })
  ).data;
export const faiRegisterStatus = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/integrations/fai/register/status",
      params,
    })
  ).data;
export const faiStatus = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/integrations/fai/status",
      params,
    })
  ).data;
export const faiSync = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/fai/sync",
      request,
    })
  ).data;

export const grubtechStatus = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/integrations/grubtech/status",
      params,
    })
  ).data;
export const grubtechSync = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/grubtech/sync",
      request,
    })
  ).data;

export const qoyodStatus = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/integrations/qoyod/status",
      params,
    })
  ).data;
export const qoyodSync = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/qoyod/sync",
      request,
    })
  ).data;

export const zatcaSetup = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/zatca/setup",
      request,
    })
  ).data;
export const zatcaRegister = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/zatca/register",
      request,
    })
  ).data;
export const zatcaStatus = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/integrations/zatca/status",
      params,
    })
  ).data;
export const zatcaSync = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/integrations/zatca/sync",
      request,
    })
  ).data;
