import apiService from "./api";

export const getPermissionMerchants = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/permissions/merchants", params }))
    .data;
export const getPermissionMerchant = async (permissionId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/permissions/merchants/${permissionId}`,
    })
  ).data;
export const createPermissionMerchant = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/permissions/merchants",
      request,
    })
  ).data;
export const updatePermissionMerchant = async (permissionId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/permissions/merchants/${permissionId}`,
      request,
    })
  ).data;
export const deletePermissionMerchant = async (permissionId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/permissions/merchants/${permissionId}`,
    })
  ).data;
