import { createSelector } from "reselect";

const selectPermissionSubscriberSelector = ({ selectPermissionSubscriber }) =>
  selectPermissionSubscriber;

export const getPermissions = createSelector(
  [selectPermissionSubscriberSelector],
  ({ permissions }) => permissions
);
export const getSearchPermissions = createSelector(
  [selectPermissionSubscriberSelector],
  ({ searchPermissions }) => searchPermissions
);

export const getIsPermissionsHasMore = createSelector(
  [selectPermissionSubscriberSelector],
  ({ isPermissionsHasMore }) => isPermissionsHasMore
);
export const getIsSearchPermissionsHasMore = createSelector(
  [selectPermissionSubscriberSelector],
  ({ isSearchPermissionsHasMore }) => isSearchPermissionsHasMore
);

export const getFetchPermissionsParams = createSelector(
  [selectPermissionSubscriberSelector],
  ({ fetchPermissionsParams }) => fetchPermissionsParams
);
export const getFetchPermissionsLoading = createSelector(
  [selectPermissionSubscriberSelector],
  ({ fetchPermissionsLoading }) => fetchPermissionsLoading
);
export const getFetchPermissionsSuccess = createSelector(
  [selectPermissionSubscriberSelector],
  ({ fetchPermissionsSuccess }) => fetchPermissionsSuccess
);
export const getFetchPermissionsFailed = createSelector(
  [selectPermissionSubscriberSelector],
  ({ fetchPermissionsFailed }) => fetchPermissionsFailed
);

export const getSearchPermissionsParams = createSelector(
  [selectPermissionSubscriberSelector],
  ({ searchPermissionsParams }) => searchPermissionsParams
);
export const getSearchPermissionsLoading = createSelector(
  [selectPermissionSubscriberSelector],
  ({ searchPermissionsLoading }) => searchPermissionsLoading
);
export const getSearchPermissionsSuccess = createSelector(
  [selectPermissionSubscriberSelector],
  ({ searchPermissionsSuccess }) => searchPermissionsSuccess
);
export const getSearchPermissionsFailed = createSelector(
  [selectPermissionSubscriberSelector],
  ({ searchPermissionsFailed }) => searchPermissionsFailed
);

export const getAppendPermissionsParams = createSelector(
  [selectPermissionSubscriberSelector],
  ({ appendPermissionsParams }) => appendPermissionsParams
);
export const getAppendPermissionsLoading = createSelector(
  [selectPermissionSubscriberSelector],
  ({ appendPermissionsLoading }) => appendPermissionsLoading
);
export const getAppendPermissionsSuccess = createSelector(
  [selectPermissionSubscriberSelector],
  ({ appendPermissionsSuccess }) => appendPermissionsSuccess
);
export const getAppendPermissionsFailed = createSelector(
  [selectPermissionSubscriberSelector],
  ({ appendPermissionsFailed }) => appendPermissionsFailed
);

export const getIsFetchPermissionsHitted = createSelector(
  [selectPermissionSubscriberSelector],
  ({ isFetchPermissionsHitted }) => isFetchPermissionsHitted
);
export const getIsSearchPermissionsHitted = createSelector(
  [selectPermissionSubscriberSelector],
  ({ isSearchPermissionsHitted }) => isSearchPermissionsHitted
);
export const getIsAppendPermissionsHitted = createSelector(
  [selectPermissionSubscriberSelector],
  ({ isAppendPermissionsHitted }) => isAppendPermissionsHitted
);
