import { useEffect, useState } from "react";
import pubsub from "sweet-pubsub";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { LANGUAGES } from "../../constants/common.constant";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../button/button.component";

import {
  ToastActionItem,
  ToastActionList,
  ToastCloseButton,
  ToastContainer,
  ToastContent,
  ToastDescription,
  ToastIcon,
  ToastInfo,
  ToastTitle,
} from "./toast.style";

import { ReactComponent as InfoSvg } from "../../assets/icons/Info.svg";
import { ReactComponent as CheckSvg } from "../../assets/icons/Check.svg";
import { ReactComponent as CloseSvg } from "../../assets/icons/Close.svg";
import { ReactComponent as QuestionSvg } from "../../assets/icons/Question.svg";
import { ReactComponent as CloseOnlySvg } from "../../assets/icons/CloseOnly.svg";

export const TOAST_TYPES = {
  CONFIRMATION: "CONFIRMATION",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INFO: "INFO",
};

const getToastIcon = (toastType) =>
  ({
    [TOAST_TYPES.CONFIRMATION]: <QuestionSvg />,
    [TOAST_TYPES.SUCCESS]: <CheckSvg />,
    [TOAST_TYPES.ERROR]: <CloseSvg />,
    [TOAST_TYPES.INFO]: <InfoSvg />,
  }[toastType]);

const ToastItem = ({ toast, removeToast }) => {
  const { t } = useTranslation();

  const { key, type, title, message, content, onConfirmClick } = toast ?? {};

  const onConfirmButtonClick = () => {
    onConfirmClick?.();
    removeToast(key);
  };

  const onCloseButtonClick = ({ currentTarget }) => {
    const key = currentTarget.getAttribute("data-key");
    removeToast(key);
  };

  return (
    <ToastContent key={key} toastType={type}>
      <ToastIcon>{getToastIcon(type)}</ToastIcon>
      <ToastInfo>
        <ToastTitle>{title}</ToastTitle>
        <ToastDescription>{message}</ToastDescription>
        {content}
        {onConfirmClick && (
          <ToastActionList>
            <ToastActionItem>
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.WARNING}
                buttonSize={BUTTON_SIZES.SMALL}
                buttonType={BUTTON_TYPES.TEXT}
                onClick={onConfirmButtonClick}
                isFullWidth
              >
                {t("Yes")}
              </Button>
            </ToastActionItem>
            <ToastActionItem>
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.SECONDARY}
                buttonSize={BUTTON_SIZES.SMALL}
                buttonType={BUTTON_TYPES.TEXT}
                onClick={onCloseButtonClick}
                isFullWidth
                data-key={key}
              >
                {t("Cancel")}
              </Button>
            </ToastActionItem>
          </ToastActionList>
        )}
      </ToastInfo>
      <ToastCloseButton
        type="button"
        onClick={onCloseButtonClick}
        data-key={key}
      >
        <CloseOnlySvg />
      </ToastCloseButton>
    </ToastContent>
  );
};

const Toast = () => {
  const { i18n } = useTranslation();

  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const addToast = (toast) => {
      const key = uuid();
      setToasts((prevValue) => [...prevValue.slice(-2), { key, ...toast }]);
      if (toast.type !== TOAST_TYPES.CONFIRMATION)
        setTimeout(() => removeToast(key), 5000);
    };

    pubsub.on("showToast", addToast);

    return () => pubsub.off("showToast", addToast);
  }, []);

  const removeToast = (key) =>
    setToasts((prevValue) => prevValue.filter((toast) => toast.key !== key));

  return (
    <ToastContainer isRtl={i18n.language === LANGUAGES.AR}>
      {toasts.map((toast, index) => (
        <ToastItem key={index} toast={toast} removeToast={removeToast} />
      ))}
    </ToastContainer>
  );
};

export default Toast;
