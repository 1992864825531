import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getPermissionAdmins } from "../../api/permission-admin.api";

import SELECT_PERMISSION_ADMIN_ACTION_TYPES from "./select-permission-admin.type";
import {
  appendPermissionAdmins,
  appendPermissionSubscribers,
  appendSearchPermissionAdmins,
  appendSearchPermissionSubscribers,
  setAppendPermissionAdminsFailed,
  setAppendPermissionAdminsLoading,
  setAppendPermissionAdminsSuccess,
  setAppendPermissionSubscribersFailed,
  setAppendPermissionSubscribersLoading,
  setAppendPermissionSubscribersSuccess,
  setFetchPermissionAdminsFailed,
  setFetchPermissionAdminsLoading,
  setFetchPermissionAdminsSuccess,
  setFetchPermissionSubscribersFailed,
  setFetchPermissionSubscribersLoading,
  setFetchPermissionSubscribersSuccess,
  setIsAppendPermissionAdminsHitted,
  setIsAppendPermissionSubscribersHitted,
  setIsFetchPermissionAdminsHitted,
  setIsFetchPermissionSubscribersHitted,
  setIsPermissionAdminsHasMore,
  setIsPermissionSubscribersHasMore,
  setIsSearchPermissionAdminsHasMore,
  setIsSearchPermissionAdminsHitted,
  setIsSearchPermissionSubscribersHasMore,
  setIsSearchPermissionSubscribersHitted,
  setPermissionAdmins,
  setPermissionSubscribers,
  setSearchPermissionAdmins,
  setSearchPermissionAdminsFailed,
  setSearchPermissionAdminsLoading,
  setSearchPermissionAdminsSuccess,
  setSearchPermissionSubscribers,
  setSearchPermissionSubscribersFailed,
  setSearchPermissionSubscribersLoading,
  setSearchPermissionSubscribersSuccess,
} from "./select-permission-admin.action";

export function* _getFetchPermissionAdmins({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchPermissionAdminsLoading(true));

    const {
      meta: { message },
      data: permissionAdmins,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsFetchPermissionAdminsHitted(true));
    yield put(
      setIsPermissionAdminsHasMore(Object.keys(permissionAdmins).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendPermissionAdmins(permissionAdmins));
    } else {
      yield put(setPermissionAdmins(permissionAdmins));
    }

    yield put(setFetchPermissionAdminsSuccess(message));
    yield put(setFetchPermissionAdminsLoading(false));
  } catch (error) {
    yield put(setFetchPermissionAdminsFailed(error));
    yield put(setFetchPermissionAdminsLoading(false));
  }
}
export function* _getSearchPermissionAdmins({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchPermissionAdminsLoading(true));

    const {
      meta: { message },
      data: permissionAdmins,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsSearchPermissionAdminsHitted(true));
    yield put(
      setIsSearchPermissionAdminsHasMore(
        Object.keys(permissionAdmins).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendSearchPermissionAdmins(permissionAdmins));
    } else {
      yield put(setSearchPermissionAdmins(permissionAdmins));
    }

    yield put(setSearchPermissionAdminsSuccess(message));
    yield put(setSearchPermissionAdminsLoading(false));
  } catch (error) {
    yield put(setSearchPermissionAdminsFailed(error));
    yield put(setSearchPermissionAdminsLoading(false));
  }
}
export function* _getAppendPermissionAdmins({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendPermissionAdminsLoading(true));

    const {
      meta: { message },
      data: permissionAdmins,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsAppendPermissionAdminsHitted(true));
    yield put(appendPermissionAdmins(permissionAdmins));

    yield put(setAppendPermissionAdminsSuccess(message));
    yield put(setAppendPermissionAdminsLoading(false));
  } catch (error) {
    yield put(setAppendPermissionAdminsFailed(error));
    yield put(setAppendPermissionAdminsLoading(false));
  }
}
export function* _getFetchPermissionSubscribers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchPermissionSubscribersLoading(true));

    const {
      meta: { message },
      data: permissionSubscribers,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsFetchPermissionSubscribersHitted(true));
    yield put(
      setIsPermissionSubscribersHasMore(
        Object.keys(permissionSubscribers).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendPermissionSubscribers(permissionSubscribers));
    } else {
      yield put(setPermissionSubscribers(permissionSubscribers));
    }

    yield put(setFetchPermissionSubscribersSuccess(message));
    yield put(setFetchPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchPermissionSubscribersFailed(error));
    yield put(setFetchPermissionSubscribersLoading(false));
  }
}
export function* _getSearchPermissionSubscribers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchPermissionSubscribersLoading(true));

    const {
      meta: { message },
      data: permissionSubscribers,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsSearchPermissionSubscribersHitted(true));
    yield put(
      setIsSearchPermissionSubscribersHasMore(
        Object.keys(permissionSubscribers).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendSearchPermissionSubscribers(permissionSubscribers));
    } else {
      yield put(setSearchPermissionSubscribers(permissionSubscribers));
    }

    yield put(setSearchPermissionSubscribersSuccess(message));
    yield put(setSearchPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setSearchPermissionSubscribersFailed(error));
    yield put(setSearchPermissionSubscribersLoading(false));
  }
}
export function* _getAppendPermissionSubscribers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendPermissionSubscribersLoading(true));

    const {
      meta: { message },
      data: permissionSubscribers,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsAppendPermissionSubscribersHitted(true));
    yield put(appendPermissionSubscribers(permissionSubscribers));

    yield put(setAppendPermissionSubscribersSuccess(message));
    yield put(setAppendPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setAppendPermissionSubscribersFailed(error));
    yield put(setAppendPermissionSubscribersLoading(false));
  }
}

export function* onFetchPermissionAdminsStart() {
  yield takeLatest(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_ADMINS_START,
    _getFetchPermissionAdmins
  );
}
export function* onSearchPermissionAdminsStart() {
  yield takeLatest(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SEARCH_PERMISSION_ADMINS_START,
    _getSearchPermissionAdmins
  );
}
export function* onAppendPermissionAdminsStart() {
  yield takeLatest(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_ADMINS_START,
    _getAppendPermissionAdmins
  );
}
export function* onFetchPermissionSubscribersStart() {
  yield takeLatest(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START,
    _getFetchPermissionSubscribers
  );
}
export function* onSearchPermissionSubscribersStart() {
  yield takeLatest(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SEARCH_PERMISSION_SUBSCRIBERS_START,
    _getSearchPermissionSubscribers
  );
}
export function* onAppendPermissionSubscribersStart() {
  yield takeLatest(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS_START,
    _getAppendPermissionSubscribers
  );
}

export function* selectPermissionAdminSaga() {
  yield all([
    call(onFetchPermissionAdminsStart),
    call(onSearchPermissionAdminsStart),
    call(onAppendPermissionAdminsStart),
    call(onFetchPermissionSubscribersStart),
    call(onSearchPermissionSubscribersStart),
    call(onAppendPermissionSubscribersStart),
  ]);
}
