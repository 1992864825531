import { isEmpty } from "../../utils/validation.utils";

import {
  FormLabelAction,
  FormLabelContainer,
  FormLabelText,
} from "./form-label.style";

export const FORM_LABEL_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

export const FORM_LABEL_COLORS = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  TRANSPARENT: "TRANSPARENT",
};

const FormLabel = ({
  labelSize = FORM_LABEL_SIZES.NORMAL,
  labelColor = FORM_LABEL_COLORS.DARK,
  actionLabel,
  handleActionClick,
  children,
  ...otherProps
}) => (
  <FormLabelContainer labelSize={labelSize} labelColor={labelColor}>
    <FormLabelText {...otherProps}>{children}</FormLabelText>
    {!isEmpty(actionLabel) && (
      <FormLabelAction onClick={handleActionClick}>
        {actionLabel}
      </FormLabelAction>
    )}
  </FormLabelContainer>
);

export default FormLabel;
