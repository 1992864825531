import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateBranchFailed,
  setCreateBranchSuccess,
  setDeleteBranchFailed,
  setDeleteBranchSuccess,
  setFetchBranchesFailed,
  setFetchBranchFailed,
  setFetchSubscriptionBranchesFailed,
  setFetchSubscriptionBranchFailed,
  setUpdateBranchFailed,
  setUpdateBranchSuccess,
} from "../../store/branch/branch.action";
import {
  getCreateBranchFailed,
  getCreateBranchSuccess,
  getDeleteBranchFailed,
  getDeleteBranchSuccess,
  getFetchBranchesFailed,
  getFetchBranchFailed,
  getFetchSubscriptionBranchesFailed,
  getFetchSubscriptionBranchFailed,
  getIsModalAutoClose,
  getUpdateBranchFailed,
  getUpdateBranchSuccess,
} from "../../store/branch/branch.selector";

const BranchPopup = () => {
  const dispatch = useDispatch();

  const isModalAutoClose = useSelector(getIsModalAutoClose);

  const createBranchSuccess = useSelector(getCreateBranchSuccess);
  const updateBranchSuccess = useSelector(getUpdateBranchSuccess);
  const deleteBranchSuccess = useSelector(getDeleteBranchSuccess);

  const fetchSubscriptionBranchesFailed = useSelector(
    getFetchSubscriptionBranchesFailed
  );
  const fetchSubscriptionBranchFailed = useSelector(
    getFetchSubscriptionBranchFailed
  );
  const fetchBranchesFailed = useSelector(getFetchBranchesFailed);
  const fetchBranchFailed = useSelector(getFetchBranchFailed);
  const createBranchFailed = useSelector(getCreateBranchFailed);
  const updateBranchFailed = useSelector(getUpdateBranchFailed);
  const deleteBranchFailed = useSelector(getDeleteBranchFailed);

  useEffect(() => {
    if (
      createBranchSuccess !== null ||
      updateBranchSuccess !== null ||
      deleteBranchSuccess !== null
    ) {
      showSuccessMessage(
        createBranchSuccess ?? updateBranchSuccess ?? deleteBranchSuccess
      );
      if (isModalAutoClose) dispatch(setIsModalOpen(false));

      if (createBranchSuccess !== null) dispatch(setCreateBranchSuccess(null));
      if (updateBranchSuccess !== null) dispatch(setUpdateBranchSuccess(null));
      if (deleteBranchSuccess !== null) dispatch(setDeleteBranchSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBranchSuccess, updateBranchSuccess, deleteBranchSuccess]);

  useEffect(() => {
    if (
      fetchSubscriptionBranchesFailed !== null ||
      fetchSubscriptionBranchFailed !== null ||
      fetchBranchesFailed !== null ||
      fetchBranchFailed !== null ||
      createBranchFailed !== null ||
      updateBranchFailed !== null ||
      deleteBranchFailed !== null
    ) {
      showErrorMessage(
        fetchSubscriptionBranchesFailed ??
          fetchSubscriptionBranchFailed ??
          fetchBranchesFailed ??
          fetchBranchFailed ??
          createBranchFailed ??
          updateBranchFailed ??
          deleteBranchFailed
      );

      if (fetchSubscriptionBranchesFailed !== null)
        dispatch(setFetchSubscriptionBranchesFailed(null));
      if (fetchSubscriptionBranchFailed !== null)
        dispatch(setFetchSubscriptionBranchFailed(null));
      if (fetchBranchesFailed !== null) dispatch(setFetchBranchesFailed(null));
      if (fetchBranchFailed !== null) dispatch(setFetchBranchFailed(null));
      if (createBranchFailed !== null) dispatch(setCreateBranchFailed(null));
      if (updateBranchFailed !== null) dispatch(setUpdateBranchFailed(null));
      if (deleteBranchFailed !== null) dispatch(setDeleteBranchFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchSubscriptionBranchesFailed,
    fetchSubscriptionBranchFailed,
    fetchBranchesFailed,
    fetchBranchFailed,
    createBranchFailed,
    updateBranchFailed,
    deleteBranchFailed,
  ]);

  return <></>;
};

export default BranchPopup;
