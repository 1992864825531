import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { storeAnalytics } from "../../utils/common.utils";

import {
  NotFoundContainer,
  NotFoundRedirect,
  NotFoundTitle,
} from "./not-found.style";

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    storeAnalytics({ title: "Not Found" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotFoundContainer>
      <NotFoundTitle>{t("Page Not Found")}</NotFoundTitle>
      <NotFoundRedirect to="/">{t("Go to home page")}</NotFoundRedirect>
    </NotFoundContainer>
  );
};

export default NotFound;
