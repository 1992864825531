import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getMarket } from "../../api/market.api";
import { getBranches } from "../../api/branch.api";
import {
  calculateCreateExtendPeriod,
  customExtendPeriod,
} from "../../api/extend-period.api";

import RENEWAL_ADMIN_ACTION_TYPES from "./renewal-admin.type";
import {
  appendRenewalBranches,
  setFetchRenewalBranchesFailed,
  setFetchRenewalBranchesLoading,
  setFetchRenewalBranchesSuccess,
  setFetchRenewalMarketFailed,
  setFetchRenewalMarketLoading,
  setFetchRenewalMarketSuccess,
  setIsFetchRenewalBranchesHitted,
  setIsFetchRenewalMarketHitted,
  setIsRenewalBranchesHasMore,
  setIsRenewalCalculateHitted,
  setIsRenewalCreateHitted,
  setRenewalBranches,
  setRenewalCalculate,
  setRenewalCalculateFailed,
  setRenewalCalculateLoading,
  setRenewalCalculateSuccess,
  setRenewalCreate,
  setRenewalCreateFailed,
  setRenewalCreateLoading,
  setRenewalCreateSuccess,
  setRenewalMarket,
} from "./renewal-admin.action";

export function* _getFetchRenewalMarket({ payload: { marketId, params } }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchRenewalMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, marketId, parameters);

    yield put(setIsFetchRenewalMarketHitted(true));
    yield put(setRenewalMarket(market));

    yield put(setFetchRenewalMarketSuccess(message));
    yield put(setFetchRenewalMarketLoading(false));
  } catch (error) {
    yield put(setFetchRenewalMarketFailed(error));
    yield put(setFetchRenewalMarketLoading(false));
  }
}
export function* _getFetchRenewalBranches({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchRenewalBranchesLoading(true));

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchRenewalBranchesHitted(true));
    yield put(setIsRenewalBranchesHasMore(branches.length > 0));

    if (parameters.page > 1) {
      yield put(appendRenewalBranches(branches));
    } else {
      yield put(setRenewalBranches(branches));
    }

    yield put(setFetchRenewalBranchesSuccess(message));
    yield put(setFetchRenewalBranchesLoading(false));
  } catch (error) {
    yield put(setFetchRenewalBranchesFailed(error));
    yield put(setFetchRenewalBranchesLoading(false));
  }
}
export function* _getRenewalCalculate({ payload: request }) {
  try {
    yield put(setRenewalCalculateLoading(true));

    const {
      meta: { message },
      data: renewalCalculate,
    } = yield call(calculateCreateExtendPeriod, request);

    yield put(setIsRenewalCalculateHitted(true));
    yield put(setRenewalCalculate(renewalCalculate));

    yield put(setRenewalCalculateSuccess(message));
    yield put(setRenewalCalculateLoading(false));
  } catch (error) {
    yield put(setRenewalCalculateFailed(error));
    yield put(setRenewalCalculateLoading(false));
  }
}
export function* _getRenewalCreate({ payload: request }) {
  try {
    yield put(setRenewalCreateLoading(true));

    const {
      meta: { message },
      data: renewalCreate,
    } = yield call(customExtendPeriod, request);

    yield put(setIsRenewalCreateHitted(true));
    yield put(setRenewalCreate(renewalCreate));

    yield put(setRenewalCreateSuccess(message));
    yield put(setRenewalCreateLoading(false));
  } catch (error) {
    yield put(setRenewalCreateFailed(error));
    yield put(setRenewalCreateLoading(false));
  }
}

export function* onFetchRenewalMarketStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_MARKET_START,
    _getFetchRenewalMarket
  );
}
export function* onFetchRenewalBranchesStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_BRANCHES_START,
    _getFetchRenewalBranches
  );
}
export function* onRenewalCalculateStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.RENEWAL_CALCULATE_START,
    _getRenewalCalculate
  );
}
export function* onRenewalCreateStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.RENEWAL_CREATE_START,
    _getRenewalCreate
  );
}

export function* renewalAdminSaga() {
  yield all([
    call(onFetchRenewalMarketStart),
    call(onFetchRenewalBranchesStart),
    call(onRenewalCalculateStart),
    call(onRenewalCreateStart),
  ]);
}
