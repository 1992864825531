import styled from "styled-components";

export const SubscriptionPlanTableHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const SubscriptionPlanTableHeaderTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: 0.25rem;
`;

export const SubscriptionPlanTableHeaderTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const SubscriptionPlanTableHeaderCurrent = styled.div`
  cursor: default;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem 0.875rem;
  border-radius: 1.5rem;
  background-color: #f2f2f4;

  font-size: 0.675rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1;
  white-space: nowrap;
`;

export const SubscriptionPlanTableHeaderBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 1.5rem;
`;

export const SubscriptionPlanTableHeaderDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;
