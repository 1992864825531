import apiService from "./api";

export const getPermissionSubscribers = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/permissions/subscribers",
      params,
    })
  ).data;
export const getPermissionSubscriber = async (permissionId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/permissions/subscribers/${permissionId}`,
    })
  ).data;
export const createPermissionSubscriber = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/permissions/subscribers",
      request,
    })
  ).data;
export const updatePermissionSubscriber = async (permissionId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/permissions/subscribers/${permissionId}`,
      request,
    })
  ).data;
export const deletePermissionSubscriber = async (permissionId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/permissions/subscribers/${permissionId}`,
    })
  ).data;
