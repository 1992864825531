import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setFetchNextReportTotalsFailed,
  setFetchPrevReportTotalsFailed,
  setFetchReportCashiersFailed,
  setFetchReportCategoriesFailed,
  setFetchReportPaymentsFailed,
  setFetchReportProductsFailed,
} from "../../store/report/report.action";
import {
  getFetchNextReportTotalsFailed,
  getFetchPrevReportTotalsFailed,
  getFetchReportCashiersFailed,
  getFetchReportCategoriesFailed,
  getFetchReportPaymentsFailed,
  getFetchReportProductsFailed,
} from "../../store/report/report.selector";

const ReportPopup = () => {
  const dispatch = useDispatch();

  const fetchPrevReportTotalsFailed = useSelector(
    getFetchPrevReportTotalsFailed
  );
  const fetchNextReportTotalsFailed = useSelector(
    getFetchNextReportTotalsFailed
  );
  const fetchReportCategoriesFailed = useSelector(
    getFetchReportCategoriesFailed
  );
  const fetchReportProductsFailed = useSelector(getFetchReportProductsFailed);
  const fetchReportCashiersFailed = useSelector(getFetchReportCashiersFailed);
  const fetchReportPaymentsFailed = useSelector(getFetchReportPaymentsFailed);

  useEffect(() => {
    if (
      fetchPrevReportTotalsFailed !== null ||
      fetchNextReportTotalsFailed !== null ||
      fetchReportCategoriesFailed !== null ||
      fetchReportProductsFailed !== null ||
      fetchReportCashiersFailed !== null ||
      fetchReportPaymentsFailed !== null
    ) {
      showErrorMessage(
        fetchPrevReportTotalsFailed ??
          fetchNextReportTotalsFailed ??
          fetchReportCategoriesFailed ??
          fetchReportProductsFailed ??
          fetchReportCashiersFailed ??
          fetchReportPaymentsFailed
      );

      if (fetchPrevReportTotalsFailed !== null)
        dispatch(setFetchPrevReportTotalsFailed(null));
      if (fetchNextReportTotalsFailed !== null)
        dispatch(setFetchNextReportTotalsFailed(null));
      if (fetchReportCategoriesFailed !== null)
        dispatch(setFetchReportCategoriesFailed(null));
      if (fetchReportProductsFailed !== null)
        dispatch(setFetchReportProductsFailed(null));
      if (fetchReportCashiersFailed !== null)
        dispatch(setFetchReportCashiersFailed(null));
      if (fetchReportPaymentsFailed !== null)
        dispatch(setFetchReportPaymentsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPrevReportTotalsFailed,
    fetchNextReportTotalsFailed,
    fetchReportCategoriesFailed,
    fetchReportProductsFailed,
    fetchReportCashiersFailed,
    fetchReportPaymentsFailed,
  ]);

  return <></>;
};

export default ReportPopup;
