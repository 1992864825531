import apiService from "./api";

export const getPaymentMethods = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/payment-methods", params })).data;
export const getPaymentMethod = async (key) =>
  (await apiService.innerGet({ urlPath: `/v2/payment-methods/${key}` })).data;
export const createPaymentMethod = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/payment-methods", request }))
    .data;
export const updatePaymentMethod = async (methodKey, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/payment-methods/${methodKey}`,
      request,
    })
  ).data;
export const deletePaymentMethod = async (methodKey) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/payment-methods/${methodKey}`,
    })
  ).data;
