import apiService from "./api";

export const getPurchases = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/purchases", params })).data;
export const getPurchase = async (purchaseId) =>
  (await apiService.innerGet({ urlPath: `/v2/purchases/${purchaseId}` })).data;
export const createPurchase = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/purchases", request })).data;
export const updatePurchase = async (purchaseId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/purchases/${purchaseId}`,
      request,
    })
  ).data;
export const deletePurchase = async (purchaseId) =>
  (await apiService.innerDelete({ urlPath: `/v2/purchases/${purchaseId}` }))
    .data;
export const calculatePurchase = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/purchases/calc", request })).data;
