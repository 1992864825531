import apiService from "./api";

export const getTaxes = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/taxes", params })).data;
export const getTax = async (taxId) =>
  (await apiService.innerGet({ urlPath: `/v2/taxes/${taxId}` })).data;
export const createTax = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/taxes", request })).data;
export const updateTax = async (taxId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/taxes/${taxId}`, request })).data;
export const deleteTax = async (taxId) =>
  (await apiService.innerDelete({ urlPath: `/v2/taxes/${taxId}` })).data;
export const sortTaxes = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/taxes/sort", request })).data;
