import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateSupplierFailed,
  setCreateSupplierSuccess,
  setDeleteSupplierFailed,
  setDeleteSupplierSuccess,
  setFetchSelectSuppliersFailed,
  setFetchSupplierFailed,
  setFetchSuppliersFailed,
  setUpdateSupplierFailed,
  setUpdateSupplierSuccess,
} from "../../store/supplier/supplier.action";
import {
  getCreateSupplierFailed,
  getCreateSupplierSuccess,
  getDeleteSupplierFailed,
  getDeleteSupplierSuccess,
  getFetchSelectSuppliersFailed,
  getFetchSupplierFailed,
  getFetchSuppliersFailed,
  getUpdateSupplierFailed,
  getUpdateSupplierSuccess,
} from "../../store/supplier/supplier.selector";

const AccountPopup = () => {
  const dispatch = useDispatch();

  const createSupplierSuccess = useSelector(getCreateSupplierSuccess);
  const updateSupplierSuccess = useSelector(getUpdateSupplierSuccess);
  const deleteSupplierSuccess = useSelector(getDeleteSupplierSuccess);
  const fetchSuppliersFailed = useSelector(getFetchSuppliersFailed);
  const fetchSelectSuppliersFailed = useSelector(getFetchSelectSuppliersFailed);
  const fetchSupplierFailed = useSelector(getFetchSupplierFailed);
  const createSupplierFailed = useSelector(getCreateSupplierFailed);
  const updateSupplierFailed = useSelector(getUpdateSupplierFailed);
  const deleteSupplierFailed = useSelector(getDeleteSupplierFailed);

  useEffect(() => {
    if (
      createSupplierSuccess !== null ||
      updateSupplierSuccess !== null ||
      deleteSupplierSuccess !== null
    ) {
      showSuccessMessage(
        createSupplierSuccess ?? updateSupplierSuccess ?? deleteSupplierSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createSupplierSuccess !== null)
        dispatch(setCreateSupplierSuccess(null));
      if (updateSupplierSuccess !== null)
        dispatch(setUpdateSupplierSuccess(null));
      if (deleteSupplierSuccess !== null)
        dispatch(setDeleteSupplierSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSupplierSuccess, updateSupplierSuccess, deleteSupplierSuccess]);

  useEffect(() => {
    if (
      fetchSuppliersFailed !== null ||
      fetchSelectSuppliersFailed !== null ||
      fetchSupplierFailed !== null ||
      createSupplierFailed !== null ||
      updateSupplierFailed !== null ||
      deleteSupplierFailed !== null
    ) {
      showErrorMessage(
        fetchSuppliersFailed ??
          fetchSupplierFailed ??
          createSupplierFailed ??
          updateSupplierFailed ??
          deleteSupplierFailed
      );

      if (fetchSuppliersFailed !== null)
        dispatch(setFetchSuppliersFailed(null));
      if (fetchSelectSuppliersFailed !== null)
        dispatch(setFetchSelectSuppliersFailed(null));
      if (fetchSupplierFailed !== null) dispatch(setFetchSupplierFailed(null));
      if (createSupplierFailed !== null)
        dispatch(setCreateSupplierFailed(null));
      if (updateSupplierFailed !== null)
        dispatch(setUpdateSupplierFailed(null));
      if (deleteSupplierFailed !== null)
        dispatch(setDeleteSupplierFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchSuppliersFailed,
    fetchSelectSuppliersFailed,
    fetchSupplierFailed,
    createSupplierFailed,
    updateSupplierFailed,
    deleteSupplierFailed,
  ]);

  return <></>;
};

export default AccountPopup;
