import { createSelector } from "reselect";

const selectProductCategorySelector = ({ selectProductCategory }) =>
  selectProductCategory;

export const getProductCategories = createSelector(
  [selectProductCategorySelector],
  ({ productCategories }) => productCategories
);
export const getSearchProductCategories = createSelector(
  [selectProductCategorySelector],
  ({ searchProductCategories }) => searchProductCategories
);

export const getIsProductCategoriesHasMore = createSelector(
  [selectProductCategorySelector],
  ({ isProductCategoriesHasMore }) => isProductCategoriesHasMore
);
export const getIsSearchProductCategoriesHasMore = createSelector(
  [selectProductCategorySelector],
  ({ isSearchProductCategoriesHasMore }) => isSearchProductCategoriesHasMore
);

export const getFetchProductCategoriesParams = createSelector(
  [selectProductCategorySelector],
  ({ fetchProductCategoriesParams }) => fetchProductCategoriesParams
);
export const getFetchProductCategoriesLoading = createSelector(
  [selectProductCategorySelector],
  ({ fetchProductCategoriesLoading }) => fetchProductCategoriesLoading
);
export const getFetchProductCategoriesSuccess = createSelector(
  [selectProductCategorySelector],
  ({ fetchProductCategoriesSuccess }) => fetchProductCategoriesSuccess
);
export const getFetchProductCategoriesFailed = createSelector(
  [selectProductCategorySelector],
  ({ fetchProductCategoriesFailed }) => fetchProductCategoriesFailed
);

export const getSearchProductCategoriesParams = createSelector(
  [selectProductCategorySelector],
  ({ searchProductCategoriesParams }) => searchProductCategoriesParams
);
export const getSearchProductCategoriesLoading = createSelector(
  [selectProductCategorySelector],
  ({ searchProductCategoriesLoading }) => searchProductCategoriesLoading
);
export const getSearchProductCategoriesSuccess = createSelector(
  [selectProductCategorySelector],
  ({ searchProductCategoriesSuccess }) => searchProductCategoriesSuccess
);
export const getSearchProductCategoriesFailed = createSelector(
  [selectProductCategorySelector],
  ({ searchProductCategoriesFailed }) => searchProductCategoriesFailed
);

export const getAppendProductCategoriesParams = createSelector(
  [selectProductCategorySelector],
  ({ appendProductCategoriesParams }) => appendProductCategoriesParams
);
export const getAppendProductCategoriesLoading = createSelector(
  [selectProductCategorySelector],
  ({ appendProductCategoriesLoading }) => appendProductCategoriesLoading
);
export const getAppendProductCategoriesSuccess = createSelector(
  [selectProductCategorySelector],
  ({ appendProductCategoriesSuccess }) => appendProductCategoriesSuccess
);
export const getAppendProductCategoriesFailed = createSelector(
  [selectProductCategorySelector],
  ({ appendProductCategoriesFailed }) => appendProductCategoriesFailed
);

export const getIsFetchProductCategoriesHitted = createSelector(
  [selectProductCategorySelector],
  ({ isFetchProductCategoriesHitted }) => isFetchProductCategoriesHitted
);
export const getIsSearchProductCategoriesHitted = createSelector(
  [selectProductCategorySelector],
  ({ isSearchProductCategoriesHitted }) => isSearchProductCategoriesHitted
);
export const getIsAppendProductCategoriesHitted = createSelector(
  [selectProductCategorySelector],
  ({ isAppendProductCategoriesHitted }) => isAppendProductCategoriesHitted
);
