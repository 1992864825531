import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreatePrinterFailed,
  setCreatePrinterSuccess,
  setDeletePrinterFailed,
  setDeletePrinterSuccess,
  setFetchPrinterFailed,
  setFetchPrintersFailed,
  setUpdatePrinterFailed,
  setUpdatePrinterSuccess,
} from "../../store/printer/printer.action";
import {
  getCreatePrinterFailed,
  getCreatePrinterSuccess,
  getDeletePrinterFailed,
  getDeletePrinterSuccess,
  getFetchPrinterFailed,
  getFetchPrintersFailed,
  getUpdatePrinterFailed,
  getUpdatePrinterSuccess,
} from "../../store/printer/printer.selector";

const PrinterPopup = () => {
  const dispatch = useDispatch();

  const createPrinterSuccess = useSelector(getCreatePrinterSuccess);
  const updatePrinterSuccess = useSelector(getUpdatePrinterSuccess);
  const deletePrinterSuccess = useSelector(getDeletePrinterSuccess);
  const fetchPrintersFailed = useSelector(getFetchPrintersFailed);
  const fetchPrinterFailed = useSelector(getFetchPrinterFailed);
  const createPrinterFailed = useSelector(getCreatePrinterFailed);
  const updatePrinterFailed = useSelector(getUpdatePrinterFailed);
  const deletePrinterFailed = useSelector(getDeletePrinterFailed);

  useEffect(() => {
    if (createPrinterSuccess !== null || updatePrinterSuccess !== null || deletePrinterSuccess !== null) {
      showSuccessMessage(createPrinterSuccess ?? updatePrinterSuccess ?? deletePrinterSuccess);
      dispatch(setIsModalOpen(false));

      if (createPrinterSuccess !== null) dispatch(setCreatePrinterSuccess(null));
      if (updatePrinterSuccess !== null) dispatch(setUpdatePrinterSuccess(null));
      if (deletePrinterSuccess !== null) dispatch(setDeletePrinterSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPrinterSuccess, updatePrinterSuccess, deletePrinterSuccess]);

  useEffect(() => {
    if (
      fetchPrintersFailed !== null ||
      fetchPrinterFailed !== null ||
      createPrinterFailed !== null ||
      updatePrinterFailed !== null ||
      deletePrinterFailed !== null
    ) {
      showErrorMessage(
        fetchPrintersFailed ?? fetchPrinterFailed ?? createPrinterFailed ?? updatePrinterFailed ?? deletePrinterFailed
      );

      if (fetchPrintersFailed !== null) dispatch(setFetchPrintersFailed(null));
      if (fetchPrinterFailed !== null) dispatch(setFetchPrinterFailed(null));
      if (createPrinterFailed !== null) dispatch(setCreatePrinterFailed(null));
      if (updatePrinterFailed !== null) dispatch(setUpdatePrinterFailed(null));
      if (deletePrinterFailed !== null) dispatch(setDeletePrinterFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrintersFailed, fetchPrinterFailed, createPrinterFailed, updatePrinterFailed, deletePrinterFailed]);

  return <></>;
};

export default PrinterPopup;
