import styled, { css } from "styled-components";

import { FORM_INPUT_STYLES } from "../../utils/styles.utils";
import { FORM_INPUT_SIZES } from "./form-input.component";

export const FormInputAdditional = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  border-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
  border-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
  border-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
  font-weight: ${FORM_INPUT_STYLES.INPUT_FONT_WEIGHT};

  svg path {
    ${({ isValid }) =>
      isValid &&
      css`
        fill: ${FORM_INPUT_STYLES.INPUT_VALID_COLOR} !important;
      `}

    ${({ isNotValid }) =>
      isNotValid &&
      css`
        fill: ${FORM_INPUT_STYLES.INPUT_INVALID_COLOR} !important;
      `}
  }

  ${({ isValid }) =>
    isValid &&
    css`
      border-right-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
      border-right-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
      border-right-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
      border-left: none;
    `}

  ${({ isNotValid }) =>
    isNotValid &&
    css`
      border-right-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
      border-right-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
      border-right-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
      border-left: none;
    `}

  ${({ isValid, isRightToLeft }) =>
    isValid &&
    isRightToLeft &&
    css`
      border-left-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
      border-left-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
      border-left-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
      border-right: none;
    `}

  ${({ isNotValid, isRightToLeft }) =>
    isNotValid &&
    isRightToLeft &&
    css`
      border-left-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
      border-left-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
      border-left-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
      border-right: none;
    `}
`;

export const FormInputField = styled.input`
  width: 100%;
  border-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
  border-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
  border-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
  border-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
  caret-color: #2900ee;
  font-weight: ${FORM_INPUT_STYLES.INPUT_FONT_WEIGHT};

  &:focus {
    border-color: ${FORM_INPUT_STYLES.INPUT_FOCUS_BORDER_COLOR};
  }
  &::placeholder {
    color: #a3a3a3;
    font-weight: ${FORM_INPUT_STYLES.INPUT_PLACEHOLDER_FONT_WEIGHT};
  }

  ${({ isBorderLess }) =>
    isBorderLess &&
    css`
      border: none;
    `}

  ${({ isNoPaddingLeft, isRightToLeft }) =>
    isNoPaddingLeft &&
    (isRightToLeft
      ? css`
          padding-right: 0 !important;
        `
      : css`
          padding-left: 0 !important;
        `)}
  
  ${({ isNoPaddingRight, isRightToLeft }) =>
    isNoPaddingRight &&
    (isRightToLeft
      ? css`
          padding-left: 0 !important;
        `
      : css`
          padding-right: 0 !important;
        `)}

  &:focus {
    ${({ isValid }) =>
      isValid &&
      css`
        border-width: 2px;
        border-color: ${FORM_INPUT_STYLES.INPUT_VALID_COLOR} !important;
      `}

    ${({ isNotValid }) =>
      isNotValid &&
      css`
        border-width: 2px;
        border-color: ${FORM_INPUT_STYLES.INPUT_INVALID_COLOR} !important;
      `}

    + ${FormInputAdditional} {
      ${({ isValid }) =>
        isValid &&
        css`
          border-width: 2px;
          border-color: ${FORM_INPUT_STYLES.INPUT_VALID_COLOR} !important;
        `}

      ${({ isNotValid }) =>
        isNotValid &&
        css`
          border-width: 2px;
          border-color: ${FORM_INPUT_STYLES.INPUT_INVALID_COLOR} !important;
        `}
    }
  }
`;

export const FormInputGroup = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  & > * {
    border-radius: 0;

    &:not(input):not(:last-child) {
      ${({ isRightToLeft }) =>
        isRightToLeft
          ? css`
              border-left-width: 0;
            `
          : css`
              border-right-width: 0;
            `}
    }

    &:last-child:not(input) {
      ${({ isRightToLeft }) =>
        isRightToLeft
          ? css`
              border-right-width: 0;
            `
          : css`
              border-left-width: 0;
            `}
    }

    &:first-child {
      ${({ isRightToLeft }) =>
        isRightToLeft
          ? css`
              border-top-right-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
              border-bottom-right-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
            `
          : css`
              border-top-left-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
              border-bottom-left-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
            `}
    }

    &:last-child {
      ${({ isRightToLeft }) =>
        isRightToLeft
          ? css`
              border-top-left-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
              border-bottom-left-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
            `
          : css`
              border-top-right-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
              border-bottom-right-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
            `}
    }
  }
`;

export const FormInputOutGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const FormInputPrefixOut = styled.div`
  margin-right: 1rem;
`;

export const FormInputSuffixOut = styled.div`
  margin-left: 1rem;
`;

export const FormInputContainer = styled.div`
  position: relative;
  width: 100%;

  ${({ isFocusing }) =>
    isFocusing &&
    css`
      ${FormInputField} {
        border-color: ${FORM_INPUT_STYLES.INPUT_FOCUS_BORDER_COLOR};
      }
      ${FormInputAdditional} {
        border-color: ${FORM_INPUT_STYLES.INPUT_FOCUS_BORDER_COLOR};
      }
    `}

  ${FormInputField} {
    height: ${({ inputSize }) => {
      switch (inputSize) {
        case FORM_INPUT_SIZES.LARGE:
          return "3.5rem";
        case FORM_INPUT_SIZES.SMALL:
          return "2.5rem";
        case FORM_INPUT_SIZES.NORMAL:
        default:
          return "3rem";
      }
    }};
    font-size: ${({ inputSize }) => {
      switch (inputSize) {
        case FORM_INPUT_SIZES.LARGE:
          return "0.925rem";
        case FORM_INPUT_SIZES.SMALL:
          return "0.75rem";
        case FORM_INPUT_SIZES.NORMAL:
        default:
          return "0.875rem";
      }
    }};
    padding-left: ${({
      isPrefixBorderless,
      isSuffixBorderless,
      isRightToLeft,
      inputSize,
    }) => {
      if (
        (isPrefixBorderless && !isRightToLeft) ||
        (isSuffixBorderless && isRightToLeft)
      ) {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1rem";
          case FORM_INPUT_SIZES.SMALL:
            return "0.75rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1rem";
        }
      } else {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1.5rem";
          case FORM_INPUT_SIZES.SMALL:
            return "1rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1.5rem";
        }
      }
    }};
    padding-right: ${({
      isPrefixBorderless,
      isSuffixBorderless,
      isRightToLeft,
      inputSize,
    }) => {
      if (
        (isPrefixBorderless && isRightToLeft) ||
        (isSuffixBorderless && !isRightToLeft)
      ) {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1rem";
          case FORM_INPUT_SIZES.SMALL:
            return "0.75rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1rem";
        }
      } else {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1.5rem";
          case FORM_INPUT_SIZES.SMALL:
            return "1rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1.5rem";
        }
      }
    }};
    background-color: ${({ isSolid }) =>
      isSolid
        ? FORM_INPUT_STYLES.INPUT_SOLID_BG_COLOR
        : FORM_INPUT_STYLES.INPUT_BACKGROUND_COLOR};

    ${({ isPrefixBorderless, isSuffixBorderless, isRightToLeft }) =>
      ((isPrefixBorderless && isRightToLeft) ||
        (isSuffixBorderless && !isRightToLeft)) &&
      css`
        border-right: none;
      `}

    ${({ isPrefixBorderless, isSuffixBorderless, isRightToLeft }) =>
      ((isPrefixBorderless && !isRightToLeft) ||
        (isSuffixBorderless && isRightToLeft)) &&
      css`
        border-left: none;
      `}
  }

  ${FormInputAdditional} {
    height: ${({ inputSize }) => {
      switch (inputSize) {
        case FORM_INPUT_SIZES.LARGE:
          return "3.5rem";
        case FORM_INPUT_SIZES.SMALL:
          return "2.5rem";
        case FORM_INPUT_SIZES.NORMAL:
        default:
          return "3rem";
      }
    }};
    font-size: ${({ inputSize }) => {
      switch (inputSize) {
        case FORM_INPUT_SIZES.LARGE:
          return "0.925rem";
        case FORM_INPUT_SIZES.SMALL:
          return "0.75rem";
        case FORM_INPUT_SIZES.NORMAL:
        default:
          return "0.875rem";
      }
    }};

    padding-left: ${({
      inputSize,
      isPrefixBorderless,
      isSuffixBorderless,
      isRightToLeft,
    }) => {
      if (
        (isPrefixBorderless && !isRightToLeft) ||
        (isSuffixBorderless && isRightToLeft)
      ) {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1rem";
          case FORM_INPUT_SIZES.SMALL:
            return "0.75rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1rem";
        }
      } else {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1.5rem";
          case FORM_INPUT_SIZES.SMALL:
            return "1rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1.5rem";
        }
      }
    }};
    padding-right: ${({
      inputSize,
      isPrefixBorderless,
      isSuffixBorderless,
      isRightToLeft,
    }) => {
      if (
        (isPrefixBorderless && isRightToLeft) ||
        (isSuffixBorderless && !isRightToLeft)
      ) {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1rem";
          case FORM_INPUT_SIZES.SMALL:
            return "0.75rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1rem";
        }
      } else {
        switch (inputSize) {
          case FORM_INPUT_SIZES.LARGE:
            return "1.5rem";
          case FORM_INPUT_SIZES.SMALL:
            return "1rem";
          case FORM_INPUT_SIZES.NORMAL:
          default:
            return "1.5rem";
        }
      }
    }};

    background-color: ${({ isSolid }) =>
      isSolid
        ? FORM_INPUT_STYLES.INPUT_SOLID_BG_COLOR
        : FORM_INPUT_STYLES.INPUT_BACKGROUND_COLOR};

    svg {
      ${({ inputSize, isValid, isNotValid }) =>
        (isValid || isNotValid) &&
        (inputSize === FORM_INPUT_SIZES.LARGE
          ? css`
              width: 1.75rem;
              min-width: 1.75rem;
              max-width: 1.75rem;
              height: 1.75rem;
              min-height: 1.75rem;
              max-height: 1.75rem;
            `
          : inputSize === FORM_INPUT_SIZES.SMALL
          ? css`
              width: 1.25rem;
              min-width: 1.25rem;
              max-width: 1.25rem;
              height: 1.25rem;
              min-height: 1.25rem;
              max-height: 1.25rem;
            `
          : css`
              width: 1.5rem;
              min-width: 1.5rem;
              max-width: 1.5rem;
              height: 1.5rem;
              min-height: 1.5rem;
              max-height: 1.5rem;
            `)}
    }
  }

  ${FormInputPrefixOut} {
    ${({ isRightToLeft }) =>
      isRightToLeft &&
      css`
        margin-right: initial;
        margin-left: 1rem;
      `}
  }
  ${FormInputSuffixOut} {
    ${({ isRightToLeft }) =>
      isRightToLeft &&
      css`
        margin-left: initial;
        margin-right: 1rem;
      `}
  }
`;
