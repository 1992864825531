import { createAction } from "../../utils/store.utils";

import RENEWAL_ADMIN_ACTION_TYPES from "./renewal-admin.type";

export const setRenewalMarket = (renewalMarket) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_MARKET, renewalMarket);
export const setRenewalBranches = (renewalBranches) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_BRANCHES,
    renewalBranches
  );
export const setRenewalCalculate = (renewalCalculate) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE,
    renewalCalculate
  );
export const setRenewalCreate = (renewalCreate) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE, renewalCreate);

export const appendRenewalBranches = (renewalBranches) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.APPEND_RENEWAL_BRANCHES,
    renewalBranches
  );

export const setIsRenewalBranchesHasMore = (isRenewalBranchesHasMore) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_BRANCHES_HAS_MORE,
    isRenewalBranchesHasMore
  );

export const setFetchRenewalMarketParams = (fetchRenewalMarketParams) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_PARAMS,
    fetchRenewalMarketParams
  );
export const setFetchRenewalMarketLoading = (fetchRenewalMarketLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_LOADING,
    fetchRenewalMarketLoading
  );
export const setFetchRenewalMarketSuccess = (fetchRenewalMarketSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_SUCCESS,
    fetchRenewalMarketSuccess
  );
export const setFetchRenewalMarketFailed = (fetchRenewalMarketFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_FAILED,
    fetchRenewalMarketFailed
  );

export const setFetchRenewalBranchesParams = (fetchRenewalBranchesParams) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_PARAMS,
    fetchRenewalBranchesParams
  );
export const setFetchRenewalBranchesLoading = (fetchRenewalBranchesLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_LOADING,
    fetchRenewalBranchesLoading
  );
export const setFetchRenewalBranchesSuccess = (fetchRenewalBranchesSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_SUCCESS,
    fetchRenewalBranchesSuccess
  );
export const setFetchRenewalBranchesFailed = (fetchRenewalBranchesFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_FAILED,
    fetchRenewalBranchesFailed
  );

export const setRenewalCalculateLoading = (renewalCalculateLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE_LOADING,
    renewalCalculateLoading
  );
export const setRenewalCalculateSuccess = (renewalCalculateSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE_SUCCESS,
    renewalCalculateSuccess
  );
export const setRenewalCalculateFailed = (renewalCalculateFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE_FAILED,
    renewalCalculateFailed
  );

export const setRenewalCreateLoading = (renewalCreateLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_LOADING,
    renewalCreateLoading
  );
export const setRenewalCreateSuccess = (renewalCreateSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_SUCCESS,
    renewalCreateSuccess
  );
export const setRenewalCreateFailed = (renewalCreateFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_FAILED,
    renewalCreateFailed
  );

export const setIsFetchRenewalMarketHitted = (isFetchRenewalMarketHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_MARKET_HITTED,
    isFetchRenewalMarketHitted
  );
export const setIsFetchRenewalBranchesHitted = (isFetchRenewalBranchesHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_BRANCHES_HITTED,
    isFetchRenewalBranchesHitted
  );
export const setIsRenewalCalculateHitted = (isRenewalCalculateHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_CALCULATE_HITTED,
    isRenewalCalculateHitted
  );
export const setIsRenewalCreateHitted = (isRenewalCreateHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_CREATE_HITTED,
    isRenewalCreateHitted
  );

export const fetchRenewalMarketStart = (marketId, params) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_MARKET_START, {
    marketId,
    params,
  });
export const fetchRenewalBranchesStart = (params) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_BRANCHES_START, params);
export const renewalCalculateStart = (request) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.RENEWAL_CALCULATE_START, request);
export const renewalCreateStart = (request) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.RENEWAL_CREATE_START, request);

export const resetRenewalAdminReducer = () =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.RESET_RENEWAL_ADMIN_REDUCER);
