import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendBranchesFailed,
  setFetchBranchesFailed,
  setSearchBranchesFailed,
} from "../../store/select-branch/select-branch.action";
import {
  getAppendBranchesFailed,
  getFetchBranchesFailed,
  getSearchBranchesFailed,
} from "../../store/select-branch/select-branch.selector";

const SelectBranchPopup = () => {
  const dispatch = useDispatch();

  const fetchBranchesFailed = useSelector(getFetchBranchesFailed);
  const searchBranchesFailed = useSelector(getSearchBranchesFailed);
  const appendBranchesFailed = useSelector(getAppendBranchesFailed);

  useEffect(() => {
    if (
      fetchBranchesFailed !== null ||
      searchBranchesFailed !== null ||
      appendBranchesFailed !== null
    ) {
      showErrorMessage(
        fetchBranchesFailed ?? searchBranchesFailed ?? appendBranchesFailed
      );

      if (fetchBranchesFailed !== null) dispatch(setFetchBranchesFailed(null));
      if (searchBranchesFailed !== null)
        dispatch(setSearchBranchesFailed(null));
      if (appendBranchesFailed !== null)
        dispatch(setAppendBranchesFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBranchesFailed, searchBranchesFailed, appendBranchesFailed]);

  return <></>;
};

export default SelectBranchPopup;
