import styled, { css } from "styled-components";

export const NotificationListRow = styled.div`
  position: relative;
`;

export const NotificationListColumn = styled.div`
  width: 100%;

  ${({ isLast }) =>
    !isLast &&
    css`
      padding-bottom: 1.25rem;
      border-bottom: 1px dashed #dfdcef;
      margin-bottom: 1.25rem;
    `}
`;
