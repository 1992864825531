import apiService from "./api";

export const getPrinters = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/printers", params })).data;
export const getPrinter = async (printerId) =>
  (await apiService.innerGet({ urlPath: `/v2/printers/${printerId}` })).data;
export const createPrinter = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/printers", request })).data;
export const updatePrinter = async (printerId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/printers/${printerId}`,
      request,
    })
  ).data;
export const deletePrinter = async (printerId) =>
  (await apiService.innerDelete({ urlPath: `/v2/printers/${printerId}` })).data;
