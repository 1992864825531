import styled, { css, keyframes } from "styled-components";
import { TOAST_TYPES } from "./toast.component";

const toastAnimation = keyframes`
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const ToastTitle = styled.h5`
  cursor: default;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
  user-select: none;
`;

export const ToastDescription = styled.p`
  cursor: default;
  font-size: 0.875rem;
  margin-bottom: 0;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ToastCloseButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;

  svg {
    min-width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ToastIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.35rem;
  margin-right: 0.75rem;

  svg {
    min-width: 2rem;
    height: 2rem;
  }
`;

export const ToastInfo = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const ToastContent = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  width: 20rem;
  margin-top: 0.5rem;
  padding: 1rem 0.75rem;
  animation: 0.3s ease-out 0s 1 ${toastAnimation};

  @media (min-width: 375px) {
    width: 24rem;
  }

  ${({ toastType }) =>
    toastType === TOAST_TYPES.CONFIRMATION &&
    css`
      background-color: #ffeedc;
      color: #fc9114;
    `}

  ${({ toastType }) =>
    toastType === TOAST_TYPES.SUCCESS &&
    css`
      background-color: #d9eedc;
      color: #008d0e;
    `}

  ${({ toastType }) =>
    toastType === TOAST_TYPES.INFO &&
    css`
      background-color: #ffeedc;
      color: #fc9114;
    `}

  ${({ toastType }) =>
    toastType === TOAST_TYPES.ERROR &&
    css`
      background-color: #fddfde;
      color: #f02c2c;
    `}

  ${ToastIcon}, ${ToastCloseButton} {
    svg path {
      ${({ toastType }) =>
        toastType === TOAST_TYPES.CONFIRMATION &&
        css`
          fill: #fc9114;
        `}
    }

    svg path {
      ${({ toastType }) =>
        toastType === TOAST_TYPES.SUCCESS &&
        css`
          fill: #008d0e;
        `}
    }

    svg path {
      ${({ toastType }) =>
        toastType === TOAST_TYPES.INFO &&
        css`
          fill: #fc9114;
        `}
    }

    svg path {
      ${({ toastType }) =>
        toastType === TOAST_TYPES.ERROR &&
        css`
          fill: #f02c2c;
        `}
    }
  }
`;

export const ToastContainer = styled.div`
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;

  ${ToastIcon} {
    ${({ isRtl }) =>
      isRtl &&
      css`
        margin-right: initial;
        margin-left: 0.75rem;
      `}
  }
`;

export const ToastActionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: 1rem;
`;

export const ToastActionItem = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 50%;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      width: 100%;
    `}
`;
