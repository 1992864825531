import { useTranslation } from "react-i18next";

import {
  EmptyStateTableContainer,
  EmptyStateTableDescription,
  EmptyStateTableIcon,
  EmptyStateTableTitle,
} from "./empty-state-table.style";

import { ReactComponent as MailBoxSvg } from "../../assets/icons/stroke/MailBox.svg";

const EmptyStateTable = (props) => {
  const { t } = useTranslation();

  return (
    <EmptyStateTableContainer {...props}>
      <EmptyStateTableIcon>
        <MailBoxSvg />
      </EmptyStateTableIcon>
      <EmptyStateTableTitle>{t("No Records Available")}</EmptyStateTableTitle>
      <EmptyStateTableDescription>
        {t("Looks like there's no data to show right now.")}
      </EmptyStateTableDescription>
    </EmptyStateTableContainer>
  );
};

export default EmptyStateTable;
