const RENEWAL_ADMIN_ACTION_TYPES = {
  RESET_RENEWAL_ADMIN_REDUCER: "renewal-admin/RESET_RENEWAL_ADMIN_REDUCER",

  SET_RENEWAL_MARKET: "renewal-admin/SET_RENEWAL_MARKET",
  SET_RENEWAL_BRANCHES: "renewal-admin/SET_RENEWAL_BRANCHES",
  SET_RENEWAL_CALCULATE: "renewal-admin/SET_RENEWAL_CALCULATE",
  SET_RENEWAL_CREATE: "renewal-admin/SET_RENEWAL_CREATE",

  APPEND_RENEWAL_BRANCHES: "renewal-admin/APPEND_RENEWAL_BRANCHES",

  SET_IS_RENEWAL_BRANCHES_HAS_MORE:
    "renewal-admin/SET_IS_RENEWAL_BRANCHES_HAS_MORE",

  SET_FETCH_RENEWAL_MARKET_PARAMS:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_PARAMS",
  SET_FETCH_RENEWAL_MARKET_LOADING:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_LOADING",
  SET_FETCH_RENEWAL_MARKET_SUCCESS:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_SUCCESS",
  SET_FETCH_RENEWAL_MARKET_FAILED:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_FAILED",

  SET_FETCH_RENEWAL_BRANCHES_PARAMS:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_PARAMS",
  SET_FETCH_RENEWAL_BRANCHES_LOADING:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_LOADING",
  SET_FETCH_RENEWAL_BRANCHES_SUCCESS:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_SUCCESS",
  SET_FETCH_RENEWAL_BRANCHES_FAILED:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_FAILED",

  SET_RENEWAL_CALCULATE_LOADING: "renewal-admin/SET_RENEWAL_CALCULATE_LOADING",
  SET_RENEWAL_CALCULATE_SUCCESS: "renewal-admin/SET_RENEWAL_CALCULATE_SUCCESS",
  SET_RENEWAL_CALCULATE_FAILED: "renewal-admin/SET_RENEWAL_CALCULATE_FAILED",

  SET_RENEWAL_CREATE_LOADING: "renewal-admin/SET_RENEWAL_CREATE_LOADING",
  SET_RENEWAL_CREATE_SUCCESS: "renewal-admin/SET_RENEWAL_CREATE_SUCCESS",
  SET_RENEWAL_CREATE_FAILED: "renewal-admin/SET_RENEWAL_CREATE_FAILED",

  SET_IS_FETCH_RENEWAL_MARKET_HITTED:
    "renewal-admin/SET_IS_FETCH_RENEWAL_MARKET_HITTED",
  SET_IS_FETCH_RENEWAL_BRANCHES_HITTED:
    "renewal-admin/SET_IS_FETCH_RENEWAL_BRANCHES_HITTED",
  SET_IS_RENEWAL_CALCULATE_HITTED:
    "renewal-admin/SET_IS_RENEWAL_CALCULATE_HITTED",
  SET_IS_RENEWAL_CREATE_HITTED: "renewal-admin/SET_IS_RENEWAL_CREATE_HITTED",

  FETCH_RENEWAL_MARKET_START: "renewal-admin/FETCH_RENEWAL_MARKET_START",
  FETCH_RENEWAL_BRANCHES_START: "renewal-admin/FETCH_RENEWAL_BRANCHES_START",
  RENEWAL_CALCULATE_START: "renewal-admin/RENEWAL_CALCULATE_START",
  RENEWAL_CREATE_START: "renewal-admin/RENEWAL_CREATE_START",
};

export default RENEWAL_ADMIN_ACTION_TYPES;
