import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setCreateAnnouncementFailed,
  setCreateAnnouncementSuccess,
  setDeleteAnnouncementFailed,
  setDeleteAnnouncementSuccess,
  setFetchAnnouncementFailed,
  setFetchAnnouncementsFailed,
  setReadAnnouncementFailed,
  setUpdateAnnouncementFailed,
  setUpdateAnnouncementSuccess,
} from "../../store/announcement/announcement.action";
import {
  getCreateAnnouncementFailed,
  getCreateAnnouncementSuccess,
  getDeleteAnnouncementFailed,
  getDeleteAnnouncementSuccess,
  getFetchAnnouncementFailed,
  getFetchAnnouncementsFailed,
  getReadAnnouncementFailed,
  getUpdateAnnouncementFailed,
  getUpdateAnnouncementSuccess,
} from "../../store/announcement/announcement.selector";

const AnnouncementPopup = () => {
  const dispatch = useDispatch();

  const createAnnouncementSuccess = useSelector(getCreateAnnouncementSuccess);
  const updateAnnouncementSuccess = useSelector(getUpdateAnnouncementSuccess);
  const deleteAnnouncementSuccess = useSelector(getDeleteAnnouncementSuccess);
  const fetchAnnouncementsFailed = useSelector(getFetchAnnouncementsFailed);
  const fetchAnnouncementFailed = useSelector(getFetchAnnouncementFailed);
  const createAnnouncementFailed = useSelector(getCreateAnnouncementFailed);
  const updateAnnouncementFailed = useSelector(getUpdateAnnouncementFailed);
  const deleteAnnouncementFailed = useSelector(getDeleteAnnouncementFailed);
  const readAnnouncementFailed = useSelector(getReadAnnouncementFailed);

  useEffect(() => {
    if (
      createAnnouncementSuccess !== null ||
      updateAnnouncementSuccess !== null ||
      deleteAnnouncementSuccess !== null
    ) {
      showSuccessMessage(createAnnouncementSuccess ?? updateAnnouncementSuccess ?? deleteAnnouncementSuccess);

      if (createAnnouncementSuccess !== null) dispatch(setCreateAnnouncementSuccess(null));
      if (updateAnnouncementSuccess !== null) dispatch(setUpdateAnnouncementSuccess(null));
      if (deleteAnnouncementSuccess !== null) dispatch(setDeleteAnnouncementSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAnnouncementSuccess, updateAnnouncementSuccess, deleteAnnouncementSuccess]);

  useEffect(() => {
    if (
      fetchAnnouncementsFailed !== null ||
      fetchAnnouncementFailed !== null ||
      createAnnouncementFailed !== null ||
      updateAnnouncementFailed !== null ||
      deleteAnnouncementFailed !== null ||
      readAnnouncementFailed !== null
    ) {
      showErrorMessage(
        fetchAnnouncementsFailed ??
          fetchAnnouncementFailed ??
          createAnnouncementFailed ??
          updateAnnouncementFailed ??
          deleteAnnouncementFailed ??
          readAnnouncementFailed
      );

      if (fetchAnnouncementsFailed !== null) dispatch(setFetchAnnouncementsFailed(null));
      if (fetchAnnouncementFailed !== null) dispatch(setFetchAnnouncementFailed(null));
      if (createAnnouncementFailed !== null) dispatch(setCreateAnnouncementFailed(null));
      if (updateAnnouncementFailed !== null) dispatch(setUpdateAnnouncementFailed(null));
      if (deleteAnnouncementFailed !== null) dispatch(setDeleteAnnouncementFailed(null));
      if (readAnnouncementFailed !== null) dispatch(setReadAnnouncementFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchAnnouncementsFailed,
    fetchAnnouncementFailed,
    createAnnouncementFailed,
    updateAnnouncementFailed,
    deleteAnnouncementFailed,
    readAnnouncementFailed,
  ]);

  return <></>;
};

export default AnnouncementPopup;
