import apiService from "./api";

export const getTopUps = async (params) =>
  (await apiService.innerGet({ urlPath: "/top-up", params })).data;
export const getTopUp = async (topUpId) =>
  (await apiService.innerGet({ urlPath: `/top-up/${topUpId}` })).data;
export const createTopUp = async (request) =>
  (await apiService.innerPost({ urlPath: "/top-up", request })).data;
export const updateTopUp = async (topUpId, request) =>
  (await apiService.innerPost({ urlPath: `/top-up/${topUpId}`, request })).data;
export const deleteTopUp = async (topUpId) =>
  (await apiService.innerDelete({ urlPath: `/top-up/${topUpId}` })).data;
