import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import { setFetchDashboardFailed } from "../../store/dashboard-subscriber/dashboard-subscriber.action";
import { getFetchDashboardFailed } from "../../store/dashboard-subscriber/dashboard-subscriber.selector";

const DashboardPopup = () => {
  const dispatch = useDispatch();

  const fetchDashboardFailed = useSelector(getFetchDashboardFailed);

  useEffect(() => {
    if (fetchDashboardFailed !== null) {
      showErrorMessage(fetchDashboardFailed);

      if (fetchDashboardFailed !== null)
        dispatch(setFetchDashboardFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDashboardFailed]);

  return <></>;
};

export default DashboardPopup;
