import { parseToFloat } from "../../utils/parser.utils";

import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";

import {
  ApplicationCompletionOverlayProgressBar,
  ApplicationCompletionOverlayProgressContainer,
  ApplicationCompletionOverlayProgressLabel,
} from "./application-completion-overlay-progress.style";

const ApplicationCompletionOverlayProgress = ({
  progressCount = 0,
  completeCount = 0,
  isLoading = false,
}) => {
  const percentage = parseToFloat((progressCount / completeCount) * 100);

  return (
    <ApplicationCompletionOverlayProgressContainer>
      {isLoading ? (
        <Shimmer radius={SHIMMER_RADIUS.PX12} width="100%" height="10px" />
      ) : (
        <ApplicationCompletionOverlayProgressBar percent={percentage} />
      )}
      {isLoading ? (
        <Shimmer
          radius={SHIMMER_RADIUS.PX12}
          width="54px"
          minWidth="54px"
          maxWidth="54px"
          height="22px"
        />
      ) : (
        <ApplicationCompletionOverlayProgressLabel>
          {`${percentage.toFixed(1)}%`}
        </ApplicationCompletionOverlayProgressLabel>
      )}
    </ApplicationCompletionOverlayProgressContainer>
  );
};

export default ApplicationCompletionOverlayProgress;
