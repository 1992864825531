import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  fetchGlobalFeatureSubscriptionsStart,
  setFetchGlobalFeatureSubscriptionsPage,
  setFetchGlobalFeatureSubscriptionsPerPage,
} from "../../store/global/global.action";
import {
  getFetchGlobalFeatureSubscriptionsLoading,
  getFetchGlobalFeatureSubscriptionsPage,
  getGlobalFeatureSubscriptions,
  getIsGlobalFeatureSubscriptionsHasMore,
} from "../../store/global/global.selector";

import Modal, {
  MODAL_POSITIONS,
  MODAL_SIZES,
} from "../../components/modal/modal.component";
import SubscriptionPlanTable from "../subscription-plan-table/subscription-plan-table.widget";

import { ReactComponent as CalendarSvg } from "../../assets/icons/stroke/Calendar.svg";

export const modalName = "SUBSCRIPTION_PLAN_DETAILS";

const SubscriptionPlanDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchFeaturesLoading = useSelector(
    getFetchGlobalFeatureSubscriptionsLoading
  );
  const fetchFeaturesPage = useSelector(getFetchGlobalFeatureSubscriptionsPage);
  const isFeaturesHasMore = useSelector(getIsGlobalFeatureSubscriptionsHasMore);
  const features = useSelector(getGlobalFeatureSubscriptions);

  const handleBottomScroll = () => {
    dispatch(setFetchGlobalFeatureSubscriptionsPage(fetchFeaturesPage + 1));
    dispatch(setFetchGlobalFeatureSubscriptionsPerPage(20));
    dispatch(fetchGlobalFeatureSubscriptionsStart());
  };

  return (
    <Modal
      modalPosition={MODAL_POSITIONS.CENTER}
      modalSize={MODAL_SIZES.EXTRA_LARGE}
      name={modalName}
      icon={<CalendarSvg />}
      title={t("Choose Your Plan")}
      description={t(
        "Find the plan that best fits your goals and take the next step."
      )}
    >
      <SubscriptionPlanTable
        features={features}
        page={fetchFeaturesPage}
        isLoading={fetchFeaturesLoading}
        isHasMore={isFeaturesHasMore}
        handleBottomScroll={handleBottomScroll}
      />
    </Modal>
  );
};

export default SubscriptionPlanDetails;
