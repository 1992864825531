import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setFetchReportAttendancesFailed,
  setFetchReportBranchTotalsFailed,
  setFetchReportCashierTotalsFailed,
  setFetchReportTotalsFailed,
} from "../../store/report-attendance/report-attendance.action";
import {
  getFetchReportAttendancesFailed,
  getFetchReportBranchTotalsFailed,
  getFetchReportCashierTotalsFailed,
  getFetchReportTotalsFailed,
} from "../../store/report-attendance/report-attendance.selector";

const ReportAttendancePopup = () => {
  const dispatch = useDispatch();

  const fetchReportTotalsFailed = useSelector(getFetchReportTotalsFailed);
  const fetchReportAttendancesFailed = useSelector(
    getFetchReportAttendancesFailed
  );
  const fetchReportCashierTotalsFailed = useSelector(
    getFetchReportCashierTotalsFailed
  );
  const fetchReportBranchTotalsFailed = useSelector(
    getFetchReportBranchTotalsFailed
  );

  useEffect(() => {
    if (
      fetchReportTotalsFailed !== null ||
      fetchReportAttendancesFailed !== null ||
      fetchReportCashierTotalsFailed !== null ||
      fetchReportBranchTotalsFailed !== null
    ) {
      showErrorMessage(
        fetchReportTotalsFailed ??
          fetchReportAttendancesFailed ??
          fetchReportCashierTotalsFailed ??
          fetchReportBranchTotalsFailed
      );

      if (fetchReportTotalsFailed !== null)
        dispatch(setFetchReportTotalsFailed(null));
      if (fetchReportAttendancesFailed !== null)
        dispatch(setFetchReportAttendancesFailed(null));
      if (fetchReportCashierTotalsFailed !== null)
        dispatch(setFetchReportCashierTotalsFailed(null));
      if (fetchReportBranchTotalsFailed !== null)
        dispatch(setFetchReportBranchTotalsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchReportTotalsFailed,
    fetchReportAttendancesFailed,
    fetchReportCashierTotalsFailed,
    fetchReportBranchTotalsFailed,
  ]);

  return <></>;
};

export default ReportAttendancePopup;
