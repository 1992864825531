import apiService from "./api";

export const getSectorCategories = async (sectorId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/sectors/${sectorId}/categories`,
      params,
    })
  ).data;
export const getSectorCategory = async (sectorId, categoryId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/sectors/${sectorId}/categories/${categoryId}`,
    })
  ).data;
