import apiService from "./api";

export const getDrivers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/drivers", params })).data;
export const getDriver = async (driverId) =>
  (await apiService.innerGet({ urlPath: `/v2/drivers/${driverId}` })).data;
export const createDriver = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/drivers", request })).data;
export const updateDriver = async (driverId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/drivers/${driverId}`, request }))
    .data;
