import styled from "styled-components";

export const EmptyStateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 3.5rem 1rem;
`;

export const EmptyStateTableIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;

  border: 2px solid #e9e9ee;
  border-radius: 1.25rem;
  margin-bottom: 0.75rem;

  svg {
    width: 1.75rem;
    min-width: 1.75rem;
    max-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
    max-height: 1.75rem;

    path {
      stroke-width: 2;

      &[fill] {
        fill: #0e072f;
      }
      &[stroke] {
        stroke: #0e072f;
      }
    }
  }
`;

export const EmptyStateTableTitle = styled.h5`
  font-size: 1rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin-bottom: 0.15rem;
`;

export const EmptyStateTableDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;
