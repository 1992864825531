import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCalculateCreateExtendPeriodFailed,
  setCalculateUpdateExtendPeriodFailed,
  setCreateExtendPeriodFailed,
  setCreateExtendPeriodSuccess,
  setCustomExtendPeriodFailed,
  setCustomExtendPeriodSuccess,
  setFetchExtendPeriodFailed,
  setFetchExtendPeriodsFailed,
  setFetchExtendPeriodsSuccess,
  setFetchExtendPeriodSuccess,
  setStopExtendPeriodFailed,
  setStopExtendPeriodSuccess,
  setUpdateExtendPeriodFailed,
  setUpdateExtendPeriodSuccess,
} from "../../store/extend-period/extend-period.action";
import {
  getCalculateCreateExtendPeriodFailed,
  getCalculateUpdateExtendPeriodFailed,
  getCreateExtendPeriodFailed,
  getCreateExtendPeriodSuccess,
  getCustomExtendPeriodFailed,
  getCustomExtendPeriodSuccess,
  getFetchExtendPeriodFailed,
  getFetchExtendPeriodsFailed,
  getFetchExtendPeriodsSuccess,
  getFetchExtendPeriodSuccess,
  getStopExtendPeriodFailed,
  getStopExtendPeriodSuccess,
  getUpdateExtendPeriodFailed,
  getUpdateExtendPeriodSuccess,
} from "../../store/extend-period/extend-period.selector";

const ExtendPeriodPopup = () => {
  const dispatch = useDispatch();

  const fetchExtendPeriodsSuccess = useSelector(getFetchExtendPeriodsSuccess);
  const fetchExtendPeriodSuccess = useSelector(getFetchExtendPeriodSuccess);
  const createExtendPeriodSuccess = useSelector(getCreateExtendPeriodSuccess);
  const updateExtendPeriodSuccess = useSelector(getUpdateExtendPeriodSuccess);
  const customExtendPeriodSuccess = useSelector(getCustomExtendPeriodSuccess);
  const stopExtendPeriodSuccess = useSelector(getStopExtendPeriodSuccess);

  const fetchExtendPeriodsFailed = useSelector(getFetchExtendPeriodsFailed);
  const fetchExtendPeriodFailed = useSelector(getFetchExtendPeriodFailed);
  const createExtendPeriodFailed = useSelector(getCreateExtendPeriodFailed);
  const updateExtendPeriodFailed = useSelector(getUpdateExtendPeriodFailed);
  const customExtendPeriodFailed = useSelector(getCustomExtendPeriodFailed);
  const stopExtendPeriodFailed = useSelector(getStopExtendPeriodFailed);
  const calculateCreateExtendPeriodFailed = useSelector(
    getCalculateCreateExtendPeriodFailed
  );
  const calculateUpdateExtendPeriodFailed = useSelector(
    getCalculateUpdateExtendPeriodFailed
  );

  useEffect(() => {
    if (
      fetchExtendPeriodsSuccess !== null ||
      fetchExtendPeriodSuccess !== null ||
      createExtendPeriodSuccess !== null ||
      updateExtendPeriodSuccess !== null ||
      customExtendPeriodSuccess !== null ||
      stopExtendPeriodSuccess !== null
    ) {
      if (
        createExtendPeriodSuccess !== null ||
        updateExtendPeriodSuccess !== null ||
        customExtendPeriodSuccess !== null ||
        stopExtendPeriodSuccess !== null
      ) {
        showSuccessMessage(
          createExtendPeriodSuccess ??
            updateExtendPeriodSuccess ??
            customExtendPeriodSuccess ??
            stopExtendPeriodSuccess
        );
      }
      dispatch(setIsModalOpen(false));

      if (fetchExtendPeriodsSuccess !== null)
        dispatch(setFetchExtendPeriodsSuccess(null));
      if (fetchExtendPeriodSuccess !== null)
        dispatch(setFetchExtendPeriodSuccess(null));
      if (createExtendPeriodSuccess !== null)
        dispatch(setCreateExtendPeriodSuccess(null));
      if (updateExtendPeriodSuccess !== null)
        dispatch(setUpdateExtendPeriodSuccess(null));
      if (customExtendPeriodSuccess !== null)
        dispatch(setCustomExtendPeriodSuccess(null));
      if (stopExtendPeriodSuccess !== null)
        dispatch(setStopExtendPeriodSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchExtendPeriodsSuccess,
    fetchExtendPeriodSuccess,
    createExtendPeriodSuccess,
    updateExtendPeriodSuccess,
    customExtendPeriodSuccess,
    stopExtendPeriodSuccess,
  ]);

  useEffect(() => {
    if (
      fetchExtendPeriodsFailed !== null ||
      fetchExtendPeriodFailed !== null ||
      calculateCreateExtendPeriodFailed !== null ||
      calculateUpdateExtendPeriodFailed !== null ||
      createExtendPeriodFailed !== null ||
      updateExtendPeriodFailed !== null ||
      customExtendPeriodFailed !== null ||
      stopExtendPeriodFailed !== null
    ) {
      showErrorMessage(
        fetchExtendPeriodsFailed ??
          fetchExtendPeriodFailed ??
          calculateCreateExtendPeriodFailed ??
          calculateUpdateExtendPeriodFailed ??
          createExtendPeriodFailed ??
          updateExtendPeriodFailed ??
          customExtendPeriodFailed ??
          stopExtendPeriodFailed
      );

      if (fetchExtendPeriodsFailed !== null)
        dispatch(setFetchExtendPeriodsFailed(null));
      if (fetchExtendPeriodFailed !== null)
        dispatch(setFetchExtendPeriodFailed(null));
      if (calculateCreateExtendPeriodFailed !== null)
        dispatch(setCalculateCreateExtendPeriodFailed(null));
      if (calculateUpdateExtendPeriodFailed !== null)
        dispatch(setCalculateUpdateExtendPeriodFailed(null));
      if (createExtendPeriodFailed !== null)
        dispatch(setCreateExtendPeriodFailed(null));
      if (updateExtendPeriodFailed !== null)
        dispatch(setUpdateExtendPeriodFailed(null));
      if (customExtendPeriodFailed !== null)
        dispatch(setCustomExtendPeriodFailed(null));
      if (stopExtendPeriodFailed !== null)
        dispatch(setStopExtendPeriodFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchExtendPeriodsFailed,
    fetchExtendPeriodFailed,
    calculateCreateExtendPeriodFailed,
    calculateUpdateExtendPeriodFailed,
    createExtendPeriodFailed,
    updateExtendPeriodFailed,
    customExtendPeriodFailed,
    stopExtendPeriodFailed,
  ]);

  return <></>;
};

export default ExtendPeriodPopup;
