import apiService from "./api";

export const getGateway = async (gatewayId) =>
  (await apiService.innerGet({ urlPath: `/v2/gateways/${gatewayId}` })).data;
export const createGateway = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/gateways", request })).data;
export const checkoutGateway = async (gatewayId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/gateways/${gatewayId}/checkout`,
      request,
    })
  ).data;
