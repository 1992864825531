import { createAction } from "../../utils/store.utils";

import BANNER_ACTION_TYPES from "./banner.type";

export const setBanners = (banners) =>
  createAction(BANNER_ACTION_TYPES.SET_BANNERS, banners);
export const setBanner = (banner) =>
  createAction(BANNER_ACTION_TYPES.SET_BANNER, banner);

export const setIsBannersHasMore = (isBannersHasMore) =>
  createAction(BANNER_ACTION_TYPES.SET_IS_BANNERS_HAS_MORE, isBannersHasMore);

export const setFetchBannersSearch = (fetchBannersSearch) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNERS_SEARCH,
    fetchBannersSearch
  );
export const setFetchBannersSort = (fetchBannersSort) =>
  createAction(BANNER_ACTION_TYPES.SET_FETCH_BANNERS_SORT, fetchBannersSort);
export const setFetchBannersKeyBy = (fetchBannersKeyBy) =>
  createAction(BANNER_ACTION_TYPES.SET_FETCH_BANNERS_KEY_BY, fetchBannersKeyBy);
export const setFetchBannersPage = (fetchBannersPage) =>
  createAction(BANNER_ACTION_TYPES.SET_FETCH_BANNERS_PAGE, fetchBannersPage);
export const setFetchBannersPerPage = (fetchBannersPerPage) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNERS_PER_PAGE,
    fetchBannersPerPage
  );
export const setFetchBannersIncludes = (fetchBannersIncludes) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNERS_PER_PAGE,
    fetchBannersIncludes
  );
export const setFetchBannersLoading = (fetchBannersLoading) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNERS_LOADING,
    fetchBannersLoading
  );
export const setFetchBannersSuccess = (fetchBannersSuccess) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNERS_SUCCESS,
    fetchBannersSuccess
  );
export const setFetchBannersFailed = (fetchBannersFailed) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNERS_FAILED,
    fetchBannersFailed
  );

export const setFetchBannerLoading = (fetchBannerLoading) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNER_LOADING,
    fetchBannerLoading
  );
export const setFetchBannerSuccess = (fetchBannerSuccess) =>
  createAction(
    BANNER_ACTION_TYPES.SET_FETCH_BANNER_SUCCESS,
    fetchBannerSuccess
  );
export const setFetchBannerFailed = (fetchBannerFailed) =>
  createAction(BANNER_ACTION_TYPES.SET_FETCH_BANNER_FAILED, fetchBannerFailed);

export const setCreateBannerLoading = (createBannerLoading) =>
  createAction(
    BANNER_ACTION_TYPES.SET_CREATE_BANNER_LOADING,
    createBannerLoading
  );
export const setCreateBannerSuccess = (createBannerSuccess) =>
  createAction(
    BANNER_ACTION_TYPES.SET_CREATE_BANNER_SUCCESS,
    createBannerSuccess
  );
export const setCreateBannerFailed = (createBannerFailed) =>
  createAction(
    BANNER_ACTION_TYPES.SET_CREATE_BANNER_FAILED,
    createBannerFailed
  );

export const setUpdateBannerLoading = (updateBannerLoading) =>
  createAction(
    BANNER_ACTION_TYPES.SET_UPDATE_BANNER_LOADING,
    updateBannerLoading
  );
export const setUpdateBannerSuccess = (updateBannerSuccess) =>
  createAction(
    BANNER_ACTION_TYPES.SET_UPDATE_BANNER_SUCCESS,
    updateBannerSuccess
  );
export const setUpdateBannerFailed = (updateBannerFailed) =>
  createAction(
    BANNER_ACTION_TYPES.SET_UPDATE_BANNER_FAILED,
    updateBannerFailed
  );

export const setDeleteBannerLoading = (deleteBannerLoading) =>
  createAction(
    BANNER_ACTION_TYPES.SET_DELETE_BANNER_LOADING,
    deleteBannerLoading
  );
export const setDeleteBannerSuccess = (deleteBannerSuccess) =>
  createAction(
    BANNER_ACTION_TYPES.SET_DELETE_BANNER_SUCCESS,
    deleteBannerSuccess
  );
export const setDeleteBannerFailed = (deleteBannerFailed) =>
  createAction(
    BANNER_ACTION_TYPES.SET_DELETE_BANNER_FAILED,
    deleteBannerFailed
  );

export const appendBanners = (banners) =>
  createAction(BANNER_ACTION_TYPES.APPEND_BANNERS, banners);

export const setIsFetchBannersHitted = (isFetchBannersHitted) =>
  createAction(
    BANNER_ACTION_TYPES.SET_IS_FETCH_BANNERS_HITTED,
    isFetchBannersHitted
  );
export const setIsFetchBannerHitted = (isFetchBannerHitted) =>
  createAction(
    BANNER_ACTION_TYPES.SET_IS_FETCH_BANNER_HITTED,
    isFetchBannerHitted
  );
export const setIsCreateBannerHitted = (isCreateBannerHitted) =>
  createAction(
    BANNER_ACTION_TYPES.SET_IS_CREATE_BANNER_HITTED,
    isCreateBannerHitted
  );
export const setIsUpdateBannerHitted = (isUpdateBannerHitted) =>
  createAction(
    BANNER_ACTION_TYPES.SET_IS_UPDATE_BANNER_HITTED,
    isUpdateBannerHitted
  );
export const setIsDeleteBannerHitted = (isDeleteBannerHitted) =>
  createAction(
    BANNER_ACTION_TYPES.SET_IS_DELETE_BANNER_HITTED,
    isDeleteBannerHitted
  );

export const fetchBannersStart = () =>
  createAction(BANNER_ACTION_TYPES.FETCH_BANNERS_START);
export const fetchBannerStart = (bannerId) =>
  createAction(BANNER_ACTION_TYPES.FETCH_BANNER_START, bannerId);
export const createBannerStart = (request) =>
  createAction(BANNER_ACTION_TYPES.CREATE_BANNER_START, request);
export const updateBannerStart = (bannerId, request) =>
  createAction(BANNER_ACTION_TYPES.UPDATE_BANNER_START, {
    bannerId,
    request,
  });
export const deleteBannerStart = (bannerId) =>
  createAction(BANNER_ACTION_TYPES.DELETE_BANNER_START, bannerId);

export const resetBannerReducer = () =>
  createAction(BANNER_ACTION_TYPES.RESET_BANNER_REDUCER);
