import { createSelector } from "reselect";

const selectMarketSelector = ({ selectMarket }) => selectMarket;

export const getMarkets = createSelector(
  [selectMarketSelector],
  ({ markets }) => markets
);
export const getSearchMarkets = createSelector(
  [selectMarketSelector],
  ({ searchMarkets }) => searchMarkets
);

export const getIsMarketsHasMore = createSelector(
  [selectMarketSelector],
  ({ isMarketsHasMore }) => isMarketsHasMore
);
export const getIsSearchMarketsHasMore = createSelector(
  [selectMarketSelector],
  ({ isSearchMarketsHasMore }) => isSearchMarketsHasMore
);

export const getFetchMarketsParams = createSelector(
  [selectMarketSelector],
  ({ fetchMarketsParams }) => fetchMarketsParams
);
export const getFetchMarketsLoading = createSelector(
  [selectMarketSelector],
  ({ fetchMarketsLoading }) => fetchMarketsLoading
);
export const getFetchMarketsSuccess = createSelector(
  [selectMarketSelector],
  ({ fetchMarketsSuccess }) => fetchMarketsSuccess
);
export const getFetchMarketsFailed = createSelector(
  [selectMarketSelector],
  ({ fetchMarketsFailed }) => fetchMarketsFailed
);

export const getSearchMarketsParams = createSelector(
  [selectMarketSelector],
  ({ searchMarketsParams }) => searchMarketsParams
);
export const getSearchMarketsLoading = createSelector(
  [selectMarketSelector],
  ({ searchMarketsLoading }) => searchMarketsLoading
);
export const getSearchMarketsSuccess = createSelector(
  [selectMarketSelector],
  ({ searchMarketsSuccess }) => searchMarketsSuccess
);
export const getSearchMarketsFailed = createSelector(
  [selectMarketSelector],
  ({ searchMarketsFailed }) => searchMarketsFailed
);

export const getAppendMarketsParams = createSelector(
  [selectMarketSelector],
  ({ appendMarketsParams }) => appendMarketsParams
);
export const getAppendMarketsLoading = createSelector(
  [selectMarketSelector],
  ({ appendMarketsLoading }) => appendMarketsLoading
);
export const getAppendMarketsSuccess = createSelector(
  [selectMarketSelector],
  ({ appendMarketsSuccess }) => appendMarketsSuccess
);
export const getAppendMarketsFailed = createSelector(
  [selectMarketSelector],
  ({ appendMarketsFailed }) => appendMarketsFailed
);

export const getIsFetchMarketsHitted = createSelector(
  [selectMarketSelector],
  ({ isFetchMarketsHitted }) => isFetchMarketsHitted
);
export const getIsSearchMarketsHitted = createSelector(
  [selectMarketSelector],
  ({ isSearchMarketsHitted }) => isSearchMarketsHitted
);
export const getIsAppendMarketsHitted = createSelector(
  [selectMarketSelector],
  ({ isAppendMarketsHitted }) => isAppendMarketsHitted
);
