import styled from "styled-components";

export const FormSlugInstructionLabel = styled.p`
  font-size: 1.15rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin-bottom: 0.25rem;
`;

export const FormSlugInstructionTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const FormSlugInstructionList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.25rem;
  list-style-type: none;
  margin-bottom: 0.75rem;
`;

export const FormSlugInstructionItem = styled.li`
  text-indent: -1em;
  font-size: 1rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;

  &::before {
    content: "";
    display: inline-block;

    width: 0.275rem;
    min-width: 0.275rem;
    max-width: 0.275rem;
    height: 0.275rem;
    min-height: 0.275rem;
    max-height: 0.275rem;

    background-color: #9691ac;
    margin-bottom: 2px;
  }
`;

export const FormSlugInstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.5rem;

  padding: 1.25rem;
  border-radius: 1rem;
  background-color: #f9f9f9;
  border: 1.5px solid #dfdcef;

  ${FormSlugInstructionList} {
    margin-left: ${({ isRightToLeft }) => (!isRightToLeft ? 0 : "unset")};
    padding-left: ${({ isRightToLeft }) => (!isRightToLeft ? 0 : "unset")};
    margin-right: ${({ isRightToLeft }) => (isRightToLeft ? 0 : "unset")};
    padding-right: ${({ isRightToLeft }) => (isRightToLeft ? 0 : "unset")};
  }
  ${FormSlugInstructionItem} {
    padding-left: ${({ isRightToLeft }) => (!isRightToLeft ? "1em" : "unset")};
    padding-right: ${({ isRightToLeft }) => (isRightToLeft ? "1em" : "unset")};

    &::before {
      margin-right: ${({ isRightToLeft }) =>
        isRightToLeft ? "unset" : "0.75rem"};
      margin-left: ${({ isRightToLeft }) =>
        !isRightToLeft ? "unset" : "0.75rem"};
    }
  }
`;
