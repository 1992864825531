import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendSuppliersFailed,
  setFetchSuppliersFailed,
  setSearchSuppliersFailed,
} from "../../store/select-supplier/select-supplier.action";
import {
  getAppendSuppliersFailed,
  getFetchSuppliersFailed,
  getSearchSuppliersFailed,
} from "../../store/select-supplier/select-supplier.selector";

const SelectSupplierPopup = () => {
  const dispatch = useDispatch();

  const fetchSuppliersFailed = useSelector(getFetchSuppliersFailed);
  const searchSuppliersFailed = useSelector(getSearchSuppliersFailed);
  const appendSuppliersFailed = useSelector(getAppendSuppliersFailed);

  useEffect(() => {
    if (
      fetchSuppliersFailed !== null ||
      searchSuppliersFailed !== null ||
      appendSuppliersFailed !== null
    ) {
      showErrorMessage(
        fetchSuppliersFailed ?? searchSuppliersFailed ?? appendSuppliersFailed
      );

      if (fetchSuppliersFailed !== null)
        dispatch(setFetchSuppliersFailed(null));
      if (searchSuppliersFailed !== null)
        dispatch(setSearchSuppliersFailed(null));
      if (appendSuppliersFailed !== null)
        dispatch(setAppendSuppliersFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSuppliersFailed, searchSuppliersFailed, appendSuppliersFailed]);

  return <></>;
};

export default SelectSupplierPopup;
