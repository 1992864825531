import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendCashiersFailed,
  setFetchCashiersFailed,
  setSearchCashiersFailed,
} from "../../store/select-cashier/select-cashier.action";
import {
  getAppendCashiersFailed,
  getFetchCashiersFailed,
  getSearchCashiersFailed,
} from "../../store/select-cashier/select-cashier.selector";

const SelectCashierPopup = () => {
  const dispatch = useDispatch();

  const fetchCashiersFailed = useSelector(getFetchCashiersFailed);
  const searchCashiersFailed = useSelector(getSearchCashiersFailed);
  const appendCashiersFailed = useSelector(getAppendCashiersFailed);

  useEffect(() => {
    if (
      fetchCashiersFailed !== null ||
      searchCashiersFailed !== null ||
      appendCashiersFailed !== null
    ) {
      showErrorMessage(
        fetchCashiersFailed ?? searchCashiersFailed ?? appendCashiersFailed
      );

      if (fetchCashiersFailed !== null) dispatch(setFetchCashiersFailed(null));
      if (searchCashiersFailed !== null)
        dispatch(setSearchCashiersFailed(null));
      if (appendCashiersFailed !== null)
        dispatch(setAppendCashiersFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCashiersFailed, searchCashiersFailed, appendCashiersFailed]);

  return <></>;
};

export default SelectCashierPopup;
