import apiService from "./api";

export const getSummaryFinancialTotal = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/financial-total", params }))
    .data;
export const getSummaryFinancialChart = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/financial-chart", params }))
    .data;
export const getSummaryTopProducts = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/top-product", params })).data;
export const getSummaryTopProductCategories = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/summary/top-product-category",
      params,
    })
  ).data;
export const getSummaryLatestOrders = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/latest-order", params }))
    .data;
export const getSummaryLatestRatings = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/latest-rating", params }))
    .data;
export const getSummarySubscriberReport = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/subscriber-report", params }))
    .data;
export const getSummaryPaymentMethods = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/payment-method", params }))
    .data;
export const getSummarySubscribers = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/subscriber", params })).data;
export const getSummaryBranches = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/branch", params })).data;
export const getSummaryTotalUser = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/total/user", params })).data;
export const getSummaryTotalMarket = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/total/market", params }))
    .data;
export const getSummaryTotalBranch = async (params) =>
  (await apiService.innerGet({ urlPath: "/summary/total/branch", params }))
    .data;
