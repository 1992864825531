import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setFetchDashboardSummaryFinancialTotalFailed,
  setFetchReportSummaryFinancialTotalFailed,
  setFetchSummaryFinancialChartFailed,
  setFetchSummaryLatestOrdersFailed,
  setFetchSummaryTopProductCategoriesFailed,
  setFetchDashboardSummaryTopProductsFailed,
  setFetchReportSummaryTopProductsFailed,
  setFetchSummaryPaymentMethodsFailed,
  setFetchSummarySubscribersFailed,
  setFetchDashboardSummaryFinancialAllTimeTotalFailed,
  setFetchReportSummaryTotalUserFailed,
  setFetchReportSummaryTotalMarketFailed,
  setFetchReportSummaryTotalBranchFailed,
  setFetchSummaryBranchesFailed,
  setFetchAccountSummaryFinancialTotalFailed,
  setFetchSummarySubscriberReportFailed,
  setFetchSummarySubscriberReportBranchFailed,
  setFetchDashboardSummaryTotalUserFailed,
  setFetchDashboardSummaryTotalMarketFailed,
  setFetchDashboardSummaryTotalBranchFailed,
} from "../../store/summary/summary.action";
import {
  getFetchAccountSummaryFinancialTotalFailed,
  getFetchDashboardSummaryFinancialAllTimeTotalFailed,
  getFetchDashboardSummaryFinancialTotalFailed,
  getFetchDashboardSummaryTopProductsFailed,
  getFetchDashboardSummaryTotalBranchFailed,
  getFetchDashboardSummaryTotalMarketFailed,
  getFetchDashboardSummaryTotalUserFailed,
  getFetchReportSummaryFinancialTotalFailed,
  getFetchReportSummaryTopProductsFailed,
  getFetchReportSummaryTotalBranchFailed,
  getFetchReportSummaryTotalMarketFailed,
  getFetchReportSummaryTotalUserFailed,
  getFetchSummaryBranchesFailed,
  getFetchSummaryFinancialChartFailed,
  getFetchSummaryLatestOrdersFailed,
  getFetchSummaryPaymentMethodsFailed,
  getFetchSummarySubscriberReportBranchFailed,
  getFetchSummarySubscriberReportFailed,
  getFetchSummarySubscribersFailed,
  getFetchSummaryTopProductCategoriesFailed,
} from "../../store/summary/summary.selector";

const SummaryPopup = () => {
  const dispatch = useDispatch();

  const fetchDashboardSummaryFinancialTotalFailed = useSelector(getFetchDashboardSummaryFinancialTotalFailed);
  const fetchDashboardSummaryFinancialAllTimeTotalFailed = useSelector(
    getFetchDashboardSummaryFinancialAllTimeTotalFailed
  );
  const fetchReportSummaryFinancialTotalFailed = useSelector(getFetchReportSummaryFinancialTotalFailed);
  const fetchSummaryFinancialChartFailed = useSelector(getFetchSummaryFinancialChartFailed);
  const fetchDashboardSummaryTopProductsFailed = useSelector(getFetchDashboardSummaryTopProductsFailed);
  const fetchReportSummaryTopProductsFailed = useSelector(getFetchReportSummaryTopProductsFailed);
  const fetchSummaryTopProductCategoriesFailed = useSelector(getFetchSummaryTopProductCategoriesFailed);
  const fetchSummaryPaymentMethodsFailed = useSelector(getFetchSummaryPaymentMethodsFailed);
  const fetchSummarySubscribersFailed = useSelector(getFetchSummarySubscribersFailed);
  const fetchSummaryBranchesFailed = useSelector(getFetchSummaryBranchesFailed);
  const fetchSummaryLatestOrdersFailed = useSelector(getFetchSummaryLatestOrdersFailed);
  const fetchReportSummaryTotalUserFailed = useSelector(getFetchReportSummaryTotalUserFailed);
  const fetchReportSummaryTotalMarketFailed = useSelector(getFetchReportSummaryTotalMarketFailed);
  const fetchReportSummaryTotalBranchFailed = useSelector(getFetchReportSummaryTotalBranchFailed);
  const fetchAccountSummaryFinancialTotalFailed = useSelector(getFetchAccountSummaryFinancialTotalFailed);
  const fetchSummarySubscriberReportFailed = useSelector(getFetchSummarySubscriberReportFailed);
  const fetchSummarySubscriberReportBranchFailed = useSelector(getFetchSummarySubscriberReportBranchFailed);
  const fetchDashboardSummaryTotalUserFailed = useSelector(getFetchDashboardSummaryTotalUserFailed);
  const fetchDashboardSummaryTotalMarketFailed = useSelector(getFetchDashboardSummaryTotalMarketFailed);
  const fetchDashboardSummaryTotalBranchFailed = useSelector(getFetchDashboardSummaryTotalBranchFailed);

  useEffect(() => {
    if (
      fetchDashboardSummaryFinancialTotalFailed !== null ||
      fetchDashboardSummaryFinancialAllTimeTotalFailed !== null ||
      fetchReportSummaryFinancialTotalFailed !== null ||
      fetchSummaryFinancialChartFailed !== null ||
      fetchDashboardSummaryTopProductsFailed !== null ||
      fetchReportSummaryTopProductsFailed !== null ||
      fetchSummaryTopProductCategoriesFailed !== null ||
      fetchSummaryPaymentMethodsFailed !== null ||
      fetchSummarySubscribersFailed !== null ||
      fetchSummaryBranchesFailed !== null ||
      fetchSummaryLatestOrdersFailed !== null ||
      fetchReportSummaryTotalUserFailed !== null ||
      fetchReportSummaryTotalMarketFailed !== null ||
      fetchReportSummaryTotalBranchFailed !== null ||
      fetchAccountSummaryFinancialTotalFailed !== null ||
      fetchSummarySubscriberReportFailed !== null ||
      fetchSummarySubscriberReportBranchFailed !== null ||
      fetchDashboardSummaryTotalUserFailed !== null ||
      fetchDashboardSummaryTotalMarketFailed !== null ||
      fetchDashboardSummaryTotalBranchFailed !== null
    ) {
      showErrorMessage(
        fetchDashboardSummaryFinancialTotalFailed ??
          fetchDashboardSummaryFinancialAllTimeTotalFailed ??
          fetchReportSummaryFinancialTotalFailed ??
          fetchSummaryFinancialChartFailed ??
          fetchDashboardSummaryTopProductsFailed ??
          fetchReportSummaryTopProductsFailed ??
          fetchSummaryTopProductCategoriesFailed ??
          fetchSummaryPaymentMethodsFailed ??
          fetchSummarySubscribersFailed ??
          fetchSummaryBranchesFailed ??
          fetchSummaryLatestOrdersFailed ??
          fetchReportSummaryTotalUserFailed ??
          fetchReportSummaryTotalMarketFailed ??
          fetchReportSummaryTotalBranchFailed ??
          fetchAccountSummaryFinancialTotalFailed ??
          fetchSummarySubscriberReportFailed ??
          fetchSummarySubscriberReportBranchFailed ??
          fetchDashboardSummaryTotalUserFailed ??
          fetchDashboardSummaryTotalMarketFailed ??
          fetchDashboardSummaryTotalBranchFailed
      );

      if (fetchDashboardSummaryFinancialTotalFailed !== null)
        dispatch(setFetchDashboardSummaryFinancialTotalFailed(null));
      if (fetchDashboardSummaryFinancialAllTimeTotalFailed !== null)
        dispatch(setFetchDashboardSummaryFinancialAllTimeTotalFailed(null));
      if (fetchReportSummaryFinancialTotalFailed !== null) dispatch(setFetchReportSummaryFinancialTotalFailed(null));
      if (fetchSummaryFinancialChartFailed !== null) dispatch(setFetchSummaryFinancialChartFailed(null));
      if (fetchDashboardSummaryTopProductsFailed !== null) dispatch(setFetchDashboardSummaryTopProductsFailed(null));
      if (fetchReportSummaryTopProductsFailed !== null) dispatch(setFetchReportSummaryTopProductsFailed(null));
      if (fetchSummaryTopProductCategoriesFailed !== null) dispatch(setFetchSummaryTopProductCategoriesFailed(null));
      if (fetchSummaryPaymentMethodsFailed !== null) dispatch(setFetchSummaryPaymentMethodsFailed(null));
      if (fetchSummarySubscribersFailed !== null) dispatch(setFetchSummarySubscribersFailed(null));
      if (fetchSummaryBranchesFailed !== null) dispatch(setFetchSummaryBranchesFailed(null));
      if (fetchSummaryLatestOrdersFailed !== null) dispatch(setFetchSummaryLatestOrdersFailed(null));
      if (fetchReportSummaryTotalUserFailed !== null) dispatch(setFetchReportSummaryTotalUserFailed(null));
      if (fetchReportSummaryTotalMarketFailed !== null) dispatch(setFetchReportSummaryTotalMarketFailed(null));
      if (fetchReportSummaryTotalBranchFailed !== null) dispatch(setFetchReportSummaryTotalBranchFailed(null));
      if (fetchAccountSummaryFinancialTotalFailed !== null) dispatch(setFetchAccountSummaryFinancialTotalFailed(null));
      if (fetchSummarySubscriberReportFailed !== null) dispatch(setFetchSummarySubscriberReportFailed(null));
      if (fetchSummarySubscriberReportBranchFailed !== null)
        dispatch(setFetchSummarySubscriberReportBranchFailed(null));
      if (fetchDashboardSummaryTotalUserFailed !== null) dispatch(setFetchDashboardSummaryTotalUserFailed(null));
      if (fetchDashboardSummaryTotalMarketFailed !== null) dispatch(setFetchDashboardSummaryTotalMarketFailed(null));
      if (fetchDashboardSummaryTotalBranchFailed !== null) dispatch(setFetchDashboardSummaryTotalBranchFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchDashboardSummaryFinancialTotalFailed,
    fetchDashboardSummaryFinancialAllTimeTotalFailed,
    fetchReportSummaryFinancialTotalFailed,
    fetchSummaryFinancialChartFailed,
    fetchDashboardSummaryTopProductsFailed,
    fetchReportSummaryTopProductsFailed,
    fetchSummaryTopProductCategoriesFailed,
    fetchSummaryPaymentMethodsFailed,
    fetchSummarySubscribersFailed,
    fetchSummaryBranchesFailed,
    fetchSummaryLatestOrdersFailed,
    fetchReportSummaryTotalUserFailed,
    fetchReportSummaryTotalMarketFailed,
    fetchReportSummaryTotalBranchFailed,
    fetchAccountSummaryFinancialTotalFailed,
    fetchSummarySubscriberReportFailed,
    fetchSummarySubscriberReportBranchFailed,
    fetchDashboardSummaryTotalUserFailed,
    fetchDashboardSummaryTotalMarketFailed,
    fetchDashboardSummaryTotalBranchFailed,
  ]);

  return <></>;
};

export default SummaryPopup;
