import { createSelector } from "reselect";

const selectBranchSelector = ({ selectBranch }) => selectBranch;

export const getBranches = createSelector(
  [selectBranchSelector],
  ({ branches }) => branches
);
export const getSearchBranches = createSelector(
  [selectBranchSelector],
  ({ searchBranches }) => searchBranches
);

export const getIsBranchesHasMore = createSelector(
  [selectBranchSelector],
  ({ isBranchesHasMore }) => isBranchesHasMore
);
export const getIsSearchBranchesHasMore = createSelector(
  [selectBranchSelector],
  ({ isSearchBranchesHasMore }) => isSearchBranchesHasMore
);

export const getFetchBranchesParams = createSelector(
  [selectBranchSelector],
  ({ fetchBranchesParams }) => fetchBranchesParams
);
export const getFetchBranchesLoading = createSelector(
  [selectBranchSelector],
  ({ fetchBranchesLoading }) => fetchBranchesLoading
);
export const getFetchBranchesSuccess = createSelector(
  [selectBranchSelector],
  ({ fetchBranchesSuccess }) => fetchBranchesSuccess
);
export const getFetchBranchesFailed = createSelector(
  [selectBranchSelector],
  ({ fetchBranchesFailed }) => fetchBranchesFailed
);

export const getSearchBranchesParams = createSelector(
  [selectBranchSelector],
  ({ searchBranchesParams }) => searchBranchesParams
);
export const getSearchBranchesLoading = createSelector(
  [selectBranchSelector],
  ({ searchBranchesLoading }) => searchBranchesLoading
);
export const getSearchBranchesSuccess = createSelector(
  [selectBranchSelector],
  ({ searchBranchesSuccess }) => searchBranchesSuccess
);
export const getSearchBranchesFailed = createSelector(
  [selectBranchSelector],
  ({ searchBranchesFailed }) => searchBranchesFailed
);

export const getAppendBranchesParams = createSelector(
  [selectBranchSelector],
  ({ appendBranchesParams }) => appendBranchesParams
);
export const getAppendBranchesLoading = createSelector(
  [selectBranchSelector],
  ({ appendBranchesLoading }) => appendBranchesLoading
);
export const getAppendBranchesSuccess = createSelector(
  [selectBranchSelector],
  ({ appendBranchesSuccess }) => appendBranchesSuccess
);
export const getAppendBranchesFailed = createSelector(
  [selectBranchSelector],
  ({ appendBranchesFailed }) => appendBranchesFailed
);

export const getIsFetchBranchesHitted = createSelector(
  [selectBranchSelector],
  ({ isFetchBranchesHitted }) => isFetchBranchesHitted
);
export const getIsSearchBranchesHitted = createSelector(
  [selectBranchSelector],
  ({ isSearchBranchesHitted }) => isSearchBranchesHitted
);
export const getIsAppendBranchesHitted = createSelector(
  [selectBranchSelector],
  ({ isAppendBranchesHitted }) => isAppendBranchesHitted
);
