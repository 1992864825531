import ReactGA from "react-ga4";
import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "moment/locale/ar";

import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "./config";

import { lazyRetry } from "./utils/common.utils";
import { useResetReducer } from "./hooks/reducer.hook";

import {
  getSignInSuccess,
  getSignOutSuccess,
  getSignUpSuccess,
} from "./store/authentication/authentication.selector";
import { setIsHasThanksPage } from "./store/component/component.action";

import Global from "./widgets/global/global.widget";
import Loading from "./routes/loading/loading.route";
import ProtectedRoutes from "./routes/protected-routes.route";
import AuthenticationLayout from "./layouts/authentication-layout-v2/authentication-layout.layout";

const AuthenticationEmailVerification = lazy(() =>
  lazyRetry(() =>
    import(
      "./routes/authentication-email-verification/authentication-email-verification.route"
    )
  )
);
const IndexRedirect = lazy(() =>
  lazyRetry(() => import("./routes/index-redirect/index-redirect.route"))
);
const PaymentCheckout = lazy(() =>
  lazyRetry(() => import("./routes/payment-checkout/payment-checkout.route"))
);
const PaymentRedirect = lazy(() =>
  lazyRetry(() => import("./routes/payment-redirect/payment-redirect.route"))
);
const ShortUrl = lazy(() =>
  lazyRetry(() => import("./routes/short-url/short-url.route"))
);
const SignIn = lazy(() =>
  lazyRetry(() => import("./routes/sign-in/sign-in.route"))
);
const SignUp = lazy(() =>
  lazyRetry(() => import("./routes/sign-up/sign-up.route"))
);

ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResetReducer = useResetReducer();

  const signInSuccess = useSelector(getSignInSuccess);
  const signUpSuccess = useSelector(getSignUpSuccess);
  const signOutSuccess = useSelector(getSignOutSuccess);

  useEffect(() => {
    if (signInSuccess !== null || signOutSuccess !== null) {
      navigate("/");
    } else if (signUpSuccess !== null) {
      dispatch(setIsHasThanksPage(true));
      navigate("/thanks");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInSuccess, signUpSuccess, signOutSuccess]);

  useEffect(() => {
    if (signOutSuccess !== null) handleResetReducer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOutSuccess]);

  return (
    <>
      <Global />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route index element={<IndexRedirect />} />
          <Route path="/short/:shortUrlKey" element={<ShortUrl />} />
          <Route
            path="/verification/verify"
            element={<AuthenticationEmailVerification />}
          />
          <Route
            path="/payment/checkout/:gatewayId"
            element={<PaymentCheckout />}
          />
          <Route
            path="/payment/redirect/:gatewayId"
            element={<PaymentRedirect />}
          />
          <Route element={<AuthenticationLayout />}>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
          </Route>
          <Route path="/*" element={<ProtectedRoutes />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
