import styled from "styled-components";

export const NotificationItemContainer = styled.div`
  position: relative;
`;

export const NotificationItemTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: #0e072f;
  margin-bottom: 0.75rem;
`;

export const NotificationItemDescription = styled.div`
  color: #342c4c;
  font-size: 600;
  line-height: 1.75;

  ol,
  ul {
    margin-left: 1rem;

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;
