export const COLORS = {
  PRIMARY: "#2900ee",
  PRIMARY_HOVER: "#2a04e5",
  SUCCESS: "#008d0e",
  SUCCESS_HOVER: "#00860e",
  DANGER: "#f02c2c",
  DANGER_HOVER: "#e72e2e",
  WARNING: "#fc9114",
  WARNING_HOVER: "#f18d17",
  SECONDARY: "#e9e9ee",
  SECONDARY_HOVER: "#e0e0e0",
  WHITE: "#ffffff",
  WHITE_HOVER: "#f9f9f9",
  LIGHT: "#f2f2f4",
  LIGHT_HOVER: "#e6e6e7",
  SEMI_DARK: "#9691ac",
  SEMI_DARK_HOVER: "#8e8aa0",
  DARK: "#0e072f",
  DARK_HOVER: "#342c4c",
  PURPLE: "#7b61ff",
  PURPLE_HOVER: "#6f57eb",
  TRANSPARENT: "transparent",

  LIGHT_PRIMARY: "#dfdafd",
  LIGHT_PRIMARY_HOVER: "#d5ccfd",
  LIGHT_SUCCESS: "#d9eedc",
  LIGHT_SUCCESS_HOVER: "#cce8cf",
  LIGHT_DANGER: "#fddfde",
  LIGHT_DANGER_HOVER: "#fcd6d5",
  LIGHT_WARNING: "#ffeedc",
  LIGHT_WARNING_HOVER: "#ffe8d0",
  LIGHT_PURPLE: "#f1efff",
  LIGHT_PURPLE_HOVER: "#e4dfff",
};

export const BORDER_RADIUS = {
  EXTRA_SMALL: ".5rem",
  SMALL: ".75rem",
  NORMAL: "1rem",
  MEDIUM: "1.5rem",
  LARGE: "2rem",
  CIRCLE: "50%",
};

export const FONT_SIZES = {
  PARAGRAPH_3: ".75rem",
  PARAGRAPH_2: ".875rem",
  PARAGRAPH_1: "1rem",
  HEADLINE_5: "1.25rem",
  HEADLINE_4: "1.5rem",
  HEADLINE_3: "2rem",
  HEADLINE_2: "2.5rem",
  HEADLINE_1: "3rem",
};

export const FONT_WEIGHTS = {
  THIN: 100,
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
};

export const FORM_INPUT_STYLES = {
  LABEL_LIGHT_COLOR: COLORS.SEMI_DARK,
  LABEL_DARK_COLOR: "#4f4a68",
  LABEL_TRANSPARENT_COLOR: COLORS.TRANSPARENT,
  LABEL_FONT_SIZE: FONT_SIZES.PARAGRAPH_2,
  LABEL_FONT_WEIGHT: FONT_WEIGHTS.REGULAR,

  INPUT_COLOR: COLORS.DARK,
  INPUT_FONT_SIZE: FONT_SIZES.PARAGRAPH_2,
  INPUT_FONT_WEIGHT: FONT_WEIGHTS.MEDIUM,
  INPUT_PLACEHOLDER_FONT_WEIGHT: FONT_WEIGHTS.REGULAR,
  INPUT_BORDER_RADIUS: BORDER_RADIUS.NORMAL,
  INPUT_BORDER_WIDTH: "1px",
  INPUT_BORDER_STYLE: "solid",
  INPUT_BORDER_COLOR: "#dfdcef",
  INPUT_FOCUS_BORDER_COLOR: COLORS.PRIMARY,
  INPUT_BACKGROUND_COLOR: COLORS.WHITE,
  INPUT_SOLID_BG_COLOR: "#f2f2f4",
  INPUT_VALID_COLOR: COLORS.SUCCESS,
  INPUT_INVALID_COLOR: COLORS.DANGER,
};
