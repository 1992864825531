import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getIsRightToLeft } from "../../store/global/global.selector";

import Table, {
  TABLE_COLUMN_FOREGROUNDS,
  TABLE_STYLES,
} from "../../components/table-v2/table.component";

import {
  FormSlugInstructionContainer,
  FormSlugInstructionItem,
  FormSlugInstructionLabel,
  FormSlugInstructionList,
  FormSlugInstructionTitle,
} from "./form-slug-instruction.style";

import { ArrowRight02Icon } from "hugeicons-react";

const FormSlugInstruction = () => {
  const { t } = useTranslation();

  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <FormSlugInstructionContainer isRightToLeft={isRightToLeft}>
      <FormSlugInstructionLabel>
        {t("Slug Writing Rules")}
      </FormSlugInstructionLabel>
      <FormSlugInstructionTitle>
        {t(
          "The 'Business Slug' should be a unique, URL-friendly identifier for your business name. Follow these steps to fill it correctly:"
        )}
      </FormSlugInstructionTitle>
      <FormSlugInstructionList>
        <FormSlugInstructionItem>
          {t("Use lowercase letters only.")}
        </FormSlugInstructionItem>
        <FormSlugInstructionItem>
          {t("Replace spaces or special characters with hyphens (-).")}
        </FormSlugInstructionItem>
        <FormSlugInstructionItem>
          {t(
            "Avoid using special symbols, punctuation marks, or numbers unless necessary."
          )}
        </FormSlugInstructionItem>
        <FormSlugInstructionItem>
          {t("Ensure it is concise and descriptive of your business name.")}
        </FormSlugInstructionItem>
      </FormSlugInstructionList>
      <Table tableStyle={TABLE_STYLES.STYLE_2_BG} hasTableHead={false}>
        <Table.Body>
          <Table.Row>
            <Table.Col colForeground={TABLE_COLUMN_FOREGROUNDS.DARK}>
              Panorama Platter
            </Table.Col>
            <Table.Col>
              <ArrowRight02Icon strokeWidth="2" />
            </Table.Col>
            <Table.Col colForeground={TABLE_COLUMN_FOREGROUNDS.DARK}>
              panorama-platter
            </Table.Col>
          </Table.Row>
          <Table.Row>
            <Table.Col colForeground={TABLE_COLUMN_FOREGROUNDS.DARK}>
              Café de Flore
            </Table.Col>
            <Table.Col>
              <ArrowRight02Icon strokeWidth="2" />
            </Table.Col>
            <Table.Col colForeground={TABLE_COLUMN_FOREGROUNDS.DARK}>
              cafe-de-flore
            </Table.Col>
          </Table.Row>
          <Table.Row>
            <Table.Col colForeground={TABLE_COLUMN_FOREGROUNDS.DARK}>
              24/7 Convenience Store
            </Table.Col>
            <Table.Col>
              <ArrowRight02Icon strokeWidth="2" />
            </Table.Col>
            <Table.Col colForeground={TABLE_COLUMN_FOREGROUNDS.DARK}>
              247-convenience-store
            </Table.Col>
          </Table.Row>
        </Table.Body>
      </Table>
    </FormSlugInstructionContainer>
  );
};

export default FormSlugInstruction;
