import apiService from "./api";

export const getSubscriptions = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/subscriptions", params })).data;
export const getSubscription = async (subscriptionKey) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/subscriptions/${subscriptionKey}`,
    })
  ).data;
export const createSubscription = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/subscriptions", request })).data;
export const updateSubscription = async (subscriptionKey, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/subscriptions/${subscriptionKey}`,
      request,
    })
  ).data;
export const deleteSubscription = async (subscriptionKey) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/subscriptions/${subscriptionKey}`,
    })
  ).data;
