import apiService from "./api";

export const getSuppliers = async (params) =>
  (await apiService.innerGet({ urlPath: "/supplier", params })).data;
export const getSupplier = async (supplierId) =>
  (await apiService.innerGet({ urlPath: `/supplier/${supplierId}` })).data;
export const createSupplier = async (request) =>
  (await apiService.innerPost({ urlPath: "/supplier", request })).data;
export const updateSupplier = async (supplierId, request) =>
  (await apiService.innerPost({ urlPath: `/supplier/${supplierId}`, request }))
    .data;
export const deleteSupplier = async (supplierId) =>
  (await apiService.innerDelete({ urlPath: `/supplier/${supplierId}` })).data;
