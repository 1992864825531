import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import {
  getIsDontShowGettingStarted,
  setIsDontShowGettingStarted,
} from "../../utils/storage.utils";

import { PERMISSION_SUBSCRIBER_PAGES } from "../../constants/permission-subscriber.constant";

import { getMasterPermitPages } from "../../store/global/global.selector";
import { setIsModalOpen } from "../../store/component/component.action";
import { getUser } from "../../store/authentication/authentication.selector";

import Modal, { MODAL_POSITIONS } from "../../components/modal/modal.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import GettingStartedList from "../getting-started-list/getting-started-list.widget";

import {
  GettingStartedBottom,
  GettingStartedDontShowCheck,
  GettingStartedDontShowLabel,
  GettingStartedTop,
} from "./getting-started.style";

import { ReactComponent as DocumentSvg } from "../../assets/icons/Document.svg";
import { ReactComponent as ChecklistSvg } from "../../assets/icons/Checklist.svg";
import { ReactComponent as Tick2Svg } from "../../assets/icons/Tick2.svg";

export const modalName = "GETTING_STARTED";

const GettingStarted = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userable } = useSelector(getUser) ?? {};
  const masterPermitPages = useSelector(getMasterPermitPages);

  const [startedItems, setStartedItems] = useState([]);
  const [isDontShowAgain, setIsDontShowAgain] = useState(false);

  const handleDontShowToggle = () =>
    setIsDontShowGettingStarted(!isDontShowAgain);
  const handleCloseModal = () => dispatch(setIsModalOpen(false));

  const handleStorageEvent = () =>
    setIsDontShowAgain(parseToBoolean(getIsDontShowGettingStarted()));

  useEffect(() => {
    const startedItems = [];

    const isHasProducts = parseToBoolean(userable?.is_has_products);
    const isHasOrders = parseToBoolean(userable?.is_has_orders);

    const isHasProductPage = masterPermitPages.includes(
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT
    );
    const isHasOrderPage = masterPermitPages.includes(
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER
    );

    startedItems.push({
      icon: <DocumentSvg />,
      title: t("Create Your Product"),
      description: t(
        "List your products to ensure they're available when you start selling."
      ),
      route: "/product",
      isDone: isHasProducts,
      isActive: !isHasProducts && isHasProductPage,
    });
    startedItems.push({
      icon: <ChecklistSvg />,
      title: t("Create Your First Order"),
      description: t(
        "Set up your orders system to ensure a smooth checkout and transaction process."
      ),
      route: "/order",
      isDone: isHasOrders,
      isActive: isHasProducts && !isHasOrders && isHasOrderPage,
    });

    setStartedItems(startedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userable, masterPermitPages]);

  useEffect(() => {
    window.addEventListener("localStorage", handleStorageEvent, false);
    return () => window.removeEventListener("localStorage", handleStorageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      modalPosition={MODAL_POSITIONS.CENTER}
      name={modalName}
      icon="👋"
      title={t("Getting Started with Flavours")}
      description={t(
        "Let's get started! Complete the simple steps below to begin."
      )}
    >
      <GettingStartedTop>
        <GettingStartedList startedItems={startedItems} />
      </GettingStartedTop>
      <GettingStartedBottom>
        <Button
          type="button"
          buttonColor={BUTTON_COLORS.LIGHT}
          buttonSize={BUTTON_SIZES.NORMAL}
          buttonType={BUTTON_TYPES.TEXT_ICON}
          prefixIcon={
            <GettingStartedDontShowCheck isActive={isDontShowAgain}>
              <Tick2Svg />
            </GettingStartedDontShowCheck>
          }
          onClick={handleDontShowToggle}
        >
          <GettingStartedDontShowLabel>
            {t("Don't show this again")}
          </GettingStartedDontShowLabel>
        </Button>
        <Button
          type="button"
          buttonColor={BUTTON_COLORS.SECONDARY}
          buttonSize={BUTTON_SIZES.NORMAL}
          buttonType={BUTTON_TYPES.TEXT}
          onClick={handleCloseModal}
        >
          {t("Close")}
        </Button>
      </GettingStartedBottom>
    </Modal>
  );
};

export default GettingStarted;
