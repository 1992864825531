export const INTEGRATION_KEYS = {
  // FAI_COMPANY_SLUG: "FAI_COMPANY_SLUG",
  // FAI_COMPANY_LOGO: "FAI_COMPANY_LOGO",
  // FAI_COM_REG_NUMBER: "FAI_COM_REG_NUMBER",
  // FAI_TAX_NUMBER: "FAI_TAX_NUMBER",
  // FAI_LOCATION_STREET: "FAI_LOCATION_STREET",
  // FAI_LOCATION_BUILDING: "FAI_LOCATION_BUILDING",
  // FAI_LOCATION_CITY: "FAI_LOCATION_CITY",
  // FAI_LOCATION_POSTAL: "FAI_LOCATION_POSTAL",
  // FAI_LOCATION_DISTRICT: "FAI_LOCATION_DISTRICT",
  // ZATCA_VAT: "ZATCA_VAT",
  // ZATCA_STREET: "ZATCA_STREET",
  // ZATCA_BUILDING_NUMBER: "ZATCA_BUILDING_NUMBER",
  // ZATCA_CITY: "ZATCA_CITY",
  // ZATCA_POSTAL_CODE: "ZATCA_POSTAL_CODE",
  // ZATCA_DISTRICT: "ZATCA_DISTRICT",
  // ZATCA_ORGANIZATION: "ZATCA_ORGANIZATION",
  // ZATCA_ORGANIZATION_UNIT: "ZATCA_ORGANIZATION_UNIT",
  // ZATCA_COUNTRY_CODE: "ZATCA_COUNTRY_CODE",

  BALLURH_API_KEY: "BALLURH_API_KEY",
  FAI_COMPANY_ID: "FAI_COMPANY_ID",
  FAI_INVOICE_METHOD: "FAI_INVOICE_METHOD",
  FAI_SALES_INVOICE_PREFIX: "FAI_SALES_INVOICE_PREFIX",
  FAI_RETURN_SALES_INVOICE_PREFIX: "FAI_RETURN_SALES_INVOICE_PREFIX",
  FAI_SALES_INVOICE_START: "FAI_SALES_INVOICE_START",
  FAI_SYNC_MESSAGE: "FAI_SYNC_MESSAGE",
  FAI_SYNC_PERCENT: "FAI_SYNC_PERCENT",
  FAI_RETURN_SALES_INVOICE_START: "FAI_RETURN_SALES_INVOICE_START",
  GRUBTECH_API_KEY: "GRUBTECH_API_KEY",
  GRUBTECH_STORE_ID: "GRUBTECH_STORE_ID",
  GRUBTECH_INTEGRATION_MODE: "GRUBTECH_INTEGRATION_MODE",
  LOYAPRO_IS_ACTIVE: "LOYAPRO_IS_ACTIVE",
  QOYOD_API_KEY: "QOYOD_API_KEY",
  QOYOD_IS_DAILY_UPDATE: "QOYOD_IS_DAILY_UPDATE",
  TAP_PAYMENT_SECRET_KEY: "TAP_PAYMENT_SECRET_KEY",
  TAP_PAYMENT_PUBLIC_KEY: "TAP_PAYMENT_PUBLIC_KEY",
  WHATSAPP_IS_SEND_INVOICE: "WHATSAPP_IS_SEND_INVOICE",
  WHATSAPP_WEBHOOK_URL: "WHATSAPP_WEBHOOK_URL",
  WHATSAPP_AUTHORIZATION: "WHATSAPP_AUTHORIZATION",
  ZATCA_OTP: "ZATCA_OTP",
  ZATCA_SELLER_ID: "ZATCA_SELLER_ID",
  ZATCA_DEVICE_ID: "ZATCA_DEVICE_ID",
  ZATCA_DEVICE_EGS: "ZATCA_DEVICE_EGS",
  ZATCA_DEVICE_NAME: "ZATCA_DEVICE_NAME",
  ZATCA_DEVICE_NUMBER: "ZATCA_DEVICE_NUMBER",
};

export const INTEGRATION_FAI_COMPLETIONS = {
  BUSINESS_TAX_NUMBER: "BUSINESS_TAX_NUMBER",
  BUSINESS_COM_REG_NUMBER: "BUSINESS_COM_REG_NUMBER",
  BUSINESS_LOGO: "BUSINESS_LOGO",
};

export const taxNumberKeys = [
  INTEGRATION_KEYS.FAI_TAX_NUMBER,
  INTEGRATION_KEYS.ZATCA_VAT,
];
export const comRegNumberKeys = [INTEGRATION_KEYS.FAI_COM_REG_NUMBER];
export const streetKeys = [
  INTEGRATION_KEYS.FAI_LOCATION_STREET,
  INTEGRATION_KEYS.ZATCA_STREET,
];
export const buildingKeys = [
  INTEGRATION_KEYS.FAI_LOCATION_BUILDING,
  INTEGRATION_KEYS.ZATCA_BUILDING_NUMBER,
];
export const cityKeys = [
  INTEGRATION_KEYS.FAI_LOCATION_CITY,
  INTEGRATION_KEYS.ZATCA_CITY,
];
export const postalKeys = [
  INTEGRATION_KEYS.FAI_LOCATION_POSTAL,
  INTEGRATION_KEYS.ZATCA_POSTAL_CODE,
];
export const districtKeys = [
  INTEGRATION_KEYS.FAI_LOCATION_DISTRICT,
  INTEGRATION_KEYS.ZATCA_DISTRICT,
];
