import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateBannerFailed,
  setCreateBannerSuccess,
  setDeleteBannerFailed,
  setDeleteBannerSuccess,
  setFetchBannersFailed,
  setFetchBannerFailed,
  setUpdateBannerFailed,
  setUpdateBannerSuccess,
} from "../../store/banner/banner.action";
import {
  getCreateBannerFailed,
  getCreateBannerSuccess,
  getDeleteBannerFailed,
  getDeleteBannerSuccess,
  getFetchBannersFailed,
  getFetchBannerFailed,
  getUpdateBannerFailed,
  getUpdateBannerSuccess,
} from "../../store/banner/banner.selector";

const BannerPopup = () => {
  const dispatch = useDispatch();

  const createBannerSuccess = useSelector(getCreateBannerSuccess);
  const updateBannerSuccess = useSelector(getUpdateBannerSuccess);
  const deleteBannerSuccess = useSelector(getDeleteBannerSuccess);

  const fetchBannersFailed = useSelector(getFetchBannersFailed);
  const fetchBannerFailed = useSelector(getFetchBannerFailed);
  const createBannerFailed = useSelector(getCreateBannerFailed);
  const updateBannerFailed = useSelector(getUpdateBannerFailed);
  const deleteBannerFailed = useSelector(getDeleteBannerFailed);

  useEffect(() => {
    if (
      createBannerSuccess !== null ||
      updateBannerSuccess !== null ||
      deleteBannerSuccess !== null
    ) {
      showSuccessMessage(
        createBannerSuccess ?? updateBannerSuccess ?? deleteBannerSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createBannerSuccess !== null) dispatch(setCreateBannerSuccess(null));
      if (updateBannerSuccess !== null) dispatch(setUpdateBannerSuccess(null));
      if (deleteBannerSuccess !== null) dispatch(setDeleteBannerSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBannerSuccess, updateBannerSuccess, deleteBannerSuccess]);

  useEffect(() => {
    if (
      fetchBannersFailed !== null ||
      fetchBannerFailed !== null ||
      createBannerFailed !== null ||
      updateBannerFailed !== null ||
      deleteBannerFailed !== null
    ) {
      showErrorMessage(
        fetchBannersFailed ??
          fetchBannerFailed ??
          createBannerFailed ??
          updateBannerFailed ??
          deleteBannerFailed
      );

      if (fetchBannersFailed !== null) dispatch(setFetchBannersFailed(null));
      if (fetchBannerFailed !== null) dispatch(setFetchBannerFailed(null));
      if (createBannerFailed !== null) dispatch(setCreateBannerFailed(null));
      if (updateBannerFailed !== null) dispatch(setUpdateBannerFailed(null));
      if (deleteBannerFailed !== null) dispatch(setDeleteBannerFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchBannersFailed,
    fetchBannerFailed,
    createBannerFailed,
    updateBannerFailed,
    deleteBannerFailed,
  ]);

  return <></>;
};

export default BannerPopup;
