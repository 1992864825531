import { forwardRef } from "react";
import { useSelector } from "react-redux";

import { getIsRightToLeft } from "../../store/global/global.selector";

import {
  TableBodyStyled,
  TableColumnAlign,
  TableColumnStyled,
  TableContainerStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from "./table.style";

export const TABLE_STYLES = {
  STYLE_1: "STYLE_1",
  STYLE_1_BG: "STYLE_1_BG",
  STYLE_2: "STYLE_2",
  STYLE_2_BG: "STYLE_2_BG",
  STYLE_3: "STYLE_3",
};

const Table = ({
  tableStyle = TABLE_STYLES.STYLE_1,
  tableResponsive = true,
  hasTableHead = true,
  children,
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <TableContainerStyled
      isRightToLeft={isRightToLeft}
      tableStyle={tableStyle}
      tableResponsive={tableResponsive}
      hasTableHead={hasTableHead}
    >
      <TableStyled>{children}</TableStyled>
    </TableContainerStyled>
  );
};

const TableHead = ({ children, ...props }, ref) => {
  return (
    <TableHeadStyled ref={ref} {...props}>
      {children}
    </TableHeadStyled>
  );
};

const TableBody = ({ children, ...props }, ref) => {
  return (
    <TableBodyStyled ref={ref} {...props}>
      {children}
    </TableBodyStyled>
  );
};

const TableRow = ({ children, ...props }, ref) => {
  return (
    <TableRowStyled ref={ref} {...props}>
      {children}
    </TableRowStyled>
  );
};

export const TABLE_COLUMN_ALIGNS = {
  START: "START",
  CENTER: "CENTER",
  END: "END",
};
export const TABLE_COLUMN_WRAPS = {
  WRAP: "WRAP",
  NOWRAP: "NOWRAP",
};
export const TABLE_COLUMN_BACKGROUNDS = {
  DEFAULT: "DEFAULT",
  LIGHT: "LIGHT",
};
export const TABLE_COLUMN_FOREGROUNDS = {
  DEFAULT: "DEFAULT",
  SECONDARY: "SECONDARY",
  DARK: "DARK",
};

const TableColumn = (
  {
    colVerAlign = TABLE_COLUMN_ALIGNS.CENTER,
    colHorAlign = TABLE_COLUMN_ALIGNS.START,
    colTextWrap = TABLE_COLUMN_WRAPS.NOWRAP,
    colBackground = TABLE_COLUMN_BACKGROUNDS.DEFAULT,
    colForeground = TABLE_COLUMN_FOREGROUNDS.DEFAULT,
    children,
    ...props
  },
  ref
) => {
  return (
    <TableColumnStyled
      ref={ref}
      colBackground={colBackground}
      colForeground={colForeground}
      {...props}
    >
      <TableColumnAlign
        colVerAlign={colVerAlign}
        colHorAlign={colHorAlign}
        colTextWrap={colTextWrap}
      >
        {children}
      </TableColumnAlign>
    </TableColumnStyled>
  );
};

Table.Head = forwardRef(TableHead);
Table.Body = forwardRef(TableBody);
Table.Row = forwardRef(TableRow);
Table.Col = forwardRef(TableColumn);

export default Table;
