import { createSelector } from "reselect";

const selectSubscriberSelector = ({ selectSubscriber }) => selectSubscriber;

export const getSubscribers = createSelector(
  [selectSubscriberSelector],
  ({ subscribers }) => subscribers
);
export const getSearchSubscribers = createSelector(
  [selectSubscriberSelector],
  ({ searchSubscribers }) => searchSubscribers
);

export const getIsSubscribersHasMore = createSelector(
  [selectSubscriberSelector],
  ({ isSubscribersHasMore }) => isSubscribersHasMore
);
export const getIsSearchSubscribersHasMore = createSelector(
  [selectSubscriberSelector],
  ({ isSearchSubscribersHasMore }) => isSearchSubscribersHasMore
);

export const getFetchSubscribersParams = createSelector(
  [selectSubscriberSelector],
  ({ fetchSubscribersParams }) => fetchSubscribersParams
);
export const getFetchSubscribersLoading = createSelector(
  [selectSubscriberSelector],
  ({ fetchSubscribersLoading }) => fetchSubscribersLoading
);
export const getFetchSubscribersSuccess = createSelector(
  [selectSubscriberSelector],
  ({ fetchSubscribersSuccess }) => fetchSubscribersSuccess
);
export const getFetchSubscribersFailed = createSelector(
  [selectSubscriberSelector],
  ({ fetchSubscribersFailed }) => fetchSubscribersFailed
);

export const getSearchSubscribersParams = createSelector(
  [selectSubscriberSelector],
  ({ searchSubscribersParams }) => searchSubscribersParams
);
export const getSearchSubscribersLoading = createSelector(
  [selectSubscriberSelector],
  ({ searchSubscribersLoading }) => searchSubscribersLoading
);
export const getSearchSubscribersSuccess = createSelector(
  [selectSubscriberSelector],
  ({ searchSubscribersSuccess }) => searchSubscribersSuccess
);
export const getSearchSubscribersFailed = createSelector(
  [selectSubscriberSelector],
  ({ searchSubscribersFailed }) => searchSubscribersFailed
);

export const getAppendSubscribersParams = createSelector(
  [selectSubscriberSelector],
  ({ appendSubscribersParams }) => appendSubscribersParams
);
export const getAppendSubscribersLoading = createSelector(
  [selectSubscriberSelector],
  ({ appendSubscribersLoading }) => appendSubscribersLoading
);
export const getAppendSubscribersSuccess = createSelector(
  [selectSubscriberSelector],
  ({ appendSubscribersSuccess }) => appendSubscribersSuccess
);
export const getAppendSubscribersFailed = createSelector(
  [selectSubscriberSelector],
  ({ appendSubscribersFailed }) => appendSubscribersFailed
);

export const getIsFetchSubscribersHitted = createSelector(
  [selectSubscriberSelector],
  ({ isFetchSubscribersHitted }) => isFetchSubscribersHitted
);
export const getIsSearchSubscribersHitted = createSelector(
  [selectSubscriberSelector],
  ({ isSearchSubscribersHitted }) => isSearchSubscribersHitted
);
export const getIsAppendSubscribersHitted = createSelector(
  [selectSubscriberSelector],
  ({ isAppendSubscribersHitted }) => isAppendSubscribersHitted
);
