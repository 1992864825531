import { createSelector } from "reselect";

const gatewaySelector = ({ gateway }) => gateway;

export const getGateway = createSelector([gatewaySelector], ({ gateway }) => gateway);

export const getCreateGateway = createSelector([gatewaySelector], ({ createGateway }) => createGateway);

export const getCheckoutGateway = createSelector([gatewaySelector], ({ checkoutGateway }) => checkoutGateway);

export const getFetchGatewayLoading = createSelector(
  [gatewaySelector],
  ({ fetchGatewayLoading }) => fetchGatewayLoading
);

export const getFetchGatewaySuccess = createSelector(
  [gatewaySelector],
  ({ fetchGatewaySuccess }) => fetchGatewaySuccess
);

export const getFetchGatewayFailed = createSelector([gatewaySelector], ({ fetchGatewayFailed }) => fetchGatewayFailed);

export const getCreateGatewayLoading = createSelector(
  [gatewaySelector],
  ({ createGatewayLoading }) => createGatewayLoading
);

export const getCreateGatewaySuccess = createSelector(
  [gatewaySelector],
  ({ createGatewaySuccess }) => createGatewaySuccess
);

export const getCreateGatewayFailed = createSelector(
  [gatewaySelector],
  ({ createGatewayFailed }) => createGatewayFailed
);

export const getCheckoutGatewayLoading = createSelector(
  [gatewaySelector],
  ({ checkoutGatewayLoading }) => checkoutGatewayLoading
);

export const getCheckoutGatewaySuccess = createSelector(
  [gatewaySelector],
  ({ checkoutGatewaySuccess }) => checkoutGatewaySuccess
);

export const getCheckoutGatewayFailed = createSelector(
  [gatewaySelector],
  ({ checkoutGatewayFailed }) => checkoutGatewayFailed
);

export const getIsFetchGatewayHitted = createSelector(
  [gatewaySelector],
  ({ isFetchGatewayHitted }) => isFetchGatewayHitted
);

export const getIsCreateGatewayHitted = createSelector(
  [gatewaySelector],
  ({ isCreateGatewayHitted }) => isCreateGatewayHitted
);

export const getIsCheckoutGatewayHitted = createSelector(
  [gatewaySelector],
  ({ isCheckoutGatewayHitted }) => isCheckoutGatewayHitted
);
