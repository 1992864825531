import RENEWAL_ADMIN_ACTION_TYPES from "./renewal-admin.type";

const RENEWAL_ADMIN_INITIAL_STATE = {
  renewalMarket: null,
  renewalBranches: [],
  renewalCalculate: null,
  renewalCreate: null,

  isRenewalBranchesHasMore: true,

  fetchRenewalMarketParams: {},
  fetchRenewalMarketLoading: false,
  fetchRenewalMarketSuccess: null,
  fetchRenewalMarketFailed: null,

  fetchRenewalBranchesParams: {},
  fetchRenewalBranchesLoading: false,
  fetchRenewalBranchesSuccess: null,
  fetchRenewalBranchesFailed: null,

  renewalCalculateLoading: false,
  renewalCalculateSuccess: null,
  renewalCalculateFailed: null,

  renewalCreateLoading: false,
  renewalCreateSuccess: null,
  renewalCreateFailed: null,

  isFetchRenewalMarketHitted: false,
  isFetchRenewalBranchesHitted: false,
  isRenewalCalculateHitted: false,
  isRenewalCreateHitted: false,
};

export const renewalAdminReducer = (
  state = RENEWAL_ADMIN_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_MARKET:
      return { ...state, renewalMarket: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_BRANCHES:
      return { ...state, renewalBranches: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE:
      return { ...state, renewalCalculate: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE:
      return { ...state, renewalCreate: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.APPEND_RENEWAL_BRANCHES:
      return {
        ...state,
        renewalBranches: [...state.renewalBranches, ...payload],
      };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_BRANCHES_HAS_MORE:
      return { ...state, isRenewalBranchesHasMore: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_PARAMS:
      return { ...state, fetchRenewalMarketParams: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_LOADING:
      return { ...state, fetchRenewalMarketLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_SUCCESS:
      return { ...state, fetchRenewalMarketSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_FAILED:
      return { ...state, fetchRenewalMarketFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_PARAMS:
      return { ...state, fetchRenewalBranchesParams: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_LOADING:
      return { ...state, fetchRenewalBranchesLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_SUCCESS:
      return { ...state, fetchRenewalBranchesSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_FAILED:
      return { ...state, fetchRenewalBranchesFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE_LOADING:
      return { ...state, renewalCalculateLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE_SUCCESS:
      return { ...state, renewalCalculateSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CALCULATE_FAILED:
      return { ...state, renewalCalculateFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_LOADING:
      return { ...state, renewalCreateLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_SUCCESS:
      return { ...state, renewalCreateSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_FAILED:
      return { ...state, renewalCreateFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_MARKET_HITTED:
      return { ...state, isFetchRenewalMarketHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_BRANCHES_HITTED:
      return { ...state, isFetchRenewalBranchesHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_CALCULATE_HITTED:
      return { ...state, isRenewalCalculateHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_CREATE_HITTED:
      return { ...state, isRenewalCreateHitted: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.RESET_RENEWAL_ADMIN_REDUCER:
      return RENEWAL_ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
