import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setExportProductBarcodeFailed,
  setExportProductBarcodeSuccess,
  setExportReportOrderFailed,
  setExportReportOrderSuccess,
  setExportReportSummaryFailed,
  setExportReportSummarySuccess,
  setExportTableQrCodeFailed,
  setExportTableQrCodeSuccess,
} from "../../store/export/export.action";
import {
  getExportProductBarcodeFailed,
  getExportProductBarcodeSuccess,
  getExportReportOrderFailed,
  getExportReportOrderSuccess,
  getExportReportSummaryFailed,
  getExportReportSummarySuccess,
  getExportTableQrCodeFailed,
  getExportTableQrCodeSuccess,
} from "../../store/export/export.selector";

const ExportPopup = () => {
  const dispatch = useDispatch();

  const exportReportOrderSuccess = useSelector(getExportReportOrderSuccess);
  const exportReportSummarySuccess = useSelector(getExportReportSummarySuccess);
  const exportTableQrCodeSuccess = useSelector(getExportTableQrCodeSuccess);
  const exportProductBarcodeSuccess = useSelector(getExportProductBarcodeSuccess);
  const exportReportOrderFailed = useSelector(getExportReportOrderFailed);
  const exportReportSummaryFailed = useSelector(getExportReportSummaryFailed);
  const exportTableQrCodeFailed = useSelector(getExportTableQrCodeFailed);
  const exportProductBarcodeFailed = useSelector(getExportProductBarcodeFailed);

  useEffect(() => {
    if (
      exportReportOrderSuccess !== null ||
      exportReportSummarySuccess !== null ||
      exportTableQrCodeSuccess !== null ||
      exportProductBarcodeSuccess !== null
    ) {
      showSuccessMessage(
        exportReportOrderSuccess ??
          exportReportSummarySuccess ??
          exportTableQrCodeSuccess ??
          exportProductBarcodeSuccess
      );
      dispatch(setIsModalOpen(false));

      if (exportReportOrderSuccess !== null) dispatch(setExportReportOrderSuccess(null));
      if (exportReportSummarySuccess !== null) dispatch(setExportReportSummarySuccess(null));
      if (exportTableQrCodeSuccess !== null) dispatch(setExportTableQrCodeSuccess(null));
      if (exportProductBarcodeSuccess !== null) dispatch(setExportProductBarcodeSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportReportOrderSuccess, exportReportSummarySuccess, exportTableQrCodeSuccess, exportProductBarcodeSuccess]);

  useEffect(() => {
    if (
      exportReportOrderFailed !== null ||
      exportReportSummaryFailed !== null ||
      exportTableQrCodeFailed !== null ||
      exportProductBarcodeFailed !== null
    ) {
      showErrorMessage(
        exportReportOrderFailed ?? exportReportSummaryFailed ?? exportTableQrCodeFailed ?? exportProductBarcodeFailed
      );

      if (exportReportOrderFailed !== null) dispatch(setExportReportOrderFailed(null));
      if (exportReportSummaryFailed !== null) dispatch(setExportReportSummaryFailed(null));
      if (exportTableQrCodeFailed !== null) dispatch(setExportTableQrCodeFailed(null));
      if (exportProductBarcodeFailed !== null) dispatch(setExportProductBarcodeFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportReportOrderFailed, exportReportSummaryFailed, exportTableQrCodeFailed, exportProductBarcodeFailed]);

  return <></>;
};

export default ExportPopup;
