import { createSelector } from "reselect";

const exportExcelSelector = ({ exportExcel }) => exportExcel;

export const getExportExcelReports = createSelector(
  [exportExcelSelector],
  ({ exportExcelReports }) => exportExcelReports
);

export const getExportExcelReportsLoading = createSelector(
  [exportExcelSelector],
  ({ exportExcelReportsLoading }) => exportExcelReportsLoading
);
export const getExportExcelReportsSuccess = createSelector(
  [exportExcelSelector],
  ({ exportExcelReportsSuccess }) => exportExcelReportsSuccess
);
export const getExportExcelReportsFailed = createSelector(
  [exportExcelSelector],
  ({ exportExcelReportsFailed }) => exportExcelReportsFailed
);

export const getIsExportExcelReportsHitted = createSelector(
  [exportExcelSelector],
  ({ isExportExcelReportsHitted }) => isExportExcelReportsHitted
);
