import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateRecipeFailed,
  setCreateRecipeSuccess,
  setDeleteRecipeFailed,
  setDeleteRecipeSuccess,
  setFetchRecipeFailed,
  setFetchRecipesFailed,
  setUpdateRecipeFailed,
  setUpdateRecipeSuccess,
} from "../../store/recipe/recipe.action";
import {
  getCreateRecipeFailed,
  getCreateRecipeSuccess,
  getDeleteRecipeFailed,
  getDeleteRecipeSuccess,
  getFetchRecipeFailed,
  getFetchRecipesFailed,
  getUpdateRecipeFailed,
  getUpdateRecipeSuccess,
} from "../../store/recipe/recipe.selector";

const RecipePopup = () => {
  const dispatch = useDispatch();

  const createRecipeSuccess = useSelector(getCreateRecipeSuccess);
  const updateRecipeSuccess = useSelector(getUpdateRecipeSuccess);
  const deleteRecipeSuccess = useSelector(getDeleteRecipeSuccess);
  const fetchRecipesFailed = useSelector(getFetchRecipesFailed);
  const fetchRecipeFailed = useSelector(getFetchRecipeFailed);
  const createRecipeFailed = useSelector(getCreateRecipeFailed);
  const updateRecipeFailed = useSelector(getUpdateRecipeFailed);
  const deleteRecipeFailed = useSelector(getDeleteRecipeFailed);

  useEffect(() => {
    if (createRecipeSuccess !== null || updateRecipeSuccess !== null || deleteRecipeSuccess !== null) {
      showSuccessMessage(createRecipeSuccess ?? updateRecipeSuccess ?? deleteRecipeSuccess);
      dispatch(setIsModalOpen(false));

      if (createRecipeSuccess !== null) dispatch(setCreateRecipeSuccess(null));
      if (updateRecipeSuccess !== null) dispatch(setUpdateRecipeSuccess(null));
      if (deleteRecipeSuccess !== null) dispatch(setDeleteRecipeSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRecipeSuccess, updateRecipeSuccess, deleteRecipeSuccess]);

  useEffect(() => {
    if (
      fetchRecipesFailed !== null ||
      fetchRecipeFailed !== null ||
      createRecipeFailed !== null ||
      updateRecipeFailed !== null ||
      deleteRecipeFailed !== null
    ) {
      showErrorMessage(
        fetchRecipesFailed ?? fetchRecipeFailed ?? createRecipeFailed ?? updateRecipeFailed ?? deleteRecipeFailed
      );

      if (fetchRecipesFailed !== null) dispatch(setFetchRecipesFailed(null));
      if (fetchRecipeFailed !== null) dispatch(setFetchRecipeFailed(null));
      if (createRecipeFailed !== null) dispatch(setCreateRecipeFailed(null));
      if (updateRecipeFailed !== null) dispatch(setUpdateRecipeFailed(null));
      if (deleteRecipeFailed !== null) dispatch(setDeleteRecipeFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRecipesFailed, fetchRecipeFailed, createRecipeFailed, updateRecipeFailed, deleteRecipeFailed]);

  return <></>;
};

export default RecipePopup;
