import apiService from "./api";

export const getCustomers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/customers", params })).data;
export const getCustomer = async (customerId) =>
  (await apiService.innerGet({ urlPath: `/v2/users/customers/${customerId}` }))
    .data;
export const createCustomer = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/customers", request }))
    .data;
export const updateCustomer = async (customerId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/customers/${customerId}`,
      request,
    })
  ).data;
