import apiService from "./api";

export const getPermissions = async (params) =>
  (await apiService.innerGet({ urlPath: "/permission", params })).data;
export const getPermission = async (permissionId) =>
  (await apiService.innerGet({ urlPath: `/permission/${permissionId}` })).data;
export const createPermission = async (request) =>
  (await apiService.innerPost({ urlPath: "/permission", request })).data;
export const updatePermission = async (permissionId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/permission/${permissionId}`,
      request,
    })
  ).data;
export const deletePermission = async (permissionId) =>
  (await apiService.innerDelete({ urlPath: `/permission/${permissionId}` }))
    .data;
