import apiService from "./api";

export const getIngredients = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/ingredients", params })).data;
export const getIngredient = async (ingredientId) =>
  (await apiService.innerGet({ urlPath: `/v2/ingredients/${ingredientId}` }))
    .data;
export const createIngredient = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/ingredients", request })).data;
export const updateIngredient = async (ingredientId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/ingredients/${ingredientId}`,
      request,
    })
  ).data;
export const deleteIngredient = async (ingredientId) =>
  (await apiService.innerDelete({ urlPath: `/v2/ingredients/${ingredientId}` }))
    .data;

export const storeStockIngredient = async (ingredientId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/ingredients/${ingredientId}/store`,
      request,
    })
  ).data;
export const reduceStockIngredient = async (ingredientId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/ingredients/${ingredientId}/reduce`,
      request,
    })
  ).data;
