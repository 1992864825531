import { createSelector } from "reselect";

const bannerTargetSelector = ({ bannerTarget }) => bannerTarget;

export const getTargetAdmins = createSelector(
  [bannerTargetSelector],
  ({ targetAdmins }) => targetAdmins
);
export const getTargetMerchants = createSelector(
  [bannerTargetSelector],
  ({ targetMerchants }) => targetMerchants
);
export const getTargetSubscribers = createSelector(
  [bannerTargetSelector],
  ({ targetSubscribers }) => targetSubscribers
);
export const getTarget = createSelector(
  [bannerTargetSelector],
  ({ target }) => target
);

export const getIsTargetAdminsHasMore = createSelector(
  [bannerTargetSelector],
  ({ isTargetAdminsHasMore }) => isTargetAdminsHasMore
);
export const getIsTargetMerchantsHasMore = createSelector(
  [bannerTargetSelector],
  ({ isTargetMerchantsHasMore }) => isTargetMerchantsHasMore
);
export const getIsTargetSubscribersHasMore = createSelector(
  [bannerTargetSelector],
  ({ isTargetSubscribersHasMore }) => isTargetSubscribersHasMore
);

export const getFetchTargetAdminsSearch = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsSearch }) => fetchTargetAdminsSearch
);
export const getFetchTargetAdminsSort = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsSort }) => fetchTargetAdminsSort
);
export const getFetchTargetAdminsKeyBy = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsKeyBy }) => fetchTargetAdminsKeyBy
);
export const getFetchTargetAdminsPage = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsPage }) => fetchTargetAdminsPage
);
export const getFetchTargetAdminsPerPage = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsPerPage }) => fetchTargetAdminsPerPage
);
export const getFetchTargetAdminsFilterBannerId = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsFilterBannerId }) => fetchTargetAdminsFilterBannerId
);
export const getFetchTargetAdminsFilterExceptBannerId = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsFilterExceptBannerId }) =>
    fetchTargetAdminsFilterExceptBannerId
);
export const getFetchTargetAdminsLoading = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsLoading }) => fetchTargetAdminsLoading
);
export const getFetchTargetAdminsSuccess = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsSuccess }) => fetchTargetAdminsSuccess
);
export const getFetchTargetAdminsFailed = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetAdminsFailed }) => fetchTargetAdminsFailed
);

export const getFetchTargetMerchantsSearch = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsSearch }) => fetchTargetMerchantsSearch
);
export const getFetchTargetMerchantsSort = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsSort }) => fetchTargetMerchantsSort
);
export const getFetchTargetMerchantsKeyBy = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsKeyBy }) => fetchTargetMerchantsKeyBy
);
export const getFetchTargetMerchantsPage = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsPage }) => fetchTargetMerchantsPage
);
export const getFetchTargetMerchantsPerPage = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsPerPage }) => fetchTargetMerchantsPerPage
);
export const getFetchTargetMerchantsFilterBannerId = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsFilterBannerId }) => fetchTargetMerchantsFilterBannerId
);
export const getFetchTargetMerchantsFilterExceptBannerId = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsFilterExceptBannerId }) =>
    fetchTargetMerchantsFilterExceptBannerId
);
export const getFetchTargetMerchantsLoading = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsLoading }) => fetchTargetMerchantsLoading
);
export const getFetchTargetMerchantsSuccess = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsSuccess }) => fetchTargetMerchantsSuccess
);
export const getFetchTargetMerchantsFailed = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetMerchantsFailed }) => fetchTargetMerchantsFailed
);

export const getFetchTargetSubscribersSearch = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersSearch }) => fetchTargetSubscribersSearch
);
export const getFetchTargetSubscribersSort = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersSort }) => fetchTargetSubscribersSort
);
export const getFetchTargetSubscribersKeyBy = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersKeyBy }) => fetchTargetSubscribersKeyBy
);
export const getFetchTargetSubscribersPage = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersPage }) => fetchTargetSubscribersPage
);
export const getFetchTargetSubscribersPerPage = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersPerPage }) => fetchTargetSubscribersPerPage
);
export const getFetchTargetSubscribersFilterBannerId = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersFilterBannerId }) =>
    fetchTargetSubscribersFilterBannerId
);
export const getFetchTargetSubscribersFilterExceptBannerId = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersFilterExceptBannerId }) =>
    fetchTargetSubscribersFilterExceptBannerId
);
export const getFetchTargetSubscribersLoading = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersLoading }) => fetchTargetSubscribersLoading
);
export const getFetchTargetSubscribersSuccess = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersSuccess }) => fetchTargetSubscribersSuccess
);
export const getFetchTargetSubscribersFailed = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSubscribersFailed }) => fetchTargetSubscribersFailed
);

export const getFetchTargetLoading = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetLoading }) => fetchTargetLoading
);
export const getFetchTargetSuccess = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetSuccess }) => fetchTargetSuccess
);
export const getFetchTargetFailed = createSelector(
  [bannerTargetSelector],
  ({ fetchTargetFailed }) => fetchTargetFailed
);

export const getIsFetchTargetAdminsHitted = createSelector(
  [bannerTargetSelector],
  ({ isFetchTargetAdminsHitted }) => isFetchTargetAdminsHitted
);
export const getIsFetchTargetMerchantsHitted = createSelector(
  [bannerTargetSelector],
  ({ isFetchTargetMerchantsHitted }) => isFetchTargetMerchantsHitted
);
export const getIsFetchTargetSubscribersHitted = createSelector(
  [bannerTargetSelector],
  ({ isFetchTargetSubscribersHitted }) => isFetchTargetSubscribersHitted
);
export const getIsFetchTargetHitted = createSelector(
  [bannerTargetSelector],
  ({ isFetchTargetHitted }) => isFetchTargetHitted
);
