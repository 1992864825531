import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreatePaymentMethodSettingFailed,
  setCreatePaymentMethodSettingSuccess,
  setDeletePaymentMethodSettingFailed,
  setDeletePaymentMethodSettingSuccess,
  setFetchActivePaymentMethodSettingsFailed,
  setFetchPaymentMethodSettingFailed,
  setFetchPaymentMethodSettingsFailed,
  setFetchSelectPaymentMethodSettingsFailed,
  setUpdatePaymentMethodSettingFailed,
  setUpdatePaymentMethodSettingSuccess,
} from "../../store/payment-method-setting/payment-method-setting.action";
import {
  getCreatePaymentMethodSettingFailed,
  getCreatePaymentMethodSettingSuccess,
  getDeletePaymentMethodSettingFailed,
  getDeletePaymentMethodSettingSuccess,
  getFetchActivePaymentMethodSettingsFailed,
  getFetchPaymentMethodSettingFailed,
  getFetchPaymentMethodSettingsFailed,
  getFetchSelectPaymentMethodSettingsFailed,
  getIsModalAutoClose,
  getUpdatePaymentMethodSettingFailed,
  getUpdatePaymentMethodSettingSuccess,
} from "../../store/payment-method-setting/payment-method-setting.selector";

const PaymentMethodSettingPopup = () => {
  const dispatch = useDispatch();

  const isModalAutoClose = useSelector(getIsModalAutoClose);
  const createPaymentMethodSettingSuccess = useSelector(
    getCreatePaymentMethodSettingSuccess
  );
  const updatePaymentMethodSettingSuccess = useSelector(
    getUpdatePaymentMethodSettingSuccess
  );
  const deletePaymentMethodSettingSuccess = useSelector(
    getDeletePaymentMethodSettingSuccess
  );
  const fetchPaymentMethodSettingsFailed = useSelector(
    getFetchPaymentMethodSettingsFailed
  );
  const fetchSelectPaymentMethodSettingsFailed = useSelector(
    getFetchSelectPaymentMethodSettingsFailed
  );
  const fetchActivePaymentMethodSettingsFailed = useSelector(
    getFetchActivePaymentMethodSettingsFailed
  );
  const fetchPaymentMethodSettingFailed = useSelector(
    getFetchPaymentMethodSettingFailed
  );
  const createPaymentMethodSettingFailed = useSelector(
    getCreatePaymentMethodSettingFailed
  );
  const updatePaymentMethodSettingFailed = useSelector(
    getUpdatePaymentMethodSettingFailed
  );
  const deletePaymentMethodSettingFailed = useSelector(
    getDeletePaymentMethodSettingFailed
  );

  useEffect(() => {
    if (
      createPaymentMethodSettingSuccess !== null ||
      updatePaymentMethodSettingSuccess !== null ||
      deletePaymentMethodSettingSuccess !== null
    ) {
      showSuccessMessage(
        createPaymentMethodSettingSuccess ??
          updatePaymentMethodSettingSuccess ??
          deletePaymentMethodSettingSuccess
      );
      if (isModalAutoClose) dispatch(setIsModalOpen(false));

      if (createPaymentMethodSettingSuccess !== null)
        dispatch(setCreatePaymentMethodSettingSuccess(null));
      if (updatePaymentMethodSettingSuccess !== null)
        dispatch(setUpdatePaymentMethodSettingSuccess(null));
      if (deletePaymentMethodSettingSuccess !== null)
        dispatch(setDeletePaymentMethodSettingSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createPaymentMethodSettingSuccess,
    updatePaymentMethodSettingSuccess,
    deletePaymentMethodSettingSuccess,
  ]);

  useEffect(() => {
    if (
      fetchPaymentMethodSettingsFailed !== null ||
      fetchSelectPaymentMethodSettingsFailed !== null ||
      fetchActivePaymentMethodSettingsFailed !== null ||
      fetchPaymentMethodSettingFailed !== null ||
      createPaymentMethodSettingFailed !== null ||
      updatePaymentMethodSettingFailed !== null ||
      deletePaymentMethodSettingFailed !== null
    ) {
      showErrorMessage(
        fetchPaymentMethodSettingsFailed ??
          fetchSelectPaymentMethodSettingsFailed ??
          fetchActivePaymentMethodSettingsFailed ??
          fetchPaymentMethodSettingFailed ??
          createPaymentMethodSettingFailed ??
          updatePaymentMethodSettingFailed ??
          deletePaymentMethodSettingFailed
      );

      if (fetchPaymentMethodSettingsFailed !== null)
        dispatch(setFetchPaymentMethodSettingsFailed(null));
      if (fetchSelectPaymentMethodSettingsFailed !== null)
        dispatch(setFetchSelectPaymentMethodSettingsFailed(null));
      if (fetchActivePaymentMethodSettingsFailed !== null)
        dispatch(setFetchActivePaymentMethodSettingsFailed(null));
      if (fetchPaymentMethodSettingFailed !== null)
        dispatch(setFetchPaymentMethodSettingFailed(null));
      if (createPaymentMethodSettingFailed !== null)
        dispatch(setCreatePaymentMethodSettingFailed(null));
      if (updatePaymentMethodSettingFailed !== null)
        dispatch(setUpdatePaymentMethodSettingFailed(null));
      if (deletePaymentMethodSettingFailed !== null)
        dispatch(setDeletePaymentMethodSettingFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPaymentMethodSettingsFailed,
    fetchSelectPaymentMethodSettingsFailed,
    fetchActivePaymentMethodSettingsFailed,
    fetchPaymentMethodSettingFailed,
    createPaymentMethodSettingFailed,
    updatePaymentMethodSettingFailed,
    deletePaymentMethodSettingFailed,
  ]);

  return <></>;
};

export default PaymentMethodSettingPopup;
