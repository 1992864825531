import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateRestockRequestFailed,
  setCreateRestockRequestSuccess,
  setDeleteRestockRequestFailed,
  setDeleteRestockRequestSuccess,
  setFetchRestockRequestFailed,
  setFetchRestockRequestsFailed,
  setUpdateRestockRequestFailed,
  setUpdateRestockRequestSuccess,
} from "../../store/restock-request/restock-request.action";
import {
  getCreateRestockRequestFailed,
  getCreateRestockRequestSuccess,
  getDeleteRestockRequestFailed,
  getDeleteRestockRequestSuccess,
  getFetchRestockRequestFailed,
  getFetchRestockRequestsFailed,
  getUpdateRestockRequestFailed,
  getUpdateRestockRequestSuccess,
} from "../../store/restock-request/restock-request.selector";

const RestockRequestPopup = () => {
  const dispatch = useDispatch();

  const createRestockRequestSuccess = useSelector(getCreateRestockRequestSuccess);
  const updateRestockRequestSuccess = useSelector(getUpdateRestockRequestSuccess);
  const deleteRestockRequestSuccess = useSelector(getDeleteRestockRequestSuccess);
  const fetchRestockRequestsFailed = useSelector(getFetchRestockRequestsFailed);
  const fetchRestockRequestFailed = useSelector(getFetchRestockRequestFailed);
  const createRestockRequestFailed = useSelector(getCreateRestockRequestFailed);
  const updateRestockRequestFailed = useSelector(getUpdateRestockRequestFailed);
  const deleteRestockRequestFailed = useSelector(getDeleteRestockRequestFailed);

  useEffect(() => {
    if (
      createRestockRequestSuccess !== null ||
      updateRestockRequestSuccess !== null ||
      deleteRestockRequestSuccess !== null
    ) {
      showSuccessMessage(createRestockRequestSuccess ?? updateRestockRequestSuccess ?? deleteRestockRequestSuccess);
      dispatch(setIsModalOpen(false));

      if (createRestockRequestSuccess !== null) dispatch(setCreateRestockRequestSuccess(null));
      if (updateRestockRequestSuccess !== null) dispatch(setUpdateRestockRequestSuccess(null));
      if (deleteRestockRequestSuccess !== null) dispatch(setDeleteRestockRequestSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRestockRequestSuccess, updateRestockRequestSuccess, deleteRestockRequestSuccess]);

  useEffect(() => {
    if (
      fetchRestockRequestsFailed !== null ||
      fetchRestockRequestFailed !== null ||
      createRestockRequestFailed !== null ||
      updateRestockRequestFailed !== null ||
      deleteRestockRequestFailed !== null
    ) {
      showErrorMessage(
        fetchRestockRequestsFailed ??
          fetchRestockRequestFailed ??
          createRestockRequestFailed ??
          updateRestockRequestFailed ??
          deleteRestockRequestFailed
      );

      if (fetchRestockRequestsFailed !== null) dispatch(setFetchRestockRequestsFailed(null));
      if (fetchRestockRequestFailed !== null) dispatch(setFetchRestockRequestFailed(null));
      if (createRestockRequestFailed !== null) dispatch(setCreateRestockRequestFailed(null));
      if (updateRestockRequestFailed !== null) dispatch(setUpdateRestockRequestFailed(null));
      if (deleteRestockRequestFailed !== null) dispatch(setDeleteRestockRequestFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRestockRequestsFailed,
    fetchRestockRequestFailed,
    createRestockRequestFailed,
    updateRestockRequestFailed,
    deleteRestockRequestFailed,
  ]);

  return <></>;
};

export default RestockRequestPopup;
