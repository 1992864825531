import { USER_ROLES } from "../constants/user.constant";

import { getPageDetails as getPageAdminDetails } from "../utils/permission-admin.utils";
import { getPageDetails as getPageMerchantDetails } from "../utils/permission-merchant.utils";
import { getPageDetails as getPageSubscriberDetails } from "../utils/permission-subscriber.utils";

import { setPermission as setPermissionAdmin } from "../store/permission-admin/permission-admin.action";
import { setPermission as setPermissionMerchant } from "../store/permission-merchant/permission-merchant.action";
import { setPermission as setPermissionSubscriber } from "../store/permission-subscriber/permission-subscriber.action";

export const getPermissionDetailsAction = (role) =>
  ({
    [USER_ROLES.USER_ADMIN]: setPermissionAdmin,
    [USER_ROLES.USER_MERCHANT]: setPermissionMerchant,
    [USER_ROLES.USER_SUBSCRIBER]: setPermissionSubscriber,
  }?.[role]);

export const getValidSectorPages = (sectorPages = [], permissionPages = []) => {
  const sectorPagesSet = new Set(sectorPages);
  const pages = permissionPages.filter((page) => sectorPagesSet.has(page));

  return pages;
};

export const getPageDetailsByRole = (role) =>
  ({
    [USER_ROLES.USER_ADMIN]: getPageAdminDetails,
    [USER_ROLES.USER_MERCHANT]: getPageMerchantDetails,
    [USER_ROLES.USER_SUBSCRIBER]: getPageSubscriberDetails,
  }?.[role]);
