import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateMarketFailed,
  setCreateMarketSuccess,
  setDeleteMarketFailed,
  setDeleteMarketSuccess,
  setFetchMarketFailed,
  setFetchMarketsFailed,
  setUpdateMarketFailed,
  setUpdateMarketSuccess,
} from "../../store/market/market.action";
import {
  getCreateMarketFailed,
  getCreateMarketSuccess,
  getDeleteMarketFailed,
  getDeleteMarketSuccess,
  getFetchMarketFailed,
  getFetchMarketsFailed,
  getIsModalAutoClose,
  getUpdateMarketFailed,
  getUpdateMarketSuccess,
} from "../../store/market/market.selector";

const MarketPopup = () => {
  const dispatch = useDispatch();

  const isModalAutoClose = useSelector(getIsModalAutoClose);
  const createMarketSuccess = useSelector(getCreateMarketSuccess);
  const updateMarketSuccess = useSelector(getUpdateMarketSuccess);
  const deleteMarketSuccess = useSelector(getDeleteMarketSuccess);
  const fetchMarketsFailed = useSelector(getFetchMarketsFailed);
  const fetchMarketFailed = useSelector(getFetchMarketFailed);
  const createMarketFailed = useSelector(getCreateMarketFailed);
  const updateMarketFailed = useSelector(getUpdateMarketFailed);
  const deleteMarketFailed = useSelector(getDeleteMarketFailed);

  useEffect(() => {
    if (
      createMarketSuccess !== null ||
      updateMarketSuccess !== null ||
      deleteMarketSuccess !== null
    ) {
      showSuccessMessage(
        createMarketSuccess ?? updateMarketSuccess ?? deleteMarketSuccess
      );
      if (isModalAutoClose) dispatch(setIsModalOpen(false));

      if (createMarketSuccess !== null) dispatch(setCreateMarketSuccess(null));
      if (updateMarketSuccess !== null) dispatch(setUpdateMarketSuccess(null));
      if (deleteMarketSuccess !== null) dispatch(setDeleteMarketSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMarketSuccess, updateMarketSuccess, deleteMarketSuccess]);

  useEffect(() => {
    if (
      fetchMarketsFailed !== null ||
      fetchMarketFailed !== null ||
      createMarketFailed !== null ||
      updateMarketFailed !== null ||
      deleteMarketFailed !== null
    ) {
      showErrorMessage(
        fetchMarketsFailed ??
          fetchMarketFailed ??
          createMarketFailed ??
          updateMarketFailed ??
          deleteMarketFailed
      );

      if (fetchMarketsFailed !== null) dispatch(setFetchMarketsFailed(null));
      if (fetchMarketFailed !== null) dispatch(setFetchMarketFailed(null));
      if (createMarketFailed !== null) dispatch(setCreateMarketFailed(null));
      if (updateMarketFailed !== null) dispatch(setUpdateMarketFailed(null));
      if (deleteMarketFailed !== null) dispatch(setDeleteMarketFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchMarketsFailed,
    fetchMarketFailed,
    createMarketFailed,
    updateMarketFailed,
    deleteMarketFailed,
  ]);

  return <></>;
};

export default MarketPopup;
