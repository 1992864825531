import apiService, { defaultOptions } from "./api";

export const uploadFile = async (request) =>
  (
    await apiService.innerPost({
      headers: {
        ...defaultOptions.headers(),
        "Content-Type": "multipart/form-data",
        webhook: false,
      },
      urlPath: "/v2/file/upload",
      request,
    })
  ).data;
export const uploadFiles = async (request) =>
  (
    await apiService.innerPost({
      headers: {
        ...defaultOptions.headers(),
        "Content-Type": "multipart/form-data",
        webhook: false,
      },
      urlPath: "/v2/files/upload",
      request,
    })
  ).data;
export const deleteFile = async (request) =>
  (await apiService.innerDelete({ urlPath: "/v2/file/delete", request })).data;
export const deleteFiles = async (request) =>
  (await apiService.innerDelete({ urlPath: "/v2/files/delete", request })).data;
