import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import NotificationItem from "../notification-item/notification-item.widget";

import {
  NotificationListColumn,
  NotificationListRow,
} from "./notification-list.style";

const NotificationList = ({
  notifications = [],
  page = 1,
  isLoading = false,
  isHasMore = false,
  onBottomScroll,
}) => {
  const { t } = useTranslation();

  const isLastNotification = (index) => notifications.length === index + 1;

  const observer = useRef();
  const lastNotificationRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && isHasMore) onBottomScroll?.();
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isHasMore]
  );

  return (
    <NotificationListRow>
      {!(page === 1 && isLoading) &&
        notifications.map((notification, index) => (
          <NotificationListColumn
            ref={isLastNotification(index) ? lastNotificationRef : null}
            key={index}
            isLast={isLastNotification(index)}
          >
            <NotificationItem notification={notification} />
          </NotificationListColumn>
        ))}
      {!isLoading && notifications.length === 0 && (
        <center>{t("No records found")}</center>
      )}
      {isLoading &&
        Array.from(Array(2).keys()).map((index) => (
          <NotificationListColumn key={index} isLast={2 === index + 1}>
            <NotificationItem isLoading />
          </NotificationListColumn>
        ))}
    </NotificationListRow>
  );
};

export default NotificationList;
