import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getPermissionMerchants } from "../../api/permission-merchant.api";

import SELECT_PERMISSION_MERCHANT_ACTION_TYPES from "./select-permission-merchant.type";
import {
  appendPermissionMerchants,
  appendPermissionSubscribers,
  appendSearchPermissionMerchants,
  appendSearchPermissionSubscribers,
  setAppendPermissionMerchantsFailed,
  setAppendPermissionMerchantsLoading,
  setAppendPermissionMerchantsSuccess,
  setAppendPermissionSubscribersFailed,
  setAppendPermissionSubscribersLoading,
  setAppendPermissionSubscribersSuccess,
  setFetchPermissionMerchantsFailed,
  setFetchPermissionMerchantsLoading,
  setFetchPermissionMerchantsSuccess,
  setFetchPermissionSubscribersFailed,
  setFetchPermissionSubscribersLoading,
  setFetchPermissionSubscribersSuccess,
  setIsAppendPermissionMerchantsHitted,
  setIsAppendPermissionSubscribersHitted,
  setIsFetchPermissionMerchantsHitted,
  setIsFetchPermissionSubscribersHitted,
  setIsPermissionMerchantsHasMore,
  setIsPermissionSubscribersHasMore,
  setIsSearchPermissionMerchantsHasMore,
  setIsSearchPermissionMerchantsHitted,
  setIsSearchPermissionSubscribersHasMore,
  setIsSearchPermissionSubscribersHitted,
  setPermissionMerchants,
  setPermissionSubscribers,
  setSearchPermissionMerchants,
  setSearchPermissionMerchantsFailed,
  setSearchPermissionMerchantsLoading,
  setSearchPermissionMerchantsSuccess,
  setSearchPermissionSubscribers,
  setSearchPermissionSubscribersFailed,
  setSearchPermissionSubscribersLoading,
  setSearchPermissionSubscribersSuccess,
} from "./select-permission-merchant.action";

export function* _getFetchPermissionMerchants({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchPermissionMerchantsLoading(true));

    const {
      meta: { message },
      data: permissionMerchants,
    } = yield call(getPermissionMerchants, parameters);

    yield put(setIsFetchPermissionMerchantsHitted(true));
    yield put(
      setIsPermissionMerchantsHasMore(
        Object.keys(permissionMerchants).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendPermissionMerchants(permissionMerchants));
    } else {
      yield put(setPermissionMerchants(permissionMerchants));
    }

    yield put(setFetchPermissionMerchantsSuccess(message));
    yield put(setFetchPermissionMerchantsLoading(false));
  } catch (error) {
    yield put(setFetchPermissionMerchantsFailed(error));
    yield put(setFetchPermissionMerchantsLoading(false));
  }
}
export function* _getSearchPermissionMerchants({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchPermissionMerchantsLoading(true));

    const {
      meta: { message },
      data: permissionMerchants,
    } = yield call(getPermissionMerchants, parameters);

    yield put(setIsSearchPermissionMerchantsHitted(true));
    yield put(
      setIsSearchPermissionMerchantsHasMore(
        Object.keys(permissionMerchants).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendSearchPermissionMerchants(permissionMerchants));
    } else {
      yield put(setSearchPermissionMerchants(permissionMerchants));
    }

    yield put(setSearchPermissionMerchantsSuccess(message));
    yield put(setSearchPermissionMerchantsLoading(false));
  } catch (error) {
    yield put(setSearchPermissionMerchantsFailed(error));
    yield put(setSearchPermissionMerchantsLoading(false));
  }
}
export function* _getAppendPermissionMerchants({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendPermissionMerchantsLoading(true));

    const {
      meta: { message },
      data: permissionMerchants,
    } = yield call(getPermissionMerchants, parameters);

    yield put(setIsAppendPermissionMerchantsHitted(true));
    yield put(appendPermissionMerchants(permissionMerchants));

    yield put(setAppendPermissionMerchantsSuccess(message));
    yield put(setAppendPermissionMerchantsLoading(false));
  } catch (error) {
    yield put(setAppendPermissionMerchantsFailed(error));
    yield put(setAppendPermissionMerchantsLoading(false));
  }
}
export function* _getFetchPermissionSubscribers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchPermissionSubscribersLoading(true));

    const {
      meta: { message },
      data: permissionSubscribers,
    } = yield call(getPermissionMerchants, parameters);

    yield put(setIsFetchPermissionSubscribersHitted(true));
    yield put(
      setIsPermissionSubscribersHasMore(
        Object.keys(permissionSubscribers).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendPermissionSubscribers(permissionSubscribers));
    } else {
      yield put(setPermissionSubscribers(permissionSubscribers));
    }

    yield put(setFetchPermissionSubscribersSuccess(message));
    yield put(setFetchPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchPermissionSubscribersFailed(error));
    yield put(setFetchPermissionSubscribersLoading(false));
  }
}
export function* _getSearchPermissionSubscribers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchPermissionSubscribersLoading(true));

    const {
      meta: { message },
      data: permissionSubscribers,
    } = yield call(getPermissionMerchants, parameters);

    yield put(setIsSearchPermissionSubscribersHitted(true));
    yield put(
      setIsSearchPermissionSubscribersHasMore(
        Object.keys(permissionSubscribers).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendSearchPermissionSubscribers(permissionSubscribers));
    } else {
      yield put(setSearchPermissionSubscribers(permissionSubscribers));
    }

    yield put(setSearchPermissionSubscribersSuccess(message));
    yield put(setSearchPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setSearchPermissionSubscribersFailed(error));
    yield put(setSearchPermissionSubscribersLoading(false));
  }
}
export function* _getAppendPermissionSubscribers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendPermissionSubscribersLoading(true));

    const {
      meta: { message },
      data: permissionSubscribers,
    } = yield call(getPermissionMerchants, parameters);

    yield put(setIsAppendPermissionSubscribersHitted(true));
    yield put(appendPermissionSubscribers(permissionSubscribers));

    yield put(setAppendPermissionSubscribersSuccess(message));
    yield put(setAppendPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setAppendPermissionSubscribersFailed(error));
    yield put(setAppendPermissionSubscribersLoading(false));
  }
}

export function* onFetchPermissionMerchantsStart() {
  yield takeLatest(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_MERCHANTS_START,
    _getFetchPermissionMerchants
  );
}
export function* onSearchPermissionMerchantsStart() {
  yield takeLatest(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SEARCH_PERMISSION_MERCHANTS_START,
    _getSearchPermissionMerchants
  );
}
export function* onAppendPermissionMerchantsStart() {
  yield takeLatest(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_MERCHANTS_START,
    _getAppendPermissionMerchants
  );
}
export function* onFetchPermissionSubscribersStart() {
  yield takeLatest(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START,
    _getFetchPermissionSubscribers
  );
}
export function* onSearchPermissionSubscribersStart() {
  yield takeLatest(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SEARCH_PERMISSION_SUBSCRIBERS_START,
    _getSearchPermissionSubscribers
  );
}
export function* onAppendPermissionSubscribersStart() {
  yield takeLatest(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS_START,
    _getAppendPermissionSubscribers
  );
}

export function* selectPermissionMerchantSaga() {
  yield all([
    call(onFetchPermissionMerchantsStart),
    call(onSearchPermissionMerchantsStart),
    call(onAppendPermissionMerchantsStart),
    call(onFetchPermissionSubscribersStart),
    call(onSearchPermissionSubscribersStart),
    call(onAppendPermissionSubscribersStart),
  ]);
}
