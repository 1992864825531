import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreatePaymentDeviceFailed,
  setCreatePaymentDeviceSuccess,
  setDeletePaymentDeviceFailed,
  setDeletePaymentDeviceSuccess,
  setFetchPaymentDeviceFailed,
  setFetchPaymentDevicesFailed,
  setUpdatePaymentDeviceFailed,
  setUpdatePaymentDeviceSuccess,
} from "../../store/payment-device/payment-device.action";
import {
  getCreatePaymentDeviceFailed,
  getCreatePaymentDeviceSuccess,
  getDeletePaymentDeviceFailed,
  getDeletePaymentDeviceSuccess,
  getFetchPaymentDeviceFailed,
  getFetchPaymentDevicesFailed,
  getUpdatePaymentDeviceFailed,
  getUpdatePaymentDeviceSuccess,
} from "../../store/payment-device/payment-device.selector";

const PaymentDevicePopup = () => {
  const dispatch = useDispatch();

  const createPaymentDeviceSuccess = useSelector(getCreatePaymentDeviceSuccess);
  const updatePaymentDeviceSuccess = useSelector(getUpdatePaymentDeviceSuccess);
  const deletePaymentDeviceSuccess = useSelector(getDeletePaymentDeviceSuccess);
  const fetchPaymentDevicesFailed = useSelector(getFetchPaymentDevicesFailed);
  const fetchPaymentDeviceFailed = useSelector(getFetchPaymentDeviceFailed);
  const createPaymentDeviceFailed = useSelector(getCreatePaymentDeviceFailed);
  const updatePaymentDeviceFailed = useSelector(getUpdatePaymentDeviceFailed);
  const deletePaymentDeviceFailed = useSelector(getDeletePaymentDeviceFailed);

  useEffect(() => {
    if (
      createPaymentDeviceSuccess !== null ||
      updatePaymentDeviceSuccess !== null ||
      deletePaymentDeviceSuccess !== null
    ) {
      showSuccessMessage(createPaymentDeviceSuccess ?? updatePaymentDeviceSuccess ?? deletePaymentDeviceSuccess);
      dispatch(setIsModalOpen(false));

      if (createPaymentDeviceSuccess !== null) dispatch(setCreatePaymentDeviceSuccess(null));
      if (updatePaymentDeviceSuccess !== null) dispatch(setUpdatePaymentDeviceSuccess(null));
      if (deletePaymentDeviceSuccess !== null) dispatch(setDeletePaymentDeviceSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPaymentDeviceSuccess, updatePaymentDeviceSuccess, deletePaymentDeviceSuccess]);

  useEffect(() => {
    if (
      fetchPaymentDevicesFailed !== null ||
      fetchPaymentDeviceFailed !== null ||
      createPaymentDeviceFailed !== null ||
      updatePaymentDeviceFailed !== null ||
      deletePaymentDeviceFailed !== null
    ) {
      showErrorMessage(
        fetchPaymentDevicesFailed ??
          fetchPaymentDeviceFailed ??
          createPaymentDeviceFailed ??
          updatePaymentDeviceFailed ??
          deletePaymentDeviceFailed
      );

      if (fetchPaymentDevicesFailed !== null) dispatch(setFetchPaymentDevicesFailed(null));
      if (fetchPaymentDeviceFailed !== null) dispatch(setFetchPaymentDeviceFailed(null));
      if (createPaymentDeviceFailed !== null) dispatch(setCreatePaymentDeviceFailed(null));
      if (updatePaymentDeviceFailed !== null) dispatch(setUpdatePaymentDeviceFailed(null));
      if (deletePaymentDeviceFailed !== null) dispatch(setDeletePaymentDeviceFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPaymentDevicesFailed,
    fetchPaymentDeviceFailed,
    createPaymentDeviceFailed,
    updatePaymentDeviceFailed,
    deletePaymentDeviceFailed,
  ]);

  return <></>;
};

export default PaymentDevicePopup;
