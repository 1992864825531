import { createAction } from "../../utils/store.utils";

import SELECT_PERMISSION_MERCHANT_ACTION_TYPES from "./select-permission-merchant.type";

export const setPermissionMerchants = (permissionMerchants) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_MERCHANTS,
    permissionMerchants
  );
export const setPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const setSearchPermissionMerchants = (searchPermissionMerchants) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS,
    searchPermissionMerchants
  );
export const setSearchPermissionSubscribers = (searchPermissionSubscribers) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS,
    searchPermissionSubscribers
  );

export const appendPermissionMerchants = (permissionMerchants) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_MERCHANTS,
    permissionMerchants
  );
export const appendPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const appendSearchPermissionMerchants = (searchPermissionMerchants) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SEARCH_PERMISSION_MERCHANTS,
    searchPermissionMerchants
  );
export const appendSearchPermissionSubscribers = (
  searchPermissionSubscribers
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SEARCH_PERMISSION_SUBSCRIBERS,
    searchPermissionSubscribers
  );

export const setIsPermissionMerchantsHasMore = (isPermissionMerchantsHasMore) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_MERCHANTS_HAS_MORE,
    isPermissionMerchantsHasMore
  );
export const setIsPermissionSubscribersHasMore = (
  isPermissionSubscribersHasMore
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isPermissionSubscribersHasMore
  );
export const setIsSearchPermissionMerchantsHasMore = (
  isSearchPermissionMerchantsHasMore
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_MERCHANTS_HAS_MORE,
    isSearchPermissionMerchantsHasMore
  );
export const setIsSearchPermissionSubscribersHasMore = (
  isSearchPermissionSubscribersHasMore
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isSearchPermissionSubscribersHasMore
  );

export const setFetchPermissionMerchantsParams = (
  fetchPermissionMerchantsParams
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_PARAMS,
    fetchPermissionMerchantsParams
  );
export const setFetchPermissionMerchantsLoading = (
  fetchPermissionMerchantsLoading
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_LOADING,
    fetchPermissionMerchantsLoading
  );
export const setFetchPermissionMerchantsSuccess = (
  fetchPermissionMerchantsSuccess
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SUCCESS,
    fetchPermissionMerchantsSuccess
  );
export const setFetchPermissionMerchantsFailed = (
  fetchPermissionMerchantsFailed
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FAILED,
    fetchPermissionMerchantsFailed
  );

export const setFetchPermissionSubscribersParams = (
  fetchPermissionSubscribersParams
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PARAMS,
    fetchPermissionSubscribersParams
  );
export const setFetchPermissionSubscribersLoading = (
  fetchPermissionSubscribersLoading
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING,
    fetchPermissionSubscribersLoading
  );
export const setFetchPermissionSubscribersSuccess = (
  fetchPermissionSubscribersSuccess
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS,
    fetchPermissionSubscribersSuccess
  );
export const setFetchPermissionSubscribersFailed = (
  fetchPermissionSubscribersFailed
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED,
    fetchPermissionSubscribersFailed
  );

export const setSearchPermissionMerchantsParams = (
  searchPermissionMerchantsParams
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_PARAMS,
    searchPermissionMerchantsParams
  );
export const setSearchPermissionMerchantsLoading = (
  searchPermissionMerchantsLoading
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_LOADING,
    searchPermissionMerchantsLoading
  );
export const setSearchPermissionMerchantsSuccess = (
  searchPermissionMerchantsSuccess
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_SUCCESS,
    searchPermissionMerchantsSuccess
  );
export const setSearchPermissionMerchantsFailed = (
  searchPermissionMerchantsFailed
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_MERCHANTS_FAILED,
    searchPermissionMerchantsFailed
  );

export const setSearchPermissionSubscribersParams = (
  searchPermissionSubscribersParams
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_PARAMS,
    searchPermissionSubscribersParams
  );
export const setSearchPermissionSubscribersLoading = (
  searchPermissionSubscribersLoading
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_LOADING,
    searchPermissionSubscribersLoading
  );
export const setSearchPermissionSubscribersSuccess = (
  searchPermissionSubscribersSuccess
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_SUCCESS,
    searchPermissionSubscribersSuccess
  );
export const setSearchPermissionSubscribersFailed = (
  searchPermissionSubscribersFailed
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_FAILED,
    searchPermissionSubscribersFailed
  );

export const setAppendPermissionMerchantsParams = (
  appendPermissionMerchantsParams
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_PARAMS,
    appendPermissionMerchantsParams
  );
export const setAppendPermissionMerchantsLoading = (
  appendPermissionMerchantsLoading
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_LOADING,
    appendPermissionMerchantsLoading
  );
export const setAppendPermissionMerchantsSuccess = (
  appendPermissionMerchantsSuccess
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_SUCCESS,
    appendPermissionMerchantsSuccess
  );
export const setAppendPermissionMerchantsFailed = (
  appendPermissionMerchantsFailed
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_MERCHANTS_FAILED,
    appendPermissionMerchantsFailed
  );

export const setAppendPermissionSubscribersParams = (
  appendPermissionSubscribersParams
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_PARAMS,
    appendPermissionSubscribersParams
  );
export const setAppendPermissionSubscribersLoading = (
  appendPermissionSubscribersLoading
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_LOADING,
    appendPermissionSubscribersLoading
  );
export const setAppendPermissionSubscribersSuccess = (
  appendPermissionSubscribersSuccess
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_SUCCESS,
    appendPermissionSubscribersSuccess
  );
export const setAppendPermissionSubscribersFailed = (
  appendPermissionSubscribersFailed
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_FAILED,
    appendPermissionSubscribersFailed
  );

export const setIsFetchPermissionMerchantsHitted = (
  isFetchPermissionMerchantsHitted
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_MERCHANTS_HITTED,
    isFetchPermissionMerchantsHitted
  );
export const setIsFetchPermissionSubscribersHitted = (
  isFetchPermissionSubscribersHitted
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED,
    isFetchPermissionSubscribersHitted
  );
export const setIsSearchPermissionMerchantsHitted = (
  isSearchPermissionMerchantsHitted
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_MERCHANTS_HITTED,
    isSearchPermissionMerchantsHitted
  );
export const setIsSearchPermissionSubscribersHitted = (
  isSearchPermissionSubscribersHitted
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HITTED,
    isSearchPermissionSubscribersHitted
  );
export const setIsAppendPermissionMerchantsHitted = (
  isAppendPermissionMerchantsHitted
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_PERMISSION_MERCHANTS_HITTED,
    isAppendPermissionMerchantsHitted
  );
export const setIsAppendPermissionSubscribersHitted = (
  isAppendPermissionSubscribersHitted
) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_PERMISSION_SUBSCRIBERS_HITTED,
    isAppendPermissionSubscribersHitted
  );

export const fetchPermissionMerchantsStart = (params) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_MERCHANTS_START,
    params
  );
export const fetchPermissionSubscribersStart = (params) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START,
    params
  );
export const searchPermissionMerchantsStart = (params) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SEARCH_PERMISSION_MERCHANTS_START,
    params
  );
export const searchPermissionSubscribersStart = (params) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.SEARCH_PERMISSION_SUBSCRIBERS_START,
    params
  );
export const appendPermissionMerchantsStart = (params) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_MERCHANTS_START,
    params
  );
export const appendPermissionSubscribersStart = (params) =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS_START,
    params
  );

export const resetPermissionMerchantReducer = () =>
  createAction(
    SELECT_PERMISSION_MERCHANT_ACTION_TYPES.RESET_SELECT_PERMISSION_MERCHANT_REDUCER
  );
