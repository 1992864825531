import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getIsRightToLeft } from "../../store/global/global.selector";
import { setIsModalOpen } from "../../store/component/component.action";

import {
  GettingStartedItemContainer,
  GettingStartedItemDescription,
  GettingStartedItemIcon,
  GettingStartedItemInfo,
  GettingStartedItemInfoArrow,
  GettingStartedItemInfoCheck,
  GettingStartedItemTitle,
} from "./getting-started-item.style";

import { ReactComponent as Tick2Svg } from "../../assets/icons/Tick2.svg";
import { ReactComponent as ChevronLeft2Svg } from "../../assets/icons/ChevronLeft2.svg";
import { ReactComponent as ChevronRight2Svg } from "../../assets/icons/ChevronRight2.svg";

const GettingStartedItem = ({ startedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isRightToLeft = useSelector(getIsRightToLeft);

  const { icon, title, description, route, isDone, isActive } =
    startedItem ?? {};

  const handleItemClick = () => {
    if (!isActive) return;

    navigate(route);
    dispatch(setIsModalOpen(false));
  };

  return (
    <GettingStartedItemContainer isActive={isActive} onClick={handleItemClick}>
      <GettingStartedItemIcon>{icon}</GettingStartedItemIcon>
      <GettingStartedItemInfo>
        <GettingStartedItemTitle>{title}</GettingStartedItemTitle>
        <GettingStartedItemDescription>
          {description}
        </GettingStartedItemDescription>
      </GettingStartedItemInfo>
      {isDone ? (
        <GettingStartedItemInfoCheck>
          <Tick2Svg />
        </GettingStartedItemInfoCheck>
      ) : (
        isActive && (
          <GettingStartedItemInfoArrow>
            {isRightToLeft ? <ChevronLeft2Svg /> : <ChevronRight2Svg />}
          </GettingStartedItemInfoArrow>
        )
      )}
    </GettingStartedItemContainer>
  );
};

export default GettingStartedItem;
