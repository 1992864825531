import styled, { css, keyframes } from "styled-components";
import { SHIMMER_RADIUS } from "./shimmer.component";

const shimmerAnimation = keyframes`
  50% { opacity: 0.5; }
  0%, 100% { opacity: 1; }
`;

export const ShimmerContainer = styled.span`
  position: relative;
  display: inline-block;
  background-color: #dddbdd;
  animation: ${shimmerAnimation} 1.5s infinite;

  ${({ radius }) =>
    radius === SHIMMER_RADIUS.PX8 &&
    css`
      border-radius: 8px;
    `}

  ${({ radius }) =>
    radius === SHIMMER_RADIUS.PX12 &&
    css`
      border-radius: 12px;
    `}

  ${({ radius }) =>
    radius === SHIMMER_RADIUS.PX16 &&
    css`
      border-radius: 16px;
    `}

  ${({ radius }) =>
    radius === SHIMMER_RADIUS.PX18 &&
    css`
      border-radius: 18px;
    `}

  ${({ radius }) =>
    radius === SHIMMER_RADIUS.PX24 &&
    css`
      border-radius: 24px;
    `}

  ${({ radius }) =>
    radius === SHIMMER_RADIUS.CIRCLE &&
    css`
      border-radius: 50%;
    `}

  ${({ shimmerWidth }) =>
    shimmerWidth &&
    css`
      width: calc(${shimmerWidth});
    `}

  ${({ shimmerMinWidth }) =>
    shimmerMinWidth &&
    css`
      min-width: calc(${shimmerMinWidth});
    `}

  ${({ shimmerMaxWidth }) =>
    shimmerMaxWidth &&
    css`
      max-width: calc(${shimmerMaxWidth});
    `}

  ${({ shimmerHeight }) =>
    shimmerHeight &&
    css`
      height: calc(${shimmerHeight});
    `}

  ${({ shimmerMinHeight }) =>
    shimmerMinHeight &&
    css`
      min-height: calc(${shimmerMinHeight});
    `}

  ${({ shimmerMaxHeight }) =>
    shimmerMaxHeight &&
    css`
      max-height: calc(${shimmerMaxHeight});
    `}

  ${({ shimmerAspectRatio }) =>
    shimmerAspectRatio &&
    css`
      aspect-ratio: ${shimmerAspectRatio};
    `}

  ${({ shimmerMarginTop }) =>
    shimmerMarginTop &&
    (shimmerMarginTop === "auto"
      ? css`
          margin-top: auto;
        `
      : css`
          margin-top: calc(${shimmerMarginTop});
        `)}

  ${({ shimmerMarginBottom }) =>
    shimmerMarginBottom &&
    (shimmerMarginBottom === "auto"
      ? css`
          margin-bottom: auto;
        `
      : css`
          margin-bottom: calc(${shimmerMarginBottom});
        `)}

  ${({ shimmerMarginRight }) =>
    shimmerMarginRight &&
    (shimmerMarginRight === "auto"
      ? css`
          margin-right: auto;
        `
      : css`
          margin-right: calc(${shimmerMarginRight});
        `)}

  ${({ shimmerMarginRight, isRtl }) =>
    shimmerMarginRight &&
    isRtl &&
    (shimmerMarginRight === "auto"
      ? css`
          margin-left: auto !important;
          margin-right: initial;
        `
      : css`
          margin-left: calc(${shimmerMarginRight}) !important;
          margin-right: initial;
        `)}

  ${({ shimmerMarginLeft }) =>
    shimmerMarginLeft &&
    (shimmerMarginLeft === "auto"
      ? css`
          margin-left: auto;
        `
      : css`
          margin-left: calc(${shimmerMarginLeft});
        `)}

  ${({ shimmerMarginLeft, isRtl }) =>
    shimmerMarginLeft &&
    isRtl &&
    (shimmerMarginLeft === "auto"
      ? css`
          margin-right: auto !important;
          margin-right: initial;
        `
      : css`
          margin-right: calc(${shimmerMarginLeft}) !important;
          margin-right: initial;
        `)}

  @media (max-width: 576px) {
    ${({ shimmerWidthXs }) =>
      shimmerWidthXs &&
      css`
        width: calc(${shimmerWidthXs});
      `}

    ${({ shimmerHeightXs }) =>
      shimmerHeightXs &&
      css`
        height: calc(${shimmerHeightXs});
      `}

    ${({ hideXs }) =>
      hideXs &&
      css`
        display: none;
      `}
  }

  @media (min-width: 576px) and (max-width: 768px) {
    ${({ shimmerWidthSm }) =>
      shimmerWidthSm &&
      css`
        width: calc(${shimmerWidthSm});
      `}

    ${({ shimmerHeightSm }) =>
      shimmerHeightSm &&
      css`
        height: calc(${shimmerHeightSm});
      `}

    ${({ hideSm }) =>
      hideSm &&
      css`
        display: none;
      `}
  }

  @media (min-width: 768px) and (max-width: 992px) {
    ${({ shimmerWidthMd }) =>
      shimmerWidthMd &&
      css`
        width: calc(${shimmerWidthMd});
      `}

    ${({ shimmerHeightMd }) =>
      shimmerHeightMd &&
      css`
        height: calc(${shimmerHeightMd});
      `}

    ${({ hideMd }) =>
      hideMd &&
      css`
        display: none;
      `}
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    ${({ shimmerWidthLg }) =>
      shimmerWidthLg &&
      css`
        width: calc(${shimmerWidthLg});
      `}

    ${({ shimmerHeightLg }) =>
      shimmerHeightLg &&
      css`
        height: calc(${shimmerHeightLg});
      `}

    ${({ hideLg }) =>
      hideLg &&
      css`
        display: none;
      `}
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    ${({ shimmerWidthXl }) =>
      shimmerWidthXl &&
      css`
        width: calc(${shimmerWidthXl});
      `}

    ${({ shimmerHeightXl }) =>
      shimmerHeightXl &&
      css`
        height: calc(${shimmerHeightXl});
      `}

    ${({ hideXl }) =>
      hideXl &&
      css`
        display: none;
      `}
  }

  @media (min-width: 1400px) {
    ${({ shimmerWidthXxl }) =>
      shimmerWidthXxl &&
      css`
        width: calc(${shimmerWidthXxl});
      `}

    ${({ shimmerHeightXxl }) =>
      shimmerHeightXxl &&
      css`
        height: calc(${shimmerHeightXxl});
      `}

    ${({ hideXxl }) =>
      hideXxl &&
      css`
        display: none;
      `}
  }
`;
