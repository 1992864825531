import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setAuthCheckFailed,
  setAuthCheckSuccess,
  setFetchMeFailed,
  setFetchMeSuccess,
  setSignInFailed,
  setSignInSuccess,
  setSignOutFailed,
  setSignOutSuccess,
  setSignUpFailed,
  setSignUpSuccess,
  setUpdateMeFailed,
  setUpdateMeSuccess,
} from "../../store/authentication/authentication.action";
import {
  getAuthCheckFailed,
  getAuthCheckSuccess,
  getFetchMeFailed,
  getFetchMeSuccess,
  getSignInFailed,
  getSignInSuccess,
  getSignOutFailed,
  getSignOutSuccess,
  getSignUpFailed,
  getSignUpSuccess,
  getUpdateMeFailed,
  getUpdateMeSuccess,
} from "../../store/authentication/authentication.selector";

const AuthenticationPopup = () => {
  const dispatch = useDispatch();

  const authCheckSuccess = useSelector(getAuthCheckSuccess);
  const fetchMeSuccess = useSelector(getFetchMeSuccess);
  const updateMeSuccess = useSelector(getUpdateMeSuccess);
  const signInSuccess = useSelector(getSignInSuccess);
  const signUpSuccess = useSelector(getSignUpSuccess);
  const signOutSuccess = useSelector(getSignOutSuccess);

  const authCheckFailed = useSelector(getAuthCheckFailed);
  const fetchMeFailed = useSelector(getFetchMeFailed);
  const updateMeFailed = useSelector(getUpdateMeFailed);
  const signInFailed = useSelector(getSignInFailed);
  const signUpFailed = useSelector(getSignUpFailed);
  const signOutFailed = useSelector(getSignOutFailed);

  useEffect(() => {
    if (
      authCheckSuccess !== null ||
      fetchMeSuccess !== null ||
      updateMeSuccess !== null ||
      signInSuccess !== null ||
      signUpSuccess !== null ||
      signOutSuccess !== null
    ) {
      if (
        signInSuccess !== null ||
        signUpSuccess !== null ||
        signOutSuccess !== null
      ) {
        showSuccessMessage(signInSuccess ?? signUpSuccess ?? signOutSuccess);
      }

      if (authCheckSuccess !== null) dispatch(setAuthCheckSuccess(null));
      if (fetchMeSuccess !== null) dispatch(setFetchMeSuccess(null));
      if (updateMeSuccess !== null) dispatch(setUpdateMeSuccess(null));
      if (signInSuccess !== null) dispatch(setSignInSuccess(null));
      if (signUpSuccess !== null) dispatch(setSignUpSuccess(null));
      if (signOutSuccess !== null) dispatch(setSignOutSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authCheckSuccess,
    fetchMeSuccess,
    updateMeSuccess,
    signInSuccess,
    signUpSuccess,
    signOutSuccess,
  ]);

  useEffect(() => {
    if (
      authCheckFailed !== null ||
      fetchMeFailed !== null ||
      updateMeFailed !== null ||
      signInFailed !== null ||
      signUpFailed !== null ||
      signOutFailed !== null
    ) {
      if (
        signInFailed !== null ||
        signUpFailed !== null ||
        signOutFailed !== null
      ) {
        showErrorMessage(signInFailed ?? signUpFailed ?? signOutFailed);
      }

      if (authCheckFailed !== null) dispatch(setAuthCheckFailed(null));
      if (fetchMeFailed !== null) dispatch(setFetchMeFailed(null));
      if (updateMeFailed !== null) dispatch(setUpdateMeFailed(null));
      if (signInFailed !== null) dispatch(setSignInFailed(null));
      if (signUpFailed !== null) dispatch(setSignUpFailed(null));
      if (signOutFailed !== null) dispatch(setSignOutFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authCheckFailed,
    fetchMeFailed,
    updateMeFailed,
    signInFailed,
    signUpFailed,
    signOutFailed,
  ]);

  return <></>;
};

export default AuthenticationPopup;
