import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendProductsFailed,
  setFetchProductsFailed,
  setSearchProductsFailed,
} from "../../store/select-product/select-product.action";
import {
  getAppendProductsFailed,
  getFetchProductsFailed,
  getSearchProductsFailed,
} from "../../store/select-product/select-product.selector";

const SelectProductPopup = () => {
  const dispatch = useDispatch();

  const fetchProductsFailed = useSelector(getFetchProductsFailed);
  const searchProductsFailed = useSelector(getSearchProductsFailed);
  const appendProductsFailed = useSelector(getAppendProductsFailed);

  useEffect(() => {
    if (
      fetchProductsFailed !== null ||
      searchProductsFailed !== null ||
      appendProductsFailed !== null
    ) {
      showErrorMessage(
        fetchProductsFailed ?? searchProductsFailed ?? appendProductsFailed
      );

      if (fetchProductsFailed !== null) dispatch(setFetchProductsFailed(null));
      if (searchProductsFailed !== null)
        dispatch(setSearchProductsFailed(null));
      if (appendProductsFailed !== null)
        dispatch(setAppendProductsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProductsFailed, searchProductsFailed, appendProductsFailed]);

  return <></>;
};

export default SelectProductPopup;
