import styled, { css } from "styled-components";

export const GettingStartedItemIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  max-height: 3rem;
  border-radius: 50%;
  background-color: #e9e9ee;
  transition: all 0.3s ease;

  svg {
    width: 1.75rem;
    min-width: 1.75rem;
    max-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
    max-height: 1.75rem;

    path {
      fill: #0e072f;
    }
  }
`;

export const GettingStartedItemInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const GettingStartedItemTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin-bottom: 0.15rem;
`;

export const GettingStartedItemDescription = styled.p`
  font-size: 0.95rem;
  font-weight: 400;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const GettingStartedItemInfoArrow = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 2.25rem;
  min-width: 2.25rem;
  max-width: 2.25rem;
  height: 2.25rem;
  min-height: 2.25rem;
  max-height: 2.25rem;
  border-radius: 50%;
  background-color: #e9e9ee;
  transition: all 0.3s ease;

  svg {
    width: 1.25rem;
    min-width: 1.25rem;
    max-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    max-height: 1.25rem;

    stroke: #0e072f;
    stroke-width: 2.5;
  }
`;

export const GettingStartedItemInfoCheck = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1.875rem;
  min-width: 1.875rem;
  max-width: 1.875rem;
  height: 1.875rem;
  min-height: 1.875rem;
  max-height: 1.875rem;
  border-radius: 50%;
  background-color: #008d0e;
  transition: all 0.3s ease;

  svg {
    width: 0.875rem;
    min-width: 0.875rem;
    max-width: 0.875rem;
    height: 0.875rem;
    min-height: 0.875rem;
    max-height: 0.875rem;

    stroke: #ffffff;
    stroke-width: 3;
  }
`;

export const GettingStartedItemContainer = styled.div`
  user-select: none;

  display: flex;
  align-items: center;
  column-gap: 1.75rem;

  padding: 1.25rem;
  border: 1.5px solid #e9e9ee;
  border-radius: 1rem;
  background-color: #f9f9fb;
  transition: all 0.3s ease;

  &:hover {
    background-color: #efeff5;

    ${GettingStartedItemIcon} {
      background-color: #dbdbe3;
    }
    ${GettingStartedItemInfoArrow} {
      background-color: #dbdbe3;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      cursor: pointer;
      background-color: #dfdafd;
      border-color: #dfdafd;

      ${GettingStartedItemIcon} {
        background-color: #d3ccfc !important;

        svg path {
          fill: #2900ee;
        }
      }
      ${GettingStartedItemDescription} {
        color: #6e6a82;
      }
      ${GettingStartedItemInfoArrow} {
        background-color: #d3ccfc !important;

        svg {
          stroke: #2900ee;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #e5e0fd;
        border-color: #e5e0fd;
      }
    `}
`;
