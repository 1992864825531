import apiService from "./api";

export const getDashboard = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/dashboards", params })).data;
export const getDashboardAdmin = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/dashboards/admin", params })).data;
export const getDashboardMerchant = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/dashboards/merchant", params }))
    .data;
export const getDashboardSubscriber = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/dashboards/subscriber", params }))
    .data;
