import apiService from "./api";

export const getExtendPeriods = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/extend-periods", params })).data;
export const getExtendPeriod = async (extendId) =>
  (await apiService.innerGet({ urlPath: `/v2/extend-periods/${extendId}` }))
    .data;
export const createExtendPeriod = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/extend-periods", request })).data;
export const updateExtendPeriod = async (extendId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/extend-periods/${extendId}`,
      request,
    })
  ).data;
export const customExtendPeriod = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/extend-periods/custom",
      request,
    })
  ).data;
export const stopExtendPeriod = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/extend-periods/stop", request }))
    .data;

export const calculateCreateExtendPeriod = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/extend-periods/calculate",
      request,
    })
  ).data;
export const calculateUpdateExtendPeriod = async (extendId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/extend-periods/${extendId}/calculate`,
      request,
    })
  ).data;
