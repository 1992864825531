import { lazy } from "react";

import { lazyRetry } from "./common.utils";

import { PERMISSION_MERCHANT_PAGES } from "../constants/permission-merchant.constant";

import { ReactComponent as PageAccountSvg } from "../assets/icons/PageAccount.svg";
import { ReactComponent as PageDashboardSvg } from "../assets/icons/PageDashboard.svg";
import { ReactComponent as PagePermissionSvg } from "../assets/icons/PagePermission.svg";
import { ReactComponent as PageSettingSvg } from "../assets/icons/PageSetting.svg";
import { ReactComponent as PageSubscriberSvg } from "../assets/icons/PageSubscriber.svg";

const AccountMerchant = lazy(() =>
  lazyRetry(() => import("../routes/account-merchant/account-merchant.route"))
);
const DashboardMerchant = lazy(() =>
  lazyRetry(() =>
    import("../routes/dashboard-merchant/dashboard-merchant.route")
  )
);
const PermissionMerchant = lazy(() =>
  lazyRetry(() =>
    import("../routes/permission-merchant/permission-merchant.route")
  )
);
const Settings = lazy(() =>
  lazyRetry(() => import("../routes/settings/settings.route"))
);
const Subscriber = lazy(() =>
  lazyRetry(() => import("../routes/subscriber/subscriber.route"))
);
const SubscriberCreate = lazy(() =>
  lazyRetry(() => import("../routes/subscriber-create/subscriber-create.route"))
);
const SubscriberDetails = lazy(() =>
  lazyRetry(() =>
    import("../routes/subscriber-details/subscriber-details.route")
  )
);

const pageDetails = {
  [PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT]: {
    key: PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT,
    icon: PageAccountSvg,
    name: "Accounts",
    route: "/settings/account",
    description: "Access and manage account information.",
  },
  [PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD]: {
    key: PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD,
    icon: PageDashboardSvg,
    name: "Dashboard",
    route: "/dashboard",
    description: "Overview of essential data points.",
  },
  [PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION]: {
    key: PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION,
    icon: PagePermissionSvg,
    name: "Permissions",
    route: "/settings/permission",
    description: "Organize permissions for better account control.",
  },
  [PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER]: {
    key: PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER,
    icon: PageSubscriberSvg,
    name: "Subscribers",
    route: "/subscriber",
    description: "Overview of active subscriber details.",
  },
};

export const generalNavPages = [
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD],
    icon: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD].icon,
    name: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD].name,
    route: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD].route,
  },
];
export const featureNavPages = [
  {
    keys: PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER,
    icon: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER].icon,
    name: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER].name,
    route: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER].route,
  },
  {
    keys: [
      PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT,
      PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION,
    ],
    icon: PageSettingSvg,
    name: "Settings",
    route: "/settings",
  },
];
export const settingsPages = [
  {
    key: PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT,
    icon: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT].icon,
    name: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT].name,
    route: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT].route,
    description:
      pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT].description,
  },
  {
    key: PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION,
    icon: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION].icon,
    name: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION].name,
    route: pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION].route,
    description:
      pageDetails[PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION].description,
  },
];
export const protectedRoutes = [
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_DASHBOARD],
    path: "/dashboard",
    element: DashboardMerchant,
  },
  {
    keys: [
      PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT,
      PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION,
    ],
    path: "/settings",
    element: Settings,
  },
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_ACCOUNT],
    path: "/settings/account",
    element: AccountMerchant,
  },
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_PERMISSION],
    path: "/settings/permission",
    element: PermissionMerchant,
  },
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber",
    element: Subscriber,
  },
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber/create",
    element: SubscriberCreate,
  },
  {
    keys: [PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber/:marketId",
    element: SubscriberDetails,
  },
];

export const getPageDetails = (pageKey) => pageDetails?.[pageKey];
