import apiService from "./api";

export const createProductModifier = async (productId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/product/${productId}/modifier`,
      request,
    })
  ).data;
export const updateProductModifier = async (productId, modifierId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/product/${productId}/modifier/${modifierId}`,
      request,
    })
  ).data;
export const getProductModifierByBarcode = async (barcode) =>
  (await apiService.innerGet({ urlPath: `/product-modifier/${barcode}` })).data;
