import apiService from "./api";

export const getUserAdmins = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/admins", params })).data;
export const getUserAdmin = async (adminId) =>
  (await apiService.innerGet({ urlPath: `/v2/users/admins/${adminId}` })).data;
export const createUserAdmin = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/admins", request })).data;
export const updateUserAdmin = async (adminId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/admins/${adminId}`,
      request,
    })
  ).data;
export const deleteUserAdmin = async (adminId) =>
  (await apiService.innerDelete({ urlPath: `/v2/users/admins/${adminId}` }))
    .data;
