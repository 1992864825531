import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import {
  setCurrentModal,
  setIsModalOpen,
} from "../../store/component/component.action";
import { getUser } from "../../store/authentication/authentication.selector";
import {
  fetchAnnouncementsStart,
  readAnnouncementStart,
  setFetchAnnouncementsFilterDisplayAt,
  setFetchAnnouncementsFilterIsRead,
  setFetchAnnouncementsFilterRole,
  setFetchAnnouncementsFilterUserId,
  setFetchAnnouncementsPage,
  setFetchAnnouncementsPerPage,
} from "../../store/announcement/announcement.action";
import {
  getAnnouncements,
  getFetchAnnouncementsLoading,
  getFetchAnnouncementsPage,
  getIsAnnouncementsHasMore,
} from "../../store/announcement/announcement.selector";

import NotificationList from "../notification-list/notification-list.widget";
import Modal, { MODAL_POSITIONS } from "../../components/modal/modal.component";

import { ReactComponent as NotificationSvg } from "../../assets/icons/Notification.svg";

export const modalName = "GLOBAL_ANNOUNCEMENT";

const GlobalAnnouncement = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authenticationUser = useSelector(getUser);
  const { id, role } = authenticationUser ?? {};

  const fetchAnnouncementsLoading = useSelector(getFetchAnnouncementsLoading);
  const fetchAnnouncementsPage = useSelector(getFetchAnnouncementsPage);
  const isAnnouncementsHasMore = useSelector(getIsAnnouncementsHasMore);
  const announcements = useSelector(getAnnouncements);

  const onModalClose = () => {
    if (isEmpty(authenticationUser)) return;
    const announcements_ids = announcements.map(({ id }) => id);
    // dispatch(readAnnouncementStart({ user_id: id, announcements_ids }));
  };

  const onAnnouncementBottomScroll = () => {
    dispatch(setFetchAnnouncementsPage(fetchAnnouncementsPage + 1));
    dispatch(setFetchAnnouncementsPerPage(20));
    dispatch(fetchAnnouncementsStart());
  };

  useEffect(() => {
    if (!isEmpty(authenticationUser)) {
      // dispatch(setFetchAnnouncementsFilterDisplayAt(moment().valueOf()));
      // dispatch(setFetchAnnouncementsFilterUserId(id));
      // dispatch(setFetchAnnouncementsFilterIsRead(0));
      // dispatch(setFetchAnnouncementsPage(1));
      // dispatch(setFetchAnnouncementsPerPage(20));
      // dispatch(fetchAnnouncementsStart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationUser]);

  useEffect(() => {
    if (announcements.length > 0) {
      dispatch(setCurrentModal(modalName));
      dispatch(setIsModalOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements]);

  return (
    <Modal
      modalPosition={MODAL_POSITIONS.CENTER}
      name={modalName}
      icon={<NotificationSvg />}
      title={t("Notification")}
      onModalClose={onModalClose}
    >
      <NotificationList
        notifications={announcements}
        page={fetchAnnouncementsPage}
        isLoading={fetchAnnouncementsLoading}
        isHasMore={isAnnouncementsHasMore}
        onBottomScroll={onAnnouncementBottomScroll}
      />
    </Modal>
  );
};

export default GlobalAnnouncement;
