import { lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { lazyRetry } from "../utils/common.utils";
import {
  protectedRoutes as protectedAdminRoutes,
  subscriberRoutes,
} from "../utils/permission-admin.utils";
import { protectedRoutes as protectedMerchantRoutes } from "../utils/permission-merchant.utils";
import { protectedRoutes as protectedSubscriberRoutes } from "../utils/permission-subscriber.utils";

import { USER_ROLES } from "../constants/user.constant";

import { getIsHasActivePlan } from "../store/global/global.selector";
import { getIsHasThanksPage } from "../store/component/component.selector";
import { getUser } from "../store/authentication/authentication.selector";

import NotFound from "./not-found/not-found.route";
import {
  MiddlewareAuthorize,
  MiddlewareMain,
  MiddlewareSubscriber,
} from "./middleware.route";

const SubscriptionSubscriber = lazy(() =>
  lazyRetry(() =>
    import("./subscription-subscriber/subscription-subscriber.route")
  )
);
const SignUpRedirect = lazy(() =>
  lazyRetry(() => import("./sign-up-redirect/sign-up-redirect.route"))
);

const getProtectedRoutes = (role) =>
  ({
    [USER_ROLES.USER_ADMIN]: protectedAdminRoutes,
    [USER_ROLES.USER_MERCHANT]: protectedMerchantRoutes,
    [USER_ROLES.USER_SUBSCRIBER]: protectedSubscriberRoutes,
  }?.[role]);

const ProtectedRoutes = () => {
  const isHasActivePlan = useSelector(getIsHasActivePlan);
  const isHasThanksPage = useSelector(getIsHasThanksPage);
  const authRole = useSelector(getUser)?.role;

  const protectedRoutes = getProtectedRoutes(authRole);

  return (
    <Routes>
      <Route element={<MiddlewareMain />}>
        {protectedRoutes?.map(({ keys, path, element: PageElement }, index) => (
          <Route key={index} element={<MiddlewareAuthorize keys={keys} />}>
            <Route path={path} element={<PageElement />} />
          </Route>
        ))}
        {subscriberRoutes.map(({ keys, path, element: PageElement }, index) => (
          <Route key={index} element={<MiddlewareSubscriber keys={keys} />}>
            <Route path={path} element={<PageElement />} />
          </Route>
        ))}
        {authRole === USER_ROLES.USER_SUBSCRIBER && !isHasActivePlan && (
          <Route path="/subscription" element={<SubscriptionSubscriber />} />
        )}
        {isHasThanksPage && (
          <Route path="/thanks" element={<SignUpRedirect />} />
        )}
        <Route path="/*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
