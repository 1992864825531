import { lazy } from "react";

import { lazyRetry } from "./common.utils";

import { PERMISSION_SUBSCRIBER_PAGES } from "../constants/permission-subscriber.constant";

import { ReactComponent as PageAccountSvg } from "../assets/icons/PageAccount.svg";
import { ReactComponent as PageAdvertisementSvg } from "../assets/icons/PageAdvertisement.svg";
import { ReactComponent as PageApplicationSvg } from "../assets/icons/PageApplication.svg";
import { ReactComponent as PageAuthenticationSvg } from "../assets/icons/PageAuthentication.svg";
import { ReactComponent as PageBranchSvg } from "../assets/icons/PageBranch.svg";
import { ReactComponent as PageCustomerSvg } from "../assets/icons/PageCustomer.svg";
import { ReactComponent as PageDashboardSvg } from "../assets/icons/PageDashboard.svg";
import { ReactComponent as PageDeviceSvg } from "../assets/icons/PageDevice.svg";
import { ReactComponent as PageDiscountSvg } from "../assets/icons/PageDiscount.svg";
import { ReactComponent as PageDriverSvg } from "../assets/icons/PageDriver.svg";
import { ReactComponent as PageInventorySvg } from "../assets/icons/PageInventory.svg";
import { ReactComponent as PageKitchenSvg } from "../assets/icons/PageKitchen.svg";
import { ReactComponent as PageOrderSvg } from "../assets/icons/PageOrder.svg";
import { ReactComponent as PageOrderOfPurchaseSvg } from "../assets/icons/PageOrderOfPurchase.svg";
import { ReactComponent as PagePaymentMethodSvg } from "../assets/icons/PagePaymentMethod.svg";
import { ReactComponent as PagePaymentTransferSvg } from "../assets/icons/PagePaymentTransfer.svg";
import { ReactComponent as PagePermissionSvg } from "../assets/icons/PagePermission.svg";
import { ReactComponent as PagePrinterSvg } from "../assets/icons/PagePrinter.svg";
import { ReactComponent as PagePrinterTypeSvg } from "../assets/icons/PagePrinterType.svg";
import { ReactComponent as PageProductSvg } from "../assets/icons/PageProduct.svg";
import { ReactComponent as PageProfileSvg } from "../assets/icons/PageProfile.svg";
import { ReactComponent as PagePurchaseSvg } from "../assets/icons/PagePurchase.svg";
import { ReactComponent as PageRecipeSvg } from "../assets/icons/PageRecipe.svg";
import { ReactComponent as PageReportSvg } from "../assets/icons/PageReport.svg";
import { ReactComponent as PageRatingSvg } from "../assets/icons/PageRating.svg";
import { ReactComponent as PageScheduleSvg } from "../assets/icons/PageSchedule.svg";
import { ReactComponent as PageScreenCashierSvg } from "../assets/icons/PageScreenCashier.svg";
import { ReactComponent as PageScreenCustomerSvg } from "../assets/icons/PageScreenCustomer.svg";
import { ReactComponent as PageScreenWaitingSvg } from "../assets/icons/PageScreenWaiting.svg";
import { ReactComponent as PageSettingSvg } from "../assets/icons/PageSetting.svg";
import { ReactComponent as PageSubscriptionSvg } from "../assets/icons/PageSubscription.svg";
import { ReactComponent as PageSupplierSvg } from "../assets/icons/PageSupplier.svg";
import { ReactComponent as PageTableSvg } from "../assets/icons/PageTable.svg";
import { ReactComponent as PageTaxSvg } from "../assets/icons/PageTax.svg";

const AccountSubscriber = lazy(() =>
  lazyRetry(() =>
    import("../routes/account-subscriber/account-subscriber.route")
  )
);
const Advertisement = lazy(() =>
  lazyRetry(() => import("../routes/advertisement/advertisement.route"))
);
const ApplicationSubscriber = lazy(() =>
  lazyRetry(() =>
    import("../routes/application-extension/application-extension.route")
  )
);
const Authentication = lazy(() =>
  lazyRetry(() => import("../routes/authentication/authentication.route"))
);
const Branch = lazy(() =>
  lazyRetry(() => import("../routes/branch/branch.route"))
);
const Customer = lazy(() =>
  lazyRetry(() => import("../routes/customer/customer.route"))
);
const CustomerDetails = lazy(() =>
  lazyRetry(() => import("../routes/customer-details/customer-details.route"))
);
const DashboardSubscriber = lazy(() =>
  lazyRetry(() =>
    import("../routes/dashboard-subscriber/dashboard-subscriber.route")
  )
);
const Device = lazy(() =>
  lazyRetry(() => import("../routes/device/device.route"))
);
const Discount = lazy(() =>
  lazyRetry(() => import("../routes/discount/discount.route"))
);
const DiscountCreateCoupon = lazy(() =>
  lazyRetry(() =>
    import("../routes/discount-create-coupon/discount-create-coupon.route")
  )
);
const DiscountCreateOffer = lazy(() =>
  lazyRetry(() =>
    import("../routes/discount-create-offer/discount-create-offer.route")
  )
);
const DiscountCreatePromotion = lazy(() =>
  lazyRetry(() =>
    import(
      "../routes/discount-create-promotion/discount-create-promotion.route"
    )
  )
);
const DiscountEditCoupon = lazy(() =>
  lazyRetry(() =>
    import("../routes/discount-edit-coupon/discount-edit-coupon.route")
  )
);
const DiscountEditOffer = lazy(() =>
  lazyRetry(() =>
    import("../routes/discount-edit-offer/discount-edit-offer.route")
  )
);
const DiscountEditPromotion = lazy(() =>
  lazyRetry(() =>
    import("../routes/discount-edit-promotion/discount-edit-promotion.route")
  )
);
const GeneralSettings = lazy(() =>
  lazyRetry(() => import("../routes/general-settings/general-settings.route"))
);
const Inventory = lazy(() =>
  lazyRetry(() => import("../routes/inventory/inventory.route"))
);
const Kitchen = lazy(() =>
  lazyRetry(() => import("../routes/kitchen/kitchen.route"))
);
const KitchenDetails = lazy(() =>
  lazyRetry(() => import("../routes/kitchen-details/kitchen-details.route"))
);
const Order = lazy(() =>
  lazyRetry(() => import("../routes/order/order.route"))
);
const OrderCreate = lazy(() =>
  lazyRetry(() => import("../routes/order-create/order-create.route"))
);
const OrderEdit = lazy(() =>
  lazyRetry(() => import("../routes/order-edit/order-edit.route"))
);
const OrderCustomer = lazy(() =>
  lazyRetry(() => import("../routes/order-customer/order-customer.route"))
);
const OrderWaiting = lazy(() =>
  lazyRetry(() => import("../routes/order-waiting/order-waiting.route"))
);
const RestockRequest = lazy(() =>
  lazyRetry(() => import("../routes/restock-request/restock-request.route"))
);
const Recipe = lazy(() =>
  lazyRetry(() => import("../routes/recipe/recipe.route"))
);
const Purchase = lazy(() =>
  lazyRetry(() => import("../routes/purchase/purchase.route"))
);
const Schedule = lazy(() =>
  lazyRetry(() => import("../routes/schedule/schedule.route"))
);
const Settings = lazy(() =>
  lazyRetry(() => import("../routes/settings/settings.route"))
);
const PaymentDevice = lazy(() =>
  lazyRetry(() => import("../routes/payment-device/payment-device.route"))
);
const PaymentMethodSubscriber = lazy(() =>
  lazyRetry(() =>
    import("../routes/payment-method-setting/payment-method-setting.route")
  )
);
const PermissionSubscriber = lazy(() =>
  lazyRetry(() =>
    import("../routes/permission-subscriber/permission-subscriber.route")
  )
);
const Printer = lazy(() =>
  lazyRetry(() => import("../routes/printer/printer.route"))
);
const Product = lazy(() =>
  lazyRetry(() => import("../routes/product/product.route"))
);
const ProductCreate = lazy(() =>
  lazyRetry(() => import("../routes/product-create/product-create.route"))
);
const ProductEdit = lazy(() =>
  lazyRetry(() => import("../routes/product-edit/product-edit.route"))
);
const Profile = lazy(() =>
  lazyRetry(() => import("../routes/profile/profile.route"))
);
const Rating = lazy(() =>
  lazyRetry(() => import("../routes/rating/rating.route"))
);
const ReportAttendance = lazy(() =>
  lazyRetry(() => import("../routes/report-attendance/report-attendance.route"))
);
const ReportOrder = lazy(() =>
  lazyRetry(() => import("../routes/report-order/report-order.route"))
);
const ReportSummary = lazy(() =>
  lazyRetry(() => import("../routes/report-summary/report-summary.route"))
);
const SubscriptionSubscriber = lazy(() =>
  lazyRetry(() =>
    import("../routes/subscription-subscriber/subscription-subscriber.route")
  )
);
const Supplier = lazy(() =>
  lazyRetry(() => import("../routes/supplier/supplier.route"))
);
const Table = lazy(() =>
  lazyRetry(() => import("../routes/table/table.route"))
);
const Tax = lazy(() => lazyRetry(() => import("../routes/tax/tax.route")));

const pageDetails = {
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT,
    icon: PageAccountSvg,
    name: "Accounts",
    route: "/settings/account",
    description: "Access and manage account information.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT,
    icon: PageAdvertisementSvg,
    name: "Advertisements",
    route: "/settings/advertisement",
    description: "Control and manage advertisement campaigns.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION,
    icon: PageApplicationSvg,
    name: "Applications",
    route: "/application",
    description: "Manage and explore integrated applications.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION,
    icon: PageAuthenticationSvg,
    name: "Authentication",
    route: "/settings/authentication",
    description: "Manage and update important business details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH,
    icon: PageBranchSvg,
    name: "Branches",
    route: "/settings/branch",
    description: "Control and update branch information.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER,
    icon: PageCustomerSvg,
    name: "Customers",
    route: "/customer",
    description: "Manage and view customer details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD,
    icon: PageDashboardSvg,
    name: "Dashboard",
    route: "/dashboard",
    description: "Overview of essential data points.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE,
    icon: PageDeviceSvg,
    name: "Devices",
    route: "/settings/device",
    description: "Manage and configure connected devices.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT,
    icon: PageDiscountSvg,
    name: "Discounts",
    route: "/discount",
    description: "Manage all available discount types.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_DRIVER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_DRIVER,
    icon: PageDriverSvg,
    name: "Drivers",
    route: null,
    description: "Manage and track driver details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS,
    icon: PageSettingSvg,
    name: "Settings",
    route: "/settings/general",
    description: "Manage system settings and preferences.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY,
    icon: PageInventorySvg,
    name: "Inventory",
    route: "/inventory/item",
    description: "Manage and track inventory items.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN,
    icon: PageKitchenSvg,
    name: "Kitchen",
    route: "/kitchen",
    description: "Manage and track incoming orders.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER,
    icon: PageOrderSvg,
    name: "Orders",
    route: "/order",
    description: "Organize and monitor customer orders.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE,
    icon: PageOrderOfPurchaseSvg,
    name: "Orders of Purchase",
    route: "/inventory/order",
    description: "Manage purchase requests and track approval status.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD,
    icon: PagePaymentMethodSvg,
    name: "Payment Methods",
    route: "/settings/payment-method",
    description: "Manage and update payment methods.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_TRANSFER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_TRANSFER,
    icon: PagePaymentTransferSvg,
    name: "Payment Transfer",
    route: null,
    description: "Enter and manage payment transfer details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION,
    icon: PagePermissionSvg,
    name: "Permissions",
    route: "/settings/permission",
    description: "Organize permissions for better account control.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRINTER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PRINTER,
    icon: PagePrinterSvg,
    name: "Printers",
    route: null,
    description: "Manage and organize printer details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRINTER_TYPE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PRINTER_TYPE,
    icon: PagePrinterTypeSvg,
    name: "Printer Types",
    route: null,
    description: "Manage and organize printer types.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT,
    icon: PageProductSvg,
    name: "Products",
    route: "/product",
    description: "Manage product data and configurations.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE,
    icon: PageProfileSvg,
    name: "Profile",
    route: "/settings/profile",
    description: "Manage and update personal profile details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE,
    icon: PagePurchaseSvg,
    name: "Purchases",
    route: "/inventory/purchase",
    description: "Manage purchase items and track inventory updates.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING,
    icon: PageRatingSvg,
    name: "Ratings",
    route: "/rating",
    description: "View and manage ratings and reviews.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE,
    icon: PageRecipeSvg,
    name: "Recipes",
    route: "/inventory/recipe",
    description: "Manage recipe data and customize ingredients.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT,
    icon: PageReportSvg,
    name: "Reports",
    route: null,
    description: "Overview of statistical reports and insights.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE,
    icon: PageReportSvg,
    name: "Report Attendances",
    route: "/report/attendance",
    description: "Track and analyze attendance records.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER,
    icon: PageReportSvg,
    name: "Report Orders",
    route: "/report/order",
    description: "Access a list of past orders for analysis.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY,
    icon: PageReportSvg,
    name: "Report Summary",
    route: "/report/summary",
    description: "Analyze performance with statistical data and charts.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE,
    icon: PageScheduleSvg,
    name: "Schedules",
    route: "/settings/schedule",
    description: "Organize and monitor work schedules.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_CASHIER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_CASHIER,
    icon: PageScreenCashierSvg,
    name: "Cashier Screen",
    route: null,
    description: "Manage and process customer orders efficiently.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_CUSTOMER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_CUSTOMER,
    icon: PageScreenCustomerSvg,
    name: "Customer Screen",
    route: "/order/customer",
    description: "Monitor your selected items as they are added to the basket.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_WAITING]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_WAITING,
    icon: PageScreenWaitingSvg,
    name: "Waiting Screen",
    route: "/order/waiting",
    description: "Stay informed on your order’s status with real-time updates.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION,
    icon: PageSubscriptionSvg,
    name: "Subscription",
    route: "/settings/subscription",
    description: "Select the subscription plan that fits your needs.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER,
    icon: PageSupplierSvg,
    name: "Suppliers",
    route: "/inventory/supplier",
    description: "Manage and track supplier details.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE,
    icon: PageTableSvg,
    name: "Tables",
    route: "/table",
    description: "Manage and organize table numbers.",
  },
  [PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX]: {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX,
    icon: PageTaxSvg,
    name: "Taxes",
    route: "/settings/tax",
    description: "Configure tax settings for all transactions.",
  },
};

export const generalNavPages = [
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD].route,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE].route,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT].route,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT].route,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER].route,
  },
];
export const featureNavPages = [
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER].route,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN].route,
  },
  {
    keys: [
      PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE,
    ],
    icon: PageInventorySvg,
    name: "Inventory",
    menus: [
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY].name,
        route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY].route,
      },
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE]
          .name,
        route:
          pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE]
            .route,
      },
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE].name,
        route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE].route,
      },
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER].name,
        route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER].route,
      },
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE].name,
        route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE].route,
      },
    ],
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING].route,
  },
  {
    keys: [
      PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY,
    ],
    icon: PageReportSvg,
    name: "Reports",
    menus: [
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE]
          .name,
        route:
          pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE].route,
      },
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER].name,
        route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER].route,
      },
      {
        key: PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY,
        name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY].name,
        route:
          pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY].route,
      },
    ],
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION],
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION].route,
  },
  {
    keys: [
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS,
    ],
    icon: PageSettingSvg,
    name: "Settings",
    route: "/settings",
  },
];
export const settingsPages = [
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX].route,
    description: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD].description,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS,
    icon: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS].icon,
    name: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS].name,
    route: pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS].route,
    description:
      pageDetails[PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS]
        .description,
  },
];
export const protectedRoutes = [
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION],
    path: "/application",
    element: ApplicationSubscriber,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER],
    path: "/customer",
    element: Customer,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER],
    path: "/customer/:customerId",
    element: CustomerDetails,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD],
    path: "/dashboard",
    element: DashboardSubscriber,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount",
    element: Discount,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount/create/coupon",
    element: DiscountCreateCoupon,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount/create/offer",
    element: DiscountCreateOffer,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount/create/promotion",
    element: DiscountCreatePromotion,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount/edit/coupon/:discountId",
    element: DiscountEditCoupon,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount/edit/offer/:discountId",
    element: DiscountEditOffer,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT],
    path: "/discount/edit/promotion/:discountId",
    element: DiscountEditPromotion,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY],
    path: "/inventory/item",
    element: Inventory,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE],
    path: "/inventory/order",
    element: RestockRequest,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE],
    path: "/inventory/purchase",
    element: Purchase,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE],
    path: "/inventory/recipe",
    element: Recipe,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER],
    path: "/inventory/supplier",
    element: Supplier,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN],
    path: "/kitchen",
    element: Kitchen,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN],
    path: "/kitchen/:kitchenGroupId",
    element: KitchenDetails,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER],
    path: "/order",
    element: Order,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER],
    path: "/order/create",
    element: OrderCreate,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER],
    path: "/order/edit/:orderId",
    element: OrderEdit,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_CUSTOMER],
    path: "/order/customer",
    element: OrderCustomer,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_WAITING],
    path: "/order/waiting",
    element: OrderWaiting,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT],
    path: "/product",
    element: Product,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT],
    path: "/product/create",
    element: ProductCreate,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT],
    path: "/product/edit/:productId",
    element: ProductEdit,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING],
    path: "/rating",
    element: Rating,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE],
    path: "/report/attendance",
    element: ReportAttendance,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER],
    path: "/report/order",
    element: ReportOrder,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY],
    path: "/report/summary",
    element: ReportSummary,
  },
  {
    keys: [
      PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION,
      PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS,
    ],
    path: "/settings",
    element: Settings,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT],
    path: "/settings/account",
    element: AccountSubscriber,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT],
    path: "/settings/advertisement",
    element: Advertisement,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION],
    path: "/settings/authentication",
    element: Authentication,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH],
    path: "/settings/branch",
    element: Branch,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE],
    path: "/settings/device",
    element: Device,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE],
    path: "/settings/device/printer",
    element: Printer,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE],
    path: "/settings/device/payment-device",
    element: PaymentDevice,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS],
    path: "/settings/general",
    element: GeneralSettings,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD],
    path: "/settings/payment-method",
    element: PaymentMethodSubscriber,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION],
    path: "/settings/permission",
    element: PermissionSubscriber,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE],
    path: "/settings/profile",
    element: Profile,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE],
    path: "/settings/schedule",
    element: Schedule,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION],
    path: "/settings/subscription",
    element: SubscriptionSubscriber,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX],
    path: "/settings/tax",
    element: Tax,
  },
  {
    keys: [PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE],
    path: "/table",
    element: Table,
  },
];

export const getPageDetails = (pageKey) => pageDetails?.[pageKey];
