import { MAPBOX_ACCESS_TOKEN } from "../config";

import apiService from "./api";

export const getLocation = async (longitude, latitude) =>
  (
    await apiService.outerGet({
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?limit=1&access_token=${MAPBOX_ACCESS_TOKEN}`,
    })
  ).data;
