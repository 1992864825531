import { lazy } from "react";

import { lazyRetry } from "./common.utils";
import {
  protectedRoutes as protectedSubscriberRoutes,
  getPageDetails as getPageSubscriberDetails,
} from "./permission-subscriber.utils";

import { PERMISSION_ADMIN_PAGES } from "../constants/permission-admin.constant";
import { PERMISSION_SUBSCRIBER_PAGES } from "../constants/permission-subscriber.constant";

import { ReactComponent as PageAccountSvg } from "../assets/icons/PageAccount.svg";
import { ReactComponent as PageApplicationSvg } from "../assets/icons/PageApplication.svg";
import { ReactComponent as PageBannerSvg } from "../assets/icons/PageBanner.svg";
import { ReactComponent as PageCountrySvg } from "../assets/icons/PageCountry.svg";
import { ReactComponent as PageDashboardSvg } from "../assets/icons/PageDashboard.svg";
import { ReactComponent as PageInvoiceSvg } from "../assets/icons/PageInvoice.svg";
import { ReactComponent as PageMerchantSvg } from "../assets/icons/PageMerchant.svg";
import { ReactComponent as PagePaymentMethodSvg } from "../assets/icons/PagePaymentMethod.svg";
import { ReactComponent as PagePermissionSvg } from "../assets/icons/PagePermission.svg";
import { ReactComponent as PagePrinterTypeSvg } from "../assets/icons/PagePrinterType.svg";
import { ReactComponent as PageProductCategorySvg } from "../assets/icons/PageProductCategory.svg";
import { ReactComponent as PageReportSvg } from "../assets/icons/PageReport.svg";
import { ReactComponent as PageSectorSvg } from "../assets/icons/PageSector.svg";
import { ReactComponent as PageSettingSvg } from "../assets/icons/PageSetting.svg";
import { ReactComponent as PageSmsSvg } from "../assets/icons/PageSms.svg";
import { ReactComponent as PageSubscriberSvg } from "../assets/icons/PageSubscriber.svg";
import { ReactComponent as PageSubscriptionSvg } from "../assets/icons/PageSubscription.svg";
import { ReactComponent as PageTermConditionSvg } from "../assets/icons/PageTermCondition.svg";

const AccountAdmin = lazy(() =>
  lazyRetry(() => import("../routes/account-admin/account-admin.route"))
);
const ApplicationAdmin = lazy(() =>
  lazyRetry(() => import("../routes/application/application.route"))
);
const Banner = lazy(() =>
  lazyRetry(() => import("../routes/banner/banner.route"))
);
const BannerCreate = lazy(() =>
  lazyRetry(() => import("../routes/banner-create/banner-create.route"))
);
const BannerEdit = lazy(() =>
  lazyRetry(() => import("../routes/banner-edit/banner-edit.route"))
);
const Country = lazy(() =>
  lazyRetry(() => import("../routes/country/country.route"))
);
const DashboardAdmin = lazy(() =>
  lazyRetry(() => import("../routes/dashboard-admin/dashboard-admin.route"))
);
const GeneralSettings = lazy(() =>
  lazyRetry(() => import("../routes/general-settings/general-settings.route"))
);
const Invoice = lazy(() =>
  lazyRetry(() => import("../routes/invoice/invoice.route"))
);
const Merchant = lazy(() =>
  lazyRetry(() => import("../routes/merchant/merchant.route"))
);
const MerchantCreate = lazy(() =>
  lazyRetry(() => import("../routes/merchant-create/merchant-create.route"))
);
const ReportOrder = lazy(() =>
  lazyRetry(() => import("../routes/report-order/report-order.route"))
);
const ReportSummary = lazy(() =>
  lazyRetry(() => import("../routes/report-summary/report-summary.route"))
);
const PaymentMethod = lazy(() =>
  lazyRetry(() => import("../routes/payment-method/payment-method.route"))
);
const PermissionAdmin = lazy(() =>
  lazyRetry(() => import("../routes/permission-admin/permission-admin.route"))
);
const PrinterType = lazy(() =>
  lazyRetry(() => import("../routes/printer-type/printer-type.route"))
);
const ProductCategory = lazy(() =>
  lazyRetry(() => import("../routes/product-category/product-category.route"))
);
const Sector = lazy(() =>
  lazyRetry(() => import("../routes/sector/sector.route"))
);
const SectorCreate = lazy(() =>
  lazyRetry(() => import("../routes/sector-create/sector-create.route"))
);
const SectorEdit = lazy(() =>
  lazyRetry(() => import("../routes/sector-edit/sector-edit.route"))
);
const Settings = lazy(() =>
  lazyRetry(() => import("../routes/settings/settings.route"))
);
const Sms = lazy(() => lazyRetry(() => import("../routes/sms/sms.route")));
const Subscriber = lazy(() =>
  lazyRetry(() => import("../routes/subscriber/subscriber.route"))
);
const SubscriberSubscription = lazy(() =>
  lazyRetry(() =>
    import("../routes/subscriber-subscription/subscriber-subscription.route")
  )
);
const SubscriberCreate = lazy(() =>
  lazyRetry(() => import("../routes/subscriber-create/subscriber-create.route"))
);
const SubscriberDetails = lazy(() =>
  lazyRetry(() =>
    import("../routes/subscriber-details/subscriber-details.route")
  )
);
const SubscriptionAdmin = lazy(() =>
  lazyRetry(() =>
    import("../routes/subscription-admin/subscription-admin.route")
  )
);
const TermCondition = lazy(() =>
  lazyRetry(() => import("../routes/term-condition/term-condition.route"))
);

const pageDetails = {
  [PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT,
    icon: PageAccountSvg,
    name: "Accounts",
    route: "/settings/account",
    description: "Access and manage account information.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_APPLICATION]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_APPLICATION,
    icon: PageApplicationSvg,
    name: "Applications",
    route: "/application",
    description: "Manage and explore integrated applications.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_BANNER]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_BANNER,
    icon: PageBannerSvg,
    name: "Banners",
    route: "/banner",
    description: "Manage banners to effectively inform and engage users.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_COUNTRY]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_COUNTRY,
    icon: PageCountrySvg,
    name: "Countries",
    route: "/country",
    description: "Easily manage country information.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD,
    icon: PageDashboardSvg,
    name: "Dashboard",
    route: "/dashboard",
    description: "Overview of essential data points.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_MERCHANT]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_MERCHANT,
    icon: PageMerchantSvg,
    name: "Merchants",
    route: "/merchant",
    description: "Control and update merchant details.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD,
    icon: PagePaymentMethodSvg,
    name: "Payment Methods",
    route: "/payment-method",
    description: "Manage and update payment methods.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_PERMISSION]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_PERMISSION,
    icon: PagePermissionSvg,
    name: "Permissions",
    route: "/settings/permission",
    description: "Organize permissions for better account control.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE,
    icon: PagePrinterTypeSvg,
    name: "Printer Types",
    route: "/printer-type",
    description: "Manage and organize printer types.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY,
    icon: PageProductCategorySvg,
    name: "Product Categories",
    route: "/product-category",
    description: "Configure and manage product groupings.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_REPORT_ORDER]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_REPORT_ORDER,
    icon: PageReportSvg,
    name: "Report Orders",
    route: "/report/order",
    description: "Access a list of past orders for analysis.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_REPORT_SUMMARY]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_REPORT_SUMMARY,
    icon: PageReportSvg,
    name: "Report Summary",
    route: "/report/summary",
    description: "Analyze performance with statistical data and charts.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_SECTOR]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_SECTOR,
    icon: PageSectorSvg,
    name: "Sectors",
    route: "/sector",
    description: "Manage and organize business types.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_SMS]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_SMS,
    icon: PageSmsSvg,
    name: "SMS",
    route: "/sms",
    description: "Manage SMS campaigns and promotions.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER,
    icon: PageSubscriberSvg,
    name: "Subscribers",
    route: "/subscriber",
    description: "Overview of active subscriber details.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION,
    icon: PageSubscriptionSvg,
    name: "Subscriptions",
    route: "/subscription",
    description: "Manage plans and related features.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_INVOICE]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_INVOICE,
    icon: PageInvoiceSvg,
    name: "Invoices & Renewals",
    route: "/invoice",
    description: "Extend subscriptions and view invoices.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_TERM_CONDITION]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_TERM_CONDITION,
    icon: PageTermConditionSvg,
    name: "Terms & Conditions",
    route: "/settings/term-condition",
    description: "Manage and update terms and conditions.",
  },
  [PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS]: {
    key: PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS,
    icon: PageSettingSvg,
    name: "Settings",
    route: "/settings/general",
    description: "Manage system settings and preferences.",
  },
};

export const generalNavPages = [
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_COUNTRY],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_COUNTRY].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_COUNTRY].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_COUNTRY].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SECTOR],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SECTOR].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SECTOR].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SECTOR].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_APPLICATION],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_APPLICATION].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_APPLICATION].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_APPLICATION].route,
  },
];
export const featureNavPages = [
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_BANNER],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_BANNER].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_BANNER].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_BANNER].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_MERCHANT],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_MERCHANT].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_MERCHANT].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_MERCHANT].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION].route,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_INVOICE],
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_INVOICE].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_INVOICE].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_INVOICE].route,
  },
  {
    keys: [
      PERMISSION_ADMIN_PAGES.PAGE_REPORT_ORDER,
      PERMISSION_ADMIN_PAGES.PAGE_REPORT_SUMMARY,
    ],
    icon: PageReportSvg,
    name: "Reports",
    menus: [
      {
        key: PERMISSION_ADMIN_PAGES.PAGE_REPORT_ORDER,
        name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_REPORT_ORDER].name,
        route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_REPORT_ORDER].route,
      },
      {
        key: PERMISSION_ADMIN_PAGES.PAGE_REPORT_SUMMARY,
        name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_REPORT_SUMMARY].name,
        route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_REPORT_SUMMARY].route,
      },
    ],
  },
  {
    keys: [
      PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT,
      PERMISSION_ADMIN_PAGES.PAGE_PERMISSION,
      PERMISSION_ADMIN_PAGES.PAGE_TERM_CONDITION,
      PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS,
    ],
    icon: PageSettingSvg,
    name: "Settings",
    route: "/settings",
  },
];
export const settingsPages = [
  {
    key: PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT,
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT].route,
    description: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT].description,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PAGE_PERMISSION,
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PERMISSION].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PERMISSION].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PERMISSION].route,
    description:
      pageDetails[PERMISSION_ADMIN_PAGES.PAGE_PERMISSION].description,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS,
    icon: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS].icon,
    name: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS].name,
    route: pageDetails[PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS].route,
    description:
      pageDetails[PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS].description,
  },
];
export const protectedRoutes = [
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_APPLICATION],
    path: "/application",
    element: ApplicationAdmin,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_BANNER],
    path: "/banner",
    element: Banner,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_BANNER],
    path: "/banner/create",
    element: BannerCreate,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_BANNER],
    path: "/banner/edit/:bannerId",
    element: BannerEdit,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_COUNTRY],
    path: "/country",
    element: Country,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_DASHBOARD],
    path: "/dashboard",
    element: DashboardAdmin,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_INVOICE],
    path: "/invoice",
    element: Invoice,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_MERCHANT],
    path: "/merchant",
    element: Merchant,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_MERCHANT],
    path: "/merchant/create",
    element: MerchantCreate,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PAYMENT_METHOD],
    path: "/payment-method",
    element: PaymentMethod,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PRINTER_TYPE],
    path: "/printer-type",
    element: PrinterType,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PRODUCT_CATEGORY],
    path: "/product-category",
    element: ProductCategory,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_REPORT],
    path: "/report/order",
    element: ReportOrder,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_REPORT],
    path: "/report/summary",
    element: ReportSummary,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SECTOR],
    path: "/sector",
    element: Sector,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SECTOR],
    path: "/sector/create",
    element: SectorCreate,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SECTOR],
    path: "/sector/edit/:sectorId",
    element: SectorEdit,
  },
  {
    keys: [
      PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT,
      PERMISSION_ADMIN_PAGES.PAGE_PERMISSION,
      PERMISSION_ADMIN_PAGES.PAGE_TERM_CONDITION,
      PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS,
    ],
    path: "/settings",
    element: Settings,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_ACCOUNT],
    path: "/settings/account",
    element: AccountAdmin,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_GENERAL_SETTINGS],
    path: "/settings/general",
    element: GeneralSettings,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_PERMISSION],
    path: "/settings/permission",
    element: PermissionAdmin,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_TERM_CONDITION],
    path: "/settings/term-condition",
    element: TermCondition,
  },
  { keys: [PERMISSION_ADMIN_PAGES.PAGE_SMS], path: "/sms", element: Sms },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber",
    element: Subscriber,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber/create",
    element: SubscriberCreate,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber/subscription",
    element: SubscriberSubscription,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER],
    path: "/subscriber/:marketId",
    element: SubscriberDetails,
  },
  {
    keys: [PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIPTION],
    path: "/subscription",
    element: SubscriptionAdmin,
  },
];

export const getSubscriberPages = (marketId) =>
  [
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_AUTHENTICATION),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_BRANCH),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_DASHBOARD),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDER),
    getPageSubscriberDetails(
      PERMISSION_SUBSCRIBER_PAGES.PAGE_ORDERS_OF_PURCHASE
    ),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_PRODUCT),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_PROFILE),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_PURCHASE),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_RECIPE),
    getPageSubscriberDetails(
      PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE
    ),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ORDER),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_SCHEDULE),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_SUPPLIER),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE),
    getPageSubscriberDetails(PERMISSION_SUBSCRIBER_PAGES.PAGE_TAX),
  ].map((page) => ({ ...page, route: `/subscriber/${marketId}${page.route}` }));
export const subscriberRoutes = protectedSubscriberRoutes.map((route) => {
  return { ...route, path: `/subscriber/:marketId${route.path}` };
});

export const getPageDetails = (pageKey) => pageDetails?.[pageKey];
