import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateSettingFailed,
  setCreateOrUpdateSettingSuccess,
  setFetchSettingFailed,
  setFetchSettingsFailed,
} from "../../store/setting/setting.action";
import {
  getCreateOrUpdateSettingFailed,
  getCreateOrUpdateSettingSuccess,
  getFetchSettingFailed,
  getFetchSettingsFailed,
} from "../../store/setting/setting.selector";

const SettingPopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateSettingSuccess = useSelector(
    getCreateOrUpdateSettingSuccess
  );
  const fetchSettingsFailed = useSelector(getFetchSettingsFailed);
  const fetchSettingFailed = useSelector(getFetchSettingFailed);
  const createOrUpdateSettingFailed = useSelector(
    getCreateOrUpdateSettingFailed
  );

  useEffect(() => {
    if (createOrUpdateSettingSuccess !== null) {
      showSuccessMessage(createOrUpdateSettingSuccess);
      dispatch(setIsModalOpen(false));

      dispatch(setCreateOrUpdateSettingSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateSettingSuccess]);

  useEffect(() => {
    if (
      fetchSettingsFailed !== null ||
      fetchSettingFailed !== null ||
      createOrUpdateSettingFailed !== null
    ) {
      showErrorMessage(
        fetchSettingsFailed ?? fetchSettingFailed ?? createOrUpdateSettingFailed
      );

      if (fetchSettingsFailed !== null) dispatch(setFetchSettingsFailed(null));
      if (fetchSettingFailed !== null) dispatch(setFetchSettingFailed(null));
      if (createOrUpdateSettingFailed !== null)
        dispatch(setCreateOrUpdateSettingFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSettingsFailed, fetchSettingFailed, createOrUpdateSettingFailed]);

  return <></>;
};

export default SettingPopup;
