import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";

import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";

import {
  FormInputAdditional,
  FormInputContainer,
  FormInputField,
  FormInputGroup,
  FormInputOutGroup,
  FormInputPrefixOut,
  FormInputSuffixOut,
} from "./form-input.style";

import { ReactComponent as CheckSvg } from "../../assets/icons/Check.svg";
import { ReactComponent as CloseSvg } from "../../assets/icons/Close.svg";

export const FORM_INPUT_TYPES = {
  EMAIL: "EMAIL",
  NUMBER: "NUMBER",
  PASSWORD: "PASSWORD",
  TEXT: "TEXT",
};

export const FORM_INPUT_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

const getFormInputType = (formInputType) =>
  ({
    [FORM_INPUT_TYPES.EMAIL]: "email",
    [FORM_INPUT_TYPES.NUMBER]: "text",
    [FORM_INPUT_TYPES.PASSWORD]: "password",
    [FORM_INPUT_TYPES.TEXT]: "text",
  }[formInputType]);

const FormInput = forwardRef(
  (
    {
      inputType = FORM_INPUT_TYPES.TEXT,
      inputSize = FORM_INPUT_SIZES.NORMAL,
      isDisabled = false,
      isSolid = false,
      isPrefixBorderless = false,
      isSuffixBorderless,
      isNoPaddingLeft,
      isNoPaddingRight,
      isBorderLess = false,
      isNotValid = false,
      isValid = false,
      label,
      prefix,
      prefixOut,
      suffix,
      suffixOut,
      max,
      onChange,
      ...props
    },
    ref
  ) => {
    const isRightToLeft = useSelector(getIsRightToLeft);

    isSuffixBorderless ??= isValid || isNotValid;
    isNoPaddingLeft ??= !isEmpty(prefix) && isPrefixBorderless;
    isNoPaddingRight ??= !isEmpty(suffix) && isSuffixBorderless;

    props.type = getFormInputType(inputType);
    props.isNoPaddingLeft = isNoPaddingLeft;
    props.isNoPaddingRight = isNoPaddingRight;
    props.isBorderLess = isBorderLess;
    props.isValid = isValid;
    props.isNotValid = isNotValid;
    props.isRightToLeft = isRightToLeft;

    const [isFocusing, setIsFocusing] = useState(false);

    const handleInputFocus = () => setIsFocusing(true);
    const handleInputBlur = () => setIsFocusing(false);
    const handleInputChange = ({ target: { name, value } }) => {
      if (inputType === FORM_INPUT_TYPES.NUMBER)
        value = value.replace(/[^0-9.]/g, "");
      if (max) value = value.slice(0, max);

      onChange?.({ name, value });
    };

    return (
      <FormInputContainer
        inputSize={inputSize}
        isRightToLeft={isRightToLeft}
        isPrefixBorderless={isPrefixBorderless}
        isSuffixBorderless={isSuffixBorderless}
        isFocusing={isFocusing}
        isValid={isValid}
        isNotValid={isNotValid}
        isSolid={isSolid}
      >
        {!isEmpty(label) && label}
        <FormInputOutGroup>
          {prefixOut && <FormInputPrefixOut>{prefixOut}</FormInputPrefixOut>}
          <FormInputGroup isRightToLeft={isRightToLeft}>
            {prefix && (
              <FormInputAdditional isRightToLeft={isRightToLeft}>
                {prefix}
              </FormInputAdditional>
            )}
            <FormInputField
              ref={ref}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={isDisabled}
              isRightToLeft={isRightToLeft}
              isNoPaddingLeft={isNoPaddingLeft}
              isNoPaddingRight={isNoPaddingRight}
              {...props}
            />
            {suffix && (
              <FormInputAdditional isRightToLeft={isRightToLeft}>
                {suffix}
              </FormInputAdditional>
            )}
            {(isValid || isNotValid) && (
              <FormInputAdditional
                isRightToLeft={isRightToLeft}
                isValid={isValid}
                isNotValid={isNotValid}
              >
                {isValid ? <CheckSvg /> : <CloseSvg />}
              </FormInputAdditional>
            )}
          </FormInputGroup>
          {suffixOut && <FormInputSuffixOut>{suffixOut}</FormInputSuffixOut>}
        </FormInputOutGroup>
      </FormInputContainer>
    );
  }
);

export default FormInput;
