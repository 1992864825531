import { createAction } from "../../utils/store.utils";

import BANNER_TARGET_ACTION_TYPES from "./banner-target.type";

export const setTargetAdmins = (targetAdmins) =>
  createAction(BANNER_TARGET_ACTION_TYPES.SET_TARGET_ADMINS, targetAdmins);
export const setTargetMerchants = (targetMerchants) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_TARGET_MERCHANTS,
    targetMerchants
  );
export const setTargetSubscribers = (targetSubscribers) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_TARGET_SUBSCRIBERS,
    targetSubscribers
  );
export const setTarget = (target) =>
  createAction(BANNER_TARGET_ACTION_TYPES.SET_TARGET, target);

export const setIsTargetAdminsHasMore = (isTargetAdminsHasMore) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_TARGET_ADMINS_HAS_MORE,
    isTargetAdminsHasMore
  );
export const setIsTargetMerchantsHasMore = (isTargetMerchantsHasMore) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_TARGET_MERCHANTS_HAS_MORE,
    isTargetMerchantsHasMore
  );
export const setIsTargetSubscribersHasMore = (isTargetSubscribersHasMore) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_TARGET_SUBSCRIBERS_HAS_MORE,
    isTargetSubscribersHasMore
  );

export const setFetchTargetAdminsSearch = (fetchTargetAdminsSearch) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_SEARCH,
    fetchTargetAdminsSearch
  );
export const setFetchTargetAdminsSort = (fetchTargetAdminsSort) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_SORT,
    fetchTargetAdminsSort
  );
export const setFetchTargetAdminsKeyBy = (fetchTargetAdminsKeyBy) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_KEY_BY,
    fetchTargetAdminsKeyBy
  );
export const setFetchTargetAdminsPage = (fetchTargetAdminsPage) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_PAGE,
    fetchTargetAdminsPage
  );
export const setFetchTargetAdminsPerPage = (fetchTargetAdminsPerPage) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_PER_PAGE,
    fetchTargetAdminsPerPage
  );
export const setFetchTargetAdminsFilterBannerId = (
  fetchTargetAdminsFilterBannerId
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_FILTER_BANNER_ID,
    fetchTargetAdminsFilterBannerId
  );
export const setFetchTargetAdminsFilterExceptBannerId = (
  fetchTargetAdminsFilterExceptBannerId
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_FILTER_EXCEPT_BANNER_ID,
    fetchTargetAdminsFilterExceptBannerId
  );
export const setFetchTargetAdminsLoading = (fetchTargetAdminsLoading) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_LOADING,
    fetchTargetAdminsLoading
  );
export const setFetchTargetAdminsSuccess = (fetchTargetAdminsSuccess) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_SUCCESS,
    fetchTargetAdminsSuccess
  );
export const setFetchTargetAdminsFailed = (fetchTargetAdminsFailed) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_ADMINS_FAILED,
    fetchTargetAdminsFailed
  );

export const setFetchTargetMerchantsSearch = (fetchTargetMerchantsSearch) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_SEARCH,
    fetchTargetMerchantsSearch
  );
export const setFetchTargetMerchantsSort = (fetchTargetMerchantsSort) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_SORT,
    fetchTargetMerchantsSort
  );
export const setFetchTargetMerchantsKeyBy = (fetchTargetMerchantsKeyBy) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_KEY_BY,
    fetchTargetMerchantsKeyBy
  );
export const setFetchTargetMerchantsPage = (fetchTargetMerchantsPage) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_PAGE,
    fetchTargetMerchantsPage
  );
export const setFetchTargetMerchantsPerPage = (fetchTargetMerchantsPerPage) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_PER_PAGE,
    fetchTargetMerchantsPerPage
  );
export const setFetchTargetMerchantsFilterBannerId = (
  fetchTargetMerchantsFilterBannerId
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_FILTER_BANNER_ID,
    fetchTargetMerchantsFilterBannerId
  );
export const setFetchTargetMerchantsFilterExceptBannerId = (
  fetchTargetMerchantsFilterExceptBannerId
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_FILTER_EXCEPT_BANNER_ID,
    fetchTargetMerchantsFilterExceptBannerId
  );
export const setFetchTargetMerchantsLoading = (fetchTargetMerchantsLoading) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_LOADING,
    fetchTargetMerchantsLoading
  );
export const setFetchTargetMerchantsSuccess = (fetchTargetMerchantsSuccess) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_SUCCESS,
    fetchTargetMerchantsSuccess
  );
export const setFetchTargetMerchantsFailed = (fetchTargetMerchantsFailed) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_MERCHANTS_FAILED,
    fetchTargetMerchantsFailed
  );

export const setFetchTargetSubscribersSearch = (fetchTargetSubscribersSearch) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_SEARCH,
    fetchTargetSubscribersSearch
  );
export const setFetchTargetSubscribersSort = (fetchTargetSubscribersSort) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_SORT,
    fetchTargetSubscribersSort
  );
export const setFetchTargetSubscribersKeyBy = (fetchTargetSubscribersKeyBy) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_KEY_BY,
    fetchTargetSubscribersKeyBy
  );
export const setFetchTargetSubscribersPage = (fetchTargetSubscribersPage) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_PAGE,
    fetchTargetSubscribersPage
  );
export const setFetchTargetSubscribersPerPage = (
  fetchTargetSubscribersPerPage
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_PER_PAGE,
    fetchTargetSubscribersPerPage
  );
export const setFetchTargetSubscribersFilterBannerId = (
  fetchTargetSubscribersFilterBannerId
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_FILTER_BANNER_ID,
    fetchTargetSubscribersFilterBannerId
  );
export const setFetchTargetSubscribersFilterExceptBannerId = (
  fetchTargetSubscribersFilterExceptBannerId
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_FILTER_EXCEPT_BANNER_ID,
    fetchTargetSubscribersFilterExceptBannerId
  );
export const setFetchTargetSubscribersLoading = (
  fetchTargetSubscribersLoading
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_LOADING,
    fetchTargetSubscribersLoading
  );
export const setFetchTargetSubscribersSuccess = (
  fetchTargetSubscribersSuccess
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_SUCCESS,
    fetchTargetSubscribersSuccess
  );
export const setFetchTargetSubscribersFailed = (fetchTargetSubscribersFailed) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUBSCRIBERS_FAILED,
    fetchTargetSubscribersFailed
  );

export const setFetchTargetLoading = (fetchTargetLoading) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_LOADING,
    fetchTargetLoading
  );
export const setFetchTargetSuccess = (fetchTargetSuccess) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_SUCCESS,
    fetchTargetSuccess
  );
export const setFetchTargetFailed = (fetchTargetFailed) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_FETCH_TARGET_FAILED,
    fetchTargetFailed
  );

export const appendTargetAdmins = (targetAdmins) =>
  createAction(BANNER_TARGET_ACTION_TYPES.SET_TARGET_ADMINS, targetAdmins);
export const appendTargetMerchants = (targetMerchants) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_TARGET_MERCHANTS,
    targetMerchants
  );
export const appendTargetSubscribers = (targetSubscribers) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_TARGET_SUBSCRIBERS,
    targetSubscribers
  );

export const setIsFetchTargetAdminsHitted = (isFetchTargetAdminsHitted) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_ADMINS_HITTED,
    isFetchTargetAdminsHitted
  );
export const setIsFetchTargetMerchantsHitted = (isFetchTargetMerchantsHitted) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_MERCHANTS_HITTED,
    isFetchTargetMerchantsHitted
  );
export const setIsFetchTargetSubscribersHitted = (
  isFetchTargetSubscribersHitted
) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_SUBSCRIBERS_HITTED,
    isFetchTargetSubscribersHitted
  );
export const setIsFetchTargetHitted = (isFetchTargetHitted) =>
  createAction(
    BANNER_TARGET_ACTION_TYPES.SET_IS_FETCH_TARGET_HITTED,
    isFetchTargetHitted
  );

export const fetchTargetAdminsStart = () =>
  createAction(BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_ADMINS_START);
export const fetchTargetMerchantsStart = () =>
  createAction(BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_MERCHANTS_START);
export const fetchTargetSubscribersStart = () =>
  createAction(BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_SUBSCRIBERS_START);
export const fetchTargetStart = (targetId) =>
  createAction(BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_START, targetId);

export const resetBannerTargetReducer = () =>
  createAction(BANNER_TARGET_ACTION_TYPES.RESET_BANNER_TARGET_REDUCER);
