const SELECT_PAYMENT_METHOD_ACTION_TYPES = {
  RESET_SELECT_PAYMENT_METHOD_REDUCER:
    "select-payment-method/RESET_SELECT_PAYMENT_METHOD_REDUCER",

  SET_PAYMENT_METHODS: "select-payment-method/SET_PAYMENT_METHODS",
  SET_SEARCH_PAYMENT_METHODS:
    "select-payment-method/SET_SEARCH_PAYMENT_METHODS",

  APPEND_PAYMENT_METHODS: "select-payment-method/APPEND_PAYMENT_METHODS",
  APPEND_SEARCH_PAYMENT_METHODS:
    "select-payment-method/APPEND_SEARCH_PAYMENT_METHODS",

  SET_IS_PAYMENT_METHODS_HAS_MORE:
    "select-payment-method/SET_IS_PAYMENT_METHODS_HAS_MORE",
  SET_IS_SEARCH_PAYMENT_METHODS_HAS_MORE:
    "select-payment-method/SET_IS_SEARCH_PAYMENT_METHODS_HAS_MORE",

  SET_FETCH_PAYMENT_METHODS_PARAMS:
    "select-payment-method/SET_FETCH_PAYMENT_METHODS_PARAMS",
  SET_FETCH_PAYMENT_METHODS_LOADING:
    "select-payment-method/SET_FETCH_PAYMENT_METHODS_LOADING",
  SET_FETCH_PAYMENT_METHODS_SUCCESS:
    "select-payment-method/SET_FETCH_PAYMENT_METHODS_SUCCESS",
  SET_FETCH_PAYMENT_METHODS_FAILED:
    "select-payment-method/SET_FETCH_PAYMENT_METHODS_FAILED",

  SET_SEARCH_PAYMENT_METHODS_PARAMS:
    "select-payment-method/SET_SEARCH_PAYMENT_METHODS_PARAMS",
  SET_SEARCH_PAYMENT_METHODS_LOADING:
    "select-payment-method/SET_SEARCH_PAYMENT_METHODS_LOADING",
  SET_SEARCH_PAYMENT_METHODS_SUCCESS:
    "select-payment-method/SET_SEARCH_PAYMENT_METHODS_SUCCESS",
  SET_SEARCH_PAYMENT_METHODS_FAILED:
    "select-payment-method/SET_SEARCH_PAYMENT_METHODS_FAILED",

  SET_APPEND_PAYMENT_METHODS_PARAMS:
    "select-payment-method/SET_APPEND_PAYMENT_METHODS_PARAMS",
  SET_APPEND_PAYMENT_METHODS_LOADING:
    "select-payment-method/SET_APPEND_PAYMENT_METHODS_LOADING",
  SET_APPEND_PAYMENT_METHODS_SUCCESS:
    "select-payment-method/SET_APPEND_PAYMENT_METHODS_SUCCESS",
  SET_APPEND_PAYMENT_METHODS_FAILED:
    "select-payment-method/SET_APPEND_PAYMENT_METHODS_FAILED",

  SET_IS_FETCH_PAYMENT_METHODS_HITTED:
    "select-payment-method/SET_IS_FETCH_PAYMENT_METHODS_HITTED",
  SET_IS_SEARCH_PAYMENT_METHODS_HITTED:
    "select-payment-method/SET_IS_SEARCH_PAYMENT_METHODS_HITTED",
  SET_IS_APPEND_PAYMENT_METHODS_HITTED:
    "select-payment-method/SET_IS_APPEND_PAYMENT_METHODS_HITTED",

  FETCH_PAYMENT_METHODS_START:
    "select-payment-method/FETCH_PAYMENT_METHODS_START",
  SEARCH_PAYMENT_METHODS_START:
    "select-payment-method/SEARCH_PAYMENT_METHODS_START",
  APPEND_PAYMENT_METHODS_START:
    "select-payment-method/APPEND_PAYMENT_METHODS_START",
};

export default SELECT_PAYMENT_METHOD_ACTION_TYPES;
