import { BASE_URL } from "../config";

import apiService from "./api";

export const getOrderReceipt = async (id, params) =>
  await apiService.outerGet({
    responseType: "blob",
    url: `${BASE_URL}/pdf/order/${id}/receipt`,
    params,
  });

export const getOrderNotes = async (id, params) =>
  await apiService.outerGet({
    responseType: "blob",
    url: `${BASE_URL}/pdf/order/${id}/notes`,
    params,
  });

export const getExtendPeriodReceipt = async (id, params) =>
  await apiService.outerGet({
    responseType: "blob",
    url: `${BASE_URL}/pdf/extend-period/${id}`,
    params,
  });
