import { createSelector } from "reselect";

const featureSubscriptionSelector = ({ featureSubscription }) =>
  featureSubscription;

export const getFeature = createSelector(
  [featureSubscriptionSelector],
  ({ feature }) => feature
);

export const getCreateFeatureLoading = createSelector(
  [featureSubscriptionSelector],
  ({ createFeatureLoading }) => createFeatureLoading
);
export const getCreateFeatureSuccess = createSelector(
  [featureSubscriptionSelector],
  ({ createFeatureSuccess }) => createFeatureSuccess
);
export const getCreateFeatureFailed = createSelector(
  [featureSubscriptionSelector],
  ({ createFeatureFailed }) => createFeatureFailed
);

export const getUpdateFeatureLoading = createSelector(
  [featureSubscriptionSelector],
  ({ updateFeatureLoading }) => updateFeatureLoading
);
export const getUpdateFeatureSuccess = createSelector(
  [featureSubscriptionSelector],
  ({ updateFeatureSuccess }) => updateFeatureSuccess
);
export const getUpdateFeatureFailed = createSelector(
  [featureSubscriptionSelector],
  ({ updateFeatureFailed }) => updateFeatureFailed
);

export const getDeleteFeatureLoading = createSelector(
  [featureSubscriptionSelector],
  ({ deleteFeatureLoading }) => deleteFeatureLoading
);
export const getDeleteFeatureSuccess = createSelector(
  [featureSubscriptionSelector],
  ({ deleteFeatureSuccess }) => deleteFeatureSuccess
);
export const getDeleteFeatureFailed = createSelector(
  [featureSubscriptionSelector],
  ({ deleteFeatureFailed }) => deleteFeatureFailed
);

export const getIsCreateFeatureHitted = createSelector(
  [featureSubscriptionSelector],
  ({ isCreateFeatureHitted }) => isCreateFeatureHitted
);
export const getIsUpdateFeatureHitted = createSelector(
  [featureSubscriptionSelector],
  ({ isUpdateFeatureHitted }) => isUpdateFeatureHitted
);
export const getIsDeleteFeatureHitted = createSelector(
  [featureSubscriptionSelector],
  ({ isDeleteFeatureHitted }) => isDeleteFeatureHitted
);
