import apiService from "./api";

export const getPermissionAdmins = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/permissions/admins", params }))
    .data;
export const getPermissionAdmin = async (permissionId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/permissions/admins/${permissionId}`,
    })
  ).data;
export const createPermissionAdmin = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/permissions/admins", request }))
    .data;
export const updatePermissionAdmin = async (permissionId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/permissions/admins/${permissionId}`,
      request,
    })
  ).data;
export const deletePermissionAdmin = async (permissionId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/permissions/admins/${permissionId}`,
    })
  ).data;
