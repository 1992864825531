import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendProductCategoriesFailed,
  setFetchProductCategoriesFailed,
  setSearchProductCategoriesFailed,
} from "../../store/select-product-category/select-product-category.action";
import {
  getAppendProductCategoriesFailed,
  getFetchProductCategoriesFailed,
  getSearchProductCategoriesFailed,
} from "../../store/select-product-category/select-product-category.selector";

const SelectProductCategoryPopup = () => {
  const dispatch = useDispatch();

  const fetchProductCategoriesFailed = useSelector(
    getFetchProductCategoriesFailed
  );
  const searchProductCategoriesFailed = useSelector(
    getSearchProductCategoriesFailed
  );
  const appendProductCategoriesFailed = useSelector(
    getAppendProductCategoriesFailed
  );

  useEffect(() => {
    if (
      fetchProductCategoriesFailed !== null ||
      searchProductCategoriesFailed !== null ||
      appendProductCategoriesFailed !== null
    ) {
      showErrorMessage(
        fetchProductCategoriesFailed ??
          searchProductCategoriesFailed ??
          appendProductCategoriesFailed
      );

      if (fetchProductCategoriesFailed !== null)
        dispatch(setFetchProductCategoriesFailed(null));
      if (searchProductCategoriesFailed !== null)
        dispatch(setSearchProductCategoriesFailed(null));
      if (appendProductCategoriesFailed !== null)
        dispatch(setAppendProductCategoriesFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchProductCategoriesFailed,
    searchProductCategoriesFailed,
    appendProductCategoriesFailed,
  ]);

  return <></>;
};

export default SelectProductCategoryPopup;
