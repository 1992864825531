import SELECT_PAYMENT_METHOD_ACTION_TYPES from "./select-payment-method.type";

const SELECT_PAYMENT_METHOD_INITIAL_STATE = {
  paymentMethods: {},
  searchPaymentMethods: {},

  isPaymentMethodsHasMore: true,
  isSearchPaymentMethodsHasMore: true,

  fetchPaymentMethodsParams: {},
  fetchPaymentMethodsLoading: false,
  fetchPaymentMethodsSuccess: null,
  fetchPaymentMethodsFailed: null,

  searchPaymentMethodsParams: {},
  searchPaymentMethodsLoading: false,
  searchPaymentMethodsSuccess: null,
  searchPaymentMethodsFailed: null,

  appendPaymentMethodsParams: {},
  appendPaymentMethodsLoading: false,
  appendPaymentMethodsSuccess: null,
  appendPaymentMethodsFailed: null,

  isFetchPaymentMethodsHitted: false,
  isSearchPaymentMethodsHitted: false,
  isAppendPaymentMethodsHitted: false,
};

export const selectPaymentMethodReducer = (
  state = SELECT_PAYMENT_METHOD_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS:
      return { ...state, searchPaymentMethods: payload };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_PAYMENT_METHODS_HAS_MORE:
      return { ...state, isPaymentMethodsHasMore: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_SEARCH_PAYMENT_METHODS_HAS_MORE:
      return { ...state, isSearchPaymentMethodsHasMore: payload };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_PARAMS:
      return { ...state, fetchPaymentMethodsParams: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_LOADING:
      return { ...state, fetchPaymentMethodsLoading: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_SUCCESS:
      return { ...state, fetchPaymentMethodsSuccess: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_FAILED:
      return { ...state, fetchPaymentMethodsFailed: payload };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_PARAMS:
      return { ...state, searchPaymentMethodsParams: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_LOADING:
      return { ...state, searchPaymentMethodsLoading: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_SUCCESS:
      return { ...state, searchPaymentMethodsSuccess: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_SEARCH_PAYMENT_METHODS_FAILED:
      return { ...state, searchPaymentMethodsFailed: payload };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_PARAMS:
      return { ...state, appendPaymentMethodsParams: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_LOADING:
      return { ...state, appendPaymentMethodsLoading: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_SUCCESS:
      return { ...state, appendPaymentMethodsSuccess: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_APPEND_PAYMENT_METHODS_FAILED:
      return { ...state, appendPaymentMethodsFailed: payload };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHODS_HITTED:
      return { ...state, isFetchPaymentMethodsHitted: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_SEARCH_PAYMENT_METHODS_HITTED:
      return { ...state, isSearchPaymentMethodsHitted: payload };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.SET_IS_APPEND_PAYMENT_METHODS_HITTED:
      return { ...state, isAppendPaymentMethodsHitted: payload };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.APPEND_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: { ...state.paymentMethods, ...payload },
      };
    case SELECT_PAYMENT_METHOD_ACTION_TYPES.APPEND_SEARCH_PAYMENT_METHODS:
      return {
        ...state,
        searchPaymentMethods: { ...state.searchPaymentMethods, ...payload },
      };

    case SELECT_PAYMENT_METHOD_ACTION_TYPES.RESET_SELECT_PAYMENT_METHOD_REDUCER:
      return SELECT_PAYMENT_METHOD_INITIAL_STATE;
    default:
      return state;
  }
};
