import apiService from "./api";

export const getUserSuppliers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/suppliers", params })).data;
export const getUserSupplier = async (supplierId) =>
  (await apiService.innerGet({ urlPath: `/v2/users/suppliers/${supplierId}` }))
    .data;
export const createUserSupplier = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/suppliers", request }))
    .data;
export const updateUserSupplier = async (supplierId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/suppliers/${supplierId}`,
      request,
    })
  ).data;
export const deleteUserSupplier = async (supplierId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/users/suppliers/${supplierId}`,
    })
  ).data;
