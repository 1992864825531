import { createSelector } from "reselect";

const selectSupplierSelector = ({ selectSupplier }) => selectSupplier;

export const getSuppliers = createSelector(
  [selectSupplierSelector],
  ({ suppliers }) => suppliers
);
export const getSearchSuppliers = createSelector(
  [selectSupplierSelector],
  ({ searchSuppliers }) => searchSuppliers
);

export const getIsSuppliersHasMore = createSelector(
  [selectSupplierSelector],
  ({ isSuppliersHasMore }) => isSuppliersHasMore
);
export const getIsSearchSuppliersHasMore = createSelector(
  [selectSupplierSelector],
  ({ isSearchSuppliersHasMore }) => isSearchSuppliersHasMore
);

export const getFetchSuppliersParams = createSelector(
  [selectSupplierSelector],
  ({ fetchSuppliersParams }) => fetchSuppliersParams
);
export const getFetchSuppliersLoading = createSelector(
  [selectSupplierSelector],
  ({ fetchSuppliersLoading }) => fetchSuppliersLoading
);
export const getFetchSuppliersSuccess = createSelector(
  [selectSupplierSelector],
  ({ fetchSuppliersSuccess }) => fetchSuppliersSuccess
);
export const getFetchSuppliersFailed = createSelector(
  [selectSupplierSelector],
  ({ fetchSuppliersFailed }) => fetchSuppliersFailed
);

export const getSearchSuppliersParams = createSelector(
  [selectSupplierSelector],
  ({ searchSuppliersParams }) => searchSuppliersParams
);
export const getSearchSuppliersLoading = createSelector(
  [selectSupplierSelector],
  ({ searchSuppliersLoading }) => searchSuppliersLoading
);
export const getSearchSuppliersSuccess = createSelector(
  [selectSupplierSelector],
  ({ searchSuppliersSuccess }) => searchSuppliersSuccess
);
export const getSearchSuppliersFailed = createSelector(
  [selectSupplierSelector],
  ({ searchSuppliersFailed }) => searchSuppliersFailed
);

export const getAppendSuppliersParams = createSelector(
  [selectSupplierSelector],
  ({ appendSuppliersParams }) => appendSuppliersParams
);
export const getAppendSuppliersLoading = createSelector(
  [selectSupplierSelector],
  ({ appendSuppliersLoading }) => appendSuppliersLoading
);
export const getAppendSuppliersSuccess = createSelector(
  [selectSupplierSelector],
  ({ appendSuppliersSuccess }) => appendSuppliersSuccess
);
export const getAppendSuppliersFailed = createSelector(
  [selectSupplierSelector],
  ({ appendSuppliersFailed }) => appendSuppliersFailed
);

export const getIsFetchSuppliersHitted = createSelector(
  [selectSupplierSelector],
  ({ isFetchSuppliersHitted }) => isFetchSuppliersHitted
);
export const getIsSearchSuppliersHitted = createSelector(
  [selectSupplierSelector],
  ({ isSearchSuppliersHitted }) => isSearchSuppliersHitted
);
export const getIsAppendSuppliersHitted = createSelector(
  [selectSupplierSelector],
  ({ isAppendSuppliersHitted }) => isAppendSuppliersHitted
);
