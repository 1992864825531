import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCalculateCheckoutOrderFailed,
  setCalculateOrderFailed,
  setCheckoutOrderFailed,
  setCheckoutOrderSuccess,
  setCreateOrderFailed,
  setCreateOrderSuccess,
  setDeleteOrderFailed,
  setDeleteOrderSuccess,
  setFetchCashierOrdersFailed,
  setFetchCustomerOrdersFailed,
  setFetchLatestOrdersFailed,
  setFetchOrderFailed,
  setFetchOrdersFailed,
  setFetchProcessOrdersFailed,
  setFetchReportOrdersFailed,
  setFetchWaitingOrdersFailed,
  setReturnOrderFailed,
  setReturnOrderSuccess,
  setUpdateOrderFailed,
  setUpdateOrderSuccess,
} from "../../store/order/order.action";
import {
  getCalculateCheckoutOrderFailed,
  getCalculateOrderFailed,
  getCheckoutOrderFailed,
  getCheckoutOrderSuccess,
  getCreateOrderFailed,
  getCreateOrderSuccess,
  getDeleteOrderFailed,
  getDeleteOrderSuccess,
  getFetchCashierOrdersFailed,
  getFetchCustomerOrdersFailed,
  getFetchLatestOrdersFailed,
  getFetchOrderFailed,
  getFetchOrdersFailed,
  getFetchProcessOrdersFailed,
  getFetchReportOrdersFailed,
  getFetchWaitingOrdersFailed,
  getReturnOrderFailed,
  getReturnOrderSuccess,
  getUpdateOrderFailed,
  getUpdateOrderSuccess,
} from "../../store/order/order.selector";

const OrderPopup = () => {
  const dispatch = useDispatch();
  const effectRan = useRef(false);

  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const updateOrderSuccess = useSelector(getUpdateOrderSuccess);
  const deleteOrderSuccess = useSelector(getDeleteOrderSuccess);
  const checkoutOrderSuccess = useSelector(getCheckoutOrderSuccess);
  const returnOrderSuccess = useSelector(getReturnOrderSuccess);

  const fetchOrdersFailed = useSelector(getFetchOrdersFailed);
  const fetchWaitingOrdersFailed = useSelector(getFetchWaitingOrdersFailed);
  const fetchProcessOrdersFailed = useSelector(getFetchProcessOrdersFailed);
  const fetchLatestOrdersFailed = useSelector(getFetchLatestOrdersFailed);
  const fetchReportOrdersFailed = useSelector(getFetchReportOrdersFailed);
  const fetchCashierOrdersFailed = useSelector(getFetchCashierOrdersFailed);
  const fetchCustomerOrdersFailed = useSelector(getFetchCustomerOrdersFailed);
  const fetchOrderFailed = useSelector(getFetchOrderFailed);
  const createOrderFailed = useSelector(getCreateOrderFailed);
  const updateOrderFailed = useSelector(getUpdateOrderFailed);
  const deleteOrderFailed = useSelector(getDeleteOrderFailed);
  const calculateOrderFailed = useSelector(getCalculateOrderFailed);
  const calculateCheckoutOrderFailed = useSelector(getCalculateCheckoutOrderFailed);
  const checkoutOrderFailed = useSelector(getCheckoutOrderFailed);
  const returnOrderFailed = useSelector(getReturnOrderFailed);

  useEffect(() => {
    if (effectRan.current === true) {
      if (
        createOrderSuccess !== null ||
        updateOrderSuccess !== null ||
        deleteOrderSuccess !== null ||
        checkoutOrderSuccess !== null ||
        returnOrderSuccess !== null
      ) {
        showSuccessMessage(
          createOrderSuccess ?? updateOrderSuccess ?? deleteOrderSuccess ?? checkoutOrderSuccess ?? returnOrderSuccess
        );
        dispatch(setIsModalOpen(false));

        if (createOrderSuccess !== null) dispatch(setCreateOrderSuccess(null));
        if (updateOrderSuccess !== null) dispatch(setUpdateOrderSuccess(null));
        if (deleteOrderSuccess !== null) dispatch(setDeleteOrderSuccess(null));
        if (checkoutOrderSuccess !== null) dispatch(setCheckoutOrderSuccess(null));
        if (returnOrderSuccess !== null) dispatch(setReturnOrderSuccess(null));
      }
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderSuccess, updateOrderSuccess, deleteOrderSuccess, checkoutOrderSuccess, returnOrderSuccess]);

  useEffect(() => {
    if (effectRan.current === true) {
      if (
        fetchOrdersFailed !== null ||
        fetchWaitingOrdersFailed !== null ||
        fetchProcessOrdersFailed !== null ||
        fetchLatestOrdersFailed !== null ||
        fetchReportOrdersFailed !== null ||
        fetchCashierOrdersFailed !== null ||
        fetchCustomerOrdersFailed !== null ||
        fetchOrderFailed !== null ||
        createOrderFailed !== null ||
        updateOrderFailed !== null ||
        deleteOrderFailed !== null ||
        calculateOrderFailed !== null ||
        calculateCheckoutOrderFailed !== null ||
        checkoutOrderFailed !== null ||
        returnOrderFailed !== null
      ) {
        showErrorMessage(
          fetchOrdersFailed ??
            fetchWaitingOrdersFailed ??
            fetchProcessOrdersFailed ??
            fetchLatestOrdersFailed ??
            fetchReportOrdersFailed ??
            fetchCashierOrdersFailed ??
            fetchCustomerOrdersFailed ??
            fetchOrderFailed ??
            createOrderFailed ??
            updateOrderFailed ??
            deleteOrderFailed ??
            calculateOrderFailed ??
            calculateCheckoutOrderFailed ??
            checkoutOrderFailed ??
            returnOrderFailed
        );

        if (fetchOrdersFailed !== null) dispatch(setFetchOrdersFailed(null));
        if (fetchWaitingOrdersFailed !== null) dispatch(setFetchWaitingOrdersFailed(null));
        if (fetchProcessOrdersFailed !== null) dispatch(setFetchProcessOrdersFailed(null));
        if (fetchLatestOrdersFailed !== null) dispatch(setFetchLatestOrdersFailed(null));
        if (fetchReportOrdersFailed !== null) dispatch(setFetchReportOrdersFailed(null));
        if (fetchCashierOrdersFailed !== null) dispatch(setFetchCashierOrdersFailed(null));
        if (fetchCustomerOrdersFailed !== null) dispatch(setFetchCustomerOrdersFailed(null));
        if (fetchOrderFailed !== null) dispatch(setFetchOrderFailed(null));
        if (createOrderFailed !== null) dispatch(setCreateOrderFailed(null));
        if (updateOrderFailed !== null) dispatch(setUpdateOrderFailed(null));
        if (deleteOrderFailed !== null) dispatch(setDeleteOrderFailed(null));
        if (calculateOrderFailed !== null) dispatch(setCalculateOrderFailed(null));
        if (calculateCheckoutOrderFailed !== null) dispatch(setCalculateCheckoutOrderFailed(null));
        if (checkoutOrderFailed !== null) dispatch(setCheckoutOrderFailed(null));
        if (returnOrderFailed !== null) dispatch(setReturnOrderFailed(null));
      }
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchOrdersFailed,
    fetchWaitingOrdersFailed,
    fetchProcessOrdersFailed,
    fetchLatestOrdersFailed,
    fetchReportOrdersFailed,
    fetchCashierOrdersFailed,
    fetchCustomerOrdersFailed,
    fetchOrderFailed,
    createOrderFailed,
    updateOrderFailed,
    deleteOrderFailed,
    calculateOrderFailed,
    calculateCheckoutOrderFailed,
    checkoutOrderFailed,
    returnOrderFailed,
  ]);

  return <></>;
};

export default OrderPopup;
