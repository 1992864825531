import { SUBSCRIPTIONS_KEYS } from "../constants/subscription.constant";

import { parseToBoolean } from "./parser.utils";

export const getActivePlans = (activePlans = []) => {
  let isHasAdvancedPlan = false;
  let isHasBasicPlan = false;
  let isHasTrialPlan = false;

  for (const activePlan of activePlans) {
    const { subscription_key } = activePlan ?? {};

    isHasAdvancedPlan ||= subscription_key === SUBSCRIPTIONS_KEYS.ADVANCED;
    isHasBasicPlan ||= subscription_key === SUBSCRIPTIONS_KEYS.BASIC;
    isHasTrialPlan ||= subscription_key === SUBSCRIPTIONS_KEYS.TRIAL;
  }

  return {
    isHasAdvancedPlan: parseToBoolean(isHasAdvancedPlan),
    isHasBasicPlan: parseToBoolean(isHasBasicPlan),
    isHasTrialPlan: parseToBoolean(isHasTrialPlan),
  };
};
