import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateProductCategoryFailed,
  setCreateProductCategorySuccess,
  setDeleteProductCategoryFailed,
  setDeleteProductCategorySuccess,
  setFetchOrderProductCategoriesFailed,
  setFetchProductCategoriesFailed,
  setFetchProductCategoryFailed,
  setFetchProductProductCategoriesFailed,
  setFetchSelectProductCategoriesFailed,
  setUpdateProductCategoryFailed,
  setUpdateProductCategorySuccess,
} from "../../store/product-category/product-category.action";
import {
  getCreateProductCategoryFailed,
  getCreateProductCategorySuccess,
  getDeleteProductCategoryFailed,
  getDeleteProductCategorySuccess,
  getFetchOrderProductCategoriesFailed,
  getFetchProductCategoriesFailed,
  getFetchProductCategoryFailed,
  getFetchProductProductCategoriesFailed,
  getFetchSelectProductCategoriesFailed,
  getUpdateProductCategoryFailed,
  getUpdateProductCategorySuccess,
} from "../../store/product-category/product-category.selector";

const ProductCategoryPopup = () => {
  const dispatch = useDispatch();

  const createProductCategorySuccess = useSelector(
    getCreateProductCategorySuccess
  );
  const updateProductCategorySuccess = useSelector(
    getUpdateProductCategorySuccess
  );
  const deleteProductCategorySuccess = useSelector(
    getDeleteProductCategorySuccess
  );

  const fetchProductCategoriesFailed = useSelector(
    getFetchProductCategoriesFailed
  );
  const fetchSelectProductCategoriesFailed = useSelector(
    getFetchSelectProductCategoriesFailed
  );
  const fetchProductProductCategoriesFailed = useSelector(
    getFetchProductProductCategoriesFailed
  );
  const fetchOrderProductCategoriesFailed = useSelector(
    getFetchOrderProductCategoriesFailed
  );
  const fetchProductCategoryFailed = useSelector(getFetchProductCategoryFailed);
  const createProductCategoryFailed = useSelector(
    getCreateProductCategoryFailed
  );
  const updateProductCategoryFailed = useSelector(
    getUpdateProductCategoryFailed
  );
  const deleteProductCategoryFailed = useSelector(
    getDeleteProductCategoryFailed
  );

  useEffect(() => {
    if (
      createProductCategorySuccess !== null ||
      updateProductCategorySuccess !== null ||
      deleteProductCategorySuccess !== null
    ) {
      showSuccessMessage(
        createProductCategorySuccess ??
          updateProductCategorySuccess ??
          deleteProductCategorySuccess
      );
      dispatch(setIsModalOpen(false));

      if (createProductCategorySuccess !== null)
        dispatch(setCreateProductCategorySuccess(null));
      if (updateProductCategorySuccess !== null)
        dispatch(setUpdateProductCategorySuccess(null));
      if (deleteProductCategorySuccess !== null)
        dispatch(setDeleteProductCategorySuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createProductCategorySuccess,
    updateProductCategorySuccess,
    deleteProductCategorySuccess,
  ]);

  useEffect(() => {
    if (
      fetchProductCategoriesFailed !== null ||
      fetchSelectProductCategoriesFailed !== null ||
      fetchProductProductCategoriesFailed !== null ||
      fetchOrderProductCategoriesFailed !== null ||
      fetchProductCategoryFailed !== null ||
      createProductCategoryFailed !== null ||
      updateProductCategoryFailed !== null ||
      deleteProductCategoryFailed !== null
    ) {
      showErrorMessage(
        fetchProductCategoriesFailed ??
          fetchSelectProductCategoriesFailed ??
          fetchProductProductCategoriesFailed ??
          fetchOrderProductCategoriesFailed ??
          fetchProductCategoryFailed ??
          createProductCategoryFailed ??
          updateProductCategoryFailed ??
          deleteProductCategoryFailed
      );

      if (fetchProductCategoriesFailed !== null)
        dispatch(setFetchProductCategoriesFailed(null));
      if (fetchSelectProductCategoriesFailed !== null)
        dispatch(setFetchSelectProductCategoriesFailed(null));
      if (fetchProductProductCategoriesFailed !== null)
        dispatch(setFetchProductProductCategoriesFailed(null));
      if (fetchOrderProductCategoriesFailed !== null)
        dispatch(setFetchOrderProductCategoriesFailed(null));
      if (fetchProductCategoryFailed !== null)
        dispatch(setFetchProductCategoryFailed(null));
      if (createProductCategoryFailed !== null)
        dispatch(setCreateProductCategoryFailed(null));
      if (updateProductCategoryFailed !== null)
        dispatch(setUpdateProductCategoryFailed(null));
      if (deleteProductCategoryFailed !== null)
        dispatch(setDeleteProductCategoryFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchProductCategoriesFailed,
    fetchSelectProductCategoriesFailed,
    fetchProductProductCategoriesFailed,
    fetchOrderProductCategoriesFailed,
    fetchProductCategoryFailed,
    createProductCategoryFailed,
    updateProductCategoryFailed,
    deleteProductCategoryFailed,
  ]);

  return <></>;
};

export default ProductCategoryPopup;
