import apiService from "./api";

export const getRecipes = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/recipes", params })).data;
export const getRecipe = async (recipeId) =>
  (await apiService.innerGet({ urlPath: `/v2/recipes/${recipeId}` })).data;
export const createRecipe = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/recipes", request })).data;
export const updateRecipe = async (recipeId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/recipes/${recipeId}`, request }))
    .data;
export const deleteRecipe = async (recipeId) =>
  (await apiService.innerDelete({ urlPath: `/v2/recipes/${recipeId}` })).data;
