import apiService from "./api";

export const getUserCustomers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/customers", params }))
    .data;
export const getUserCustomer = async (customerId) =>
  (await apiService.innerGet({ urlPath: `/v2/users/customers/${customerId}` }))
    .data;
export const createUserCustomer = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/customers", request }))
    .data;
export const updateUserCustomer = async (customerId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/customers/${customerId}`,
      request,
    })
  ).data;

export const addMarketUserCustomer = async (customerId, marketId) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/customers/${customerId}/markets/${marketId}`,
    })
  ).data;
export const removeMarketUserCustomer = async (customerId, marketId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/users/customers/${customerId}/markets/${marketId}`,
    })
  ).data;
