import apiService from "./api";

export const getMerchants = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/merchants", params })).data;
export const getMerchant = async (merchantId) =>
  (await apiService.innerGet({ urlPath: `/v2/merchants/${merchantId}` })).data;
export const createMerchant = async (request) =>
  (await apiService.innerPost({ urlPath: `/v2/merchants`, request })).data;
export const updateMerchant = async (merchantId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/merchants/${merchantId}`,
      request,
    })
  ).data;
export const deleteMerchant = async (merchantId) =>
  (await apiService.innerDelete({ urlPath: `/v2/merchants/${merchantId}` }))
    .data;
