import apiService from "./api";

export const getAttendances = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/attendances", params })).data;
export const getAttendance = async (attendanceId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/attendances/${attendanceId}`,
      params,
    })
  ).data;
export const createOrUpdateAttendance = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/attendances", request })).data;
