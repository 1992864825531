import styled from "styled-components";

export const ApplicationCompletionTrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 1.25rem;
  border-radius: 1rem;
  background-color: #f9f9f9;
  border: 1.5px solid #dfdcef;
`;

export const ApplicationCompletionTrackTitle = styled.p`
  font-size: 1.15rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin-bottom: 0.35rem;
`;

export const ApplicationCompletionTrackDescription = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;
