import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateProductFailed,
  setCreateProductSuccess,
  setDeleteProductFailed,
  setDeleteProductSuccess,
  setFetchOrderProductsFailed,
  setFetchProductFailed,
  setFetchProductsFailed,
  setMultipleCreateProductsFailed,
  setMultipleCreateProductsSuccess,
  setMultipleDeleteProductsFailed,
  setMultipleDeleteProductsSuccess,
  setUpdateProductFailed,
  setUpdateProductSuccess,
} from "../../store/product/product.action";
import {
  getCreateProductFailed,
  getCreateProductSuccess,
  getDeleteProductFailed,
  getDeleteProductSuccess,
  getFetchOrderProductsFailed,
  getFetchProductFailed,
  getFetchProductsFailed,
  getMultipleCreateProductsFailed,
  getMultipleCreateProductsSuccess,
  getMultipleDeleteProductsFailed,
  getMultipleDeleteProductsSuccess,
  getUpdateProductFailed,
  getUpdateProductSuccess,
} from "../../store/product/product.selector";
import {
  setCreateProductFailed as setQuickCreateProductFailed,
  setCreateProductsFailed as setQuickCreateProductsFailed,
  setCreateProductSuccess as setQuickCreateProductSuccess,
  setCreateProductsSuccess as setQuickCreateProductsSuccess,
  setUpdateProductFailed as setQuickUpdateProductFailed,
  setUpdateProductSuccess as setQuickUpdateProductSuccess,
} from "../../store/product-quick/product-quick.action";
import {
  getCreateProductFailed as getQuickCreateProductFailed,
  getCreateProductsFailed as getQuickCreateProductsFailed,
  getCreateProductSuccess as getQuickCreateProductSuccess,
  getCreateProductsSuccess as getQuickCreateProductsSuccess,
  getUpdateProductFailed as getQuickUpdateProductFailed,
  getUpdateProductSuccess as getQuickUpdateProductSuccess,
} from "../../store/product-quick/product-quick.selector";

const ProductPopup = () => {
  const dispatch = useDispatch();
  const effectRan = useRef(false);

  const createProductSuccess = useSelector(getCreateProductSuccess);
  const updateProductSuccess = useSelector(getUpdateProductSuccess);
  const deleteProductSuccess = useSelector(getDeleteProductSuccess);
  const multipleCreateProductsSuccess = useSelector(
    getMultipleCreateProductsSuccess
  );
  const multipleDeleteProductsSuccess = useSelector(
    getMultipleDeleteProductsSuccess
  );

  const quickCreateProductSuccess = useSelector(getQuickCreateProductSuccess);
  const quickUpdateProductSuccess = useSelector(getQuickUpdateProductSuccess);
  const quickCreateProductsSuccess = useSelector(getQuickCreateProductsSuccess);

  const fetchProductsFailed = useSelector(getFetchProductsFailed);
  const fetchOrderProductsFailed = useSelector(getFetchOrderProductsFailed);
  const fetchProductFailed = useSelector(getFetchProductFailed);
  const createProductFailed = useSelector(getCreateProductFailed);
  const updateProductFailed = useSelector(getUpdateProductFailed);
  const deleteProductFailed = useSelector(getDeleteProductFailed);
  const multipleCreateProductsFailed = useSelector(
    getMultipleCreateProductsFailed
  );
  const multipleDeleteProductsFailed = useSelector(
    getMultipleDeleteProductsFailed
  );

  const quickCreateProductFailed = useSelector(getQuickCreateProductFailed);
  const quickUpdateProductFailed = useSelector(getQuickUpdateProductFailed);
  const quickCreateProductsFailed = useSelector(getQuickCreateProductsFailed);

  useEffect(() => {
    if (effectRan.current === true) {
      if (
        createProductSuccess !== null ||
        updateProductSuccess !== null ||
        deleteProductSuccess !== null ||
        multipleCreateProductsSuccess !== null ||
        multipleDeleteProductsSuccess !== null ||
        quickCreateProductSuccess !== null ||
        quickUpdateProductSuccess !== null ||
        quickCreateProductsSuccess !== null
      ) {
        showSuccessMessage(
          createProductSuccess ??
            updateProductSuccess ??
            deleteProductSuccess ??
            multipleCreateProductsSuccess ??
            multipleDeleteProductsSuccess ??
            quickCreateProductSuccess ??
            quickUpdateProductSuccess ??
            quickCreateProductsSuccess
        );
        dispatch(setIsModalOpen(false));

        if (createProductSuccess !== null)
          dispatch(setCreateProductSuccess(null));
        if (updateProductSuccess !== null)
          dispatch(setUpdateProductSuccess(null));
        if (deleteProductSuccess !== null)
          dispatch(setDeleteProductSuccess(null));
        if (multipleCreateProductsSuccess !== null)
          dispatch(setMultipleCreateProductsSuccess(null));
        if (multipleDeleteProductsSuccess !== null)
          dispatch(setMultipleDeleteProductsSuccess(null));
        if (quickCreateProductSuccess !== null)
          dispatch(setQuickCreateProductSuccess(null));
        if (quickUpdateProductSuccess !== null)
          dispatch(setQuickUpdateProductSuccess(null));
        if (quickCreateProductsSuccess !== null)
          dispatch(setQuickCreateProductsSuccess(null));
      }
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createProductSuccess,
    updateProductSuccess,
    deleteProductSuccess,
    multipleCreateProductsSuccess,
    multipleDeleteProductsSuccess,
    quickCreateProductSuccess,
    quickUpdateProductSuccess,
    quickCreateProductsSuccess,
  ]);

  useEffect(() => {
    if (effectRan.current === true) {
      if (
        fetchProductsFailed !== null ||
        fetchOrderProductsFailed !== null ||
        fetchProductFailed !== null ||
        createProductFailed !== null ||
        updateProductFailed !== null ||
        deleteProductFailed !== null ||
        multipleCreateProductsFailed !== null ||
        multipleDeleteProductsFailed !== null ||
        quickCreateProductFailed !== null ||
        quickUpdateProductFailed !== null ||
        quickCreateProductsFailed !== null
      ) {
        showErrorMessage(
          fetchProductsFailed ??
            fetchOrderProductsFailed ??
            fetchProductFailed ??
            createProductFailed ??
            updateProductFailed ??
            deleteProductFailed ??
            multipleCreateProductsFailed ??
            multipleDeleteProductsFailed ??
            quickCreateProductFailed ??
            quickUpdateProductFailed ??
            quickCreateProductsFailed
        );

        if (fetchProductsFailed !== null)
          dispatch(setFetchProductsFailed(null));
        if (fetchOrderProductsFailed !== null)
          dispatch(setFetchOrderProductsFailed(null));
        if (fetchProductFailed !== null) dispatch(setFetchProductFailed(null));
        if (createProductFailed !== null)
          dispatch(setCreateProductFailed(null));
        if (updateProductFailed !== null)
          dispatch(setUpdateProductFailed(null));
        if (deleteProductFailed !== null)
          dispatch(setDeleteProductFailed(null));
        if (multipleCreateProductsFailed !== null)
          dispatch(setMultipleCreateProductsFailed(null));
        if (multipleDeleteProductsFailed !== null)
          dispatch(setMultipleDeleteProductsFailed(null));
        if (quickCreateProductFailed !== null)
          dispatch(setQuickCreateProductFailed(null));
        if (quickUpdateProductFailed !== null)
          dispatch(setQuickUpdateProductFailed(null));
        if (quickCreateProductsFailed !== null)
          dispatch(setQuickCreateProductsFailed(null));
      }
    }

    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchProductsFailed,
    fetchOrderProductsFailed,
    fetchProductFailed,
    createProductFailed,
    updateProductFailed,
    deleteProductFailed,
    multipleCreateProductsFailed,
    multipleDeleteProductsFailed,
    quickCreateProductFailed,
    quickUpdateProductFailed,
    quickCreateProductsFailed,
  ]);

  return <></>;
};

export default ProductPopup;
