import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendPermissionsFailed,
  setFetchPermissionsFailed,
  setSearchPermissionsFailed,
} from "../../store/select-permission-subscriber/select-permission-subscriber.action";
import {
  getAppendPermissionsFailed,
  getFetchPermissionsFailed,
  getSearchPermissionsFailed,
} from "../../store/select-permission-subscriber/select-permission-subscriber.selector";

const SelectPermissionSubscriberPopup = () => {
  const dispatch = useDispatch();

  const fetchPermissionsFailed = useSelector(getFetchPermissionsFailed);
  const searchPermissionsFailed = useSelector(getSearchPermissionsFailed);
  const appendPermissionsFailed = useSelector(getAppendPermissionsFailed);

  useEffect(() => {
    if (
      fetchPermissionsFailed !== null ||
      searchPermissionsFailed !== null ||
      appendPermissionsFailed !== null
    ) {
      showErrorMessage(
        fetchPermissionsFailed ??
          searchPermissionsFailed ??
          appendPermissionsFailed
      );

      if (fetchPermissionsFailed !== null)
        dispatch(setFetchPermissionsFailed(null));
      if (searchPermissionsFailed !== null)
        dispatch(setSearchPermissionsFailed(null));
      if (appendPermissionsFailed !== null)
        dispatch(setAppendPermissionsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPermissionsFailed,
    searchPermissionsFailed,
    appendPermissionsFailed,
  ]);

  return <></>;
};

export default SelectPermissionSubscriberPopup;
