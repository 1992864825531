import { createSelector } from "reselect";

const selectCountrySelector = ({ selectCountry }) => selectCountry;

export const getCountries = createSelector(
  [selectCountrySelector],
  ({ countries }) => countries
);
export const getSearchCountries = createSelector(
  [selectCountrySelector],
  ({ searchCountries }) => searchCountries
);

export const getIsCountriesHasMore = createSelector(
  [selectCountrySelector],
  ({ isCountriesHasMore }) => isCountriesHasMore
);
export const getIsSearchCountriesHasMore = createSelector(
  [selectCountrySelector],
  ({ isSearchCountriesHasMore }) => isSearchCountriesHasMore
);

export const getFetchCountriesParams = createSelector(
  [selectCountrySelector],
  ({ fetchCountriesParams }) => fetchCountriesParams
);
export const getFetchCountriesLoading = createSelector(
  [selectCountrySelector],
  ({ fetchCountriesLoading }) => fetchCountriesLoading
);
export const getFetchCountriesSuccess = createSelector(
  [selectCountrySelector],
  ({ fetchCountriesSuccess }) => fetchCountriesSuccess
);
export const getFetchCountriesFailed = createSelector(
  [selectCountrySelector],
  ({ fetchCountriesFailed }) => fetchCountriesFailed
);

export const getSearchCountriesParams = createSelector(
  [selectCountrySelector],
  ({ searchCountriesParams }) => searchCountriesParams
);
export const getSearchCountriesLoading = createSelector(
  [selectCountrySelector],
  ({ searchCountriesLoading }) => searchCountriesLoading
);
export const getSearchCountriesSuccess = createSelector(
  [selectCountrySelector],
  ({ searchCountriesSuccess }) => searchCountriesSuccess
);
export const getSearchCountriesFailed = createSelector(
  [selectCountrySelector],
  ({ searchCountriesFailed }) => searchCountriesFailed
);

export const getAppendCountriesParams = createSelector(
  [selectCountrySelector],
  ({ appendCountriesParams }) => appendCountriesParams
);
export const getAppendCountriesLoading = createSelector(
  [selectCountrySelector],
  ({ appendCountriesLoading }) => appendCountriesLoading
);
export const getAppendCountriesSuccess = createSelector(
  [selectCountrySelector],
  ({ appendCountriesSuccess }) => appendCountriesSuccess
);
export const getAppendCountriesFailed = createSelector(
  [selectCountrySelector],
  ({ appendCountriesFailed }) => appendCountriesFailed
);

export const getIsFetchCountriesHitted = createSelector(
  [selectCountrySelector],
  ({ isFetchCountriesHitted }) => isFetchCountriesHitted
);
export const getIsSearchCountriesHitted = createSelector(
  [selectCountrySelector],
  ({ isSearchCountriesHitted }) => isSearchCountriesHitted
);
export const getIsAppendCountriesHitted = createSelector(
  [selectCountrySelector],
  ({ isAppendCountriesHitted }) => isAppendCountriesHitted
);
