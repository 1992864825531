import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateCardFailed,
  setCreateOrUpdateCardSuccess,
  setFetchCardsFailed,
} from "../../store/card/card.action";
import {
  getCreateOrUpdateCardFailed,
  getCreateOrUpdateCardSuccess,
  getFetchCardsFailed,
} from "../../store/card/card.selector";

const CardPopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateCardSuccess = useSelector(getCreateOrUpdateCardSuccess);
  const fetchCardsFailed = useSelector(getFetchCardsFailed);
  const createOrUpdateCardFailed = useSelector(getCreateOrUpdateCardFailed);

  useEffect(() => {
    if (createOrUpdateCardSuccess !== null) {
      showSuccessMessage(createOrUpdateCardSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setCreateOrUpdateCardSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateCardSuccess]);

  useEffect(() => {
    if (fetchCardsFailed !== null || createOrUpdateCardFailed !== null) {
      showErrorMessage(fetchCardsFailed ?? createOrUpdateCardFailed);

      if (fetchCardsFailed !== null) dispatch(setFetchCardsFailed(null));
      if (createOrUpdateCardFailed !== null) dispatch(setCreateOrUpdateCardFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCardsFailed, createOrUpdateCardFailed]);

  return <></>;
};

export default CardPopup;
