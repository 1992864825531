import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateAdvertisementFailed,
  setDeleteAdvertisementFailed,
  setCreateAdvertisementSuccess,
  setDeleteAdvertisementSuccess,
  setFetchAdvertisementFailed,
  setFetchAdvertisementsFailed,
  setUpdateAdvertisementFailed,
  setUpdateAdvertisementSuccess,
} from "../../store/advertisement/advertisement.action";
import {
  getCreateAdvertisementFailed,
  getCreateAdvertisementSuccess,
  getDeleteAdvertisementFailed,
  getDeleteAdvertisementSuccess,
  getFetchAdvertisementFailed,
  getFetchAdvertisementsFailed,
  getUpdateAdvertisementFailed,
  getUpdateAdvertisementSuccess,
} from "../../store/advertisement/advertisement.selector";

const AdvertisementPopup = () => {
  const dispatch = useDispatch();

  const createAdvertisementSuccess = useSelector(getCreateAdvertisementSuccess);
  const updateAdvertisementSuccess = useSelector(getUpdateAdvertisementSuccess);
  const deleteAdvertisementSuccess = useSelector(getDeleteAdvertisementSuccess);
  const fetchAdvertisementsFailed = useSelector(getFetchAdvertisementsFailed);
  const fetchAdvertisementFailed = useSelector(getFetchAdvertisementFailed);
  const createAdvertisementFailed = useSelector(getCreateAdvertisementFailed);
  const updateAdvertisementFailed = useSelector(getUpdateAdvertisementFailed);
  const deleteAdvertisementFailed = useSelector(getDeleteAdvertisementFailed);

  useEffect(() => {
    if (
      createAdvertisementSuccess !== null ||
      updateAdvertisementSuccess !== null ||
      deleteAdvertisementSuccess !== null
    ) {
      showSuccessMessage(createAdvertisementSuccess ?? updateAdvertisementSuccess ?? deleteAdvertisementSuccess);
      dispatch(setIsModalOpen(false));

      if (createAdvertisementSuccess !== null) dispatch(setCreateAdvertisementSuccess(null));
      if (updateAdvertisementSuccess !== null) dispatch(setUpdateAdvertisementSuccess(null));
      if (deleteAdvertisementSuccess !== null) dispatch(setDeleteAdvertisementSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAdvertisementSuccess, updateAdvertisementSuccess, deleteAdvertisementSuccess]);

  useEffect(() => {
    if (
      fetchAdvertisementsFailed !== null ||
      fetchAdvertisementFailed !== null ||
      createAdvertisementFailed !== null ||
      updateAdvertisementFailed !== null ||
      deleteAdvertisementFailed !== null
    ) {
      showErrorMessage(
        fetchAdvertisementsFailed ??
          fetchAdvertisementFailed ??
          createAdvertisementFailed ??
          updateAdvertisementFailed ??
          deleteAdvertisementFailed
      );

      if (fetchAdvertisementsFailed !== null) dispatch(setFetchAdvertisementsFailed(null));
      if (fetchAdvertisementFailed !== null) dispatch(setFetchAdvertisementFailed(null));
      if (createAdvertisementFailed !== null) dispatch(setCreateAdvertisementFailed(null));
      if (updateAdvertisementFailed !== null) dispatch(setUpdateAdvertisementFailed(null));
      if (deleteAdvertisementFailed !== null) dispatch(setDeleteAdvertisementFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchAdvertisementsFailed,
    fetchAdvertisementFailed,
    createAdvertisementFailed,
    updateAdvertisementFailed,
    deleteAdvertisementFailed,
  ]);

  return <></>;
};

export default AdvertisementPopup;
