const SELECT_COMMON_ACTION_TYPES = {
  RESET_SELECT_COMMON_REDUCER: "select-common/RESET_SELECT_COMMON_REDUCER",

  SET_USER_ROLES: "select-common/SET_USER_ROLES",

  SET_ORDER_STATUSES: "select-common/SET_ORDER_STATUSES",

  SET_SUBSCRIPTION_PLANS: "select-common/SET_SUBSCRIPTION_PLANS",
  SET_SUBSCRIPTION_PERIODS: "select-common/SET_SUBSCRIPTION_PERIODS",
  SET_SUBSCRIPTION_STATUSES: "select-common/SET_SUBSCRIPTION_STATUSES",

  SET_REPORT_EXPORT_TYPES: "select-common/SET_REPORT_EXPORT_TYPES",

  SET_DISCOUNT_TYPES: "select-common/SET_DISCOUNT_TYPES",

  SET_PRODUCT_TYPES: "select-common/SET_PRODUCT_TYPES",
  SET_PRODUCT_OPTION_PRICES: "select-common/SET_PRODUCT_OPTION_PRICES",
  SET_PRODUCT_ADDITIONAL_PRICES: "select-common/SET_PRODUCT_ADDITIONAL_PRICES",

  SET_DASHBOARD_SUBSCRIBER_TABS: "select-common/SET_DASHBOARD_SUBSCRIBER_TABS",
  SET_SUBSCRIPTION_ADMIN_TABS: "select-common/SET_SUBSCRIPTION_ADMIN_TABS",
  SET_SUBSCRIPTION_SUBSCRIBER_TABS:
    "select-common/SET_SUBSCRIPTION_SUBSCRIBER_TABS",

  PERMISSION_ADMIN_PAGES: "select-common/PERMISSION_ADMIN_PAGES",
  PERMISSION_MERCHANT_PAGES: "select-common/PERMISSION_MERCHANT_PAGES",
  PERMISSION_SUBSCRIBER_PAGES: "select-common/PERMISSION_SUBSCRIBER_PAGES",
};

export default SELECT_COMMON_ACTION_TYPES;
