import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getPermissionSubscribers } from "../../api/permission-subscriber.api";

import SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES from "./select-permission-subscriber.type";
import {
  appendPermissions,
  appendSearchPermissions,
  setAppendPermissionsFailed,
  setAppendPermissionsLoading,
  setAppendPermissionsSuccess,
  setFetchPermissionsFailed,
  setFetchPermissionsLoading,
  setFetchPermissionsSuccess,
  setIsAppendPermissionsHitted,
  setIsFetchPermissionsHitted,
  setIsPermissionsHasMore,
  setIsSearchPermissionsHasMore,
  setIsSearchPermissionsHitted,
  setPermissions,
  setSearchPermissions,
  setSearchPermissionsFailed,
  setSearchPermissionsLoading,
  setSearchPermissionsSuccess,
} from "./select-permission-subscriber.action";

export function* _getFetchPermissions({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchPermissionsLoading(true));

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionSubscribers, parameters);

    yield put(setIsFetchPermissionsHitted(true));
    yield put(setIsPermissionsHasMore(Object.keys(permissions).length > 0));

    if (parameters.page > 1) {
      yield put(appendPermissions(permissions));
    } else {
      yield put(setPermissions(permissions));
    }

    yield put(setFetchPermissionsSuccess(message));
    yield put(setFetchPermissionsLoading(false));
  } catch (error) {
    yield put(setFetchPermissionsFailed(error));
    yield put(setFetchPermissionsLoading(false));
  }
}
export function* _getSearchPermissions({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchPermissionsLoading(true));

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionSubscribers, parameters);

    yield put(setIsSearchPermissionsHitted(true));
    yield put(
      setIsSearchPermissionsHasMore(Object.keys(permissions).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendSearchPermissions(permissions));
    } else {
      yield put(setSearchPermissions(permissions));
    }

    yield put(setSearchPermissionsSuccess(message));
    yield put(setSearchPermissionsLoading(false));
  } catch (error) {
    yield put(setSearchPermissionsFailed(error));
    yield put(setSearchPermissionsLoading(false));
  }
}
export function* _getAppendPermissions({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendPermissionsLoading(true));

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionSubscribers, parameters);

    yield put(setIsAppendPermissionsHitted(true));
    yield put(appendPermissions(permissions));

    yield put(setAppendPermissionsSuccess(message));
    yield put(setAppendPermissionsLoading(false));
  } catch (error) {
    yield put(setAppendPermissionsFailed(error));
    yield put(setAppendPermissionsLoading(false));
  }
}

export function* onFetchPermissionsStart() {
  yield takeLatest(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_PERMISSIONS_START,
    _getFetchPermissions
  );
}
export function* onSearchPermissionsStart() {
  yield takeLatest(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.SEARCH_PERMISSIONS_START,
    _getSearchPermissions
  );
}
export function* onAppendPermissionsStart() {
  yield takeLatest(
    SELECT_PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_PERMISSIONS_START,
    _getAppendPermissions
  );
}

export function* selectPermissionSubscriberSaga() {
  yield all([
    call(onFetchPermissionsStart),
    call(onSearchPermissionsStart),
    call(onAppendPermissionsStart),
  ]);
}
