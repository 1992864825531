const BANNER_ACTION_TYPES = {
  RESET_BANNER_REDUCER: "banner/RESET_BANNER_REDUCER",

  SET_BANNERS: "banner/SET_BANNERS",
  SET_BANNER: "banner/SET_BANNER",

  SET_IS_BANNERS_HAS_MORE: "banner/SET_IS_BANNERS_HAS_MORE",

  SET_FETCH_BANNERS_SEARCH: "banner/SET_FETCH_BANNERS_SEARCH",
  SET_FETCH_BANNERS_SORT: "banner/SET_FETCH_BANNERS_SORT",
  SET_FETCH_BANNERS_KEY_BY: "banner/SET_FETCH_BANNERS_KEY_BY",
  SET_FETCH_BANNERS_PAGE: "banner/SET_FETCH_BANNERS_PAGE",
  SET_FETCH_BANNERS_PER_PAGE: "banner/SET_FETCH_BANNERS_PER_PAGE",
  SET_FETCH_BANNERS_INCLUDES: "banner/SET_FETCH_BANNERS_INCLUDES",
  SET_FETCH_BANNERS_LOADING: "banner/SET_FETCH_BANNERS_LOADING",
  SET_FETCH_BANNERS_SUCCESS: "banner/SET_FETCH_BANNERS_SUCCESS",
  SET_FETCH_BANNERS_FAILED: "banner/SET_FETCH_BANNERS_FAILED",

  SET_FETCH_BANNER_LOADING: "banner/SET_FETCH_BANNER_LOADING",
  SET_FETCH_BANNER_SUCCESS: "banner/SET_FETCH_BANNER_SUCCESS",
  SET_FETCH_BANNER_FAILED: "banner/SET_FETCH_BANNER_FAILED",

  SET_CREATE_BANNER_LOADING: "banner/SET_CREATE_BANNER_LOADING",
  SET_CREATE_BANNER_SUCCESS: "banner/SET_CREATE_BANNER_SUCCESS",
  SET_CREATE_BANNER_FAILED: "banner/SET_CREATE_BANNER_FAILED",

  SET_UPDATE_BANNER_LOADING: "banner/SET_UPDATE_BANNER_LOADING",
  SET_UPDATE_BANNER_SUCCESS: "banner/SET_UPDATE_BANNER_SUCCESS",
  SET_UPDATE_BANNER_FAILED: "banner/SET_UPDATE_BANNER_FAILED",

  SET_DELETE_BANNER_LOADING: "banner/SET_DELETE_BANNER_LOADING",
  SET_DELETE_BANNER_SUCCESS: "banner/SET_DELETE_BANNER_SUCCESS",
  SET_DELETE_BANNER_FAILED: "banner/SET_DELETE_BANNER_FAILED",

  APPEND_BANNERS: "banner/APPEND_BANNERS",

  SET_IS_FETCH_BANNERS_HITTED: "banner/SET_IS_FETCH_BANNERS_HITTED",
  SET_IS_FETCH_BANNER_HITTED: "banner/SET_IS_FETCH_BANNER_HITTED",
  SET_IS_CREATE_BANNER_HITTED: "banner/SET_IS_CREATE_BANNER_HITTED",
  SET_IS_UPDATE_BANNER_HITTED: "banner/SET_IS_UPDATE_BANNER_HITTED",
  SET_IS_DELETE_BANNER_HITTED: "banner/SET_IS_DELETE_BANNER_HITTED",

  FETCH_BANNERS_START: "banner/FETCH_BANNERS_START",
  FETCH_BANNER_START: "banner/FETCH_BANNER_START",
  CREATE_BANNER_START: "banner/CREATE_BANNER_START",
  UPDATE_BANNER_START: "banner/UPDATE_BANNER_START",
  DELETE_BANNER_START: "banner/DELETE_BANNER_START",
};

export default BANNER_ACTION_TYPES;
