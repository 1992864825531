import apiService from "./api";

export const getDiscounts = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/discounts", params })).data;
export const getDiscount = async (discountId) =>
  (await apiService.innerGet({ urlPath: `/v2/discounts/${discountId}` })).data;
export const createDiscount = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/discounts", request })).data;
export const updateDiscount = async (discountId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/discounts/${discountId}`,
      request,
    })
  ).data;
export const deleteDiscount = async (discountId) =>
  (await apiService.innerDelete({ urlPath: `/v2/discounts/${discountId}` }))
    .data;
export const verifyCouponDiscount = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/discounts/coupon/verify",
      request,
    })
  ).data;
export const verifyLoyaProDiscount = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/discounts/loyapro/verify",
      request,
    })
  ).data;
