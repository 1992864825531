import { createSelector } from "reselect";

const bannerSelector = ({ banner }) => banner;

export const getBanners = createSelector(
  [bannerSelector],
  ({ banners }) => banners
);
export const getBanner = createSelector(
  [bannerSelector],
  ({ banner }) => banner
);

export const getIsBannersHasMore = createSelector(
  [bannerSelector],
  ({ isBannersHasMore }) => isBannersHasMore
);

export const getFetchBannersSearch = createSelector(
  [bannerSelector],
  ({ fetchBannersSearch }) => fetchBannersSearch
);
export const getFetchBannersSort = createSelector(
  [bannerSelector],
  ({ fetchBannersSort }) => fetchBannersSort
);
export const getFetchBannersKeyBy = createSelector(
  [bannerSelector],
  ({ fetchBannersKeyBy }) => fetchBannersKeyBy
);
export const getFetchBannersPage = createSelector(
  [bannerSelector],
  ({ fetchBannersPage }) => fetchBannersPage
);
export const getFetchBannersPerPage = createSelector(
  [bannerSelector],
  ({ fetchBannersPerPage }) => fetchBannersPerPage
);
export const getFetchBannersIncludes = createSelector(
  [bannerSelector],
  ({ fetchBannersIncludes }) => fetchBannersIncludes
);
export const getFetchBannersLoading = createSelector(
  [bannerSelector],
  ({ fetchBannersLoading }) => fetchBannersLoading
);
export const getFetchBannersSuccess = createSelector(
  [bannerSelector],
  ({ fetchBannersSuccess }) => fetchBannersSuccess
);
export const getFetchBannersFailed = createSelector(
  [bannerSelector],
  ({ fetchBannersFailed }) => fetchBannersFailed
);

export const getFetchBannerLoading = createSelector(
  [bannerSelector],
  ({ fetchBannerLoading }) => fetchBannerLoading
);
export const getFetchBannerSuccess = createSelector(
  [bannerSelector],
  ({ fetchBannerSuccess }) => fetchBannerSuccess
);
export const getFetchBannerFailed = createSelector(
  [bannerSelector],
  ({ fetchBannerFailed }) => fetchBannerFailed
);

export const getCreateBannerLoading = createSelector(
  [bannerSelector],
  ({ createBannerLoading }) => createBannerLoading
);
export const getCreateBannerSuccess = createSelector(
  [bannerSelector],
  ({ createBannerSuccess }) => createBannerSuccess
);
export const getCreateBannerFailed = createSelector(
  [bannerSelector],
  ({ createBannerFailed }) => createBannerFailed
);

export const getUpdateBannerLoading = createSelector(
  [bannerSelector],
  ({ updateBannerLoading }) => updateBannerLoading
);
export const getUpdateBannerSuccess = createSelector(
  [bannerSelector],
  ({ updateBannerSuccess }) => updateBannerSuccess
);
export const getUpdateBannerFailed = createSelector(
  [bannerSelector],
  ({ updateBannerFailed }) => updateBannerFailed
);

export const getDeleteBannerLoading = createSelector(
  [bannerSelector],
  ({ deleteBannerLoading }) => deleteBannerLoading
);
export const getDeleteBannerSuccess = createSelector(
  [bannerSelector],
  ({ deleteBannerSuccess }) => deleteBannerSuccess
);
export const getDeleteBannerFailed = createSelector(
  [bannerSelector],
  ({ deleteBannerFailed }) => deleteBannerFailed
);

export const getIsFetchBannersHitted = createSelector(
  [bannerSelector],
  ({ isFetchBannersHitted }) => isFetchBannersHitted
);
export const getIsFetchBannerHitted = createSelector(
  [bannerSelector],
  ({ isFetchBannerHitted }) => isFetchBannerHitted
);
export const getIsCreateBannerHitted = createSelector(
  [bannerSelector],
  ({ isCreateBannerHitted }) => isCreateBannerHitted
);
export const getIsUpdateBannerHitted = createSelector(
  [bannerSelector],
  ({ isUpdateBannerHitted }) => isUpdateBannerHitted
);
export const getIsDeleteBannerHitted = createSelector(
  [bannerSelector],
  ({ isDeleteBannerHitted }) => isDeleteBannerHitted
);
