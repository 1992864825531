import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { USER_ROLES } from "../../constants/user.constant";

import { getTarget, getTargets } from "../../api/banner-target.api";

import BANNER_TARGET_ACTION_TYPES from "./banner-target.type";
import {
  appendTargetAdmins,
  appendTargetMerchants,
  appendTargetSubscribers,
  setFetchTargetAdminsFailed,
  setFetchTargetAdminsLoading,
  setFetchTargetAdminsSuccess,
  setFetchTargetFailed,
  setFetchTargetLoading,
  setFetchTargetMerchantsFailed,
  setFetchTargetMerchantsLoading,
  setFetchTargetMerchantsSuccess,
  setFetchTargetSubscribersFailed,
  setFetchTargetSubscribersLoading,
  setFetchTargetSubscribersSuccess,
  setFetchTargetSuccess,
  setIsFetchTargetAdminsHitted,
  setIsFetchTargetHitted,
  setIsFetchTargetMerchantsHitted,
  setIsFetchTargetSubscribersHitted,
  setIsTargetAdminsHasMore,
  setIsTargetMerchantsHasMore,
  setIsTargetSubscribersHasMore,
  setTarget,
  setTargetAdmins,
  setTargetMerchants,
  setTargetSubscribers,
} from "./banner-target.action";
import {
  getFetchTargetAdminsFilterBannerId,
  getFetchTargetAdminsFilterExceptBannerId,
  getFetchTargetAdminsKeyBy,
  getFetchTargetAdminsPage,
  getFetchTargetAdminsPerPage,
  getFetchTargetAdminsSearch,
  getFetchTargetAdminsSort,
  getFetchTargetMerchantsFilterBannerId,
  getFetchTargetMerchantsFilterExceptBannerId,
  getFetchTargetMerchantsKeyBy,
  getFetchTargetMerchantsPage,
  getFetchTargetMerchantsPerPage,
  getFetchTargetMerchantsSearch,
  getFetchTargetMerchantsSort,
  getFetchTargetSubscribersFilterBannerId,
  getFetchTargetSubscribersFilterExceptBannerId,
  getFetchTargetSubscribersKeyBy,
  getFetchTargetSubscribersPage,
  getFetchTargetSubscribersPerPage,
  getFetchTargetSubscribersSearch,
  getFetchTargetSubscribersSort,
} from "./banner-target.selector";

export function* _getTargetAdmins() {
  try {
    yield put(setFetchTargetAdminsLoading(true));

    const search = yield select(getFetchTargetAdminsSearch);
    const sort = yield select(getFetchTargetAdminsSort);
    const key_by = yield select(getFetchTargetAdminsKeyBy);
    const page = yield select(getFetchTargetAdminsPage);
    const per_page = yield select(getFetchTargetAdminsPerPage);
    const banner_id = yield select(getFetchTargetAdminsFilterBannerId);
    const except_banner_id = yield select(
      getFetchTargetAdminsFilterExceptBannerId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        banner_id,
        except_banner_id,
        role: USER_ROLES.USER_ADMIN,
      },
    };

    const {
      meta: { message },
      data: targets,
    } = yield call(getTargets, parameters);

    yield put(setIsFetchTargetAdminsHitted(true));
    yield put(setIsTargetAdminsHasMore(Object.keys(targets).length > 0));

    if (page > 1) {
      yield put(appendTargetAdmins(targets));
    } else {
      yield put(setTargetAdmins(targets));
    }

    yield put(setFetchTargetAdminsSuccess(message));
    yield put(setFetchTargetAdminsLoading(false));
  } catch (error) {
    yield put(setFetchTargetAdminsFailed(error));
    yield put(setFetchTargetAdminsLoading(false));
  }
}
export function* _getTargetMerchants() {
  try {
    yield put(setFetchTargetMerchantsLoading(true));

    const search = yield select(getFetchTargetMerchantsSearch);
    const sort = yield select(getFetchTargetMerchantsSort);
    const key_by = yield select(getFetchTargetMerchantsKeyBy);
    const page = yield select(getFetchTargetMerchantsPage);
    const per_page = yield select(getFetchTargetMerchantsPerPage);
    const banner_id = yield select(getFetchTargetMerchantsFilterBannerId);
    const except_banner_id = yield select(
      getFetchTargetMerchantsFilterExceptBannerId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        banner_id,
        except_banner_id,
        role: USER_ROLES.USER_MERCHANT,
      },
    };

    const {
      meta: { message },
      data: targets,
    } = yield call(getTargets, parameters);

    yield put(setIsFetchTargetMerchantsHitted(true));
    yield put(setIsTargetMerchantsHasMore(Object.keys(targets).length > 0));

    if (page > 1) {
      yield put(appendTargetMerchants(targets));
    } else {
      yield put(setTargetMerchants(targets));
    }

    yield put(setFetchTargetMerchantsSuccess(message));
    yield put(setFetchTargetMerchantsLoading(false));
  } catch (error) {
    yield put(setFetchTargetMerchantsFailed(error));
    yield put(setFetchTargetMerchantsLoading(false));
  }
}
export function* _getTargetSubscribers() {
  try {
    yield put(setFetchTargetSubscribersLoading(true));

    const search = yield select(getFetchTargetSubscribersSearch);
    const sort = yield select(getFetchTargetSubscribersSort);
    const key_by = yield select(getFetchTargetSubscribersKeyBy);
    const page = yield select(getFetchTargetSubscribersPage);
    const per_page = yield select(getFetchTargetSubscribersPerPage);
    const banner_id = yield select(getFetchTargetSubscribersFilterBannerId);
    const except_banner_id = yield select(
      getFetchTargetSubscribersFilterExceptBannerId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        banner_id,
        except_banner_id,
        role: USER_ROLES.USER_SUBSCRIBER,
      },
    };

    const {
      meta: { message },
      data: targets,
    } = yield call(getTargets, parameters);

    yield put(setIsFetchTargetSubscribersHitted(true));
    yield put(setIsTargetSubscribersHasMore(Object.keys(targets).length > 0));

    if (page > 1) {
      yield put(appendTargetSubscribers(targets));
    } else {
      yield put(setTargetSubscribers(targets));
    }

    yield put(setFetchTargetSubscribersSuccess(message));
    yield put(setFetchTargetSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchTargetSubscribersFailed(error));
    yield put(setFetchTargetSubscribersLoading(false));
  }
}
export function* _getTarget({ payload: targetId }) {
  try {
    yield put(setFetchTargetLoading(true));

    const {
      meta: { message },
      data: target,
    } = yield call(getTarget, targetId);

    yield put(setIsFetchTargetHitted(true));
    yield put(setTarget(target));

    yield put(setFetchTargetSuccess(message));
    yield put(setFetchTargetLoading(false));
  } catch (error) {
    yield put(setFetchTargetFailed(error));
    yield put(setFetchTargetLoading(false));
  }
}

export function* onFetchAdminsStart() {
  yield takeLatest(
    BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_ADMINS_START,
    _getTargetAdmins
  );
}
export function* onFetchMerchantsStart() {
  yield takeLatest(
    BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_MERCHANTS_START,
    _getTargetMerchants
  );
}
export function* onFetchSubscribersStart() {
  yield takeLatest(
    BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_SUBSCRIBERS_START,
    _getTargetSubscribers
  );
}
export function* onFetchTargetStart() {
  yield takeLatest(BANNER_TARGET_ACTION_TYPES.FETCH_TARGET_START, _getTarget);
}

export function* bannerTargetSaga() {
  yield all([
    call(onFetchAdminsStart),
    call(onFetchMerchantsStart),
    call(onFetchSubscribersStart),
    call(onFetchTargetStart),
  ]);
}
