import styled from "styled-components";

export const SubscriptionPlanTableFeature = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const SubscriptionPlanTableList = styled.ul`
  list-style: square;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  row-gap: 0.35rem;
`;

export const SubscriptionPlanTableItem = styled.li`
  font-size: 0.875rem;
  font-weight: 500;
  color: #64748b;
  line-height: 1.5;
  margin: 0;
`;

export const SubscriptionPlanTableLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #64748b;
  line-height: 1.5;
  margin: 0;
`;

export const SubscriptionPlanTableCheck = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;

    path,
    polyline,
    line,
    circle {
      &[fill] {
        fill: ${({ isAllowed }) => (isAllowed ? "#008d0e" : "#94a3b8")};
      }
      &[stroke] {
        stroke: ${({ isAllowed }) => (isAllowed ? "#008d0e" : "#94a3b8")};
      }
    }
  }
`;
