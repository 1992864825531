import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateCountryFailed,
  setCreateCountrySuccess,
  setDeleteCountryFailed,
  setDeleteCountrySuccess,
  setFetchCountriesFailed,
  setFetchCountryFailed,
  setUpdateCountryFailed,
  setUpdateCountrySuccess,
} from "../../store/country/country.action";
import {
  getCreateCountryFailed,
  getCreateCountrySuccess,
  getDeleteCountryFailed,
  getDeleteCountrySuccess,
  getFetchCountriesFailed,
  getFetchCountryFailed,
  getUpdateCountryFailed,
  getUpdateCountrySuccess,
} from "../../store/country/country.selector";

const CountryPopup = () => {
  const dispatch = useDispatch();

  const createCountrySuccess = useSelector(getCreateCountrySuccess);
  const updateCountrySuccess = useSelector(getUpdateCountrySuccess);
  const deleteCountrySuccess = useSelector(getDeleteCountrySuccess);

  const fetchCountriesFailed = useSelector(getFetchCountriesFailed);
  const fetchCountryFailed = useSelector(getFetchCountryFailed);
  const createCountryFailed = useSelector(getCreateCountryFailed);
  const updateCountryFailed = useSelector(getUpdateCountryFailed);
  const deleteCountryFailed = useSelector(getDeleteCountryFailed);

  useEffect(() => {
    if (
      createCountrySuccess !== null ||
      updateCountrySuccess !== null ||
      deleteCountrySuccess !== null
    ) {
      showSuccessMessage(
        createCountrySuccess ?? updateCountrySuccess ?? deleteCountrySuccess
      );
      dispatch(setIsModalOpen(false));

      if (createCountrySuccess !== null)
        dispatch(setCreateCountrySuccess(null));
      if (updateCountrySuccess !== null)
        dispatch(setUpdateCountrySuccess(null));
      if (deleteCountrySuccess !== null)
        dispatch(setDeleteCountrySuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCountrySuccess, updateCountrySuccess, deleteCountrySuccess]);

  useEffect(() => {
    if (
      fetchCountriesFailed !== null ||
      fetchCountryFailed !== null ||
      createCountryFailed !== null ||
      updateCountryFailed !== null ||
      deleteCountryFailed !== null
    ) {
      showErrorMessage(
        fetchCountriesFailed ??
          fetchCountryFailed ??
          createCountryFailed ??
          updateCountryFailed ??
          deleteCountryFailed
      );

      if (fetchCountriesFailed !== null)
        dispatch(setFetchCountriesFailed(null));
      if (fetchCountryFailed !== null) dispatch(setFetchCountryFailed(null));
      if (createCountryFailed !== null) dispatch(setCreateCountryFailed(null));
      if (updateCountryFailed !== null) dispatch(setUpdateCountryFailed(null));
      if (deleteCountryFailed !== null) dispatch(setDeleteCountryFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchCountriesFailed,
    fetchCountryFailed,
    createCountryFailed,
    updateCountryFailed,
    deleteCountryFailed,
  ]);

  return <></>;
};

export default CountryPopup;
