import apiService from "./api";

export const getRestockRequests = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/restock-requests", params })).data;
export const getRestockRequest = async (restockId) =>
  (await apiService.innerGet({ urlPath: `/v2/restock-requests/${restockId}` }))
    .data;
export const createRestockRequest = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/restock-requests", request }))
    .data;
export const updateRestockRequest = async (restockId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/restock-requests/${restockId}`,
      request,
    })
  ).data;
export const deleteRestockRequest = async (restockId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/restock-requests/${restockId}`,
    })
  ).data;
