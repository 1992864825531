import moment from "moment";

import { getDateTimeFormat } from "../utils/formatter.utils";

import { LANGUAGES } from "../constants/common.constant";

export const getActiveSchedule = (schedules) => {
  const currentTime = moment().valueOf();
  const yesterday = moment(currentTime).subtract(1, "day").valueOf();

  const todayShort = getDateTimeFormat(currentTime, "ddd", LANGUAGES.EN).toUpperCase();
  const yesterdayShort = getDateTimeFormat(yesterday, "ddd", LANGUAGES.EN).toUpperCase();

  const todaySchedule = schedules?.find(({ day }) => day === todayShort);
  const yesterdaySchedule = schedules?.find(({ day }) => day === yesterdayShort);

  let todayStartTime = todaySchedule?.start_time;
  let todayEndTime = todaySchedule?.end_time;
  let yesterdayStartTime = yesterdaySchedule?.start_time;
  let yesterdayEndTime = yesterdaySchedule?.end_time;

  let isTodayOff = ["", null, undefined].includes(todayStartTime);
  isTodayOff ||= ["", null, undefined].includes(todayEndTime);

  let isYesterdayOff = ["", null, undefined].includes(yesterdayStartTime);
  isYesterdayOff ||= ["", null, undefined].includes(yesterdayEndTime);

  let todayStartDateTime = moment(
    getDateTimeFormat(currentTime, "YYYY-MM-DD ") + todayStartTime ?? "00:00:00",
    "YYYY-MM-DD HH:mm:ss"
  ).valueOf();
  let todayEndDateTime = moment(
    getDateTimeFormat(currentTime, "YYYY-MM-DD ") + todayEndTime ?? "00:00:00",
    "YYYY-MM-DD HH:mm:ss"
  ).valueOf();
  let yesterdayStartDateTime = moment(
    getDateTimeFormat(yesterday, "YYYY-MM-DD ") + yesterdayStartTime ?? "00:00:00",
    "YYYY-MM-DD HH:mm:ss"
  ).valueOf();
  let yesterdayEndDateTime = moment(
    getDateTimeFormat(yesterday, "YYYY-MM-DD ") + yesterdayEndTime ?? "00:00:00",
    "YYYY-MM-DD HH:mm:ss"
  ).valueOf();

  if (todayStartDateTime > todayEndDateTime) {
    todayEndDateTime = moment(todayEndDateTime).add(1, "day").valueOf();
  }
  if (yesterdayStartDateTime > yesterdayEndDateTime) {
    yesterdayEndDateTime = moment(yesterdayEndDateTime).add(1, "day").valueOf();
  }

  let isTodaySchedule = !isTodayOff && currentTime >= todayStartDateTime;
  isTodaySchedule &&= todayEndDateTime >= currentTime;

  let isYesterdaySchedule = !isYesterdayOff && currentTime >= yesterdayStartDateTime;
  isYesterdaySchedule &&= yesterdayEndDateTime >= currentTime;

  const currentSchedule = isTodaySchedule
    ? [todayStartDateTime, todayEndDateTime]
    : isYesterdaySchedule
    ? [yesterdayStartDateTime, yesterdayEndDateTime]
    : [];

  return currentSchedule;
};
