import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendPermissionAdminsFailed,
  setAppendPermissionSubscribersFailed,
  setFetchPermissionAdminsFailed,
  setFetchPermissionSubscribersFailed,
  setSearchPermissionAdminsFailed,
  setSearchPermissionSubscribersFailed,
} from "../../store/select-permission-admin/select-permission-admin.action";
import {
  getAppendPermissionAdminsFailed,
  getAppendPermissionSubscribersFailed,
  getFetchPermissionAdminsFailed,
  getFetchPermissionSubscribersFailed,
  getSearchPermissionAdminsFailed,
  getSearchPermissionSubscribersFailed,
} from "../../store/select-permission-admin/select-permission-admin.selector";

const SelectPermissionAdminPopup = () => {
  const dispatch = useDispatch();

  const fetchPermissionAdminsFailed = useSelector(
    getFetchPermissionAdminsFailed
  );
  const searchPermissionAdminsFailed = useSelector(
    getSearchPermissionAdminsFailed
  );
  const appendPermissionAdminsFailed = useSelector(
    getAppendPermissionAdminsFailed
  );
  const fetchPermissionSubscribersFailed = useSelector(
    getFetchPermissionSubscribersFailed
  );
  const searchPermissionSubscribersFailed = useSelector(
    getSearchPermissionSubscribersFailed
  );
  const appendPermissionSubscribersFailed = useSelector(
    getAppendPermissionSubscribersFailed
  );

  useEffect(() => {
    if (
      fetchPermissionAdminsFailed !== null ||
      searchPermissionAdminsFailed !== null ||
      appendPermissionAdminsFailed !== null ||
      fetchPermissionSubscribersFailed !== null ||
      searchPermissionSubscribersFailed !== null ||
      appendPermissionSubscribersFailed !== null
    ) {
      showErrorMessage(
        fetchPermissionAdminsFailed ??
          searchPermissionAdminsFailed ??
          appendPermissionAdminsFailed ??
          fetchPermissionSubscribersFailed ??
          searchPermissionSubscribersFailed ??
          appendPermissionSubscribersFailed
      );

      if (fetchPermissionAdminsFailed !== null)
        dispatch(setFetchPermissionAdminsFailed(null));
      if (searchPermissionAdminsFailed !== null)
        dispatch(setSearchPermissionAdminsFailed(null));
      if (appendPermissionAdminsFailed !== null)
        dispatch(setAppendPermissionAdminsFailed(null));
      if (fetchPermissionSubscribersFailed !== null)
        dispatch(setFetchPermissionSubscribersFailed(null));
      if (searchPermissionSubscribersFailed !== null)
        dispatch(setSearchPermissionSubscribersFailed(null));
      if (appendPermissionSubscribersFailed !== null)
        dispatch(setAppendPermissionSubscribersFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPermissionAdminsFailed,
    searchPermissionAdminsFailed,
    appendPermissionAdminsFailed,
    fetchPermissionSubscribersFailed,
    searchPermissionSubscribersFailed,
    appendPermissionSubscribersFailed,
  ]);

  return <></>;
};

export default SelectPermissionAdminPopup;
