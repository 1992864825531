import styled, { css } from "styled-components";

import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import {
  TABLE_COLUMN_ALIGNS,
  TABLE_COLUMN_BACKGROUNDS,
  TABLE_COLUMN_FOREGROUNDS,
  TABLE_COLUMN_WRAPS,
  TABLE_STYLES,
} from "./table.component";

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

export const TableColumnAlign = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: ${({ colHorAlign }) => {
    switch (colHorAlign) {
      case TABLE_COLUMN_ALIGNS.START:
        return "flex-start";
      case TABLE_COLUMN_ALIGNS.CENTER:
        return "center";
      case TABLE_COLUMN_ALIGNS.END:
        return "flex-end";
    }
  }};
  justify-content: ${({ colVerAlign }) => {
    switch (colVerAlign) {
      case TABLE_COLUMN_ALIGNS.START:
        return "flex-start";
      case TABLE_COLUMN_ALIGNS.CENTER:
        return "center";
      case TABLE_COLUMN_ALIGNS.END:
        return "flex-end";
    }
  }};
  white-space: ${({ colTextWrap }) => {
    switch (colTextWrap) {
      case TABLE_COLUMN_WRAPS.WRAP:
        return "pre-wrap";
      case TABLE_COLUMN_WRAPS.NOWRAP:
        return "nowrap";
    }
  }};
`;

export const TableColumnStyled = styled.td`
  height: 3.125rem;
  padding: 0.75rem;
  border: 1.5px solid #dfdcef;
  transition: 0.25s all ease;

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;

  cursor: ${({ onClick }) => (!isEmpty(onClick) ? "pointer" : "unset")};
  user-select: ${({ onClick }) => (!isEmpty(onClick) ? "none" : "unset")};

  &:hover {
    background-color: ${({ onClick }) =>
      !isEmpty(onClick) ? "#f2f2f4" : "unset"};
  }

  ${({ colBackground }) =>
    colBackground === TABLE_COLUMN_BACKGROUNDS.LIGHT &&
    css`
      background-color: #f9f9fb !important;
    `}
  ${({ colForeground }) =>
    colForeground === TABLE_COLUMN_FOREGROUNDS.DARK
      ? css`
          color: #0e072f !important;
        `
      : colForeground === TABLE_COLUMN_FOREGROUNDS.SECONDARY &&
        css`
          color: #9691ac !important;
        `}
`;

export const TableRowStyled = styled.tr`
  &:not(:first-child) {
    ${TableColumnStyled} {
      border-top: none;
    }
  }
`;

export const TableHeadStyled = styled.thead`
  ${TableRowStyled} {
    ${TableColumnStyled} {
      color: #9691ac;
    }
  }
`;

export const TableBodyStyled = styled.tbody`
  ${TableRowStyled} {
    ${TableColumnStyled} {
      color: #0e072f;
    }
  }
`;

const getBorderWidth = ({ tableStyle }) => {
  switch (tableStyle) {
    case TABLE_STYLES.STYLE_1_BG:
    case TABLE_STYLES.STYLE_2_BG:
      return "2px";
    case TABLE_STYLES.STYLE_1:
    case TABLE_STYLES.STYLE_2:
    case TABLE_STYLES.STYLE_3:
    default:
      return "1.5px";
  }
};

const getBorderColor = ({ tableStyle }) => {
  switch (tableStyle) {
    case TABLE_STYLES.STYLE_1_BG:
    case TABLE_STYLES.STYLE_2_BG:
      return "#e9e9ee";
    case TABLE_STYLES.STYLE_3:
      return "#e2e8f0";
    case TABLE_STYLES.STYLE_1:
    case TABLE_STYLES.STYLE_2:
    default:
      return "#dfdcef";
  }
};

const getBackgroundColor = ({ tableStyle }) => {
  switch (tableStyle) {
    case TABLE_STYLES.STYLE_1_BG:
    case TABLE_STYLES.STYLE_2_BG:
      return "#ffffff";
    case TABLE_STYLES.STYLE_1:
    case TABLE_STYLES.STYLE_2:
    case TABLE_STYLES.STYLE_3:
    default:
      return "transparent";
  }
};

const getTable1Styles = ({ isRightToLeft, hasTableHead }) => css`
  ${TableStyled} {
    ${TableRowStyled} {
      ${TableColumnStyled} {
        &:not(:last-child) {
          ${!isRightToLeft && "border-right: none;"}
          ${isRightToLeft && "border-left: none;"}
        }
      }
    }
    ${TableBodyStyled} {
      ${TableRowStyled} {
        &:first-child {
          ${TableColumnStyled} {
            ${hasTableHead && "border-top: none;"}
          }
        }
      }
    }
  }
`;
const getTable2Styles = ({ isRightToLeft, hasTableHead }) => css`
  ${TableStyled} {
    ${TableRowStyled} {
      ${TableColumnStyled} {
        &:not(:first-child) {
          ${!isRightToLeft && "border-left: none;"}
          ${isRightToLeft && "border-right: none;"}
        }
        &:not(:last-child) {
          ${isRightToLeft && "border-left: none;"}
          ${!isRightToLeft && "border-right: none;"}
        }
      }
    }
    ${TableBodyStyled} {
      ${TableRowStyled} {
        &:first-child {
          ${TableColumnStyled} {
            ${hasTableHead && "border-top: none;"}
          }
        }
      }
    }
  }
`;
const getTable3Styles = ({ isRightToLeft, hasTableHead }) => css`
  ${TableStyled} {
    ${TableRowStyled} {
      ${TableColumnStyled} {
        padding-top: 1rem;
        padding-bottom: 1rem;

        border-left: none;
        border-right: none;
        border-radius: 0 !important;

        &:first-child {
          ${!isRightToLeft && "padding-left: 0"}
          ${isRightToLeft && "padding-right: 0"}
        }
        &:last-child {
          ${!isRightToLeft && "padding-right: 0"}
          ${isRightToLeft && "padding-left: 0"}
        }
      }
    }

    ${hasTableHead
      ? css`
          ${TableHeadStyled} {
            ${TableRowStyled} {
              &:first-child {
                ${TableColumnStyled} {
                  border-top: none;
                }
              }
            }
          }
          ${TableBodyStyled} {
            ${TableRowStyled} {
              &:first-child {
                ${TableColumnStyled} {
                  border-top: none;
                }
              }
              &:last-child {
                ${TableColumnStyled} {
                  border-bottom: none;
                }
              }
            }
          }
        `
      : css`
          ${TableBodyStyled} {
            ${TableRowStyled} {
              &:first-child {
                ${TableColumnStyled} {
                  border-top: none;
                }
              }
              &:last-child {
                ${TableColumnStyled} {
                  border-bottom: none;
                }
              }
            }
          }
        `}
  }
`;

const getTableStyles = ({ tableStyle, ...props }) => {
  switch (tableStyle) {
    case TABLE_STYLES.STYLE_1:
    case TABLE_STYLES.STYLE_1_BG:
      return getTable1Styles(props);
    case TABLE_STYLES.STYLE_2:
    case TABLE_STYLES.STYLE_2_BG:
      return getTable2Styles(props);
    case TABLE_STYLES.STYLE_3:
      return getTable3Styles(props);
  }
};

export const TableContainerStyled = styled.div`
  width: 100%;

  ${({ tableResponsive }) =>
    parseToBoolean(tableResponsive) && `overflow-x: auto;`}

  ${TableStyled} {
    ${({ tableResponsive }) =>
      !parseToBoolean(tableResponsive) && `table-layout: fixed;`}

    ${TableRowStyled} {
      ${TableColumnStyled} {
        border-width: ${getBorderWidth};
        border-color: ${getBorderColor};
        background-color: ${getBackgroundColor};

        &:first-child {
          ${({ isRightToLeft }) => !isRightToLeft && "padding-left: 1rem;"};
          ${({ isRightToLeft }) => isRightToLeft && "padding-right: 1rem;"};
        }
        &:last-child {
          ${({ isRightToLeft }) => isRightToLeft && "padding-left: 1rem;"};
          ${({ isRightToLeft }) => !isRightToLeft && "padding-right: 1rem;"};
        }
      }
    }

    ${({ hasTableHead }) =>
      hasTableHead
        ? css`
            ${TableHeadStyled} {
              ${TableRowStyled} {
                &:first-child {
                  ${TableColumnStyled} {
                    &:first-child {
                      ${({ isRightToLeft }) =>
                        isRightToLeft
                          ? "border-top-right-radius: 1rem;"
                          : "border-top-left-radius: 1rem;"}
                    }
                    &:last-child {
                      ${({ isRightToLeft }) =>
                        !isRightToLeft
                          ? "border-top-right-radius: 1rem;"
                          : "border-top-left-radius: 1rem;"}
                    }
                  }
                }
              }
            }
            ${TableBodyStyled} {
              ${TableRowStyled} {
                &:last-child {
                  ${TableColumnStyled} {
                    &:first-child {
                      ${({ isRightToLeft }) =>
                        isRightToLeft
                          ? "border-bottom-right-radius: 1rem;"
                          : "border-bottom-left-radius: 1rem;"}
                    }
                    &:last-child {
                      ${({ isRightToLeft }) =>
                        !isRightToLeft
                          ? "border-bottom-right-radius: 1rem;"
                          : "border-bottom-left-radius: 1rem;"}
                    }
                  }
                }
              }
            }
          `
        : css`
            ${TableBodyStyled} {
              ${TableRowStyled} {
                &:first-child {
                  ${TableColumnStyled} {
                    &:first-child {
                      ${({ isRightToLeft }) =>
                        isRightToLeft
                          ? "border-top-right-radius: 1rem;"
                          : "border-top-left-radius: 1rem;"}
                    }
                    &:last-child {
                      ${({ isRightToLeft }) =>
                        !isRightToLeft
                          ? "border-top-right-radius: 1rem;"
                          : "border-top-left-radius: 1rem;"}
                    }
                  }
                }
                &:last-child {
                  ${TableColumnStyled} {
                    &:first-child {
                      ${({ isRightToLeft }) =>
                        isRightToLeft
                          ? "border-bottom-right-radius: 1rem;"
                          : "border-bottom-left-radius: 1rem;"}
                    }
                    &:last-child {
                      ${({ isRightToLeft }) =>
                        !isRightToLeft
                          ? "border-bottom-right-radius: 1rem;"
                          : "border-bottom-left-radius: 1rem;"}
                    }
                  }
                }
              }
            }
          `}
  }

  ${getTableStyles}
`;
