import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import { USER_ROLES } from "../../constants/user.constant";
import { SUBSCRIPTIONS_KEYS } from "../../constants/subscription.constant";
import { PERMISSION_SUBSCRIBER_PAGES } from "../../constants/permission-subscriber.constant";

import {
  getCurrentMarket,
  getCurrentMarketId,
  getIsHasActiveAdvancedPlan,
  getIsHasActiveBasicPlan,
  getIsHasActiveTrialPlan,
  getIsMarketAdministrator,
  getMasterPermitPages,
} from "../../store/global/global.selector";
import { setIsModalOpen } from "../../store/component/component.action";
import { getUser } from "../../store/authentication/authentication.selector";
import { getBillingCycle } from "../../store/state-subscription/state-subscription.selector";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";

import {
  SubscriptionPlanTableHeaderBottom,
  SubscriptionPlanTableHeaderContainer,
  SubscriptionPlanTableHeaderCurrent,
  SubscriptionPlanTableHeaderDescription,
  SubscriptionPlanTableHeaderTitle,
  SubscriptionPlanTableHeaderTop,
} from "./subscription-plan-table-header.style";

const SubscriptionPlanTableHeader = ({ billingPlan: plan }) => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { role } = useSelector(getUser) ?? {};

  const isMarketAdministrator = useSelector(getIsMarketAdministrator);
  const masterPermitPages = useSelector(getMasterPermitPages);
  const currentMarketId = useSelector(getCurrentMarketId);

  const currentMarket = useSelector(getCurrentMarket);
  const billingCycle = useSelector(getBillingCycle);

  const isHasActiveAdvancedPlan = useSelector(getIsHasActiveAdvancedPlan);
  const isHasActiveBasicPlan = useSelector(getIsHasActiveBasicPlan);
  const isHasActiveTrialPlan = useSelector(getIsHasActiveTrialPlan);

  const billingPlan = useMemo(() => plan, [plan]);

  const [subscriptionKey, setSubscriptionKey] = useState(null);
  const [isCurrentActive, setIsCurrentActive] = useState(false);
  const [isSwitchShowing, setIsSwitchShowing] = useState(false);
  const [isSwitchDisable, setIsSwitchDisable] = useState(false);

  const [planName, setPlanName] = useState("");
  const [planInfo, setPlanInfo] = useState("");

  const { key, name_en, name_ar, description_en, description_ar } =
    billingPlan ?? {};

  const getIsSwitchShowing = () =>
    ({
      [USER_ROLES.USER_ADMIN]:
        isMarketAdministrator && !isEmpty(currentMarketId),
      [USER_ROLES.USER_MERCHANT]:
        isMarketAdministrator && !isEmpty(currentMarketId),
      [USER_ROLES.USER_SUBSCRIBER]: masterPermitPages.includes(
        PERMISSION_SUBSCRIBER_PAGES.PAGE_SUBSCRIPTION
      ),
    }?.[role] ?? false);

  const handleSwitchPlan = () => {
    dispatch(setIsModalOpen(false));

    if (location.pathname.includes("/subscription")) return;

    navigate(
      isMarketAdministrator
        ? `/subscriber/${currentMarketId}/settings/subscription`
        : "/settings/subscription"
    );
  };
  const handlePlanDisable = () => {};

  const handleSwitchShowing = () =>
    setIsSwitchShowing(getIsSwitchShowing(role));

  useEffect(() => {
    setIsCurrentActive(subscriptionKey === key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionKey, key]);

  useEffect(() => {
    let isSwitchDisable = key === SUBSCRIPTIONS_KEYS.TRIAL;
    isSwitchDisable &&= subscriptionKey !== SUBSCRIPTIONS_KEYS.TRIAL;
    isSwitchDisable ||= isEmpty(billingCycle);

    setIsSwitchDisable(parseToBoolean(isSwitchDisable));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingCycle]);

  useEffect(() => {
    setPlanName(billingPlan?.[`name_${i18n.language}`] ?? name_en ?? name_ar);
    setPlanInfo(
      billingPlan?.[`description_${i18n.language}`] ??
        description_en ??
        description_ar
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingPlan]);

  useEffect(() => {
    const switchTimeout = setTimeout(handleSwitchShowing, 500);
    return () => clearTimeout(switchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, isMarketAdministrator, currentMarketId, masterPermitPages]);

  useEffect(() => {
    setSubscriptionKey(
      isHasActiveAdvancedPlan
        ? SUBSCRIPTIONS_KEYS.ADVANCED
        : isHasActiveBasicPlan
        ? SUBSCRIPTIONS_KEYS.BASIC
        : isHasActiveTrialPlan
        ? SUBSCRIPTIONS_KEYS.TRIAL
        : currentMarket?.setting?.subscription_key
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isHasActiveAdvancedPlan,
    isHasActiveBasicPlan,
    isHasActiveTrialPlan,
    currentMarket,
  ]);

  return (
    <SubscriptionPlanTableHeaderContainer>
      <SubscriptionPlanTableHeaderTop>
        <SubscriptionPlanTableHeaderTitle>
          {!isEmpty(planName) ? planName : t("No name")}
        </SubscriptionPlanTableHeaderTitle>
        {role === USER_ROLES.USER_SUBSCRIBER && isCurrentActive && (
          <SubscriptionPlanTableHeaderCurrent>
            {t("Current")}
          </SubscriptionPlanTableHeaderCurrent>
        )}
      </SubscriptionPlanTableHeaderTop>
      <SubscriptionPlanTableHeaderBottom>
        <SubscriptionPlanTableHeaderDescription>
          {!isEmpty(planInfo) ? planInfo : t("No information")}
        </SubscriptionPlanTableHeaderDescription>
        {isSwitchShowing &&
          (isCurrentActive ? (
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.LIGHT}
              buttonSize={BUTTON_SIZES.SMALL}
              buttonType={BUTTON_TYPES.TEXT}
              onClick={handlePlanDisable}
              isRoundedSm
              isDisabled
            >
              {t("Current Plan")}
            </Button>
          ) : isSwitchDisable ? (
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.LIGHT}
              buttonSize={BUTTON_SIZES.SMALL}
              buttonType={BUTTON_TYPES.TEXT}
              onClick={handlePlanDisable}
              isRoundedSm
              isDisabled
            >
              {t("Switch to :plan", { plan: planName })}
            </Button>
          ) : (
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.DARK}
              buttonSize={BUTTON_SIZES.SMALL}
              buttonType={BUTTON_TYPES.TEXT}
              onClick={handleSwitchPlan}
              isRoundedSm
            >
              {t("Switch to :plan", { plan: planName })}
            </Button>
          ))}
      </SubscriptionPlanTableHeaderBottom>
    </SubscriptionPlanTableHeaderContainer>
  );
};

export default SubscriptionPlanTableHeader;
