import { createAction } from "../../utils/store.utils";

import SELECT_PERMISSION_ADMIN_ACTION_TYPES from "./select-permission-admin.type";

export const setPermissionAdmins = (permissionAdmins) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_ADMINS,
    permissionAdmins
  );
export const setPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const setSearchPermissionAdmins = (searchPermissionAdmins) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS,
    searchPermissionAdmins
  );
export const setSearchPermissionSubscribers = (searchPermissionSubscribers) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS,
    searchPermissionSubscribers
  );

export const appendPermissionAdmins = (permissionAdmins) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_ADMINS,
    permissionAdmins
  );
export const appendPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const appendSearchPermissionAdmins = (searchPermissionAdmins) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_SEARCH_PERMISSION_ADMINS,
    searchPermissionAdmins
  );
export const appendSearchPermissionSubscribers = (
  searchPermissionSubscribers
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_SEARCH_PERMISSION_SUBSCRIBERS,
    searchPermissionSubscribers
  );

export const setIsPermissionAdminsHasMore = (isPermissionAdminsHasMore) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_ADMINS_HAS_MORE,
    isPermissionAdminsHasMore
  );
export const setIsPermissionSubscribersHasMore = (
  isPermissionSubscribersHasMore
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isPermissionSubscribersHasMore
  );
export const setIsSearchPermissionAdminsHasMore = (
  isSearchPermissionAdminsHasMore
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_ADMINS_HAS_MORE,
    isSearchPermissionAdminsHasMore
  );
export const setIsSearchPermissionSubscribersHasMore = (
  isSearchPermissionSubscribersHasMore
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isSearchPermissionSubscribersHasMore
  );

export const setFetchPermissionAdminsParams = (fetchPermissionAdminsParams) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_PARAMS,
    fetchPermissionAdminsParams
  );
export const setFetchPermissionAdminsLoading = (fetchPermissionAdminsLoading) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_LOADING,
    fetchPermissionAdminsLoading
  );
export const setFetchPermissionAdminsSuccess = (fetchPermissionAdminsSuccess) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SUCCESS,
    fetchPermissionAdminsSuccess
  );
export const setFetchPermissionAdminsFailed = (fetchPermissionAdminsFailed) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_FAILED,
    fetchPermissionAdminsFailed
  );

export const setFetchPermissionSubscribersParams = (
  fetchPermissionSubscribersParams
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PARAMS,
    fetchPermissionSubscribersParams
  );
export const setFetchPermissionSubscribersLoading = (
  fetchPermissionSubscribersLoading
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING,
    fetchPermissionSubscribersLoading
  );
export const setFetchPermissionSubscribersSuccess = (
  fetchPermissionSubscribersSuccess
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS,
    fetchPermissionSubscribersSuccess
  );
export const setFetchPermissionSubscribersFailed = (
  fetchPermissionSubscribersFailed
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED,
    fetchPermissionSubscribersFailed
  );

export const setSearchPermissionAdminsParams = (searchPermissionAdminsParams) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_PARAMS,
    searchPermissionAdminsParams
  );
export const setSearchPermissionAdminsLoading = (
  searchPermissionAdminsLoading
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_LOADING,
    searchPermissionAdminsLoading
  );
export const setSearchPermissionAdminsSuccess = (
  searchPermissionAdminsSuccess
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_SUCCESS,
    searchPermissionAdminsSuccess
  );
export const setSearchPermissionAdminsFailed = (searchPermissionAdminsFailed) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_FAILED,
    searchPermissionAdminsFailed
  );

export const setSearchPermissionSubscribersParams = (
  searchPermissionSubscribersParams
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_PARAMS,
    searchPermissionSubscribersParams
  );
export const setSearchPermissionSubscribersLoading = (
  searchPermissionSubscribersLoading
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_LOADING,
    searchPermissionSubscribersLoading
  );
export const setSearchPermissionSubscribersSuccess = (
  searchPermissionSubscribersSuccess
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_SUCCESS,
    searchPermissionSubscribersSuccess
  );
export const setSearchPermissionSubscribersFailed = (
  searchPermissionSubscribersFailed
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_FAILED,
    searchPermissionSubscribersFailed
  );

export const setAppendPermissionAdminsParams = (appendPermissionAdminsParams) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_PARAMS,
    appendPermissionAdminsParams
  );
export const setAppendPermissionAdminsLoading = (
  appendPermissionAdminsLoading
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_LOADING,
    appendPermissionAdminsLoading
  );
export const setAppendPermissionAdminsSuccess = (
  appendPermissionAdminsSuccess
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_SUCCESS,
    appendPermissionAdminsSuccess
  );
export const setAppendPermissionAdminsFailed = (appendPermissionAdminsFailed) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_FAILED,
    appendPermissionAdminsFailed
  );

export const setAppendPermissionSubscribersParams = (
  appendPermissionSubscribersParams
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_PARAMS,
    appendPermissionSubscribersParams
  );
export const setAppendPermissionSubscribersLoading = (
  appendPermissionSubscribersLoading
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_LOADING,
    appendPermissionSubscribersLoading
  );
export const setAppendPermissionSubscribersSuccess = (
  appendPermissionSubscribersSuccess
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_SUCCESS,
    appendPermissionSubscribersSuccess
  );
export const setAppendPermissionSubscribersFailed = (
  appendPermissionSubscribersFailed
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_FAILED,
    appendPermissionSubscribersFailed
  );

export const setIsFetchPermissionAdminsHitted = (
  isFetchPermissionAdminsHitted
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_ADMINS_HITTED,
    isFetchPermissionAdminsHitted
  );
export const setIsFetchPermissionSubscribersHitted = (
  isFetchPermissionSubscribersHitted
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED,
    isFetchPermissionSubscribersHitted
  );
export const setIsSearchPermissionAdminsHitted = (
  isSearchPermissionAdminsHitted
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_ADMINS_HITTED,
    isSearchPermissionAdminsHitted
  );
export const setIsSearchPermissionSubscribersHitted = (
  isSearchPermissionSubscribersHitted
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HITTED,
    isSearchPermissionSubscribersHitted
  );
export const setIsAppendPermissionAdminsHitted = (
  isAppendPermissionAdminsHitted
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_PERMISSION_ADMINS_HITTED,
    isAppendPermissionAdminsHitted
  );
export const setIsAppendPermissionSubscribersHitted = (
  isAppendPermissionSubscribersHitted
) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_PERMISSION_SUBSCRIBERS_HITTED,
    isAppendPermissionSubscribersHitted
  );

export const fetchPermissionAdminsStart = (params) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_ADMINS_START,
    params
  );
export const fetchPermissionSubscribersStart = (params) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START,
    params
  );
export const searchPermissionAdminsStart = (params) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SEARCH_PERMISSION_ADMINS_START,
    params
  );
export const searchPermissionSubscribersStart = (params) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.SEARCH_PERMISSION_SUBSCRIBERS_START,
    params
  );
export const appendPermissionAdminsStart = (params) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_ADMINS_START,
    params
  );
export const appendPermissionSubscribersStart = (params) =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS_START,
    params
  );

export const resetPermissionAdminReducer = () =>
  createAction(
    SELECT_PERMISSION_ADMIN_ACTION_TYPES.RESET_SELECT_PERMISSION_ADMIN_REDUCER
  );
