import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateMerchantFailed,
  setCreateMerchantSuccess,
  setDeleteMerchantFailed,
  setDeleteMerchantSuccess,
  setFetchMerchantFailed,
  setFetchMerchantsFailed,
  setUpdateMerchantFailed,
  setUpdateMerchantSuccess,
} from "../../store/merchant/merchant.action";
import {
  getCreateMerchantFailed,
  getCreateMerchantSuccess,
  getDeleteMerchantFailed,
  getDeleteMerchantSuccess,
  getFetchMerchantFailed,
  getFetchMerchantsFailed,
  getUpdateMerchantFailed,
  getUpdateMerchantSuccess,
} from "../../store/merchant/merchant.selector";

const MerchantPopup = () => {
  const dispatch = useDispatch();

  const createMerchantSuccess = useSelector(getCreateMerchantSuccess);
  const updateMerchantSuccess = useSelector(getUpdateMerchantSuccess);
  const deleteMerchantSuccess = useSelector(getDeleteMerchantSuccess);

  const fetchMerchantsFailed = useSelector(getFetchMerchantsFailed);
  const fetchMerchantFailed = useSelector(getFetchMerchantFailed);
  const createMerchantFailed = useSelector(getCreateMerchantFailed);
  const updateMerchantFailed = useSelector(getUpdateMerchantFailed);
  const deleteMerchantFailed = useSelector(getDeleteMerchantFailed);

  useEffect(() => {
    if (
      createMerchantSuccess !== null ||
      updateMerchantSuccess !== null ||
      deleteMerchantSuccess !== null
    ) {
      showSuccessMessage(
        createMerchantSuccess ?? updateMerchantSuccess ?? deleteMerchantSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createMerchantSuccess !== null)
        dispatch(setCreateMerchantSuccess(null));
      if (updateMerchantSuccess !== null)
        dispatch(setUpdateMerchantSuccess(null));
      if (deleteMerchantSuccess !== null)
        dispatch(setDeleteMerchantSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMerchantSuccess, updateMerchantSuccess, deleteMerchantSuccess]);

  useEffect(() => {
    if (
      fetchMerchantsFailed !== null ||
      fetchMerchantFailed !== null ||
      createMerchantFailed !== null ||
      updateMerchantFailed !== null ||
      deleteMerchantFailed !== null
    ) {
      showErrorMessage(
        fetchMerchantsFailed ??
          fetchMerchantFailed ??
          createMerchantFailed ??
          updateMerchantFailed ??
          deleteMerchantFailed
      );

      if (fetchMerchantsFailed !== null)
        dispatch(setFetchMerchantsFailed(null));
      if (fetchMerchantFailed !== null) dispatch(setFetchMerchantFailed(null));
      if (createMerchantFailed !== null)
        dispatch(setCreateMerchantFailed(null));
      if (updateMerchantFailed !== null)
        dispatch(setUpdateMerchantFailed(null));
      if (deleteMerchantFailed !== null)
        dispatch(setDeleteMerchantFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchMerchantsFailed,
    fetchMerchantFailed,
    createMerchantFailed,
    updateMerchantFailed,
    deleteMerchantFailed,
  ]);

  return <></>;
};

export default MerchantPopup;
