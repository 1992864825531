import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getPaymentMethods } from "../../api/payment-method.api";

import SELECT_PAYMENT_METHOD_ACTION_TYPES from "./select-payment-method.type";
import {
  appendPaymentMethods,
  appendSearchPaymentMethods,
  setAppendPaymentMethodsFailed,
  setAppendPaymentMethodsLoading,
  setAppendPaymentMethodsSuccess,
  setFetchPaymentMethodsFailed,
  setFetchPaymentMethodsLoading,
  setFetchPaymentMethodsSuccess,
  setIsAppendPaymentMethodsHitted,
  setIsFetchPaymentMethodsHitted,
  setIsPaymentMethodsHasMore,
  setIsSearchPaymentMethodsHasMore,
  setIsSearchPaymentMethodsHitted,
  setPaymentMethods,
  setSearchPaymentMethods,
  setSearchPaymentMethodsFailed,
  setSearchPaymentMethodsLoading,
  setSearchPaymentMethodsSuccess,
} from "./select-payment-method.action";

export function* _getFetchPaymentMethods({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchPaymentMethodsLoading(true));

    const {
      meta: { message },
      data: paymentMethods,
    } = yield call(getPaymentMethods, parameters);

    yield put(setIsFetchPaymentMethodsHitted(true));
    yield put(
      setIsPaymentMethodsHasMore(Object.keys(paymentMethods).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendPaymentMethods(paymentMethods));
    } else {
      yield put(setPaymentMethods(paymentMethods));
    }

    yield put(setFetchPaymentMethodsSuccess(message));
    yield put(setFetchPaymentMethodsLoading(false));
  } catch (error) {
    yield put(setFetchPaymentMethodsFailed(error));
    yield put(setFetchPaymentMethodsLoading(false));
  }
}
export function* _getSearchPaymentMethods({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchPaymentMethodsLoading(true));

    const {
      meta: { message },
      data: paymentMethods,
    } = yield call(getPaymentMethods, parameters);

    yield put(setIsSearchPaymentMethodsHitted(true));
    yield put(
      setIsSearchPaymentMethodsHasMore(Object.keys(paymentMethods).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendSearchPaymentMethods(paymentMethods));
    } else {
      yield put(setSearchPaymentMethods(paymentMethods));
    }

    yield put(setSearchPaymentMethodsSuccess(message));
    yield put(setSearchPaymentMethodsLoading(false));
  } catch (error) {
    yield put(setSearchPaymentMethodsFailed(error));
    yield put(setSearchPaymentMethodsLoading(false));
  }
}
export function* _getAppendPaymentMethods({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendPaymentMethodsLoading(true));

    const {
      meta: { message },
      data: paymentMethods,
    } = yield call(getPaymentMethods, parameters);

    yield put(setIsAppendPaymentMethodsHitted(true));
    yield put(appendPaymentMethods(paymentMethods));

    yield put(setAppendPaymentMethodsSuccess(message));
    yield put(setAppendPaymentMethodsLoading(false));
  } catch (error) {
    yield put(setAppendPaymentMethodsFailed(error));
    yield put(setAppendPaymentMethodsLoading(false));
  }
}

export function* onFetchPaymentMethodsStart() {
  yield takeLatest(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.FETCH_PAYMENT_METHODS_START,
    _getFetchPaymentMethods
  );
}
export function* onSearchPaymentMethodsStart() {
  yield takeLatest(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.SEARCH_PAYMENT_METHODS_START,
    _getSearchPaymentMethods
  );
}
export function* onAppendPaymentMethodsStart() {
  yield takeLatest(
    SELECT_PAYMENT_METHOD_ACTION_TYPES.APPEND_PAYMENT_METHODS_START,
    _getAppendPaymentMethods
  );
}

export function* selectPaymentMethodSaga() {
  yield all([
    call(onFetchPaymentMethodsStart),
    call(onSearchPaymentMethodsStart),
    call(onAppendPaymentMethodsStart),
  ]);
}
