import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import {
  setCreateGatewayFailed,
  setCreateGatewaySuccess,
  setFetchGatewayFailed,
} from "../../store/gateway/gateway.action";
import {
  getCreateGatewayFailed,
  getCreateGatewaySuccess,
  getFetchGatewayFailed,
} from "../../store/gateway/gateway.selector";

const GatewayPopup = () => {
  const dispatch = useDispatch();

  const createGatewaySuccess = useSelector(getCreateGatewaySuccess);
  const fetchGatewayFailed = useSelector(getFetchGatewayFailed);
  const createGatewayFailed = useSelector(getCreateGatewayFailed);

  useEffect(() => {
    if (createGatewaySuccess !== null) {
      showSuccessMessage(createGatewaySuccess);
      dispatch(setCreateGatewaySuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGatewaySuccess]);

  useEffect(() => {
    if (fetchGatewayFailed !== null || createGatewayFailed !== null) {
      showErrorMessage(fetchGatewayFailed ?? createGatewayFailed);

      if (fetchGatewayFailed !== null) dispatch(setFetchGatewayFailed(null));
      if (createGatewayFailed !== null) dispatch(setCreateGatewayFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGatewayFailed, createGatewayFailed]);

  return <></>;
};

export default GatewayPopup;
