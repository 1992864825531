import styled from "styled-components";

import { FORM_INPUT_STYLES } from "../../utils/styles.utils";

import { FORM_LABEL_COLORS, FORM_LABEL_SIZES } from "./form-label.component";

export const FormLabelText = styled.label`
  display: inline-block;
  font-weight: ${FORM_INPUT_STYLES.LABEL_FONT_WEIGHT};
  margin: 0;
`;

export const FormLabelAction = styled.p`
  position: relative;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  color: #2900ee;
  margin: 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #2900ee;
  }
`;

export const FormLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  ${FormLabelText} {
    font-size: ${({ labelSize }) => {
      switch (labelSize) {
        case FORM_LABEL_SIZES.LARGE:
          return "0.925rem";
        case FORM_LABEL_SIZES.SMALL:
          return "0.75rem";
        case FORM_LABEL_SIZES.NORMAL:
        default:
          return "0.875rem";
      }
    }};
    color: ${({ labelColor }) => {
      switch (labelColor) {
        case FORM_LABEL_COLORS.LIGHT:
          return FORM_INPUT_STYLES.LABEL_LIGHT_COLOR;
        case FORM_LABEL_COLORS.TRANSPARENT:
          return FORM_INPUT_STYLES.LABEL_TRANSPARENT_COLOR;
        case FORM_LABEL_COLORS.DARK:
        default:
          return FORM_INPUT_STYLES.LABEL_DARK_COLOR;
      }
    }};
  }
  ${FormLabelAction} {
    font-size: ${({ labelSize }) => {
      switch (labelSize) {
        case FORM_LABEL_SIZES.LARGE:
          return "0.925rem";
        case FORM_LABEL_SIZES.SMALL:
          return "0.75rem";
        case FORM_LABEL_SIZES.NORMAL:
        default:
          return "0.875rem";
      }
    }};
  }
`;
