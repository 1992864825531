import { Link } from "react-router-dom";
import styled from "styled-components";

export const ApplicationCompletionOverlayItemContainer = styled.div`
  cursor: default;
  user-select: none;

  display: flex;
  align-items: center;
  column-gap: 0.75rem;

  padding: 0.75rem 1rem;
  border: 1.5px solid #e9e9ee;
  border-radius: 0.75rem;
  background-color: #ffffff;
`;

export const ApplicationCompletionOverlayItemIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;

    path,
    polyline,
    line,
    circle {
      fill: ${({ isActive }) => (isActive ? "#008d0e" : "#d1d5db")};
      transition: 0.3s all ease;
    }
  }
`;

export const ApplicationCompletionOverlayItemInfo = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ApplicationCompletionOverlayItemLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
    stroke-width: 2.5;

    path,
    polyline,
    line,
    circle {
      stroke: #9ca3af;
      transition: 0.3s all ease;
    }
  }

  &:hover {
    svg {
      path,
      polyline,
      line,
      circle {
        stroke: #0e072f;
      }
    }
  }
`;

export const ApplicationCompletionOverlayItemLabel = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.675rem 0.75rem;
  border-radius: 0.75rem;
  background-color: ${({ isRequired }) => (isRequired ? "#d9eedc" : "#f2f2f4")};

  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ isRequired }) => (isRequired ? "#008d0e" : "#0e072f")};
  line-height: 1;
  margin: 0;
`;

export const ApplicationCompletionOverlayListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.35rem;

  ${ApplicationCompletionOverlayItemIcon} {
    margin-right: ${({ isRightToLeft }) =>
      !isRightToLeft ? "0.25rem" : "unset"};
    margin-left: ${({ isRightToLeft }) =>
      isRightToLeft ? "0.25rem" : "unset"};
  }
  ${ApplicationCompletionOverlayItemLink} {
    margin-left: ${({ isRightToLeft }) => (!isRightToLeft ? "auto" : "unset")};
    margin-right: ${({ isRightToLeft }) => (isRightToLeft ? "auto" : "unset")};
  }
`;
