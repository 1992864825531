import SELECT_PERMISSION_ADMIN_ACTION_TYPES from "./select-permission-admin.type";

const SELECT_PERMISSION_ADMIN_INITIAL_STATE = {
  permissionAdmins: {},
  permissionSubscribers: {},
  searchPermissionAdmins: {},
  searchPermissionSubscribers: {},

  isPermissionAdminsHasMore: true,
  isPermissionSubscribersHasMore: true,
  isSearchPermissionAdminsHasMore: true,
  isSearchPermissionSubscribersHasMore: true,

  fetchPermissionAdminsParams: {},
  fetchPermissionAdminsLoading: false,
  fetchPermissionAdminsSuccess: null,
  fetchPermissionAdminsFailed: null,

  fetchPermissionSubscribersParams: {},
  fetchPermissionSubscribersLoading: false,
  fetchPermissionSubscribersSuccess: null,
  fetchPermissionSubscribersFailed: null,

  searchPermissionAdminsParams: {},
  searchPermissionAdminsLoading: false,
  searchPermissionAdminsSuccess: null,
  searchPermissionAdminsFailed: null,

  searchPermissionSubscribersParams: {},
  searchPermissionSubscribersLoading: false,
  searchPermissionSubscribersSuccess: null,
  searchPermissionSubscribersFailed: null,

  appendPermissionAdminsParams: {},
  appendPermissionAdminsLoading: false,
  appendPermissionAdminsSuccess: null,
  appendPermissionAdminsFailed: null,

  appendPermissionSubscribersParams: {},
  appendPermissionSubscribersLoading: false,
  appendPermissionSubscribersSuccess: null,
  appendPermissionSubscribersFailed: null,

  isFetchPermissionAdminsHitted: false,
  isFetchPermissionSubscribersHitted: false,
  isSearchPermissionAdminsHitted: false,
  isSearchPermissionSubscribersHitted: false,
  isAppendPermissionAdminsHitted: false,
  isAppendPermissionSubscribersHitted: false,
};

export const selectPermissionAdminReducer = (
  state = SELECT_PERMISSION_ADMIN_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_ADMINS:
      return { ...state, permissionAdmins: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS:
      return { ...state, permissionSubscribers: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS:
      return { ...state, searchPermissionAdmins: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS:
      return { ...state, searchPermissionSubscribers: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_ADMINS_HAS_MORE:
      return { ...state, isPermissionAdminsHasMore: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isPermissionSubscribersHasMore: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_ADMINS_HAS_MORE:
      return { ...state, isSearchPermissionAdminsHasMore: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSearchPermissionSubscribersHasMore: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_PARAMS:
      return { ...state, fetchPermissionAdminsParams: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_LOADING:
      return { ...state, fetchPermissionAdminsLoading: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SUCCESS:
      return { ...state, fetchPermissionAdminsSuccess: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_FAILED:
      return { ...state, fetchPermissionAdminsFailed: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PARAMS:
      return { ...state, fetchPermissionSubscribersParams: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, fetchPermissionSubscribersLoading: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchPermissionSubscribersSuccess: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, fetchPermissionSubscribersFailed: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_PARAMS:
      return { ...state, searchPermissionAdminsParams: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_LOADING:
      return { ...state, searchPermissionAdminsLoading: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_SUCCESS:
      return { ...state, searchPermissionAdminsSuccess: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_ADMINS_FAILED:
      return { ...state, searchPermissionAdminsFailed: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_PARAMS:
      return { ...state, searchPermissionSubscribersParams: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, searchPermissionSubscribersLoading: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, searchPermissionSubscribersSuccess: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_SEARCH_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, searchPermissionSubscribersFailed: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_PARAMS:
      return { ...state, appendPermissionAdminsParams: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_LOADING:
      return { ...state, appendPermissionAdminsLoading: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_SUCCESS:
      return { ...state, appendPermissionAdminsSuccess: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_ADMINS_FAILED:
      return { ...state, appendPermissionAdminsFailed: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_PARAMS:
      return { ...state, appendPermissionSubscribersParams: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, appendPermissionSubscribersLoading: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, appendPermissionSubscribersSuccess: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, appendPermissionSubscribersFailed: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_ADMINS_HITTED:
      return { ...state, isFetchPermissionAdminsHitted: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isFetchPermissionSubscribersHitted: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_ADMINS_HITTED:
      return { ...state, isSearchPermissionAdminsHitted: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SEARCH_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isSearchPermissionSubscribersHitted: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_PERMISSION_ADMINS_HITTED:
      return { ...state, isAppendPermissionAdminsHitted: payload };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isAppendPermissionSubscribersHitted: payload };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_ADMINS:
      return {
        ...state,
        permissionAdmins: { ...state.permissionAdmins, ...payload },
      };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        permissionSubscribers: { ...state.permissionSubscribers, ...payload },
      };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_SEARCH_PERMISSION_ADMINS:
      return {
        ...state,
        searchPermissionAdmins: { ...state.searchPermissionAdmins, ...payload },
      };
    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.APPEND_SEARCH_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        searchPermissionSubscribers: {
          ...state.searchPermissionSubscribers,
          ...payload,
        },
      };

    case SELECT_PERMISSION_ADMIN_ACTION_TYPES.RESET_SELECT_PERMISSION_ADMIN_REDUCER:
      return SELECT_PERMISSION_ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
