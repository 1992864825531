const INTEGRATION_ACTION_TYPES = {
  RESET_INTEGRATION_REDUCER: "integration/RESET_INTEGRATION_REDUCER",

  SET_IS_MODAL_AUTO_CLOSE: "integration/SET_IS_MODAL_AUTO_CLOSE",
  SET_INTEGRATIONS: "integration/SET_INTEGRATIONS",
  SET_INTEGRATION: "integration/SET_INTEGRATION",
  SET_QOYOD_STATUS: "integration/SET_QOYOD_STATUS",
  SET_QOYOD_SYNC: "integration/SET_QOYOD_SYNC",
  SET_GRUBTECH_STATUS: "integration/SET_GRUBTECH_STATUS",
  SET_GRUBTECH_SYNC: "integration/SET_GRUBTECH_SYNC",
  SET_BALLURH_STATUS: "integration/SET_BALLURH_STATUS",
  SET_BALLURH_GENERATE: "integration/SET_BALLURH_GENERATE",
  SET_BALLURH_SYNC: "integration/SET_BALLURH_SYNC",
  SET_ZATCA_SETUP: "integration/SET_ZATCA_SETUP",
  SET_ZATCA_REGISTER: "integration/SET_ZATCA_REGISTER",
  SET_ZATCA_STATUS: "integration/SET_ZATCA_STATUS",
  SET_ZATCA_SYNC: "integration/SET_ZATCA_SYNC",
  SET_FAI_SETUP: "integration/SET_FAI_SETUP",
  SET_FAI_REGISTER: "integration/SET_FAI_REGISTER",
  SET_FAI_REGISTER_STATUS: "integration/SET_FAI_REGISTER_STATUS",
  SET_FAI_STATUS: "integration/SET_FAI_STATUS",
  SET_FAI_SYNC: "integration/SET_FAI_SYNC",

  SET_IS_INTEGRATIONS_HAS_MORE: "integration/SET_IS_INTEGRATIONS_HAS_MORE",

  SET_FETCH_INTEGRATIONS_SORT: "integration/SET_FETCH_INTEGRATIONS_SORT",
  SET_FETCH_INTEGRATIONS_KEY_BY: "integration/SET_FETCH_INTEGRATIONS_KEY_BY",
  SET_FETCH_INTEGRATIONS_PAGE: "integration/SET_FETCH_INTEGRATIONS_PAGE",
  SET_FETCH_INTEGRATIONS_PER_PAGE:
    "integration/SET_FETCH_INTEGRATIONS_PER_PAGE",
  SET_FETCH_INTEGRATIONS_INCLUDES:
    "integration/SET_FETCH_INTEGRATIONS_INCLUDES",
  SET_FETCH_INTEGRATIONS_FILTER_MARKET_ID:
    "integration/SET_FETCH_INTEGRATIONS_FILTER_MARKET_ID",
  SET_FETCH_INTEGRATIONS_FILTER_BRANCH_ID:
    "integration/SET_FETCH_INTEGRATIONS_FILTER_BRANCH_ID",
  SET_FETCH_INTEGRATIONS_FILTER_KEYS:
    "integration/SET_FETCH_INTEGRATIONS_FILTER_KEYS",
  SET_FETCH_INTEGRATIONS_LOADING: "integration/SET_FETCH_INTEGRATIONS_LOADING",
  SET_FETCH_INTEGRATIONS_SUCCESS: "integration/SET_FETCH_INTEGRATIONS_SUCCESS",
  SET_FETCH_INTEGRATIONS_FAILED: "integration/SET_FETCH_INTEGRATIONS_FAILED",

  SET_FETCH_INTEGRATION_LOADING: "integration/SET_FETCH_INTEGRATION_LOADING",
  SET_FETCH_INTEGRATION_SUCCESS: "integration/SET_FETCH_INTEGRATION_SUCCESS",
  SET_FETCH_INTEGRATION_FAILED: "integration/SET_FETCH_INTEGRATION_FAILED",

  SET_CREATE_OR_UPDATE_INTEGRATION_LOADING:
    "integration/SET_CREATE_OR_UPDATE_INTEGRATION_LOADING",
  SET_CREATE_OR_UPDATE_INTEGRATION_SUCCESS:
    "integration/SET_CREATE_OR_UPDATE_INTEGRATION_SUCCESS",
  SET_CREATE_OR_UPDATE_INTEGRATION_FAILED:
    "integration/SET_CREATE_OR_UPDATE_INTEGRATION_FAILED",

  SET_FETCH_QOYOD_STATUS_LOADING: "integration/SET_FETCH_QOYOD_STATUS_LOADING",
  SET_FETCH_QOYOD_STATUS_SUCCESS: "integration/SET_FETCH_QOYOD_STATUS_SUCCESS",
  SET_FETCH_QOYOD_STATUS_FAILED: "integration/SET_FETCH_QOYOD_STATUS_FAILED",

  SET_SYNC_QOYOD_LOADING: "integration/SET_SYNC_QOYOD_LOADING",
  SET_SYNC_QOYOD_SUCCESS: "integration/SET_SYNC_QOYOD_SUCCESS",
  SET_SYNC_QOYOD_FAILED: "integration/SET_SYNC_QOYOD_FAILED",

  SET_FETCH_GRUBTECH_STATUS_LOADING:
    "integration/SET_FETCH_GRUBTECH_STATUS_LOADING",
  SET_FETCH_GRUBTECH_STATUS_SUCCESS:
    "integration/SET_FETCH_GRUBTECH_STATUS_SUCCESS",
  SET_FETCH_GRUBTECH_STATUS_FAILED:
    "integration/SET_FETCH_GRUBTECH_STATUS_FAILED",

  SET_SYNC_GRUBTECH_LOADING: "integration/SET_SYNC_GRUBTECH_LOADING",
  SET_SYNC_GRUBTECH_SUCCESS: "integration/SET_SYNC_GRUBTECH_SUCCESS",
  SET_SYNC_GRUBTECH_FAILED: "integration/SET_SYNC_GRUBTECH_FAILED",

  SET_FETCH_BALLURH_STATUS_LOADING:
    "integration/SET_FETCH_BALLURH_STATUS_LOADING",
  SET_FETCH_BALLURH_STATUS_SUCCESS:
    "integration/SET_FETCH_BALLURH_STATUS_SUCCESS",
  SET_FETCH_BALLURH_STATUS_FAILED:
    "integration/SET_FETCH_BALLURH_STATUS_FAILED",

  SET_GENERATE_BALLURH_LOADING: "integration/SET_GENERATE_BALLURH_LOADING",
  SET_GENERATE_BALLURH_SUCCESS: "integration/SET_GENERATE_BALLURH_SUCCESS",
  SET_GENERATE_BALLURH_FAILED: "integration/SET_GENERATE_BALLURH_FAILED",

  SET_SYNC_BALLURH_LOADING: "integration/SET_SYNC_BALLURH_LOADING",
  SET_SYNC_BALLURH_SUCCESS: "integration/SET_SYNC_BALLURH_SUCCESS",
  SET_SYNC_BALLURH_FAILED: "integration/SET_SYNC_BALLURH_FAILED",

  SET_SETUP_ZATCA_LOADING: "integration/SET_SETUP_ZATCA_LOADING",
  SET_SETUP_ZATCA_SUCCESS: "integration/SET_SETUP_ZATCA_SUCCESS",
  SET_SETUP_ZATCA_FAILED: "integration/SET_SETUP_ZATCA_FAILED",

  SET_REGISTER_ZATCA_LOADING: "integration/SET_REGISTER_ZATCA_LOADING",
  SET_REGISTER_ZATCA_SUCCESS: "integration/SET_REGISTER_ZATCA_SUCCESS",
  SET_REGISTER_ZATCA_FAILED: "integration/SET_REGISTER_ZATCA_FAILED",

  SET_FETCH_ZATCA_STATUS_LOADING: "integration/SET_FETCH_ZATCA_STATUS_LOADING",
  SET_FETCH_ZATCA_STATUS_SUCCESS: "integration/SET_FETCH_ZATCA_STATUS_SUCCESS",
  SET_FETCH_ZATCA_STATUS_FAILED: "integration/SET_FETCH_ZATCA_STATUS_FAILED",

  SET_SYNC_ZATCA_LOADING: "integration/SET_SYNC_ZATCA_LOADING",
  SET_SYNC_ZATCA_SUCCESS: "integration/SET_SYNC_ZATCA_SUCCESS",
  SET_SYNC_ZATCA_FAILED: "integration/SET_SYNC_ZATCA_FAILED",

  SET_SETUP_FAI_LOADING: "integration/SET_SETUP_FAI_LOADING",
  SET_SETUP_FAI_SUCCESS: "integration/SET_SETUP_FAI_SUCCESS",
  SET_SETUP_FAI_FAILED: "integration/SET_SETUP_FAI_FAILED",

  SET_REGISTER_FAI_LOADING: "integration/SET_REGISTER_FAI_LOADING",
  SET_REGISTER_FAI_SUCCESS: "integration/SET_REGISTER_FAI_SUCCESS",
  SET_REGISTER_FAI_FAILED: "integration/SET_REGISTER_FAI_FAILED",

  SET_FETCH_REGISTER_FAI_STATUS_LOADING:
    "integration/SET_FETCH_REGISTER_FAI_STATUS_LOADING",
  SET_FETCH_REGISTER_FAI_STATUS_SUCCESS:
    "integration/SET_FETCH_REGISTER_FAI_STATUS_SUCCESS",
  SET_FETCH_REGISTER_FAI_STATUS_FAILED:
    "integration/SET_FETCH_REGISTER_FAI_STATUS_FAILED",

  SET_FETCH_FAI_STATUS_LOADING: "integration/SET_FETCH_FAI_STATUS_LOADING",
  SET_FETCH_FAI_STATUS_SUCCESS: "integration/SET_FETCH_FAI_STATUS_SUCCESS",
  SET_FETCH_FAI_STATUS_FAILED: "integration/SET_FETCH_FAI_STATUS_FAILED",

  SET_SYNC_FAI_LOADING: "integration/SET_SYNC_FAI_LOADING",
  SET_SYNC_FAI_SUCCESS: "integration/SET_SYNC_FAI_SUCCESS",
  SET_SYNC_FAI_FAILED: "integration/SET_SYNC_FAI_FAILED",

  APPEND_INTEGRATIONS: "integration/APPEND_INTEGRATIONS",

  SET_IS_FETCH_INTEGRATIONS_HITTED:
    "integration/SET_IS_FETCH_INTEGRATIONS_HITTED",
  SET_IS_FETCH_INTEGRATION_HITTED:
    "integration/SET_IS_FETCH_INTEGRATION_HITTED",
  SET_IS_CREATE_OR_UPDATE_INTEGRATION_HITTED:
    "integration/SET_IS_CREATE_OR_UPDATE_INTEGRATION_HITTED",
  SET_IS_FETCH_QOYOD_STATUS_HITTED:
    "integration/SET_IS_FETCH_QOYOD_STATUS_HITTED",
  SET_IS_SYNC_QOYOD_HITTED: "integration/SET_IS_SYNC_QOYOD_HITTED",
  SET_IS_FETCH_GRUBTECH_STATUS_HITTED:
    "integration/SET_IS_FETCH_GRUBTECH_STATUS_HITTED",
  SET_IS_SYNC_GRUBTECH_HITTED: "integration/SET_IS_SYNC_GRUBTECH_HITTED",
  SET_IS_FETCH_BALLURH_STATUS_HITTED:
    "integration/SET_IS_FETCH_BALLURH_STATUS_HITTED",
  SET_IS_GENERATE_BALLURH_HITTED: "integration/SET_IS_GENERATE_BALLURH_HITTED",
  SET_IS_SYNC_BALLURH_HITTED: "integration/SET_IS_SYNC_BALLURH_HITTED",
  SET_IS_SETUP_ZATCA_HITTED: "integration/SET_IS_SETUP_ZATCA_HITTED",
  SET_IS_REGISTER_ZATCA_HITTED: "integration/SET_IS_REGISTER_ZATCA_HITTED",
  SET_IS_FETCH_ZATCA_STATUS_HITTED:
    "integration/SET_IS_FETCH_ZATCA_STATUS_HITTED",
  SET_IS_SYNC_ZATCA_HITTED: "integration/SET_IS_SYNC_ZATCA_HITTED",
  SET_IS_SETUP_FAI_HITTED: "integration/SET_IS_SETUP_FAI_HITTED",
  SET_IS_REGISTER_FAI_HITTED: "integration/SET_IS_REGISTER_FAI_HITTED",
  SET_IS_FETCH_REGISTER_FAI_STATUS_HITTED:
    "integration/SET_IS_FETCH_REGISTER_FAI_STATUS_HITTED",
  SET_IS_FETCH_FAI_STATUS_HITTED: "integration/SET_IS_FETCH_FAI_STATUS_HITTED",
  SET_IS_SYNC_FAI_HITTED: "integration/SET_IS_SYNC_FAI_HITTED",

  FETCH_INTEGRATIONS_START: "integration/FETCH_INTEGRATIONS_START",
  FETCH_INTEGRATION_START: "integration/FETCH_INTEGRATION_START",
  CREATE_OR_UPDATE_INTEGRATION_START:
    "integration/CREATE_OR_UPDATE_INTEGRATION_START",
  FETCH_QOYOD_STATUS_START: "integration/FETCH_QOYOD_STATUS_START",
  SYNC_QOYOD_START: "integration/SYNC_QOYOD_START",
  FETCH_GRUBTECH_STATUS_START: "integration/FETCH_GRUBTECH_STATUS_START",
  SYNC_GRUBTECH_START: "integration/SYNC_GRUBTECH_START",
  FETCH_BALLURH_STATUS_START: "integration/FETCH_BALLURH_STATUS_START",
  GENERATE_BALLURH_START: "integration/GENERATE_BALLURH_START",
  SYNC_BALLURH_START: "integration/SYNC_BALLURH_START",
  SETUP_ZATCA_START: "integration/SETUP_ZATCA_START",
  REGISTER_ZATCA_START: "integration/REGISTER_ZATCA_START",
  FETCH_ZATCA_STATUS_START: "integration/FETCH_ZATCA_STATUS_START",
  SYNC_ZATCA_START: "integration/SYNC_ZATCA_START",
  SETUP_FAI_START: "integration/SETUP_FAI_START",
  REGISTER_FAI_START: "integration/REGISTER_FAI_START",
  FETCH_REGISTER_FAI_STATUS_START:
    "integration/FETCH_REGISTER_FAI_STATUS_START",
  FETCH_FAI_STATUS_START: "integration/FETCH_FAI_STATUS_START",
  SYNC_FAI_START: "integration/SYNC_FAI_START",
};

export default INTEGRATION_ACTION_TYPES;
