import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreatePrinterTypeFailed,
  setCreatePrinterTypeSuccess,
  setDeletePrinterTypeFailed,
  setDeletePrinterTypeSuccess,
  setFetchPrinterTypeFailed,
  setFetchPrinterTypesFailed,
  setFetchSelectPrinterTypesFailed,
  setUpdatePrinterTypeFailed,
  setUpdatePrinterTypeSuccess,
} from "../../store/printer-type/printer-type.action";
import {
  getCreatePrinterTypeFailed,
  getCreatePrinterTypeSuccess,
  getDeletePrinterTypeFailed,
  getDeletePrinterTypeSuccess,
  getFetchPrinterTypeFailed,
  getFetchPrinterTypesFailed,
  getFetchSelectPrinterTypesFailed,
  getUpdatePrinterTypeFailed,
  getUpdatePrinterTypeSuccess,
} from "../../store/printer-type/printer-type.selector";

const PrinterTypePopup = () => {
  const dispatch = useDispatch();

  const createPrinterTypeSuccess = useSelector(getCreatePrinterTypeSuccess);
  const updatePrinterTypeSuccess = useSelector(getUpdatePrinterTypeSuccess);
  const deletePrinterTypeSuccess = useSelector(getDeletePrinterTypeSuccess);
  const fetchPrinterTypesFailed = useSelector(getFetchPrinterTypesFailed);
  const fetchSelectPrinterTypesFailed = useSelector(getFetchSelectPrinterTypesFailed);
  const fetchPrinterTypeFailed = useSelector(getFetchPrinterTypeFailed);
  const createPrinterTypeFailed = useSelector(getCreatePrinterTypeFailed);
  const updatePrinterTypeFailed = useSelector(getUpdatePrinterTypeFailed);
  const deletePrinterTypeFailed = useSelector(getDeletePrinterTypeFailed);

  useEffect(() => {
    if (createPrinterTypeSuccess !== null || updatePrinterTypeSuccess !== null || deletePrinterTypeSuccess !== null) {
      showSuccessMessage(createPrinterTypeSuccess ?? updatePrinterTypeSuccess ?? deletePrinterTypeSuccess);
      dispatch(setIsModalOpen(false));

      if (createPrinterTypeSuccess !== null) dispatch(setCreatePrinterTypeSuccess(null));
      if (updatePrinterTypeSuccess !== null) dispatch(setUpdatePrinterTypeSuccess(null));
      if (deletePrinterTypeSuccess !== null) dispatch(setDeletePrinterTypeSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPrinterTypeSuccess, updatePrinterTypeSuccess, deletePrinterTypeSuccess]);

  useEffect(() => {
    if (
      fetchPrinterTypesFailed !== null ||
      fetchSelectPrinterTypesFailed !== null ||
      fetchPrinterTypeFailed !== null ||
      createPrinterTypeFailed !== null ||
      updatePrinterTypeFailed !== null ||
      deletePrinterTypeFailed !== null
    ) {
      showErrorMessage(
        fetchPrinterTypesFailed ??
          fetchSelectPrinterTypesFailed ??
          fetchPrinterTypeFailed ??
          createPrinterTypeFailed ??
          updatePrinterTypeFailed ??
          deletePrinterTypeFailed
      );

      if (fetchPrinterTypesFailed !== null) dispatch(setFetchPrinterTypesFailed(null));
      if (fetchSelectPrinterTypesFailed !== null) dispatch(setFetchSelectPrinterTypesFailed(null));
      if (fetchPrinterTypeFailed !== null) dispatch(setFetchPrinterTypeFailed(null));
      if (createPrinterTypeFailed !== null) dispatch(setCreatePrinterTypeFailed(null));
      if (updatePrinterTypeFailed !== null) dispatch(setUpdatePrinterTypeFailed(null));
      if (deletePrinterTypeFailed !== null) dispatch(setDeletePrinterTypeFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPrinterTypesFailed,
    fetchSelectPrinterTypesFailed,
    fetchPrinterTypeFailed,
    createPrinterTypeFailed,
    updatePrinterTypeFailed,
    deletePrinterTypeFailed,
  ]);

  return <></>;
};

export default PrinterTypePopup;
