import apiService from "./api";

export const downloadReportOrder = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/export/report/orders", params }))
    .data;
export const downloadReportSummary = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/export/report/summaries",
      params,
    })
  ).data;

export const downloadTableQrCode = async (params) =>
  (await apiService.innerGet({ urlPath: "/export/table-qrcode", params })).data;
export const downloadProductBarcode = async (params) =>
  (await apiService.innerGet({ urlPath: "/export/product-barcode", params }))
    .data;

export const downloadCustomerReport = async (params) =>
  await apiService.innerGet({
    responseType: "blob",
    urlPath: "/export/customer",
    params,
  });
export const downloadIngredientReport = async (params) =>
  await apiService.innerGet({
    responseType: "blob",
    urlPath: "/export/ingredient",
    params,
  });
export const downloadIngredientHistoryReport = async (params) =>
  await apiService.innerGet({
    responseType: "blob",
    urlPath: "/export/ingredient-history",
    params,
  });
export const downloadSubscriptionReceipt = async (id, params) =>
  await apiService.innerGet({
    responseType: "blob",
    urlPath: `/pdf/extend-period/receipt/${id}`,
    params,
  });

export const exportPdfOrderInvoice = async (orderId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/export/pdf/orders/${orderId}/invoice/generate`,
      request,
    })
  ).data;
export const exportPdfOrderKitchen = async (orderId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/export/pdf/orders/${orderId}/kitchen/generate`,
      request,
    })
  ).data;
export const exportPdfExtendInvoice = async (extendId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/export/pdf/extends/${extendId}/invoice/generate`,
      request,
    })
  ).data;

export const exportExcelReports = async (request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/export/excel/reports/generate`,
      request,
    })
  ).data;
