import { createSelector } from "reselect";

const selectCustomerSelector = ({ selectCustomer }) => selectCustomer;

export const getCustomers = createSelector(
  [selectCustomerSelector],
  ({ customers }) => customers
);
export const getSearchCustomers = createSelector(
  [selectCustomerSelector],
  ({ searchCustomers }) => searchCustomers
);

export const getIsCustomersHasMore = createSelector(
  [selectCustomerSelector],
  ({ isCustomersHasMore }) => isCustomersHasMore
);
export const getIsSearchCustomersHasMore = createSelector(
  [selectCustomerSelector],
  ({ isSearchCustomersHasMore }) => isSearchCustomersHasMore
);

export const getFetchCustomersParams = createSelector(
  [selectCustomerSelector],
  ({ fetchCustomersParams }) => fetchCustomersParams
);
export const getFetchCustomersLoading = createSelector(
  [selectCustomerSelector],
  ({ fetchCustomersLoading }) => fetchCustomersLoading
);
export const getFetchCustomersSuccess = createSelector(
  [selectCustomerSelector],
  ({ fetchCustomersSuccess }) => fetchCustomersSuccess
);
export const getFetchCustomersFailed = createSelector(
  [selectCustomerSelector],
  ({ fetchCustomersFailed }) => fetchCustomersFailed
);

export const getSearchCustomersParams = createSelector(
  [selectCustomerSelector],
  ({ searchCustomersParams }) => searchCustomersParams
);
export const getSearchCustomersLoading = createSelector(
  [selectCustomerSelector],
  ({ searchCustomersLoading }) => searchCustomersLoading
);
export const getSearchCustomersSuccess = createSelector(
  [selectCustomerSelector],
  ({ searchCustomersSuccess }) => searchCustomersSuccess
);
export const getSearchCustomersFailed = createSelector(
  [selectCustomerSelector],
  ({ searchCustomersFailed }) => searchCustomersFailed
);

export const getAppendCustomersParams = createSelector(
  [selectCustomerSelector],
  ({ appendCustomersParams }) => appendCustomersParams
);
export const getAppendCustomersLoading = createSelector(
  [selectCustomerSelector],
  ({ appendCustomersLoading }) => appendCustomersLoading
);
export const getAppendCustomersSuccess = createSelector(
  [selectCustomerSelector],
  ({ appendCustomersSuccess }) => appendCustomersSuccess
);
export const getAppendCustomersFailed = createSelector(
  [selectCustomerSelector],
  ({ appendCustomersFailed }) => appendCustomersFailed
);

export const getIsFetchCustomersHitted = createSelector(
  [selectCustomerSelector],
  ({ isFetchCustomersHitted }) => isFetchCustomersHitted
);
export const getIsSearchCustomersHitted = createSelector(
  [selectCustomerSelector],
  ({ isSearchCustomersHitted }) => isSearchCustomersHitted
);
export const getIsAppendCustomersHitted = createSelector(
  [selectCustomerSelector],
  ({ isAppendCustomersHitted }) => isAppendCustomersHitted
);
