import apiService from "./api";

export const getSectors = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/public/sectors", params })).data;
export const getSector = async (sectorId) =>
  (await apiService.innerGet({ urlPath: `/v2/public/sectors/${sectorId}` }))
    .data;
export const createSector = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/sectors", request })).data;
export const updateSector = async (sectorId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/sectors/${sectorId}`, request }))
    .data;
export const deleteSector = async (sectorId) =>
  (await apiService.innerDelete({ urlPath: `/v2/sectors/${sectorId}` })).data;
