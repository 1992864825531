import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setAppendSelectPermissionMerchantsFailed,
  setAppendSelectPermissionSubscribersFailed,
  setCreatePermissionFailed,
  setCreatePermissionSuccess,
  setDeletePermissionFailed,
  setDeletePermissionSuccess,
  setFetchPermissionMerchantsFailed,
  setFetchPermissionFailed,
  setFetchPermissionSubscribersFailed,
  setFetchSelectPermissionMerchantsFailed,
  setFetchSelectPermissionSubscribersFailed,
  setUpdatePermissionFailed,
  setUpdatePermissionSuccess,
} from "../../store/permission-merchant/permission-merchant.action";
import {
  getAppendSelectPermissionMerchantsFailed,
  getAppendSelectPermissionSubscribersFailed,
  getCreatePermissionFailed,
  getCreatePermissionSuccess,
  getDeletePermissionFailed,
  getDeletePermissionSuccess,
  getFetchPermissionMerchantsFailed,
  getFetchPermissionFailed,
  getFetchPermissionSubscribersFailed,
  getFetchSelectPermissionMerchantsFailed,
  getFetchSelectPermissionSubscribersFailed,
  getUpdatePermissionFailed,
  getUpdatePermissionSuccess,
} from "../../store/permission-merchant/permission-merchant.selector";

const PermissionPopupMerchant = () => {
  const dispatch = useDispatch();

  const createPermissionSuccess = useSelector(getCreatePermissionSuccess);
  const updatePermissionSuccess = useSelector(getUpdatePermissionSuccess);
  const deletePermissionSuccess = useSelector(getDeletePermissionSuccess);
  const fetchPermissionMerchantsFailed = useSelector(
    getFetchPermissionMerchantsFailed
  );
  const fetchPermissionSubscribersFailed = useSelector(
    getFetchPermissionSubscribersFailed
  );
  const fetchSelectPermissionMerchantsFailed = useSelector(
    getFetchSelectPermissionMerchantsFailed
  );
  const fetchSelectPermissionSubscribersFailed = useSelector(
    getFetchSelectPermissionSubscribersFailed
  );
  const appendSelectPermissionMerchantsFailed = useSelector(
    getAppendSelectPermissionMerchantsFailed
  );
  const appendSelectPermissionSubscribersFailed = useSelector(
    getAppendSelectPermissionSubscribersFailed
  );
  const fetchPermissionFailed = useSelector(getFetchPermissionFailed);
  const createPermissionFailed = useSelector(getCreatePermissionFailed);
  const updatePermissionFailed = useSelector(getUpdatePermissionFailed);
  const deletePermissionFailed = useSelector(getDeletePermissionFailed);

  useEffect(() => {
    if (
      createPermissionSuccess !== null ||
      updatePermissionSuccess !== null ||
      deletePermissionSuccess !== null
    ) {
      showSuccessMessage(
        createPermissionSuccess ??
          updatePermissionSuccess ??
          deletePermissionSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createPermissionSuccess !== null)
        dispatch(setCreatePermissionSuccess(null));
      if (updatePermissionSuccess !== null)
        dispatch(setUpdatePermissionSuccess(null));
      if (deletePermissionSuccess !== null)
        dispatch(setDeletePermissionSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createPermissionSuccess,
    updatePermissionSuccess,
    deletePermissionSuccess,
  ]);

  useEffect(() => {
    if (
      fetchPermissionMerchantsFailed !== null ||
      fetchPermissionSubscribersFailed !== null ||
      fetchSelectPermissionMerchantsFailed !== null ||
      fetchSelectPermissionSubscribersFailed !== null ||
      appendSelectPermissionMerchantsFailed !== null ||
      appendSelectPermissionSubscribersFailed !== null ||
      fetchPermissionFailed !== null ||
      createPermissionFailed !== null ||
      updatePermissionFailed !== null ||
      deletePermissionFailed !== null
    ) {
      showErrorMessage(
        fetchPermissionMerchantsFailed ??
          fetchPermissionSubscribersFailed ??
          fetchSelectPermissionMerchantsFailed ??
          fetchSelectPermissionSubscribersFailed ??
          appendSelectPermissionMerchantsFailed ??
          appendSelectPermissionSubscribersFailed ??
          fetchPermissionFailed ??
          createPermissionFailed ??
          updatePermissionFailed ??
          deletePermissionFailed
      );

      if (fetchPermissionMerchantsFailed !== null)
        dispatch(setFetchPermissionMerchantsFailed(null));
      if (fetchPermissionSubscribersFailed !== null)
        dispatch(setFetchPermissionSubscribersFailed(null));
      if (fetchSelectPermissionMerchantsFailed !== null)
        dispatch(setFetchSelectPermissionMerchantsFailed(null));
      if (fetchSelectPermissionSubscribersFailed !== null)
        dispatch(setFetchSelectPermissionSubscribersFailed(null));
      if (appendSelectPermissionMerchantsFailed !== null)
        dispatch(setAppendSelectPermissionMerchantsFailed(null));
      if (appendSelectPermissionSubscribersFailed !== null)
        dispatch(setAppendSelectPermissionSubscribersFailed(null));
      if (fetchPermissionFailed !== null)
        dispatch(setFetchPermissionFailed(null));
      if (createPermissionFailed !== null)
        dispatch(setCreatePermissionFailed(null));
      if (updatePermissionFailed !== null)
        dispatch(setUpdatePermissionFailed(null));
      if (deletePermissionFailed !== null)
        dispatch(setDeletePermissionFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPermissionMerchantsFailed,
    fetchPermissionSubscribersFailed,
    fetchSelectPermissionMerchantsFailed,
    fetchSelectPermissionSubscribersFailed,
    appendSelectPermissionMerchantsFailed,
    appendSelectPermissionSubscribersFailed,
    fetchPermissionFailed,
    createPermissionFailed,
    updatePermissionFailed,
    deletePermissionFailed,
  ]);

  return <></>;
};

export default PermissionPopupMerchant;
