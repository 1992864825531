import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendPermissionMerchantsFailed,
  setAppendPermissionSubscribersFailed,
  setFetchPermissionMerchantsFailed,
  setFetchPermissionSubscribersFailed,
  setSearchPermissionMerchantsFailed,
  setSearchPermissionSubscribersFailed,
} from "../../store/select-permission-merchant/select-permission-merchant.action";
import {
  getAppendPermissionMerchantsFailed,
  getAppendPermissionSubscribersFailed,
  getFetchPermissionMerchantsFailed,
  getFetchPermissionSubscribersFailed,
  getSearchPermissionMerchantsFailed,
  getSearchPermissionSubscribersFailed,
} from "../../store/select-permission-merchant/select-permission-merchant.selector";

const SelectPermissionMerchantPopup = () => {
  const dispatch = useDispatch();

  const fetchPermissionMerchantsFailed = useSelector(
    getFetchPermissionMerchantsFailed
  );
  const searchPermissionMerchantsFailed = useSelector(
    getSearchPermissionMerchantsFailed
  );
  const appendPermissionMerchantsFailed = useSelector(
    getAppendPermissionMerchantsFailed
  );
  const fetchPermissionSubscribersFailed = useSelector(
    getFetchPermissionSubscribersFailed
  );
  const searchPermissionSubscribersFailed = useSelector(
    getSearchPermissionSubscribersFailed
  );
  const appendPermissionSubscribersFailed = useSelector(
    getAppendPermissionSubscribersFailed
  );

  useEffect(() => {
    if (
      fetchPermissionMerchantsFailed !== null ||
      searchPermissionMerchantsFailed !== null ||
      appendPermissionMerchantsFailed !== null ||
      fetchPermissionSubscribersFailed !== null ||
      searchPermissionSubscribersFailed !== null ||
      appendPermissionSubscribersFailed !== null
    ) {
      showErrorMessage(
        fetchPermissionMerchantsFailed ??
          searchPermissionMerchantsFailed ??
          appendPermissionMerchantsFailed ??
          fetchPermissionSubscribersFailed ??
          searchPermissionSubscribersFailed ??
          appendPermissionSubscribersFailed
      );

      if (fetchPermissionMerchantsFailed !== null)
        dispatch(setFetchPermissionMerchantsFailed(null));
      if (searchPermissionMerchantsFailed !== null)
        dispatch(setSearchPermissionMerchantsFailed(null));
      if (appendPermissionMerchantsFailed !== null)
        dispatch(setAppendPermissionMerchantsFailed(null));
      if (fetchPermissionSubscribersFailed !== null)
        dispatch(setFetchPermissionSubscribersFailed(null));
      if (searchPermissionSubscribersFailed !== null)
        dispatch(setSearchPermissionSubscribersFailed(null));
      if (appendPermissionSubscribersFailed !== null)
        dispatch(setAppendPermissionSubscribersFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchPermissionMerchantsFailed,
    searchPermissionMerchantsFailed,
    appendPermissionMerchantsFailed,
    fetchPermissionSubscribersFailed,
    searchPermissionSubscribersFailed,
    appendPermissionSubscribersFailed,
  ]);

  return <></>;
};

export default SelectPermissionMerchantPopup;
