import apiService from "./api";

export const getUserSubscribers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/users/subscribers", params }))
    .data;
export const getUserSubscriber = async (subscriberId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/users/subscribers/${subscriberId}`,
    })
  ).data;
export const createUserSubscriber = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/users/subscribers", request }))
    .data;
export const updateUserSubscriber = async (subscriberId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/users/subscribers/${subscriberId}`,
      request,
    })
  ).data;
export const deleteUserSubscriber = async (subscriberId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/users/subscribers/${subscriberId}`,
    })
  ).data;
