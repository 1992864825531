import styled from "styled-components";

export const GettingStartedTop = styled.div`
  position: relative;
  display: block;
  margin-bottom: 2.5rem;
`;

export const GettingStartedBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
`;

export const GettingStartedDontShowCheck = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1.35rem;
  min-width: 1.35rem;
  max-width: 1.35rem;
  height: 1.35rem;
  min-height: 1.35rem;
  max-height: 1.35rem;

  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  background-color: ${({ isActive }) => (isActive ? "#2900ee" : "#ffffff")};
  border-color: ${({ isActive }) => (isActive ? "#2900ee" : "#9e9Bab")};

  svg {
    display: ${({ isActive }) => (isActive ? "inline-block" : "none")};
    width: 0.75rem;
    min-width: 0.75rem;
    max-width: 0.75rem;
    height: 0.75rem;
    min-height: 0.75rem;
    max-height: 0.75rem;
    stroke: #ffffff;
    stroke-width: 3;
  }
`;

export const GettingStartedDontShowLabel = styled.span`
  color: #0e072f !important;
`;
