import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setFetchRenewalBranchesFailed,
  setFetchRenewalBranchesSuccess,
  setFetchRenewalMarketFailed,
  setFetchRenewalMarketSuccess,
  setRenewalCalculateFailed,
  setRenewalCalculateSuccess,
  setRenewalCreateFailed,
  setRenewalCreateSuccess,
} from "../../store/renewal-admin/renewal-admin.action";
import {
  getFetchRenewalBranchesFailed,
  getFetchRenewalBranchesSuccess,
  getFetchRenewalMarketFailed,
  getFetchRenewalMarketSuccess,
  getRenewalCalculateFailed,
  getRenewalCalculateSuccess,
  getRenewalCreateFailed,
  getRenewalCreateSuccess,
} from "../../store/renewal-admin/renewal-admin.selector";

const RenewalAdminPopup = () => {
  const dispatch = useDispatch();

  const fetchRenewalMarketSuccess = useSelector(getFetchRenewalMarketSuccess);
  const fetchRenewalBranchesSuccess = useSelector(
    getFetchRenewalBranchesSuccess
  );
  const renewalCalculateSuccess = useSelector(getRenewalCalculateSuccess);
  const renewalCreateSuccess = useSelector(getRenewalCreateSuccess);

  const fetchRenewalMarketFailed = useSelector(getFetchRenewalMarketFailed);
  const fetchRenewalBranchesFailed = useSelector(getFetchRenewalBranchesFailed);
  const renewalCalculateFailed = useSelector(getRenewalCalculateFailed);
  const renewalCreateFailed = useSelector(getRenewalCreateFailed);

  useEffect(() => {
    if (
      fetchRenewalMarketSuccess !== null ||
      fetchRenewalBranchesSuccess !== null ||
      renewalCalculateSuccess !== null ||
      renewalCreateSuccess !== null
    ) {
      if (renewalCreateSuccess !== null) {
        showSuccessMessage(renewalCreateSuccess);
        dispatch(setIsModalOpen(false));
      }

      if (fetchRenewalMarketSuccess !== null)
        dispatch(setFetchRenewalMarketSuccess(null));
      if (fetchRenewalBranchesSuccess !== null)
        dispatch(setFetchRenewalBranchesSuccess(null));
      if (renewalCalculateSuccess !== null)
        dispatch(setRenewalCalculateSuccess(null));
      if (renewalCreateSuccess !== null)
        dispatch(setRenewalCreateSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalMarketSuccess,
    fetchRenewalBranchesSuccess,
    renewalCalculateSuccess,
    renewalCreateSuccess,
  ]);

  useEffect(() => {
    if (
      fetchRenewalMarketFailed !== null ||
      fetchRenewalBranchesFailed !== null ||
      renewalCalculateFailed !== null ||
      renewalCreateFailed !== null
    ) {
      showErrorMessage(
        fetchRenewalMarketFailed ??
          fetchRenewalBranchesFailed ??
          renewalCalculateFailed ??
          renewalCreateFailed
      );

      if (fetchRenewalMarketFailed !== null)
        dispatch(setFetchRenewalMarketFailed(null));
      if (fetchRenewalBranchesFailed !== null)
        dispatch(setFetchRenewalBranchesFailed(null));
      if (renewalCalculateFailed !== null)
        dispatch(setRenewalCalculateFailed(null));
      if (renewalCreateFailed !== null) dispatch(setRenewalCreateFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalMarketFailed,
    fetchRenewalBranchesFailed,
    renewalCalculateFailed,
    renewalCreateFailed,
  ]);

  return <></>;
};

export default RenewalAdminPopup;
