import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateCustomerFailed,
  setCreateCustomerSuccess,
  setFetchCustomerFailed,
  setFetchCustomersFailed,
  setFetchCustomersSuccess,
  setFetchCustomerSuccess,
  setFetchSearchCustomersFailed,
  setFetchSearchCustomersSuccess,
  setUpdateCustomerFailed,
  setUpdateCustomerSuccess,
} from "../../store/customer/customer.action";
import {
  getCreateCustomerFailed,
  getCreateCustomerSuccess,
  getFetchCustomerFailed,
  getFetchCustomersFailed,
  getFetchCustomersSuccess,
  getFetchCustomerSuccess,
  getFetchSearchCustomersFailed,
  getFetchSearchCustomersSuccess,
  getUpdateCustomerFailed,
  getUpdateCustomerSuccess,
} from "../../store/customer/customer.selector";

const CustomerPopup = () => {
  const dispatch = useDispatch();

  const fetchCustomersSuccess = useSelector(getFetchCustomersSuccess);
  const fetchSearchCustomersSuccess = useSelector(
    getFetchSearchCustomersSuccess
  );
  const fetchCustomerSuccess = useSelector(getFetchCustomerSuccess);
  const createCustomerSuccess = useSelector(getCreateCustomerSuccess);
  const updateCustomerSuccess = useSelector(getUpdateCustomerSuccess);

  const fetchCustomersFailed = useSelector(getFetchCustomersFailed);
  const fetchSearchCustomersFailed = useSelector(getFetchSearchCustomersFailed);
  const fetchCustomerFailed = useSelector(getFetchCustomerFailed);
  const createCustomerFailed = useSelector(getCreateCustomerFailed);
  const updateCustomerFailed = useSelector(getUpdateCustomerFailed);

  useEffect(() => {
    if (
      fetchCustomersSuccess !== null ||
      fetchSearchCustomersSuccess !== null ||
      fetchCustomerSuccess !== null ||
      createCustomerSuccess !== null ||
      updateCustomerSuccess !== null
    ) {
      if (createCustomerSuccess !== null || updateCustomerSuccess !== null) {
        showSuccessMessage(createCustomerSuccess ?? updateCustomerSuccess);
        dispatch(setIsModalOpen(false));
      }

      if (fetchCustomersSuccess !== null)
        dispatch(setFetchCustomersSuccess(null));
      if (fetchSearchCustomersSuccess !== null)
        dispatch(setFetchSearchCustomersSuccess(null));
      if (fetchCustomerSuccess !== null)
        dispatch(setFetchCustomerSuccess(null));
      if (createCustomerSuccess !== null)
        dispatch(setCreateCustomerSuccess(null));
      if (updateCustomerSuccess !== null)
        dispatch(setUpdateCustomerSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchCustomersSuccess,
    fetchSearchCustomersSuccess,
    fetchCustomerSuccess,
    createCustomerSuccess,
    updateCustomerSuccess,
  ]);

  useEffect(() => {
    if (
      fetchCustomersFailed !== null ||
      fetchSearchCustomersFailed !== null ||
      fetchCustomerFailed !== null ||
      createCustomerFailed !== null ||
      updateCustomerFailed !== null
    ) {
      if (
        fetchCustomersFailed !== null ||
        fetchCustomerFailed !== null ||
        createCustomerFailed !== null ||
        updateCustomerFailed !== null
      ) {
        showErrorMessage(
          fetchCustomersFailed ??
            fetchCustomerFailed ??
            createCustomerFailed ??
            updateCustomerFailed
        );
      }

      if (fetchCustomersFailed !== null)
        dispatch(setFetchCustomersFailed(null));
      if (fetchSearchCustomersFailed !== null)
        dispatch(setFetchSearchCustomersFailed(null));
      if (fetchCustomerFailed !== null) dispatch(setFetchCustomerFailed(null));
      if (createCustomerFailed !== null)
        dispatch(setCreateCustomerFailed(null));
      if (updateCustomerFailed !== null)
        dispatch(setUpdateCustomerFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchCustomersFailed,
    fetchSearchCustomersFailed,
    fetchCustomerFailed,
    createCustomerFailed,
    updateCustomerFailed,
  ]);

  return <></>;
};

export default CustomerPopup;
