import { createSelector } from "reselect";

const selectIngredientSelector = ({ selectIngredient }) => selectIngredient;

export const getIngredients = createSelector(
  [selectIngredientSelector],
  ({ ingredients }) => ingredients
);
export const getSearchIngredients = createSelector(
  [selectIngredientSelector],
  ({ searchIngredients }) => searchIngredients
);

export const getIsIngredientsHasMore = createSelector(
  [selectIngredientSelector],
  ({ isIngredientsHasMore }) => isIngredientsHasMore
);
export const getIsSearchIngredientsHasMore = createSelector(
  [selectIngredientSelector],
  ({ isSearchIngredientsHasMore }) => isSearchIngredientsHasMore
);

export const getFetchIngredientsParams = createSelector(
  [selectIngredientSelector],
  ({ fetchIngredientsParams }) => fetchIngredientsParams
);
export const getFetchIngredientsLoading = createSelector(
  [selectIngredientSelector],
  ({ fetchIngredientsLoading }) => fetchIngredientsLoading
);
export const getFetchIngredientsSuccess = createSelector(
  [selectIngredientSelector],
  ({ fetchIngredientsSuccess }) => fetchIngredientsSuccess
);
export const getFetchIngredientsFailed = createSelector(
  [selectIngredientSelector],
  ({ fetchIngredientsFailed }) => fetchIngredientsFailed
);

export const getSearchIngredientsParams = createSelector(
  [selectIngredientSelector],
  ({ searchIngredientsParams }) => searchIngredientsParams
);
export const getSearchIngredientsLoading = createSelector(
  [selectIngredientSelector],
  ({ searchIngredientsLoading }) => searchIngredientsLoading
);
export const getSearchIngredientsSuccess = createSelector(
  [selectIngredientSelector],
  ({ searchIngredientsSuccess }) => searchIngredientsSuccess
);
export const getSearchIngredientsFailed = createSelector(
  [selectIngredientSelector],
  ({ searchIngredientsFailed }) => searchIngredientsFailed
);

export const getAppendIngredientsParams = createSelector(
  [selectIngredientSelector],
  ({ appendIngredientsParams }) => appendIngredientsParams
);
export const getAppendIngredientsLoading = createSelector(
  [selectIngredientSelector],
  ({ appendIngredientsLoading }) => appendIngredientsLoading
);
export const getAppendIngredientsSuccess = createSelector(
  [selectIngredientSelector],
  ({ appendIngredientsSuccess }) => appendIngredientsSuccess
);
export const getAppendIngredientsFailed = createSelector(
  [selectIngredientSelector],
  ({ appendIngredientsFailed }) => appendIngredientsFailed
);

export const getIsFetchIngredientsHitted = createSelector(
  [selectIngredientSelector],
  ({ isFetchIngredientsHitted }) => isFetchIngredientsHitted
);
export const getIsSearchIngredientsHitted = createSelector(
  [selectIngredientSelector],
  ({ isSearchIngredientsHitted }) => isSearchIngredientsHitted
);
export const getIsAppendIngredientsHitted = createSelector(
  [selectIngredientSelector],
  ({ isAppendIngredientsHitted }) => isAppendIngredientsHitted
);
