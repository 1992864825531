import { createSelector } from "reselect";

const orderProductSelector = ({ orderProduct }) => orderProduct;

export const getOrderProduct = createSelector([orderProductSelector], ({ orderProduct }) => orderProduct);

export const getFetchOrderProductLoading = createSelector(
  [orderProductSelector],
  ({ fetchOrderProductLoading }) => fetchOrderProductLoading
);

export const getFetchOrderProductSuccess = createSelector(
  [orderProductSelector],
  ({ fetchOrderProductSuccess }) => fetchOrderProductSuccess
);

export const getFetchOrderProductFailed = createSelector(
  [orderProductSelector],
  ({ fetchOrderProductFailed }) => fetchOrderProductFailed
);

export const getUpdateOrderProductLoading = createSelector(
  [orderProductSelector],
  ({ updateOrderProductLoading }) => updateOrderProductLoading
);

export const getUpdateOrderProductSuccess = createSelector(
  [orderProductSelector],
  ({ updateOrderProductSuccess }) => updateOrderProductSuccess
);

export const getUpdateOrderProductFailed = createSelector(
  [orderProductSelector],
  ({ updateOrderProductFailed }) => updateOrderProductFailed
);

export const getIsFetchOrderProductHitted = createSelector(
  [orderProductSelector],
  ({ isFetchOrderProductHitted }) => isFetchOrderProductHitted
);

export const getIsUpdateOrderProductHitted = createSelector(
  [orderProductSelector],
  ({ isUpdateOrderProductHitted }) => isUpdateOrderProductHitted
);
