import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendCustomersFailed,
  setFetchCustomersFailed,
  setSearchCustomersFailed,
} from "../../store/select-customer/select-customer.action";
import {
  getAppendCustomersFailed,
  getFetchCustomersFailed,
  getSearchCustomersFailed,
} from "../../store/select-customer/select-customer.selector";

const SelectCustomerPopup = () => {
  const dispatch = useDispatch();

  const fetchCustomersFailed = useSelector(getFetchCustomersFailed);
  const searchCustomersFailed = useSelector(getSearchCustomersFailed);
  const appendCustomersFailed = useSelector(getAppendCustomersFailed);

  useEffect(() => {
    if (
      fetchCustomersFailed !== null ||
      searchCustomersFailed !== null ||
      appendCustomersFailed !== null
    ) {
      showErrorMessage(
        fetchCustomersFailed ?? searchCustomersFailed ?? appendCustomersFailed
      );

      if (fetchCustomersFailed !== null)
        dispatch(setFetchCustomersFailed(null));
      if (searchCustomersFailed !== null)
        dispatch(setSearchCustomersFailed(null));
      if (appendCustomersFailed !== null)
        dispatch(setAppendCustomersFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomersFailed, searchCustomersFailed, appendCustomersFailed]);

  return <></>;
};

export default SelectCustomerPopup;
