import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { setCompletionType } from "../../utils/storage.utils";
import { isEmpty } from "../../utils/validation.utils";

import { INTEGRATION_COMPETION_TYPES } from "../../constants/integration-completion.constant";

import {
  getCurrentMarketId,
  getIsMarketAdministrator,
  getIsRightToLeft,
} from "../../store/global/global.selector";

import {
  ApplicationCompletionTrackItemContainer,
  ApplicationCompletionTrackItemIcon,
  ApplicationCompletionTrackItemInfo,
  ApplicationCompletionTrackItemLabel,
  ApplicationCompletionTrackItemLink,
  ApplicationCompletionTrackListContainer,
} from "./application-completion-track-list.style";

import { LinkSquare02Icon } from "hugeicons-react";
import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";

export const ApplicationCompletionTrackItem = ({ completion }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isMarketAdministrator = useSelector(getIsMarketAdministrator);
  const currentMarketId = useSelector(getCurrentMarketId);

  const { label, value, urlPath, required } = completion ?? {};

  const handleGoToPage = () => {
    setCompletionType(INTEGRATION_COMPETION_TYPES.COMPLETION_FAI);
    navigate(
      isMarketAdministrator && !isEmpty(currentMarketId)
        ? `/subscriber/${currentMarketId}${urlPath}`
        : urlPath
    );
  };

  return (
    <ApplicationCompletionTrackItemContainer>
      <ApplicationCompletionTrackItemIcon isActive={!isEmpty(value)}>
        <ConfirmSvg />
      </ApplicationCompletionTrackItemIcon>
      <ApplicationCompletionTrackItemInfo>
        {!isEmpty(label) ? label : t("No information")}
      </ApplicationCompletionTrackItemInfo>
      <ApplicationCompletionTrackItemLabel
        isRequired={parseToBoolean(required)}
      >
        {parseToBoolean(required) ? t("Required") : t("Optional")}
      </ApplicationCompletionTrackItemLabel>
      {isEmpty(value) && (
        <ApplicationCompletionTrackItemLink onClick={handleGoToPage}>
          <LinkSquare02Icon />
        </ApplicationCompletionTrackItemLink>
      )}
    </ApplicationCompletionTrackItemContainer>
  );
};

const ApplicationCompletionTrackList = ({ completions = [] }) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <ApplicationCompletionTrackListContainer isRightToLeft={isRightToLeft}>
      {completions.map((completion, index) => (
        <ApplicationCompletionTrackItem key={index} completion={completion} />
      ))}
    </ApplicationCompletionTrackListContainer>
  );
};

export default ApplicationCompletionTrackList;
