import { useEffect, useMemo, useState } from "react";

import { parseToBoolean, parseToFloat } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import {
  ApplicationCompletionTrackProgressBar,
  ApplicationCompletionTrackProgressContainer,
  ApplicationCompletionTrackProgressLabel,
} from "./application-completion-track-progress.style";

const ApplicationCompletionTrackProgress = ({ completions: data = [] }) => {
  const completions = useMemo(() => data, [data]);

  const [totalPercent, setTotalPercent] = useState(0);

  useEffect(() => {
    let currentCount = 0;
    let overallCount = 0;

    for (const completion of completions) {
      const { value, required } = completion;

      if (parseToBoolean(required)) {
        if (!isEmpty(value)) currentCount++;
        overallCount++;
      }
    }

    setTotalPercent(parseToFloat((currentCount / overallCount) * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completions]);

  return (
    <ApplicationCompletionTrackProgressContainer>
      <ApplicationCompletionTrackProgressBar percent={totalPercent} />
      <ApplicationCompletionTrackProgressLabel>
        {`${totalPercent.toFixed(1)}%`}
      </ApplicationCompletionTrackProgressLabel>
    </ApplicationCompletionTrackProgressContainer>
  );
};

export default ApplicationCompletionTrackProgress;
