import apiService from "./api";

export const getBanners = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/banners", params })).data;
export const getBanner = async (bannerId) =>
  (await apiService.innerGet({ urlPath: `/v2/banners/${bannerId}` })).data;
export const createBanner = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/banners", request })).data;
export const updateBanner = async (bannerId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/banners/${bannerId}`, request }))
    .data;
export const deleteBanner = async (bannerId) =>
  (await apiService.innerDelete({ urlPath: `/v2/banners/${bannerId}` })).data;
