import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateKitchenGroupFailed,
  setCreateKitchenGroupSuccess,
  setDeleteKitchenGroupFailed,
  setDeleteKitchenGroupSuccess,
  setFetchKitchenGroupFailed,
  setFetchKitchenGroupsFailed,
  setUpdateKitchenGroupFailed,
  setUpdateKitchenGroupSuccess,
} from "../../store/kitchen-group/kitchen-group.action";
import {
  getCreateKitchenGroupFailed,
  getCreateKitchenGroupSuccess,
  getDeleteKitchenGroupFailed,
  getDeleteKitchenGroupSuccess,
  getFetchKitchenGroupFailed,
  getFetchKitchenGroupsFailed,
  getUpdateKitchenGroupFailed,
  getUpdateKitchenGroupSuccess,
} from "../../store/kitchen-group/kitchen-group.selector";
import {
  setUpdateOrderProductFailed,
  setUpdateOrderProductSuccess,
} from "../../store/order-product/order-product.action";
import {
  getUpdateOrderProductFailed,
  getUpdateOrderProductSuccess,
} from "../../store/order-product/order-product.selector";

const KitchenPopup = () => {
  const dispatch = useDispatch();

  const createKitchenGroupSuccess = useSelector(getCreateKitchenGroupSuccess);
  const updateKitchenGroupSuccess = useSelector(getUpdateKitchenGroupSuccess);
  const deleteKitchenGroupSuccess = useSelector(getDeleteKitchenGroupSuccess);
  const fetchKitchenGroupsFailed = useSelector(getFetchKitchenGroupsFailed);
  const fetchKitchenGroupFailed = useSelector(getFetchKitchenGroupFailed);
  const createKitchenGroupFailed = useSelector(getCreateKitchenGroupFailed);
  const updateKitchenGroupFailed = useSelector(getUpdateKitchenGroupFailed);
  const deleteKitchenGroupFailed = useSelector(getDeleteKitchenGroupFailed);
  const updateOrderProductSuccess = useSelector(getUpdateOrderProductSuccess);
  const updateOrderProductFailed = useSelector(getUpdateOrderProductFailed);

  useEffect(() => {
    if (
      createKitchenGroupSuccess !== null ||
      updateKitchenGroupSuccess !== null ||
      deleteKitchenGroupSuccess !== null ||
      updateOrderProductSuccess !== null
    ) {
      showSuccessMessage(
        createKitchenGroupSuccess ?? updateKitchenGroupSuccess ?? deleteKitchenGroupSuccess ?? updateOrderProductSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createKitchenGroupSuccess !== null) dispatch(setCreateKitchenGroupSuccess(null));
      if (updateKitchenGroupSuccess !== null) dispatch(setUpdateKitchenGroupSuccess(null));
      if (deleteKitchenGroupSuccess !== null) dispatch(setDeleteKitchenGroupSuccess(null));
      if (updateOrderProductSuccess !== null) dispatch(setUpdateOrderProductSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createKitchenGroupSuccess, updateKitchenGroupSuccess, deleteKitchenGroupSuccess, updateOrderProductSuccess]);

  useEffect(() => {
    if (
      fetchKitchenGroupsFailed !== null ||
      fetchKitchenGroupFailed !== null ||
      createKitchenGroupFailed !== null ||
      updateKitchenGroupFailed !== null ||
      deleteKitchenGroupFailed !== null ||
      updateOrderProductFailed !== null
    ) {
      showErrorMessage(
        fetchKitchenGroupsFailed ??
          fetchKitchenGroupFailed ??
          createKitchenGroupFailed ??
          updateKitchenGroupFailed ??
          deleteKitchenGroupFailed ??
          updateOrderProductFailed
      );

      if (fetchKitchenGroupsFailed !== null) dispatch(setFetchKitchenGroupsFailed(null));
      if (fetchKitchenGroupFailed !== null) dispatch(setFetchKitchenGroupFailed(null));
      if (createKitchenGroupFailed !== null) dispatch(setCreateKitchenGroupFailed(null));
      if (updateKitchenGroupFailed !== null) dispatch(setUpdateKitchenGroupFailed(null));
      if (deleteKitchenGroupFailed !== null) dispatch(setDeleteKitchenGroupFailed(null));
      if (updateOrderProductFailed !== null) dispatch(setUpdateOrderProductFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchKitchenGroupsFailed,
    fetchKitchenGroupFailed,
    createKitchenGroupFailed,
    updateKitchenGroupFailed,
    deleteKitchenGroupFailed,
    updateOrderProductFailed,
  ]);

  return <></>;
};

export default KitchenPopup;
