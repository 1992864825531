import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ArrayExtension } from "../utils/extension.utils";
import { USER_ROLES } from "../constants/user.constant";
import { isEmpty } from "../utils/validation.utils";

import { PERMISSION_ADMIN_PAGES } from "../constants/permission-admin.constant";
import { PERMISSION_MERCHANT_PAGES } from "../constants/permission-merchant.constant";

import { setCurrentMarketId } from "../store/global/global.action";
import {
  getFetchInitializeLoading,
  getIsHasActivePlan,
  getIsMarketAdministrator,
  getMasterPermitPages,
  getSecondPermitPages,
} from "../store/global/global.selector";
import { getUser } from "../store/authentication/authentication.selector";

import Loading from "./loading/loading.route";
import NotFound from "./not-found/not-found.route";

export const MiddlewareMain = () => {
  const { marketId: paramsMarketId } = useParams();
  const dispatch = useDispatch();

  const fetchInitializeLoading = useSelector(getFetchInitializeLoading);
  const authMarketId = useSelector(getUser)?.userable?.market_id;

  useEffect(() => {
    const currentMarketId = !isEmpty(paramsMarketId)
      ? paramsMarketId
      : !isEmpty(authMarketId)
      ? authMarketId
      : null;

    dispatch(setCurrentMarketId(currentMarketId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId, authMarketId]);

  if (fetchInitializeLoading) return <Loading />;

  return <Outlet />;
};

export const MiddlewareAuthorize = ({ keys }) => {
  const isHasActivePlan = useSelector(getIsHasActivePlan);
  const authRole = useSelector(getUser)?.role;

  const masterPermitPages = useSelector(getMasterPermitPages);

  if (
    !ArrayExtension.getHasSameValue(masterPermitPages, keys) ||
    (!isHasActivePlan && authRole === USER_ROLES.USER_SUBSCRIBER)
  ) {
    return <NotFound />;
  }

  return <Outlet />;
};

const getSubscriberPageKey = (role) =>
  ({
    [USER_ROLES.USER_ADMIN]: PERMISSION_ADMIN_PAGES.PAGE_SUBSCRIBER,
    [USER_ROLES.USER_MERCHANT]: PERMISSION_MERCHANT_PAGES.PAGE_SUBSCRIBER,
  }?.[role]);

export const MiddlewareSubscriber = ({ keys }) => {
  const isMarketAdministrator = useSelector(getIsMarketAdministrator);
  const masterPermitPages = useSelector(getMasterPermitPages);
  const secondPermitPages = useSelector(getSecondPermitPages);
  const authRole = useSelector(getUser)?.role;

  const pageKey = getSubscriberPageKey(authRole);

  const isPageValid =
    isMarketAdministrator &&
    masterPermitPages.includes(pageKey) &&
    (!ArrayExtension.getHasSameValue(secondPermitPages, keys) ||
      isEmpty(secondPermitPages));

  if (!isPageValid) return <NotFound />;

  return <Outlet />;
};
