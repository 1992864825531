import apiService from "./api";

export const getPaymentDevices = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/payment-devices", params })).data;
export const getPaymentDevice = async (deviceId) =>
  (await apiService.innerGet({ urlPath: `/v2/payment-devices/${deviceId}` }))
    .data;
export const createPaymentDevice = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/payment-devices", request }))
    .data;
export const updatePaymentDevice = async (deviceId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/payment-devices/${deviceId}`,
      request,
    })
  ).data;
export const deletePaymentDevice = async (deviceId) =>
  (await apiService.innerDelete({ urlPath: `/v2/payment-devices/${deviceId}` }))
    .data;
