import apiService from "./api";

export const getTables = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/tables", params })).data;
export const getTable = async (tableId) =>
  (await apiService.innerGet({ urlPath: `/v2/tables/${tableId}` })).data;
export const createTable = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/tables", request })).data;
export const updateTable = async (tableId, request) =>
  (await apiService.innerPost({ urlPath: `/v2/tables/${tableId}`, request }))
    .data;
export const deleteTable = async (tableId) =>
  (await apiService.innerDelete({ urlPath: `/v2/tables/${tableId}` })).data;

export const multipleCreateTable = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/tables/multiple", request }))
    .data;
