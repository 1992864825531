import apiService from "./api";

export const getOrders = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/orders", params })).data;
export const getOrder = async (orderId) =>
  (await apiService.innerGet({ urlPath: `/v2/orders/${orderId}` })).data;
export const calculateOrder = async (request, params) =>
  (await apiService.innerPost({ urlPath: "/v2/orders/calc", request, params }))
    .data;
export const createOrder = async (request, params) =>
  (await apiService.innerPost({ urlPath: "/v2/orders", request, params })).data;
export const updateOrder = async (orderId, request, params) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/orders/${orderId}`,
      request,
      params,
    })
  ).data;
export const deleteOrder = async (orderId) =>
  (await apiService.innerDelete({ urlPath: `/order/${orderId}` })).data;
export const checkoutOrder = async (orderId, request, params) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/orders/${orderId}/checkout`,
      request,
      params,
    })
  ).data;
export const returnOrder = async (orderId, request, params) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/orders/${orderId}/return`,
      request,
      params,
    })
  ).data;
