import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateOrUpdateScheduleFailed,
  setCreateOrUpdateScheduleSuccess,
  setFetchSchedulesFailed,
} from "../../store/schedule/schedule.action";
import {
  getCreateOrUpdateScheduleFailed,
  getCreateOrUpdateScheduleSuccess,
  getFetchSchedulesFailed,
} from "../../store/schedule/schedule.selector";

const SchedulePopup = () => {
  const dispatch = useDispatch();

  const createOrUpdateScheduleSuccess = useSelector(getCreateOrUpdateScheduleSuccess);
  const fetchSchedulesFailed = useSelector(getFetchSchedulesFailed);
  const createOrUpdateScheduleFailed = useSelector(getCreateOrUpdateScheduleFailed);

  useEffect(() => {
    if (createOrUpdateScheduleSuccess !== null) {
      showSuccessMessage(createOrUpdateScheduleSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setCreateOrUpdateScheduleSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrUpdateScheduleSuccess]);

  useEffect(() => {
    if (fetchSchedulesFailed !== null || createOrUpdateScheduleFailed !== null) {
      showErrorMessage(fetchSchedulesFailed ?? createOrUpdateScheduleFailed);

      if (fetchSchedulesFailed !== null) dispatch(setFetchSchedulesFailed(null));
      if (createOrUpdateScheduleFailed !== null) dispatch(setCreateOrUpdateScheduleFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSchedulesFailed, createOrUpdateScheduleFailed]);

  return <></>;
};

export default SchedulePopup;
