import { createSelector } from "reselect";

const selectPermissionMerchantSelector = ({ selectPermissionMerchant }) =>
  selectPermissionMerchant;

export const getPermissionMerchants = createSelector(
  [selectPermissionMerchantSelector],
  ({ permissionMerchants }) => permissionMerchants
);
export const getPermissionSubscribers = createSelector(
  [selectPermissionMerchantSelector],
  ({ permissionSubscribers }) => permissionSubscribers
);
export const getSearchPermissionMerchants = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionMerchants }) => searchPermissionMerchants
);
export const getSearchPermissionSubscribers = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionSubscribers }) => searchPermissionSubscribers
);

export const getIsPermissionMerchantsHasMore = createSelector(
  [selectPermissionMerchantSelector],
  ({ isPermissionMerchantsHasMore }) => isPermissionMerchantsHasMore
);
export const getIsPermissionSubscribersHasMore = createSelector(
  [selectPermissionMerchantSelector],
  ({ isPermissionSubscribersHasMore }) => isPermissionSubscribersHasMore
);
export const getIsSearchPermissionMerchantsHasMore = createSelector(
  [selectPermissionMerchantSelector],
  ({ isSearchPermissionMerchantsHasMore }) => isSearchPermissionMerchantsHasMore
);
export const getIsSearchPermissionSubscribersHasMore = createSelector(
  [selectPermissionMerchantSelector],
  ({ isSearchPermissionSubscribersHasMore }) =>
    isSearchPermissionSubscribersHasMore
);

export const getFetchPermissionMerchantsParams = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionMerchantsParams }) => fetchPermissionMerchantsParams
);
export const getFetchPermissionMerchantsLoading = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionMerchantsLoading }) => fetchPermissionMerchantsLoading
);
export const getFetchPermissionMerchantsSuccess = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionMerchantsSuccess }) => fetchPermissionMerchantsSuccess
);
export const getFetchPermissionMerchantsFailed = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionMerchantsFailed }) => fetchPermissionMerchantsFailed
);

export const getFetchPermissionSubscribersParams = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionSubscribersParams }) => fetchPermissionSubscribersParams
);
export const getFetchPermissionSubscribersLoading = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionSubscribersLoading }) => fetchPermissionSubscribersLoading
);
export const getFetchPermissionSubscribersSuccess = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionSubscribersSuccess }) => fetchPermissionSubscribersSuccess
);
export const getFetchPermissionSubscribersFailed = createSelector(
  [selectPermissionMerchantSelector],
  ({ fetchPermissionSubscribersFailed }) => fetchPermissionSubscribersFailed
);

export const getSearchPermissionMerchantsParams = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionMerchantsParams }) => searchPermissionMerchantsParams
);
export const getSearchPermissionMerchantsLoading = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionMerchantsLoading }) => searchPermissionMerchantsLoading
);
export const getSearchPermissionMerchantsSuccess = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionMerchantsSuccess }) => searchPermissionMerchantsSuccess
);
export const getSearchPermissionMerchantsFailed = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionMerchantsFailed }) => searchPermissionMerchantsFailed
);

export const getSearchPermissionSubscribersParams = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionSubscribersParams }) => searchPermissionSubscribersParams
);
export const getSearchPermissionSubscribersLoading = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionSubscribersLoading }) => searchPermissionSubscribersLoading
);
export const getSearchPermissionSubscribersSuccess = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionSubscribersSuccess }) => searchPermissionSubscribersSuccess
);
export const getSearchPermissionSubscribersFailed = createSelector(
  [selectPermissionMerchantSelector],
  ({ searchPermissionSubscribersFailed }) => searchPermissionSubscribersFailed
);

export const getAppendPermissionMerchantsParams = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionMerchantsParams }) => appendPermissionMerchantsParams
);
export const getAppendPermissionMerchantsLoading = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionMerchantsLoading }) => appendPermissionMerchantsLoading
);
export const getAppendPermissionMerchantsSuccess = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionMerchantsSuccess }) => appendPermissionMerchantsSuccess
);
export const getAppendPermissionMerchantsFailed = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionMerchantsFailed }) => appendPermissionMerchantsFailed
);

export const getAppendPermissionSubscribersParams = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionSubscribersParams }) => appendPermissionSubscribersParams
);
export const getAppendPermissionSubscribersLoading = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionSubscribersLoading }) => appendPermissionSubscribersLoading
);
export const getAppendPermissionSubscribersSuccess = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionSubscribersSuccess }) => appendPermissionSubscribersSuccess
);
export const getAppendPermissionSubscribersFailed = createSelector(
  [selectPermissionMerchantSelector],
  ({ appendPermissionSubscribersFailed }) => appendPermissionSubscribersFailed
);

export const getIsFetchPermissionMerchantsHitted = createSelector(
  [selectPermissionMerchantSelector],
  ({ isFetchPermissionMerchantsHitted }) => isFetchPermissionMerchantsHitted
);
export const getIsFetchPermissionSubscribersHitted = createSelector(
  [selectPermissionMerchantSelector],
  ({ isFetchPermissionSubscribersHitted }) => isFetchPermissionSubscribersHitted
);
export const getIsSearchPermissionMerchantsHitted = createSelector(
  [selectPermissionMerchantSelector],
  ({ isSearchPermissionMerchantsHitted }) => isSearchPermissionMerchantsHitted
);
export const getIsSearchPermissionSubscribersHitted = createSelector(
  [selectPermissionMerchantSelector],
  ({ isSearchPermissionSubscribersHitted }) =>
    isSearchPermissionSubscribersHitted
);
export const getIsAppendPermissionMerchantsHitted = createSelector(
  [selectPermissionMerchantSelector],
  ({ isAppendPermissionMerchantsHitted }) => isAppendPermissionMerchantsHitted
);
export const getIsAppendPermissionSubscribersHitted = createSelector(
  [selectPermissionMerchantSelector],
  ({ isAppendPermissionSubscribersHitted }) =>
    isAppendPermissionSubscribersHitted
);
