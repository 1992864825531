import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateUserSubscriberFailed,
  setCreateUserSubscriberSuccess,
  setDeleteUserSubscriberFailed,
  setDeleteUserSubscriberSuccess,
  setFetchUserSubscriberFailed,
  setFetchUserSubscribersFailed,
  setUpdateUserSubscriberFailed,
  setUpdateUserSubscriberSuccess,
} from "../../store/user-subscriber/user-subscriber.action";
import {
  getCreateUserSubscriberFailed,
  getCreateUserSubscriberSuccess,
  getDeleteUserSubscriberFailed,
  getDeleteUserSubscriberSuccess,
  getFetchUserSubscriberFailed,
  getFetchUserSubscribersFailed,
  getUpdateUserSubscriberFailed,
  getUpdateUserSubscriberSuccess,
} from "../../store/user-subscriber/user-subscriber.selector";

const UserSubscriberPopup = () => {
  const dispatch = useDispatch();

  const createUserSubscriberSuccess = useSelector(
    getCreateUserSubscriberSuccess
  );
  const updateUserSubscriberSuccess = useSelector(
    getUpdateUserSubscriberSuccess
  );
  const deleteUserSubscriberSuccess = useSelector(
    getDeleteUserSubscriberSuccess
  );
  const fetchUserSubscribersFailed = useSelector(getFetchUserSubscribersFailed);
  const fetchUserSubscriberFailed = useSelector(getFetchUserSubscriberFailed);
  const createUserSubscriberFailed = useSelector(getCreateUserSubscriberFailed);
  const updateUserSubscriberFailed = useSelector(getUpdateUserSubscriberFailed);
  const deleteUserSubscriberFailed = useSelector(getDeleteUserSubscriberFailed);

  useEffect(() => {
    if (
      createUserSubscriberSuccess !== null ||
      updateUserSubscriberSuccess !== null ||
      deleteUserSubscriberSuccess !== null
    ) {
      showSuccessMessage(
        createUserSubscriberSuccess ??
          updateUserSubscriberSuccess ??
          deleteUserSubscriberSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createUserSubscriberSuccess !== null)
        dispatch(setCreateUserSubscriberSuccess(null));
      if (updateUserSubscriberSuccess !== null)
        dispatch(setUpdateUserSubscriberSuccess(null));
      if (deleteUserSubscriberSuccess !== null)
        dispatch(setDeleteUserSubscriberSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createUserSubscriberSuccess,
    updateUserSubscriberSuccess,
    deleteUserSubscriberSuccess,
  ]);

  useEffect(() => {
    if (
      fetchUserSubscribersFailed !== null ||
      fetchUserSubscriberFailed !== null ||
      createUserSubscriberFailed !== null ||
      updateUserSubscriberFailed !== null ||
      deleteUserSubscriberFailed !== null
    ) {
      showErrorMessage(
        fetchUserSubscribersFailed ??
          fetchUserSubscriberFailed ??
          createUserSubscriberFailed ??
          updateUserSubscriberFailed ??
          deleteUserSubscriberFailed
      );

      if (fetchUserSubscribersFailed !== null)
        dispatch(setFetchUserSubscribersFailed(null));
      if (fetchUserSubscriberFailed !== null)
        dispatch(setFetchUserSubscriberFailed(null));
      if (createUserSubscriberFailed !== null)
        dispatch(setCreateUserSubscriberFailed(null));
      if (updateUserSubscriberFailed !== null)
        dispatch(setUpdateUserSubscriberFailed(null));
      if (deleteUserSubscriberFailed !== null)
        dispatch(setDeleteUserSubscriberFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUserSubscribersFailed,
    fetchUserSubscriberFailed,
    createUserSubscriberFailed,
    updateUserSubscriberFailed,
    deleteUserSubscriberFailed,
  ]);

  return <></>;
};

export default UserSubscriberPopup;
